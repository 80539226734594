import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

import ADAUtils from "../../vendor/utils/ADAUtils";
import MiniTracker from "../../vendor/tracking/MiniTracker";
import DirectionUtils from "./../../vendor/utils/DirectionUtils";
import ResizeUtils from "../../vendor/utils/ResizeUtils";
import Branding from "../common/Branding.react";
import Search from "../common/Search.react";

import SecurityPlannerStore from "../../stores/SecurityPlannerStore";

import ActionButton from "../common/ActionButton.react";

/**
 * <pre>
 * Class To create menus in the Application.
 * Currently, this is placed as top menu. 
 * Package Name - components/common
 * </pre>
 * @class components.common.SecondaryMenu
 */
class SecondaryMenu extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  static propTypes = {
    currentLocation: PropTypes.string,
    stringList: PropTypes.object, // StringList
    className: PropTypes.string,
    allowFocus: PropTypes.bool,
    selectedLanguage: PropTypes.object, // Language
    availableLanguages: PropTypes.arrayOf(PropTypes.object), // Language[]
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    hardLoad: PropTypes.bool,
    goToPage: PropTypes.func.isRequired,
    hasPlan: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  /**
   * Renders the secondary menu object.
   * @function render
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  render() {
    const hasPlan = !!this.props.hasPlan();
    const showHamburger = this.state.breakpoint === "tiny" || this.state.breakpoint === "small";

    // Items
    const linksMenuItems = [
      <>
      <Branding key="branding" goToPage={this.props.goToPage}
        routes={this.props.routes} stringList={this.props.stringList} hardLoad={this.props.hardLoad} />
      </>
        ,
    ];

    // Menu/close button
    if (showHamburger) {
      linksMenuItems.push(
        <>
         <button
              className={"searchToggle mobileSearchToggleHome" + (this.state.isSearching ? " search--active" : "")}
              tabIndex={0}  
              role="menuitem"
              title={this.props.stringList.get("menu-search-caption")}
              aria-label={this.props.stringList.get("menu-search-caption")}
              onKeyDown={ADAUtils.handleKeyboard(this.toggleSearch)}
              onClick={this.toggleSearch}
            ></button>
            <div className="menu" key="menu-button"
              onClick={this.handleMenuToggle}>
              <a href="#">
                <span className="sr-only">{this.props.stringList.get(this.state.isMenuOpen ? "menu-close" : "menu-open")}</span>
                {this.state.isMenuOpen ? (
                  <img src={require("./../../../images/ui/hamburger-close.svg")} alt="" />
                ) : (
                  <img src={require("./../../../images/ui/hamburger.svg")} alt="" />
                )}
              </a>
            </div>
        </>,
      );
    }

    // Home, about, all-tools links
    if (!showHamburger) {
      linksMenuItems.push(
        <ul className="link-group link-group--info" key="info" role="menu">
            {hasPlan ? 
              <li className="link">
                  <a
                tabIndex='0'
                role="menuitem"
                className="nav-action-plan"
                title={this.props.stringList.get("cover-button-plan-caption")}
                aria-label={this.props.stringList.get("cover-button-plan-caption")}
                onKeyDown={ADAUtils.handleKeyboard(this.showMyPlan)}
                onClick={this.showMyPlan}
                >
                {this.props.stringList.get("cover-button-plan-caption")}
              </a>
              </li>
            : null}
          <li className={"link" + (this.props.currentLocation === SecondaryMenu.LOCATION_ABOUT ? " link--active" : "")} role="none">
            <a
              href={this.props.routes.getUriAbout()}
              tabIndex={!this.props.allowFocus || this.props.currentLocation === SecondaryMenu.LOCATION_ABOUT ? -1 : 0}
              role="menuitem"
              title={this.props.stringList.get("menu-about-caption")}
              aria-label={this.props.stringList.get("menu-about-caption")}
              onKeyDown={ADAUtils.handleKeyboard(this.showAbout)}
              onClick={this.showAbout}
            >
              {this.props.stringList.get("menu-about-caption")}
            </a>
          </li>
          <li className={"link" + (this.props.currentLocation === SecondaryMenu.LOCATION_ALL_TOOLS ? " link--active" : "")} role="none">
            <a
              href={this.props.routes.getUriAllTools()}
              tabIndex={!this.props.allowFocus || this.props.currentLocation === SecondaryMenu.LOCATION_ALL_TOOLS ? -1 : 0}
              role="menuitem"
              title={this.props.stringList.get("menu-alltools-caption")}
              aria-label={this.props.stringList.get("menu-alltools-caption")}
              onKeyDown={ADAUtils.handleKeyboard(this.showAllTools)}
              onClick={this.showAllTools}
            >
              {this.props.stringList.get("menu-alltools-caption")}
            </a>
          </li>
          <li className={"link link--search" + (this.state.isSearching ? " link--active" : "")} role="none">
            <button
              className={"searchToggle" + (this.state.isSearching ? " search--active" : "")}
              tabIndex={0}  
              role="menuitem"
              title={this.props.stringList.get("menu-search-caption")}
              aria-label={this.props.stringList.get("menu-search-caption")}
              // onKeyDown={this.escFunction}
              onClick={this.toggleSearch}
            >
              {this.props.stringList.get("menu-search-caption")}
            </button>
          </li>
          <li className={"link"} role="none">
            <a
              className="emergency"
              href={this.props.routes.getUriEmergencyTool()}
              tabIndex={!this.props.allowFocus || this.props.currentLocation === SecondaryMenu.LOCATION_ALL_TOOLS ? -1 : 0}
              role="menuitem"
              title={this.props.stringList.get("menu-emergency-resources-caption")}
              aria-label={this.props.stringList.get("menu-emergency-resources-caption")}
              onKeyDown={ADAUtils.handleKeyboard(this.showAllTools)}
              onClick={this.showEmergency}
            >
              <img className="icon" src={require("./../../../images/ui/emergency.svg")}
                alt="" />
              {this.props.stringList.get("menu-emergency-resources-caption")}
            </a>
          </li>
          {this.showLanguageOptions(this.props.availableLanguages, this.goToLangugageSite)}          
        </ul>,
      );
    }

    const commonMenuClasses = cx(
      {
        dropdown: true,
        full: true,
        "common-secondary-menu": true,
        "common-secondary-menu--open": this.state.isMenuOpen,
      },
      DirectionUtils.getClass(this.props.stringList),
      this.props.className,
    );

    const commonMenuContainerClasses = cx(
      {
        "common-secondary-menu-container": true,
      },
      DirectionUtils.getClass(this.props.stringList),
      this.props.className,
    );

    // Finally, return the elements
    return (
      <div className={commonMenuContainerClasses}>
        <a href={`#${this.props.currentLocation}`} className="skip-link">
          {this.props.stringList.get("menu-skip-link")}
        </a>
          <a href="https://www.google.com/" className="escape-hatch">
            <span className="cross"></span>
            {this.props.stringList.get('escape-hatch-text')}
          </a>
        <div className="menu-inner">
    	 {(showHamburger) ? (
          <nav className={commonMenuClasses} role="menu">
            {showHamburger ? (
              <>
                <div className="dropdown-trigger" role="menuitem">{linksMenuItems}</div>
                {this.state.isMenuOpen ? this.renderDropdownMenu() : null}
              </>
            ) : (
              <>{linksMenuItems}</>
            )}
          </nav>
         ) : (
          <nav className={commonMenuClasses}>
          	<>{linksMenuItems}</>
          </nav>          
         )} 
        </div>
        <Search isSearching={this.state.isSearching} tools={this.props.tools} goToPage={this.props.goToPage} routes={this.props.routes} stringList={this.props.stringList}
              onKeyDown={this.escFunction} searchChange={this.escFunction}/>
      </div>
    );
  }

  /**
   * Renders the secondary menu dropdown for smaller screens. 
   * @function renderDropdownMenu
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  renderDropdownMenu = () => {
    const hasPlan = !!this.props.hasPlan();
    const items = [
      {
        title: this.props.stringList.get("menu-about-caption"),
        currentLocation: this.props.currentLocation === SecondaryMenu.LOCATION_ABOUT,
        onClick: this.showAbout,
      },
      {
        title: this.props.stringList.get("menu-alltools-caption"),
        currentLocation: this.props.currentLocation === SecondaryMenu.LOCATION_ALL_TOOLS,
        onClick: this.showAllTools,
      },
      {
        title: this.props.stringList.get("menu-emergency-resources-caption"),
        currentLocation: this.props.currentLocation === SecondaryMenu.LOCATION_EMERGENCY_RESOURCES,
        onClick: this.showEmergency,
        element: (
          <div className="emergency">
            <img className="icon" src={require("./../../../images/ui/emergency.svg")}
              alt=""/>
            {this.props.stringList.get("menu-emergency-resources-caption")}
          </div>
        ),
      },
    ];

    return (
      
      <div className="dropdown-menu">
        <ul className="dropdown-option-list" role="radiogroup"
          aria-label={this.props.label}>
          {hasPlan ? 
          <li className='dropdown-option-list-item'>
              <a
            tabIndex={!this.props.allowFocus || this.props.currentLocation === SecondaryMenu.LOCATION_ABOUT ? -1 : 0}
            role="menuitem"
            className="option-name"
            title={this.props.stringList.get("cover-button-plan-caption")}
            aria-label={this.props.stringList.get("cover-button-plan-caption")}
            onKeyDown={ADAUtils.handleKeyboard(this.showMyPlan)}
            onClick={this.showMyPlan}
            >
            {this.props.stringList.get("cover-button-plan-caption")}
          </a>
          </li>
        : null}
          {items.map(({ title, currentLocation, onClick, element }, i) => {
            const isOptionActive = this.props.activeOption === i;

            const listItemClasses = cx({
              "dropdown-option-list-item": true,
              "is-active": isOptionActive,
            });

              return (
                <>
              <li className={listItemClasses} key={i}>
                <button
                  className="option-name"
                  tabIndex={this.props.allowFocus && currentLocation ? 0 : -1}
                  onKeyDown={ADAUtils.handleKeyboard(onClick)}
                  onClick={onClick}
                  role="button"
                  title={title}
                  aria-label={title}
                >
                  {element || title}
                </button>
              </li>
                </>
  
              );
          })}
		  {this.showHamburgerLanguageOptions(this.props.availableLanguages, this.goToLangugageSite)}          
        </ul>
      </div>
    );
  };

  escFunction = (event) => {
      document.querySelector('.search-input').value = "";
      
      this.setState({
        isSearching: false,
        showSearch: true,
        showClear: false,
        searchResults: []
      });

      const searchInput = document.querySelector(".search-input");
      searchInput.value = "";
      MiniTracker.trackEvent({
        event: "search-close",
        keyword: searchInput.value
      });
      if(document.querySelector('.search-results-container')) {
        document.querySelector('.search-results-container').style.display = 'none';
      }
  }

  /**
   * Action for the emergency menu in SecondaryMenu.
   * @function showEmergency
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  showEmergency = (e) => {
    e.preventDefault();
    this.props.goToPage(this.props.routes.getUriEmergencyTool(), true, true, this.props.hardLoad );
  }

  /**
   * Action for the about menu in SecondaryMenu.
   * @function showAbout
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  showAbout = (e) => {
    e.preventDefault();
    this.closeMenuDelayed();

    MiniTracker.trackEvent("button", "click", "who-we-are");
    this.props.goToPage(this.props.routes.getUriAbout(), true, true, this.props.hardLoad);
  };

  /**
   * Action for the about menu in SecondaryMenu.
   * @function showMyPlan
   * @memberof components.common.SecondaryMenu
   * @instance
   */
    showMyPlan = (e) => {
      e.preventDefault();
      this.closeMenuDelayed();
  
      MiniTracker.trackEvent("button", "click", "my-plan");
      const { hash } = SecurityPlannerStore.getPlan() || {};
      this.props.goToPage(this.props.routes.getUriReportWithHash(hash), true, true, this.props.hardLoad);
    };

  /**
   * Action for the all tools menu in SecondaryMenu.
   * @function showAllTools
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  showAllTools = (e) => {
    e.preventDefault();
    this.closeMenuDelayed();

    MiniTracker.trackEvent("button", "click", "all-recommendations");
    this.props.goToPage(this.props.routes.getUriAllTools(), true, true, this.props.hardLoad);
  };

  /**
   * Handle the menu toggle in SecondaryMenu for smaller screens.
   * @function handleMenuToggle
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  handleMenuToggle = (e) => {
    e.preventDefault();

    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  };

  /**
   * Toggle visibility of the search UI
   * @function toggleSearch
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  toggleSearch = (e) => {
    e.preventDefault();

    if(window.innerWidth <= 767) {
      var newPlaceholder = document.querySelector('.search-input').getAttribute('data-mobile-placeholder');
      document.querySelector('.search-input').setAttribute('placeholder', newPlaceholder);
    }
    console.log('toggle searcg - menu: ' + this.state.isSearching);
    this.setState({
      isSearching: !this.state.isSearching,
    });
  };

  /**
   * Handle the menu close in SecondaryMenu.
   * @function handleMenuToggle
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  closeMenuDelayed = () => {
    // Sort of hacky - we want to close the menu when we click the link, but we don't want to see it.
    setTimeout(
      function() {
        this.setState({
          isMenuOpen: false,
        });
      }.bind(this),
      500,
    );
  };

  /**
   * Displays the language option in dropdown menu in SecondaryMenu for smaller screens. 
   * The language option is displayed as per CM-5610
   * @param  {array} languageOptions   
   * @param  {string} func      
   * @function showHamburgerLanguageOptions
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  showHamburgerLanguageOptions = (languageOptions, func) => { 
     var switchers = [], count = 0;
     let currLangKey = localStorage.preferredLanguage;
     for (i=0; i<languageOptions.length; i++) {         
        if ( currLangKey == languageOptions[i].id ){ continue; } 
		if(count == 0){ 
		 switchers.push( 
		   <li className={"dropdown-option-list-item"} key={"divider"}>
		     <hr className="menu-hr-hamburger"/>
		   </li> 
		 );    
        }
        let langTitle = (languageOptions[i].name.indexOf('Spanish') > -1) ? 'Español' : languageOptions[i].name;
        switchers.push(
         <li className={"dropdown-option-list-item"} key={languageOptions[i].id}>
           <button className="option-name" role="button" tabIndex={0} title={languageOptions[i].id} 
           		id={languageOptions[i].id} aria-label={languageOptions[i].id}  
           		onKeyDown={ADAUtils.handleKeyboard(func)} onClick={func}> {langTitle} </button>         
         </li>   
        ); 
        count++; //console.log(switchers);
	 }
	 return switchers; 
  };    

  /**
   * Displays the language option in dropdown menu in SecondaryMenu.
   * The language option is displayed as per CM-5610   
   * @param  {array} languageOptions   
   * @param  {string} func      
   * @function showLanguageOptions
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  showLanguageOptions = (languageOptions, func) => { 
     var switchers = [];
     let currLangKey = localStorage.preferredLanguage;
     for (i=0; i<languageOptions.length; i++) {         
        if ( currLangKey == languageOptions[i].id ){ continue; } 
        let langTitle = (languageOptions[i].name.indexOf('Spanish') > -1) ? 'Español' : languageOptions[i].name;
        switchers.push(
          <li className={"link"} key={languageOptions[i].id} role="none">
            <a href="#" tabIndex={0} role="menuitem" id={languageOptions[i].id } 
              title={languageOptions[i].name} aria-label={languageOptions[i].name}
              onKeyDown={ADAUtils.handleKeyboard(func)}
              onClick={func}> {langTitle} </a>
          </li>         
        ); 
	 }
	 //console.log(switchers);
	 return switchers; 
  };  

  /**
   * Action for the language option in SecondaryMenu.
   * Action is set as per CM-5609   
   * @function goToLangugageSite
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  goToLangugageSite = (e) => {
    MiniTracker.trackEvent("button", "click", "languageswitcher");
    let url = ''; console.log(e.currentTarget.id);
    if( e.currentTarget.id != localStorage.preferredLanguage ){ 
      if( e.currentTarget.id == 'es-US' ){ 
        localStorage.setItem("preferredLanguage", "es-US");
        window.location.assign( '/es' ); 
      }else{
        localStorage.setItem("preferredLanguage", "en-US");
        window.location.assign( '/' );       
      }
    }else{
      if( e.currentTarget.id == 'es-US' ){ 
        localStorage.setItem("preferredLanguage", "es-US");
        window.location.assign( '/es' ); 
      }else{
        localStorage.setItem("preferredLanguage", "en-US");
        window.location.assign( '/' );       
      }    
    }
    e.preventDefault();
  }; 

  /**
   * Action for the resize of the SecondaryMenu for media breakpoints.
   * @function onResize
   * @memberof components.common.SecondaryMenu
   * @instance
   */
  onResize = () => {
    this.setState({
      breakpoint: ResizeUtils.getCurrentBreakpoint(),
    });
  };

  state = {
    breakpoint: ResizeUtils.getCurrentBreakpoint(), // string
    isMenuOpen: false, // bool
    isSearching: false, // bool
  };
}

SecondaryMenu.LOCATION_HOME = "home";
SecondaryMenu.LOCATION_ABOUT = "about";
SecondaryMenu.LOCATION_EMERGENCY_RESOURCES = "emergency-resources";
SecondaryMenu.LOCATION_ALL_TOOLS = "all-tools";
SecondaryMenu.LOCATION_ACTION_PLAN = "action-plan";
SecondaryMenu.LOCATION_PARTNERSHIP = "partnership";
SecondaryMenu.LOCATION_TERMS = "terms";
SecondaryMenu.LOCATION_FEEDBACK = "feedback";
SecondaryMenu.LOCATION_NOMATCH = "no-match";
SecondaryMenu.LOCATION_PHILOSOPHY = "philosophy";

export default SecondaryMenu;
