import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import * as marked from "marked";

import ToolCheckbox from "./ToolCheckbox.react";
import SecondaryMenu from "./SecondaryMenu.react";

import ADAUtils from "../../vendor/utils/ADAUtils";
import PageSectionHelper from "../../vendor/utils/PageSectionHelper";
import SecurityPlannerActions from "../../actions/SecurityPlannerActions";

/**
 * <pre>
 * Class to create tool overlay header for the Application.
 * Package Name - components/common/toolOverlayHeader
 * </pre>
 * @class components.common.ToolOverlayHeader
 */

class ToolOverlayHeader extends React.Component {
    static propTypes = {
        stringList: PropTypes.object, // StringList
        routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
        goToPage: PropTypes.func.isRequired,
        tool: PropTypes.object, // Tool
        tools: PropTypes.array, // Tool[]
        hasPlan: PropTypes.func.isRequired,
        onClickClose: PropTypes.func,
        availableLanguages: PropTypes.array, // Language[]
        selectedLanguage: PropTypes.object, // Language
        scrollerRef: PropTypes.object
    };

    state = {
        visible: false,
        showing: false,
        hiding: false,
    };

    helper = undefined;

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        this.helper = new PageSectionHelper(this);
    }

    componentDidMount() {
        this.helper.setComponent(this.props.scrollerRef);
        document.addEventListener("keydown", this.handleKeyPress); 
    }

    componentDidUpdate() {
        this.helper.setComponent(this.props.scrollerRef);
        this.helper.forceCheck();
    }

    componentWillUnmount() {
        this.helper.destroy();
    }

    /**
     * Renders the tool overlay header object in tool overlay.
     * @function render
     * @memberof components.common.ToolOverlayHeader
     * @instance
     */
    render() { 
        return(
            <div className="overlay-header">
                <SecondaryMenu
                    stringList={this.props.stringList}
                    currentLocation={SecondaryMenu.LOCATION_NOMATCH}
                    availableLanguages={this.props.availableLanguages}
                    selectedLanguage={this.props.selectedLanguage}
                    routes={this.props.routes}
                    goToPage={this.props.goToPage}
                    allowFocus={true}
                    useLightStyle={false}
                    hasPlan={this.props.hasPlan}
                    className={"header"}
                    tools={this.props.tools}
                    hardLoad={true}
                    ref="menu"
                />
                <div className="wrapper overlay-header-back">
                    <div
                    className="close"
                    tabIndex={this.helper.isActive() ? 0 : -1}
                    role="button"
                    onKeyDown={ADAUtils.handleKeyboard(this.close)}
                    onClick={this.close}
                    >
                    <img
                        className="show-fading"
                        src={require("../../../images/ui/chevron-down.svg")}
                        alt={this.props.stringList.get("common-ui-back")}
                    />{" "}
                    {this.props.stringList.get("common-ui-back")}
                    </div>
                    {this.props.hasPlan() ?
                        <ToolCheckbox
                        checked={this.props.tool.completed}
                        onClick={() => this.checkedOnClick(this.props.tool.id)}
                        shortCaption={this.props.stringList.get("tool-caption-short")}
                        longCaption={this.props.stringList.get("tool-caption-long")}
                        tool={this.props.tool}
                        />
                        : null
                    }
                </div>
            </div>
        );
    }


    /**
     * Action when the overlay is closed
     * @function close
     * @memberof components.common.overlayHeader
     * @instance    
     */	
    close = () => {
        document.removeEventListener("keydown", this.handleKeyPress);
        if (this.props.onClickClose) this.props.onClickClose();
    };

    /**
     * Checked on click given toolId
     * @param  {number} toolId      
     * @function checkedOnClick
     * @memberof components.common.overlayHeader
     * @instance    
     */	
    checkedOnClick = (toolId) => {
        SecurityPlannerActions.toggleToolCompleted(toolId);
    };

    /**
   * Handles keypress of the overlay
   * @function handleKeyPress
   * @memberof components.common.overlayHeader
   * @instance    
   */	
    handleKeyPress = (event) => {
        if (event.key == "Escape") {
            this.close();
        }
    };
}

export default ToolOverlayHeader;