import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

import ADAUtils from "./../../../vendor/utils/ADAUtils";
import PageSectionHelper from "./../../../vendor/utils/PageSectionHelper";
import MarkdownUtils from "./../../../vendor/utils/MarkdownUtils";
import DirectionUtils from "./../../../vendor/utils/DirectionUtils";

/**
 * <pre>
 * Class to create a BioOverlay in the Application.
 * Package Name - components/overlays/bio
 * </pre>
 * @class components.overlays.bio.BioOverlay
 */
class BioOverlay extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      showing: false,
      hiding: false,
    };

    this.helper = new PageSectionHelper(this);
  }
  static propTypes = {
    stringList: PropTypes.object, // StringList
    bio: PropTypes.object.isRequired,
    scrollPosition: PropTypes.number,
    onClickClose: PropTypes.func,
  };

  componentDidMount() {
    this.helper.setComponent(this.refs.scroller);
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentDidUpdate() {
    this.helper.setComponent(this.refs.scroller);
    this.helper.forceCheck();
  }

  componentWillUnmount() {
    this.helper.destroy();
  }

  /**
   * Renders the biooverlay object.
   * @function render
   * @memberof components.overlays.bio.BioOverlay
   * @instance
   */
  render() {
    const classes = cx(
      {
        overlay: true,
        overlayBio: true,
        hidden: !this.state.visible,
        showing: this.state.showing,
        hiding: this.state.hiding,
      },
      DirectionUtils.getClass(this.props.stringList),
    );

    const descriptionParagraphs = this.props.bio.description.split("\n");

    return (
      <div className={classes} aria-hidden={!this.helper.isActive()}
        style={{ height: this.helper.getWindowHeight() }}>
        <div className="cover" />
        <div className="mask mask-cropping mask-fading">
          <div className="background" />
          <div className="overlay-header">
            <div className="wrapper">
              <div
                className="close"
                tabIndex={this.helper.isActive() ? 0 : -1}
                role="button"
                onKeyDown={ADAUtils.handleKeyboard(this.close)}
                onClick={this.close}
              >
                <img
                  className="show-fading"
                  src={require("./../../../../images/ui/chevron-down.svg")}
                  alt={this.props.stringList.get("common-ui-back")}
                />{" "}
                {this.props.stringList.get("common-ui-back")}
              </div>
            </div>
          </div>
          <div className="content show-fading" ref="scroller">
            <div className="wrapper" style={{ minHeight: this.helper.getMinScrollableHeight() }}>
              <div className="body show-sliding-left">
                <div className="img-wrapper">
                  <img src={this.props.bio.image} alt={this.props.bio.name} />
                </div>
                <div className="name">{this.props.bio.name}</div>
                <div className="organization">{this.props.bio.organization}</div>
                <hr className="divider" />
                <div className="description">
                  {descriptionParagraphs.map((text, i) => {
                    return <p key={i}>{MarkdownUtils.parseURL(text)}</p>;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Ran when the section becomes the focused section
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	       
   * @function onActivate
   * @memberof components.overlays.bio.BioOverlay
   * @instance  
   */
  onActivate = (travelOffset, viaHistoryAPI) => {
    this.helper.onActivate(travelOffset, viaHistoryAPI);
  };

  /**
   * Ran when the section is about to lose focus 
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	       
   * @function onDeactivate
   * @memberof components.overlays.bio.BioOverlay
   * @instance     
   */
  onDeactivate = (travelOffset, viaHistoryAPI) => {
    this.helper.onDeactivate(travelOffset, viaHistoryAPI);
  };

  /**
   * Returns the state transition of the overlay
   * @param  {string} callback
   * @function startTransitionShow
   * @memberof components.overlays.bio.BioOverlay
   * @instance     
   */
  startTransitionShow = (callback) => {
    this.setState({ visible: false, showing: true, hiding: false });

    requestAnimationFrame(callback);

    setTimeout(() => {
      if (this.state.showing) {
        this.setState({ visible: true, showing: false, hiding: false });

        document.body.style.top = `-${this.props.scrollPosition}px`;
        document.body.style.marginTop = `${this.props.scrollPosition}px`;
        document.body.style.height = `${window.innerHeight}px`;
        document.body.style.overflow = "hidden";
      }
    }, 830);
  };

  /**
   * Hides the state transition of the overlay
   * @param  {string} callback
   * @function startTransitionHide
   * @memberof components.overlays.bio.BioOverlay
   * @instance     
   */
  startTransitionHide = (callback) => {
    this.setState({ visible: true, showing: false, hiding: true });

    document.body.style.top = "";
    document.body.style.marginTop = "";
    document.body.style.height = "";
    document.body.style.overflow = "";

    setTimeout(() => {
      callback();
    }, 330);
  };

  /**
   * Action for closing the overlay
   * @function close
   * @memberof components.overlays.bio.BioOverlay
   * @instance     
   */
  close = () => {
    document.removeEventListener("keydown", this.handleKeyPress);
    if (this.props.onClickClose) this.props.onClickClose();
  };

  /**
   * Action for handling keypress of the overlay
   * @function handleKeyPress
   * @memberof components.overlays.bio.BioOverlay
   * @instance     
   */
  handleKeyPress = (event) => {
    if (event.key == "Escape") {
      this.close();
    }
  };
}

export default BioOverlay;
