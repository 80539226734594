import PropTypes from "prop-types";
import React from "react";

/**
 * <pre>
 * Class to generate a nomatch page body for the Application.
 * Package Name - components/pages/nomatch
 * </pre>
 * @class components.pages.nomatch.Body
 */
class Body extends React.Component {
  static propTypes = {
    stringList: PropTypes.object, // StringList
    allowFocus: PropTypes.bool,
  };

  /**
   * Renders the nomatch page body object.
   * @function render
   * @memberof components.pages.nomatch.Body
   * @instance
   */
  render() {
    // Hardcoded 404 text content. To be replaced by Contentful data
    return (
      <div className="body">
        <div className="title">{"404"}</div>
        <div className="subtitle">{this.props.stringList.get("page-not-found")}</div>
      </div>
    );
  }
}

export default Body;
