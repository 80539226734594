import PropTypes from "prop-types";
import React from "react";
import ClickTouchHandler from "./../../vendor/utils/ClickTouchHandler.js";
import ADAUtils from "./../../vendor/utils/ADAUtils";


/**
 * <pre>
 * Class used for Creating a felxible banner.
 * Package Name - components/common
 * </pre>
 * @class components.common.FlexibleBanner
 */
class FlexibleBanner extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    stringList: PropTypes.object, // StringList
  };

  /**
   * Renders the flexible banner object.
   * @function render
   * @memberof components.common.FlexibleBanner
   * @instance
   */  
  render() {
    
    this.touchHandler = new ClickTouchHandler(this.onClickSelf, true);

    if(this.props.stringList.get('flex-banner-title') && this.props.stringList.get('flex-banner-title') != null && this.props.stringList.get('flex-banner-title') != undefined && this.props.stringList.get('flex-banner-title') != "" && this.props.stringList.get('flex-banner-title') != "{NOT_FOUND}" && this.props.stringList.get('flex-banner-title') != "N/A") {
      return (
        <div className="flex-banner">
            <div className="flex-banner--container">
                <div className="flex-banner--img-container">
                    <img src={require("./../../../images/overlay-share/img-banner.png")} alt="" className="flex-banner--img" />
                </div>
                <div className="flex-banner--inner">
                    <h2 className="flex-banner--title">{this.props.stringList.get('flex-banner-title')}</h2>
                    <p className="flex-banner--text" dangerouslySetInnerHTML={{__html: this.props.stringList.get('flex-banner-copy')}}></p>
                    {/* <a className="flex-banner--button" href={this.props.stringList.get('flex-banner-cta-link')}>{this.props.stringList.get('flex-banner-cta-text')}</a> */}
                </div>
                <div className="flex-banner--close-container">
                    <button className="flex-banner--close" onClick={this.touchHandler.handler}><img src={require("./../../../images/ui/hamburger-close-yellow.svg")} alt="" /></button>
                </div>
            </div>
        </div>
      );
    } else {
      return(null);
    }
  }
  /**
   * Action on click of the action button object.
   * @function onClickSelf
   * @memberof components.common.ActionButton
   * @instance
   */
  onClickSelf = (e) => {
    e.preventDefault();
    document.querySelector('.flex-banner').classList.add('closed')
  };

}

export default FlexibleBanner;
