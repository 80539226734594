import PropTypes from "prop-types";
import React from "react";

import ReactDOM from "react-dom";

import MiniTracker from "../../../vendor/tracking/MiniTracker";
import DirectionUtils from "./../../../vendor/utils/DirectionUtils";
import ADAUtils from "./../../../vendor/utils/ADAUtils";

import ActionButton from "../../common/ActionButton.react";
import TrackClick from "./../../../vendor/tracking/AdobeAnalytics";

/**
 * <pre>
 * Class to generate a footer share section on report footer.
 * Uses the ActionButton component to generate.
 * Package Name - components/pages/report
 * </pre>
 * @class components.pages.report.FooterShare
 */
class FooterShare extends React.Component {
  static propTypes = {
    stringList: PropTypes.object.isRequired, // StringList
    shareURL: PropTypes.string.isRequired,
    isFromSharing: PropTypes.bool,
    allowFocus: PropTypes.bool,
    onClickSave: PropTypes.func,
    onClickRetake: PropTypes.func,
    downloading: PropTypes.bool,
  };

  state = {
    copiedShareURLToClipboard: false,
  };

  /**
   * Renders the footer share object.
   * @function render
   * @memberof components.pages.report.FooterShare
   * @instance
   */
  render() {
    return (
      <div className={"footer-share " + DirectionUtils.getClass(this.props.stringList)}>
        <div className="inner-wrapper">
          <div className="footer-row copy-row">
            <p className="title">{this.props.stringList.get("action-plan-footer-title")}</p>
            <div className="copy-link">
              <a
                className="link"
                ref="action-plan-link"
                tabIndex={this.props.allowFocus ? 0 : -1}
                href={this.props.shareURL}
                role="button"
                onKeyDown={ADAUtils.handleKeyboard((e) => this.copyComponentText(e, "action-plan-link"))}
                onClick={(e) => this.copyComponentText(e, "action-plan-link")}
              >
                {this.props.shareURL}
              </a>
              <p className={"copy-mark" + (this.state.copiedShareURLToClipboard ? "" : " hidden")}>
                {this.props.stringList.get("action-plan-footer-copied")}
                <img src={require("./../../../../images/ui/checkmark-small-green.svg")} alt="" />
              </p>
            </div>

            <ActionButton className="footer-button" onClick={(e) => this.copyComponentText(e, "action-plan-link")}>
              <div className="text">{this.props.stringList.get("copy-url-footer")}</div>
              <img className="icon" src={require("./../../../../images/ui/copy-green.svg")}
                alt="" />
            </ActionButton>
          </div>
          <div className="footer-row">
            <p className="title">{this.props.stringList.get("action-plan-footer-print")}</p>
            <ActionButton className="footer-button" onClick={this.props.onClickSave}>
              <div className="text">{this.props.stringList.get("download-pdf-footer")}</div>
              <img className="icon" src={this.props.downloading ? require("./../../../../images/ui/spinner_dark_green.svg") : require("./../../../../images/ui/download-green.svg")}
                alt="" />
            </ActionButton>
          </div>
          <div className="footer-row retake-row">
            <p className="title">{this.props.stringList.get("action-plan-footer-startover-title")}</p>
            <ActionButton className="footer-button" onClick={this.props.onClickRetake}>
              <div className="text">{this.props.stringList.get("action-plan-footer-startover-button")}</div>
            </ActionButton>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Copies the page url from copy button
   * @param  {object} event 
   * @param  {object} ref	      
   * @function copyComponentText
   * @memberof components.pages.report.FooterShare
   * @instance
   */
  copyComponentText = (event, ref) => {
    TrackClick("copy_url-button");
    const element = ReactDOM.findDOMNode(this.refs[ref]);
    if (element) {
      // Selects everything
      const range = document.createRange();
      range.selectNodeContents(element);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      const canCopy = document.queryCommandSupported && document.queryCommandSupported("copy") && document.queryCommandEnabled("copy");

      // Tries to copy
      let success = false;

      if (canCopy) {
        // Use execCommand as expected
        try {
          success = document.execCommand("copy");
        } catch (err) {
          success = false;
        }
      }

      if (!success) {
        if (window.clipboardData) {
          // MSIE
          window.clipboardData.setData("Text", this.shareURL);
          success = true;
        } else {
          // Cannot copy, just select
          element.setSelectionRange(0, 99999);
        }
      }

      if (success) {
        window.getSelection().removeAllRanges();
      }

      if (success) {
        MiniTracker.trackEvent("button", "click", "copy-url");
        event.preventDefault();
      } else {
        MiniTracker.trackEvent("button", "click", "selected-url");
      }

      this.setState(Object.assign({}, this.state, { copiedShareURLToClipboard: success }));
    }
  };

  /**
   * Copies the page url from copy button
   * @function start
   * @memberof components.pages.report.FooterShare
   * @instance
   */
  start = () => {
    MiniTracker.trackEvent("button", "click", "start");
    this.props.onClickRetake();
  };
}

export default FooterShare;
