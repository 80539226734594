import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import * as marked from "marked";

import FormFieldInput from "./FormFieldInput.react";
import ActionButton from "./ActionButton.react";
import ImageContainer from "./ImageContainer.react";
import TrackClick from "./../../vendor/tracking/AdobeAnalytics"; 

import TrackRegister from "./../../vendor/tracking/AdobeAnalyticsRegister";
import TrackSuccess from "./../../vendor/tracking/AdobeAnalyticsRegisterSuccess";

/**
 * <pre>
 * Class to create a JoinForm in the application.
 * Package Name - components/common
 * </pre>
 * @class components.common.JoinBanner
 */
class JoinBanner extends React.Component {
  static propTypes = {
    stringList: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.func.isRequired,
  };

  state = {
    open: false,
    visible: false,
    firstName: "",
    firstError: true,
    lastName: "",
    lastError: true,
    email: "",
    emailError: true,
    registerError: null,
    joinClicked: false,
    showSuccess: false,
  };

  componentDidMount() {
    this.setState({ visible: !this.props.isLoggedIn() });
  }

  /**
   * Renders the link object.
   * @param  {string} markdown    
   * @function linkRenderer
   * @memberof components.common.JoinBanner
   * @instance
   */
  linkRenderer = (markdown) => {
    const renderer = new marked.Renderer();
    const linkRenderer = renderer.link;
    renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text);
        return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
    }
   return marked(markdown, { renderer });
  }

  /**
   * Generates the toggle for the banner.
   * @function toggleOpen
   * @memberof components.common.JoinBanner
   * @instance
   */
  toggleOpen = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  /**
   * Generates the firstname input in banner.
   * @param  {string} firstName      
   * @function firstNameChange 
   * @memberof components.common.JoinBanner
   * @instance
   */
  firstNameChange = (firstName) => {
    firstName = firstName.trim().slice(0, 250);

    this.setState({ firstName, firstError: !firstName || firstName.length === 0 });
  };

  /**
   * Generates the lastname input in banner.
   * @param  {string} lastName    
   * @function lastNameChange
   * @memberof components.common.JoinBanner
   * @instance
   */
  lastNameChange = (lastName) => {
    lastName = lastName.trim().slice(0, 250);

    this.setState({ lastName, lastError: !lastName || lastName.length === 0 });
  };

  /**
   * Generates the email input in banner.
   * @param  {string} email      
   * @function emailChange
   * @memberof components.common.JoinBanner
   * @instance
   */
  emailChange = (email) => {
    this.setState({ email: email.slice(0, 250).trim(), registerError: null });
  };

  /**
   * Generates the email input in banner.
   * @param  {boolean} hasError    
   * @function onError
   * @memberof components.common.JoinBanner
   * @instance
   */
  onError = (hasError) => {
    const { emailError } = this.state;

    if (emailError !== hasError) {
      this.setState({ emailError: hasError, registerError: null });
    }
  }

  /**
   * Generates the delayed action in join banner.
   * @function delayedHide
   * @memberof components.common.JoinBanner
   * @instance
   */
  delayedHide = () => {
    setTimeout(() => {
      this.setState({ visible: !this.props.isLoggedIn() });
    }, 5000);
  }

  /**
   * Generates the on close action in join banner.
   * @function close
   * @memberof components.common.JoinBanner
   * @instance
   */
  close = () => {
    this.setState({ open: false });
  }

  /**
   * Generates the setState action in join banner.
   * @param  {boolean} show    
   * @function setStateParameters
   * @memberof components.common.JoinBanner
   * @instance
   */
  setStateParameters = (show) => {
    this.setState({ visible: show && !this.props.isLoggedIn() });
  }

  /**
   * Generates the setState action in join banner.
   * @param  {object} event   
   * @function register
   * @memberof components.common.JoinBanner
   * @instance
   */
  register = (event) => {
    TrackClick("join-button");

    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const { firstName, lastName, email, firstError, lastError, emailError } = this.state;

    if (!firstError && !lastError && !emailError) {
      TrackRegister('registration-in-process');    
      this.props.register(firstName, lastName, email)
        .then(() => {
          this.setState({ registerError: null, joinClicked: true, showSuccess: true });
          TrackSuccess('registration-success'); 
        })
        .catch(({ message }) => {
          if (message) {
            this.setState({ registerError: message, joinClicked: true });
          } else {
            this.setState({ registerError: this.props.stringList.get("join-error-message-unexpected"), joinClicked: true });
          }
        }).then(() => {
          this.delayedHide();
        });
    } else {
      this.setState({ joinClicked: true });
      // eslint-disable-next-line no-console
      console.error("Form has error");
    }
  };

  /**
   * Gets the error messages on join banner.
   * @function getErrorMessage
   * @memberof components.common.JoinBanner
   * @instance
   */
  getErrorMessage = () => {
    const { firstError, lastError, emailError, registerError } = this.state;

    if (registerError) return registerError;
    if (emailError) return this.props.stringList.get("join-error-message-invalid-email");
    if (firstError) return this.props.stringList.get("join-error-message-invalid-first");
    if (lastError) return this.props.stringList.get("join-error-message-invalid-last");

    return null;
  }

  /**
   * Renders the (join) banner object.
   * @function render
   * @memberof components.common.JoinBanner
   * @instance
   */
  render() {
    const { open, firstName, email, lastName, visible, joinClicked, firstError, lastError, emailError, showSuccess } = this.state;

    const errorMessage = this.getErrorMessage();
    const disabled = !!errorMessage;

    const menuClasses = cx({
      "common-join-banner": true,
      "is-fixed": true,
      "is-hidden": !visible,
    });

    const joinFormClasses = cx({
      "join-form": true,
      "is-open": open,
      "success": showSuccess,
    });

    const triangle = require("./../../../images/ui/chevron-down-white.svg");

    return (
      <div className={menuClasses}>
        <div className="get-exclusive-access-wrapper" onClick={this.toggleOpen}>
          <div className="get-exclusive-access">
            {this.props.stringList.get("join-banner-text")} <div className="join-cta">{this.props.stringList.get("join-banner-link")}</div>
          </div>
          <ImageContainer className={cx({ icon: true, "is-open": open })} src={triangle} />
        </div>
        <div className={joinFormClasses}>
          {showSuccess ? (
            <div className="join-success">
              <div className="title">{this.props.stringList.get("join-success-title")}</div>
              <div className="text">{this.props.stringList.get("join-success-text")}</div>
            </div>
          ) : (
            <form onSubmit={this.register}>
              <div className="join-row" style={{ marginBottom: "16px" }}>
                <FormFieldInput
                  allowFocus={true}
                  inputClassName={cx({ "join-name": true, text: true, invalid: joinClicked && firstError })}
                  placeholder={this.props.stringList.get("join-field-first-name")}
                  value={firstName}
                  onChange={this.firstNameChange}
                />
                <FormFieldInput
                  allowFocus={true}
                  inputClassName={cx({ "join-name": true, text: true, invalid: joinClicked && lastError })}
                  placeholder={this.props.stringList.get("join-field-last-name")}
                  value={lastName}
                  onChange={this.lastNameChange}
                />
              </div>
              <div className="join-row">
                <FormFieldInput
                  allowFocus={true}
                  type="email"
                  inputClassName={cx({ "join-email": true, text: true, invalid: joinClicked && emailError })}
                  placeholder={this.props.stringList.get("join-field-email")}
                  errorMessage={this.props.stringList.get("join-field-email-error")}
                  value={email}
                  onChange={this.emailChange}
                  onError={this.onError}
                />
              </div>
              <div className={cx({ "join-row": true, "join-error": true, visible: disabled && joinClicked })}>
                {errorMessage || this.props.stringList.get("join-error-message-no-error")}
              </div>
              <div className="join-row">
                <p className="disclaimer" dangerouslySetInnerHTML={{__html: this.linkRenderer(this.props.stringList.get("join-disclaimer") || "")}} />
              </div>
              <div className="join-row" style={{ marginTop: "16px" }}>
                <ActionButton disabled={disabled} onClick={this.register}
                  className="join-button">{this.props.stringList.get("join-button-text")}</ActionButton>
              </div>
              <div className="join-row">
                <p className="already-member">
                  {this.props.stringList.get("join-member-already")} <a className="link" href={this.props.stringList.get("sign-in-url") + "?referrer=" + encodeURIComponent(window.location.pathname) }>{this.props.stringList.get("join-member-sign-in")}</a>
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default JoinBanner;
