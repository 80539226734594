import PropTypes from "prop-types";
import React from "react";

import Threat from "./Threat.react";

/**
 * <pre>
 * Class to generate a threatlist component in the Application.
 * Uses the Threat component for generation.
 * Package Name - components/pages/printreport
 * </pre>
 * @class components.pages.printreport.ThreatList
 */
class ThreatList extends React.Component {
  static propTypes = {
    stringList: PropTypes.object,
    threats: PropTypes.array, // Threat[]
    tools: PropTypes.array, // Tool[]
  };

  /**
   * Renders the threatlist object.
   * @function render
   * @memberof components.pages.printreport.ThreatList
   * @instance
   */
  render() {
    return <div className="threat-list">{this.renderThreats()}</div>;
  }

  /**
   * Renders the threats object.
   * @function renderThreats
   * @memberof components.pages.printreport.ThreatList
   * @instance
   */
  renderThreats = () => {
    const components = [];
    this.props.threats.forEach((threat, index) => {
      if (index > 0) components.push(<hr key={"hr-" + index} />);
      components.push(this.renderThreat(threat, index));
    });
    return components;
  };

  /**
   * Renders each threat object.
   * @param  {object} threat 
   * @param  {number} index	      
   * @function renderThreat
   * @memberof components.pages.printreport.ThreatList
   * @instance
   */
  renderThreat = (threat, index) => {
    return (
      <Threat
        key={"threat-" + index}
        stringList={this.props.stringList}
        threat={threat}
        tools={this.props.tools.filter((tool) => tool.threat == threat)}
      />
    );
  };
}

export default ThreatList;
