import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import cx from "classnames";

import PageUtils from "./../../vendor/utils/PageUtils";
import ADAUtils from "./../../vendor/utils/ADAUtils";

import ImageContainer from "./ImageContainer.react";

function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
}

/**
 * <pre>
 * Class for creating the dropdowns in the application. 
 * Package Name - components/common
 * </pre>
 * @class components.common.Dropdown
 */
const Dropdown = createReactClass({
  displayName: "Dropdown",
  uuid: undefined,

  propTypes: {
    className: PropTypes.string,
    activeOption: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    label: PropTypes.oneOfType([ PropTypes.string, PropTypes.func, PropTypes.element ]),
    options: PropTypes.array.isRequired,
    allowFocus: PropTypes.bool,
    onSelectOption: PropTypes.func.isRequired,
    hasCategories: PropTypes.bool,
    textLabel: PropTypes.string,
    onOpen: PropTypes.func,
    onBlur: PropTypes.func,
  },

  state: {
    isSearching: false,
  },

  /**
   * Generates the initial state of the object.
   * @function getInitialState
   * @memberof components.common.Dropdown
   * @instance
   */  
  getInitialState: function() {
    return {
      isMenuOpen: false,
    };
  },

  componentDidUpdate: function(prevProps, prevState) {
    if (this.state.isMenuOpen !== prevState.isMenuOpen && prevState.isMenuOpen && this.props.onOpen) {
      this.props.onOpen();
    }

    //Toggle a menu open class on html element to help with iOS scroll issues
    document.documentElement.classList.toggle('dropdown-menu-is-open', this.state.isMenuOpen);
  },

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount: function() {
    window.addEventListener(PageUtils.isTouchDevice() ? "touchend" : "click", this.listener);
    document.addEventListener("keydown", this.handleKeyPress);
    this.uuid = `__label_id__${Date.now()}__${Math.round(Math.random() * 100000)}_`;
  },

  componentWillUnmount: function() {
    window.removeEventListener(PageUtils.isTouchDevice() ? "touchend" : "click", this.listener);
    document.removeEventListener("keydown", this.handleKeyPress);
  },

  /**
   * Renders the text label for the dropdown.
   * @function renderTextLabel
   * @memberof components.common.Dropdown
   * @instance
   */  
  renderTextLabel: function() {
    const triangle = require("./../../../images/ui/chevron-down-white.svg");

    return (
      <>
        <div className="label">{this.props.label}</div>
        <ImageContainer className="icon" src={triangle} />
      </>
    );
  },

  /**
   * Action for the blur event for the dropdown.
   * @function onBlur
   * @memberof components.common.Dropdown
   * @instance
   */  
  onBlur: function() {
    // To give the onClick time to fire.
    // The click event has a lower priority than blur, and fires after
    // If the menu buttons seem non-responsive, it's because the blur is completing before the onClick has fully executed
    setTimeout(() => {
      this.setState({ isMenuOpen: false });

      if (this.props.onBlur) {
        this.props.onBlur();
      }
    }, 250);
  },

  /**
   * Renders the dropdown object.
   * @function render
   * @memberof components.common.Dropdown
   * @instance
   */  
  render() {
    const triggerClasses = cx({
      "dropdown-trigger": true,
    });

    return (
      <div className={cx(["dropdown", this.props.className || ""])}>
        <div
          className={triggerClasses}
          tabIndex={this.props.allowFocus ? 0 : -1}
          aria-expanded={this.state.isMenuOpen}
          aria-label={`${this.props.textLabel ?? this.props.label}, ${this.props.options[this.props.activeOption] ?? ''}`}
          onKeyDown={ADAUtils.handleKeyboard(this.toggleMenu)}
          onClick={this.toggleMenu}
          onBlur={() => this.onBlur()}
          ref={(e) => (this.triggerElement = e)}
        >
          {!isFunction(this.props.label) ? this.renderTextLabel() : this.props.label(this.state.isMenuOpen)}
        </div>
        {this.state.isMenuOpen ? this.renderDropdownMenu() : null}
      </div>
    );
  },

  /**
   * Renders the category dropdown object.
   * @function renderCategoryDropdown
   * @memberof components.common.Dropdown
   * @instance
   */  
  renderCategoryDropdown: function() {
    return (
      <div className="dropdown-menu">
        {this.props.options.map((category, j) => {
          const { options, label } = category;

          return (
            <React.Fragment key={j}>
              <label className="dropdown-category" key={"label" + j}>
                {label}
              </label>
              <ul className="dropdown-option-list" key={"category" + j}
                role="radiogroup" aria-label={this.props.textLabel ?? this.props.label}>
                {options.map((option, i) => {
                  const isOptionActive = this.props.activeOption && this.props.activeOption[j] === i;

                  const listItemClasses = cx({
                    "dropdown-option-list-item": true,
                    "is-active": isOptionActive,
                  });

                  return (
                    <li className={listItemClasses} key={"" + i + "" + j}>
                      <button
                        className="option-name"
                        tabIndex={this.props.allowFocus ? 0 : -1}
                        onKeyDown={ADAUtils.handleKeyboard((e) => this.onClickOption(i, e, j))}
                        onClick={() => this.onClickOption(i, undefined, j)}
                        role="radio"
                        aria-label={`${this.props.textLabel ?? this.props.label}, ${option}`}
                        aria-checked={isOptionActive}
                      >
                        {option}
                      </button>
                      <span className="checkmark">
                        <ImageContainer className="icon" src={require("./../../../images/ui/checkmark-small-green.svg")} />
                      </span>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          );
        })}
      </div>
    );
  },

  /**
   * Renders the dropdown menu object.
   * @function renderDropdownMenu
   * @memberof components.common.Dropdown
   * @instance
   */  
  renderDropdownMenu: function() {
    if (this.props.hasCategories) {
      return this.renderCategoryDropdown();
    }

    return (
      <div className="dropdown-menu">
        <ul className="dropdown-option-list" role="radiogroup"
          aria-label={this.props.textLabel ?? this.props.label}>
          {this.props.options.map((option, i) => {
            const isOptionActive = this.props.activeOption === i;

            const listItemClasses = cx({
              "dropdown-option-list-item": true,
              "is-active": isOptionActive,
            });

            return (
              <li className={listItemClasses} key={i}>
                <button
                  className="option-name"
                  tabIndex={this.props.allowFocus ? 0 : -1}
                  onKeyDown={ADAUtils.handleKeyboard((e) => this.onClickOption(i, e))}
                  onClick={() => this.onClickOption(i)}
                  role="radio"
                  aria-label={`${this.props.textLabel ?? this.props.label}, ${option}`}
                  aria-checked={isOptionActive}
                >
                  <span className="option-text">{option}</span>
                </button>
                <span className="checkmark">
                  <ImageContainer className="icon" src={require("./../../../images/ui/checkmark-small-green.svg")} />
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  },

  /**
   * Renders on click menu action.
   * @param  {object} i  
   * @param  {object} e       
   * @param  {number} categoryIdx     
   * @function onClickOption
   * @memberof components.common.Dropdown
   * @instance
   */  
  onClickOption: function(i, e, categoryIdx) {
    this.setState({ isMenuOpen: false });
    this.props.onSelectOption(i, e, categoryIdx);
  },

  /**
   * Generates the state of toggle menu.
   * @function toggleMenu
   * @memberof components.common.Dropdown
   * @instance
   */  
  toggleMenu: function() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });

    document.querySelector('.search-input').value = "";
    if(document.querySelector('.common-search').classList.contains('is-open')) {
      document.querySelector('.common-search').classList.remove('is-open');
      document.querySelector('.common-search').classList.remove('is-open-by-ada');
      document.querySelector('.mobileSearchToggle').classList.remove('search--active');
      document.querySelector('.search-results-container').style.display = 'none';
    }
  },

  /**
   * Handles the keypress of menu item.
   * @function handleKeyPress
   * @memberof components.common.Dropdown
   * @instance
   */  
  handleKeyPress: function(event) {
    if (this.state.isMenuOpen && event.key == "Escape") {
      this.setState({
        isMenuOpen: false,
      });
    }
  },
});

export default Dropdown;
