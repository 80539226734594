import React from "react";
import PropTypes from "prop-types";

import cx from "classnames";

/**
 * <pre>
 * Creates a FormFieldInput for the application.
 * Package Name - components/common
 * </pre>
 * @class components.common.FormFieldInput
 */
class FormFieldInput extends React.Component {
  state = {
    showErrorMessage: false,
    showInvalidEmail: false,
    debounceInput: null,
    valid: false,
  };

  static propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
    maxLength: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.string.isRequired,
    allowFocus: PropTypes.bool,
    onChange: PropTypes.func,
    onError: PropTypes.func,
    onGotFocus: PropTypes.func,
    onLostFocus: PropTypes.func,
    idmod: PropTypes.string,      
  };

  /**
   * Renders the formfield input object.
   * @function render
   * @memberof components.common.FormFieldInput
   * @instance
   */
  render() {
    const classes = cx(
      "field",
      {
        hidden: this.props.hidden,
        invalid: this.state.showErrorMessage || (this.state.showInvalidEmail && !this.isValid()),
      },
      this.props.className,
    );

	const idGen = (this.props.idmod != null) ? 
					"comment-text-input-" + this.props.idmod + '-' + this.props.placeholder.replace(/\s+/g,'').toLowerCase()
					: "comment-text-input-" + this.props.placeholder.replace(/\s+/g,'').toLowerCase(); 

    return (
      <div className={classes}>
        <input
          className={"input " + this.props.inputClassName}
          maxLength={this.props.maxLength}
          type={this.props.type || "text"}
          value={this.props.value}
          tabIndex={this.props.allowFocus ? 0 : -1}
          role="textbox"
          aria-multiline="false"
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          id={idGen}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }

  /**
   * Action of the formfield input object.
   * @function onChange
   * @memberof components.common.FormFieldInput
   * @instance
   */
  onChange = (e) => {
    if (this.state.showErrorMessage && this.isValid()) this.setState({ showErrorMessage: false, showInvalidEmail: true });

    const newValue = e.target.value;
    if (this.props.onChange) this.props.onChange(newValue);

    if (this.state.debounceInput) {
      clearTimeout(this.state.debounceInput);
    }

    this.setState({ debounceInput: setTimeout(() => {
      this.onBlur();
      this.setState({ debounceInput: null });
    }, 100) });
  };

  /**
   * Action of the formfield input object on focus.
   * @function onFocus
   * @memberof components.common.FormFieldInput
   * @instance
   */
  onFocus = () => {
    if (this.props.onGotFocus) this.props.onGotFocus();
  };

  /**
   * Action of the formfield input object on blur.
   * @function onBlur
   * @memberof components.common.FormFieldInput
   * @instance
   */
  onBlur = () => {
    this.setState({ showErrorMessage: !this.isValid() });
    if (this.props.onLostFocus) this.props.onLostFocus();
  };

  /**
   * Checks whether the formfield input object is valid.
   * @function isValid
   * @memberof components.common.FormFieldInput
   * @instance
   */
  isValid = () => {
    const { valid } = this.state;

    if (this.props.type === "email") {
      const basicEmailRegex = /^[\w\.\-\\+]{1,}\@([\da-zA-Z\-]{1,}\.){1,}[\da-zA-Z]+$/;
      const crEmailRegexes = [
        /^jenmanz@aol.com$/,
        /^noemail@yahoo.com$/,
        /^some@yahoo.com$/,
        /^nowhere@anywhere.com$/,
        /^bogus@yahoo.com$/,
        /^[\w\.\-\\+]{1,}\@nowhere.com/,
        /^[\w\.\-\\+]{1,}\@none.com/,
        /^[\w\.\-\\+]{1,}\@noemails.com/,
        /^[\w\.\-\\+]{1,}\@no.com/,
        /^[\w\.\-\\+]{1,}\@noemail.com/,
        /^[\w\.\-\\+]{1,}\@nobody.com/,
        /^[\w\.\-\\+]{1,}\@nowhere.net/,
        /^[\w\.\-\\+]{1,}\@example.com/,
        /^[\w\.\-\\+]{1,}\@example.org/,
        /^[\w\.\-\\+]{1,}\@example.net/,
        /^[\w\.\-\\+]{1,}\@example.edu/,
      ];

      // console.log("Email: " + this.props.value);
      // console.log("Basic " + basicEmailRegex.test(this.props.value));
      // console.log("CR " + crEmailRegexes.every((regex) => !regex.test(this.props.value)));

      if (!basicEmailRegex.test(this.props.value)) return false;

      const result = crEmailRegexes.every((regex) => !regex.test(this.props.value));

      if (this.props.onError && result !== valid) {
        this.props.onError(!result);
      }

      return result;
    } else {
      this.setState({ valid: true });

      if (this.props.onError && !valid) {
        this.props.onError(false);
      }

      return true;
    }
  };
}

export default FormFieldInput;
