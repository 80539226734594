import React, { useState } from 'react';

const QaGroup = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className={"accordion-title is-open-" + JSON.stringify(isActive)} onClick={() => setIsActive(!isActive)}>
        <div><span className="accordion-title--q">Q:</span> {title} <span className={"accordion-icon is-open-" + JSON.stringify(isActive)}></span></div>
      </div>
      <div className={"accordion-content is-open-" + JSON.stringify(isActive)}>{content}</div>
    </div>
  );
};

export default QaGroup;