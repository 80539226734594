import PropTypes from "prop-types";
import React from "react";

/**
 * Class to create the question for rating in the Application.
 * Package Name - components/pages/feedback
 * @class components.pages.feedback.ChooseQuestion
 */
class ChooseQuestion extends React.Component {
  static propTypes = {
    question: PropTypes.object, // Question
    stringList: PropTypes.object, // StringList
    onChange: PropTypes.func, // index, value
    value: PropTypes.array, // current value
  };

  /**
   * Renders the rate question object.
   * @function render
   * @memberof components.pages.feedback.ChooseQuestion
   * @instance
   */
  render() {
    return (
      <div className={"cards cards-" + this.props.question.type}>
        {this.props.question.answers.map((answer, index) => {
          const id = this.props.question.id + "--" + index;

          return (
            <div key={index} className={"inputPreview cards-card cards-card-" + this.props.question.type}>
              <input
                type={this.props.question.type}
                className={"css-checkbox output-" + this.props.question.type}
                name={this.props.question.id}
                id={id}
                value={answer}
                checked={this.props.value && this.props.value.indexOf(answer) !== -1}
                onChange={this.onChangeInput}
              />
              <label htmlFor={id}>{answer}</label>
              {this.props.question.other && this.props.question.other === answer && (
                <label className="other_option_response">
                  <span>{answer}</span>
                  <input
                    type="text"
                    name={`${this.props.question.id}.other_option_response`}
                    id={`${id}.other_option_response`}
                    className="single-line"
                    onChange={this.onChangeOther}
                  />
                </label>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  /**
   * Action when question other input has been changed.
   * @function onChangeOther
   * @memberof components.pages.feedback.RateQuestion
   * @instance
   */
  onChangeOther = (e) => {
    this.props.onChange( `${this.props.question.id}.other_option_response`, e.target.value );
  };

  /**
   * Action when question input has been changed.
   * @function onChangeInput
   * @memberof components.pages.feedback.RateQuestion
   * @instance
   */
  onChangeInput = (e) => {
    this.props.onChange(this.props.question.id, e.target.value, this.props.question.type === "checkbox", !e.target.checked);
  };
}

export default ChooseQuestion;
