import AppDispatcher from "../dispatcher/AppDispatcher";
import SecurityPlannerConstants from "../constants/SecurityPlannerConstants";
import SecurityPlannerStore from "../stores/SecurityPlannerStore";

import MiniTracker from "../vendor/tracking/MiniTracker";

/**
 * <pre>
 * Defines the Actions that can be done on SecurityPlanner.
 * Package Name - actions
 * </pre>
 * @namespace actions.SecurityPlannerActions
 */
const SecurityPlannerActions = {

  /**
   * Toggles the selected state of a statement.
   * @param  {string} statementId  id of the statement 
   * @param  {string} statementSlug slug of the statement (optional) 
   * @function toggleStatementSelected 
   * @memberof actions.SecurityPlannerActions
   */
  toggleStatementSelected: function(statementId, statementSlug = undefined) {
    if (statementSlug) {
      if (SecurityPlannerStore.isStatementSelected(statementId)) {
        MiniTracker.trackEvent("statement-card", "deselected", statementSlug);
      } else {
        MiniTracker.trackEvent("statement-card", "selected", statementSlug);
      }
    }
    AppDispatcher.dispatch({
      actionType: SecurityPlannerConstants.Actions.SECURITY_PLANNER_TOGGLE_STATEMENT_SELECTED,
      text: statementId,
    });
  },

  /**
   * Deselects the statements selected.
   * @function deselectAllStatements
   * @memberof actions.SecurityPlannerActions
   */
  deselectAllStatements: function() {
    AppDispatcher.dispatch({
      actionType: SecurityPlannerConstants.Actions.SECURITY_PLANNER_DESELECT_ALL_STATEMENTS,
    });
  },

  /**
   * Shows the toast notification.
   * @param  {string} text  
   * @param  {string} success   
   * @param  {string} icon       
   * @function showToastNotification
   * @memberof actions.SecurityPlannerActions
   */
  showToastNotification: function(text, success, icon) {
    AppDispatcher.dispatch({
      actionType: SecurityPlannerConstants.Actions.SECURITY_PLANNER_SHOW_TOAST_NOTIFICATION,
      text: text,
      success: success,
      icon: icon,
    });
  },

  /**
   * Cleans the Toast Notifications.
   * @function cleanupToastNotifications 
   * @memberof actions.SecurityPlannerActions
   */
  cleanupToastNotifications: function() {
    AppDispatcher.dispatch({
      actionType: SecurityPlannerConstants.Actions.SECURITY_PLANNER_CLEANUP_TOAST_NOTIFICATIONS,
    });
  },

  /**
   * Toggle tools that have been selected as "completed".
   * @param  {string} toolId  id of the tool completed. 
   * @function toggleToolCompleted 
   * @memberof actions.SecurityPlannerActions
   */
  toggleToolCompleted: (toolId) => {
    AppDispatcher.dispatch({
      actionType: SecurityPlannerConstants.Actions.SECURITY_PLANNER_TOGGLE_TOOL_COMPLETED,
      text: toolId,
    });
  },
};

/**
 * TOAST_ICON_CHECK 
 * A static member of namespace SecurityPlannerActions
 * @type {string} 
 * @default check
 * @memberof actions.SecurityPlannerActions
 */
SecurityPlannerActions.TOAST_ICON_CHECK = "check";

export default SecurityPlannerActions;
