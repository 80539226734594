/**
 * <pre>
 * Class for a Primitive String component in store from retrieved data.
 * Package - stores/data. 
 * </pre>
 * @class stores.data.PrimitiveString
 */
export default class PrimitiveString {
  // ================================================================================================================
  // CONSTRUCTOR ----------------------------------------------------------------------------------------------------

  /**
   * Creates An Object of PrimitiveString Component. 
   * @constructor    
   */    
  constructor() {
    this.id = "";
    this.value = "";
    this.translationOutdated = false;
  }
}
