import StringUtils from "../../vendor/utils/StringUtils";

import SecurityPlannerConstants from "../../constants/SecurityPlannerConstants";

import Bio from "../data/Bio";
import Effect from "../data/Effect";
import Language from "../data/Language";
import Level from "../data/Level";
import ResourceLink from "../data/ResourceLink";
import Review from "../data/Review";
import SecurityState from "../data/SecurityState";
import Statement from "../data/Statement";
import Threat from "../data/Threat";
import Tool from "../data/Tool";
import PrimitiveString from "../data/PrimitiveString";
import ContentfulUpdates from "../ContentfulUpdates";

const DOMAIN_REGEXP = new RegExp("\\[\\[domain]]", "g");

/**
 * <pre>
 * Parses data from contentful into the models that are required for SecurityPlanner
 * Package - stores/parsing.  
 * </pre> 
 * @class stores.parsing.SecurityPlannerContentfulParser
 */
export default class SecurityPlannerContentfulParser {
  /**
   * Parses data from contentful into the models we need
   */

  // ================================================================================================================
  // CONSTRUCTOR ----------------------------------------------------------------------------------------------------

  /**
   * Creates an instance of SecurityPlannerContentfulParser
   * @param {Object} contentfulLoader
   * @param {array} desiredLanguages
   * @param {boolean} skipMassagingData
   * @constructor
   */
  constructor(contentfulLoader, desiredLanguages, skipMassagingData = false) {
    this.errors = [];
    this.warnings = [];

    this.skipMassagingData = skipMassagingData;

    this.desiredLanguages = desiredLanguages;
    this.contentfulLoader = contentfulLoader;
    this.defaultLanguage = undefined; // Language
    this.usedLanguage = undefined; // Language; Picked later, when parsing languages

    // Support meta-data
    this.languages = []; // Array of Language
    this.colors = {}; // key: id, value: integer

    // Real data
    this.statements = []; // Array of Statement
    this.levels = []; // Array of Level
    this.tools = []; // Array of Tool
    this.threats = []; // Array of Threat
    this.bios = []; // Array of Bio
    this.forms = []; // Array of Form

    this.strings = {}; // Key: id, value: PrimitiveString

    // Aux data (children)
    this.reviews = {}; // Key: id, value: Review
    this.links = {}; // Key: id, value: Link
    this.resourceLinks = {}; // Key: id, value: ResourceLink
    this.labels = {}; // Key: id, value: PrimitiveString

    const currentUrl = new URL(window.location.href);
    this.domain = `${currentUrl.protocol}//${currentUrl.host}`;

    // Parse everything
    this.parse();
  }

  // ================================================================================================================
  // PUBLIC INTERFACE -----------------------------------------------------------------------------------------------

  /**
   * Gets the errors of the parser.     
   * @function getErrors
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance      
   */
  getErrors() {
    return this.errors;
  }

  /**
   * Gets the warnings of the parser.     
   * @function getWarnings
   * @memberof stores.parsing.SecurityPlannerContentfulParser      
   * @instance      
   */
  getWarnings() {
    return this.warnings;
  }

  /**
   * Try to set the content to a new language id 
   * @param {string} languageId 
   * @function setLanguage
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance         
   */
  setLanguage(languageId) {
    const lang = this.languages.find((language) => language.id === languageId);
    if (lang) {
      // Changing the html lang attribute dynamically
      if (lang.code) {
        document.documentElement.lang = lang.code;
      }

      this.usedLanguage = lang;
      this.parse(true);
    }
  }

  /**
   * All the metadata available
   * @function getMetadata
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance          
   */
  getMetadata() {
    return this.contentfulLoader.metadata;
  }

  /**
   * Generate a simple list of strings
   * @function getStrings
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance       
   */
  getStrings() {
    const strs = {};
    Object.keys(this.strings).forEach((key) => {
      strs[key] = this.strings[key].value;
    });

    return strs;
  }

  // ================================================================================================================
  // INTERNAL INTERFACE ---------------------------------------------------------------------------------------------

  /**
   * Parses everything 
   * @param {boolean} redo    
   * @function getStrings
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance          
   */
  parse(redo = false) {
    if (!redo) this.parseLanguages();
    if (!redo) this.parseColors();
    this.parseStrings(redo);
    this.parseThreats(redo);
    this.parseResourceLinks(redo);
    this.parseLabels(redo);
    this.parseForms(redo);
    this.parseStatements(redo); // Depends on labels, colors, assets, links, resource links, threats
    this.parseQuestions(redo); // Depends on, and modifies, statements
    this.parseTools(redo); // Depends on links, resource links, reviews, threats, and labels
    if (!redo) this.parseEffects(); // Depends on a lot, modifies statements

    if (SecurityPlannerConstants.Parameters.IS_DEBUGGING) {
      /* eslint-disable no-console */
      console.log("LEVELS (" + this.levels.length + "): ", this.levels);
      console.log("STATEMENTS (" + this.statements.length + "): ", this.statements);
      console.log("TOOLS (" + this.tools.length + "): ", this.tools);
      console.log("THREATS (" + this.threats.length + "): ", this.threats);
      console.log("BIOS (" + this.bios.length + "): ", this.bios);
      console.log("FORMS (" + this.forms.length + "): ", this.forms);
      /* eslint-enable no-console */
    }
  }

  /**
   * Parses available languages
   * @function parseLanguages
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance        
   */
  parseLanguages() {
    const tLanguages = this.contentfulLoader.getEntries().language;
    this.languages = tLanguages.map((language) => {
      const langData = this.getLocaleNode(language);
      return Object.assign(new Language(), {
        id: langData.id,
        name: langData.name,
        direction: langData.direction,
        fallbackLanguage: langData.fallbackLanguage,
        default: langData.default,
        enabled: langData.enabled,
        code: langData.code,
      });
    });

    this.languages = this.removeDisabledItems(this.languages);

    this.defaultLanguage = this.languages.find((language) => language.default) || this.languages[0];

    // Also pick whatever language fits best
    let bestFit = undefined;
    for (let i = 0; i < this.desiredLanguages.length; i++) {
      // Search all available languages for a perfect fit
      const desiredLanguageId = this.desiredLanguages[i];
      bestFit = this.languages.find((language) => language.id === desiredLanguageId);
      if (bestFit) break;
      // Search for a close fit (e.g. "en-US" matches "en")
      bestFit = this.languages.find((language) => language.id.substr(0, 2) === desiredLanguageId.substr(0, 2));
      if (bestFit) break;
    }

    // If not found, just use the CMS's default language
    if (!bestFit) bestFit = this.defaultLanguage;

    this.usedLanguage = bestFit;
  }

  /**
   * Parse and interprets all colors
   * @function parseColors
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance           
   */
  parseColors() {
    this.colors = {};
    const tColors = this.contentfulLoader.getEntries().color;
    tColors.forEach((color) => {
      const colorData = this.getLocaleNode(color);
      if (!colorData.code) {
        return;
      }

      this.colors[colorData._id] = this.parseColor(colorData.code);
    });
  }

  /**
   * Parses tools' threat groups 
   * @param {boolean} inPlace      
   * @function parseThreats
   * @memberof stores.parsing.SecurityPlannerContentfulParser     
   * @instance       
   */
  parseThreats(inPlace) {
    const tThreats = this.contentfulLoader.getEntries().threat;
    const newThreats = tThreats.map((threat) => {
      const threatData = this.getLocaleNode(threat, this.usedLanguage.id);
      const threatDataEN = this.getLocaleNode(threat);
      return Object.assign(new Threat(), {
        id: threatData._id,
        slug: StringUtils.slugify(threatDataEN.name),
        name: threatData.name,
        shortDescription: this.insertDomain(threatData.description),
        longDescription: this.insertDomain(threatData.recommendation),
        stats: threatData.statistics,
        statsSource: threatData.statisticsSourceUrl,
        statsName: threatData.statisticsSource,
        isAdditionalHelp: threatData.isAdditionalHelp,
        translationOutdated: threatData.translationOutdated,
        deprioritizeInLists: !!threatData.deprioritizeInLists,
      });
    });

    // For testing purposes:
    // newThreats[0].name = "a";
    // newThreats[1].name = "bbbbb bbbb bbb"; // works in large
    // newThreats[1].name = "bbbbb bbbb bbbb"; // break in large, works in largest medium

    if (!inPlace) {
      this.threats = newThreats;
    } else {
      this.mergeArrays(this.threats, newThreats);
    }
  }

  insertDomain(input) {
    if (!input || typeof input !== "string") return input;

    return input.replace(DOMAIN_REGEXP, this.domain);
  }

  /**
   * Resource links, used in tools 
   * @param {boolean} inPlace      
   * @function parseResourceLinks
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance      
   */
  parseResourceLinks(inPlace) {
    const newResourceLinks = {};
    const tResourceLinks = this.contentfulLoader.getEntries().resourceLink;
    tResourceLinks.forEach((resourceLink) => {
      const linkData = this.getLocaleNode(resourceLink, this.usedLanguage.id);
      const linkDataEN = this.getLocaleNode(resourceLink);
      const newLink = Object.assign(new ResourceLink(), {
        id: linkData._id,
        slug: StringUtils.slugify(linkDataEN.caption) + "--" + StringUtils.slugify(linkDataEN.source || ""),
        caption: this.insertDomain(linkData.caption),
        url: this.insertDomain(linkData.url),
        source: linkData.source,
        translationOutdated: linkData.translationOutdated,
      });
      newResourceLinks[newLink.id] = newLink;
    });

    if (!inPlace) {
      this.resourceLinks = newResourceLinks;
    } else {
      this.mergeObjects(this.resourceLinks, newResourceLinks);
    }
  }

  /**
   * Parse tool labels
   * @param {boolean} inPlace      
   * @function parseLabels
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance        
   */
  parseLabels(inPlace) {
    const newLabels = {};
    const tLabels = this.contentfulLoader.getEntries().label;
    tLabels.forEach((label) => {
      const labelData = this.getLocaleNode(label, this.usedLanguage.id);
      newLabels[labelData._id] = Object.assign(new PrimitiveString(), {
        id: labelData._id,
        value: labelData.caption,
        translationOutdated: labelData.translationOutdated,
      });
    });

    if (!inPlace) {
      this.labels = newLabels;
    } else {
      this.mergeObjects(this.labels, newLabels);
    }
  }

  /**
   * Parse forms
   * @param {boolean} inPlace      
   * @function parseForms
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance        
   */
    parseForms(inPlace) {
      const newForms = {};
      const tForms = this.contentfulLoader.getEntries().form;
      tForms.forEach((form) => {
        const formData = this.getLocaleNode(form, this.usedLanguage.id);
        newForms[formData.slug] = Object.assign(new PrimitiveString(), {
          slug: formData.slug,
          data: formData.data,
          translationOutdated: formData.translationOutdated,
        });
      });
  
      if (!inPlace) {
        this.forms = newForms;
      } else {
        this.mergeObjects(this.forms, newForms);
      }
    }

  /**
   * Parses statement cards 
   * @param {boolean} inPlace      
   * @function parseStatements
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance      
   */
  parseStatements(inPlace) {
    const tStatements = this.contentfulLoader.getEntries().statement;

    let newStatements = tStatements.map((statement) => {
      if (Object.keys(statement).length <= 1) {
        return;
      }

      const statementData = this.getLocaleNode(statement, this.usedLanguage.id);
      const statementDataEN = this.getLocaleNode(statement);
      const image = this.getAsset(statementData.image.sys.id);

      return Object.assign(new Statement(), {
        id: statementData._id,
        slug: StringUtils.slugify(statementDataEN.caption),
        text: statementData.caption,
        image: image.url,
        imageDescription: image.description,
        backgroundColor: this.getColor(statementData.backgroundColor.sys.id),
        isRequired: statementData.alwaysVisible,
        requirements: this.getStatementRequirementsAny(statementData.prerequisites),
        translationOutdated: statementData.translationOutdated,
        enabled: statementData.enabled,
        // These will come later
        level: undefined, // added during parseQuestions()
        selectedEffects: [], // added during parseEffects()
        deselectedEffects: [], // added during parseEffects()
      });
    });

    newStatements = this.removeDisabledItems(newStatements);

    if (!inPlace) {
      this.statements = newStatements;
    } else {
      this.mergeArrays(this.statements, newStatements, ["selected"]);
    }
  }

  /**
   * Parse question levels 
   * @param {boolean} inPlace      
   * @function parseQuestions
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance       
   */
  parseQuestions(inPlace) {
    let tQuestions = this.contentfulLoader.getEntries().question;

    // TODO: REMOVE FOR PRODUCTION
    tQuestions = tQuestions.map((question) => {
      const title = question.title["en-US"].trim();

      switch (title) {
      case "What do you use to handle your private information?": {
        return {
          ...question,
          title: {
            "en-US": "Which of the following do you use?",
          },
        };
      }
      case "Click all your top online security concerns.": {
        return {
          ...question,
          title: {
            "en-US": "What do you want to do?",
          },
        };
      }
      default: {
        return question;
      }
      }
    });

    let newLevels = tQuestions.map((question) => {
      const questionData = this.getLocaleNode(question, this.usedLanguage.id);
      const questionDataEN = this.getLocaleNode(question);
      const newLevel = Object.assign(new Level(), {
        id: questionData._id,
        order: questionData.order,
        slug: StringUtils.slugify(questionData.order + "--" + questionDataEN.title),
        title: questionData.title,
        recommendationsNeeded: questionData.showRecommendationAfter ? 1 : 0,
        answersRequired: questionData.minimumRequiredStatements,
        translationOutdated: questionData.translationOutdated,
        enabled: questionData.enabled,
      });

      // Also update the statements, with which level they belong to
      const statementsData = questionData.statements;
      statementsData.forEach((statementData) => {
        const statement = this.getStatement(statementData.sys.id);
        if (statement) {
          statement.level = newLevel.id;
          newLevel.statements.push(statement);
        }
      });

      return newLevel;
    });

    newLevels = this.removeDisabledItems(newLevels);

    // Re-order
    newLevels.sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });

    if (!inPlace) {
      this.levels = newLevels;
    } else {
      this.mergeArrays(this.levels, newLevels);
    }
  }

  /**
   * Parse tools 
   * @param {boolean} inPlace      
   * @function parseTools
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance         
   */
  parseTools(inPlace) {
    const tTools = this.contentfulLoader.getEntries().tool;
    let newTools = tTools.map((tool) => {
      if (Object.keys(tool).length <= 1) {
        return;
      }

      const toolData = this.getLocaleNode(tool, this.usedLanguage.id);
      const toolDataEN = this.getLocaleNode(tool);

      const image = this.getAsset(toolData.image.sys.id);

      // Massage data: if they used the word "Free" in the cost, remove it
      const freeEquivalent = this.strings["all-tools-filter-cost-free"].value;
      if (!this.skipMassagingData && toolDataEN.cost && freeEquivalent && toolDataEN.cost.toLowerCase() === freeEquivalent.toLowerCase()) {
        toolDataEN.cost = "";
        toolData.cost = "";
      }

      return Object.assign(new Tool(), {
        id: toolData._id,
        slug: toolData.slug || StringUtils.slugify(toolDataEN.name),
        threat: this.getThreat(toolData.threat.sys.id),
        image: image.url,
        imageDescription: image.description,
        name: toolData.name,
        headline: toolData.callToAction,
        label: this.getLabelValue(toolData.label.sys.id),
        price: toolData.cost,
        shortDescription: this.insertDomain(toolData.shortDescription),
        longDescription: this.insertDomain(toolData.longDescription),
        overlayDescription: this.insertDomain(toolData.overlayDescription),
        date: this.parseDate(toolData._updatedAt),
        dump: toolData,
        whyItsImportant: this.insertDomain(toolData.whyItsImportant),
        buttons: [],
        // DEPRECATED
        // buttons: this.getLinkList(toolData.buttons),
        earlyRecommendationAllowed: toolData.allowAsEarlyRecommendation,
        translationOutdated: toolData.translationOutdated,
        enabled: toolData.enabled,
        requirements: this.getStatementRequirementsAny(toolData.prerequisites),
        // DEPRECATED
        // reviews: this.getReviewList(toolData.reviews),
        resources: this.getResourceLinkList(toolData.resources),
        keywords: toolData.keywords || []
      });
    });

    newTools = this.removeDisabledItems(newTools);

    if (!inPlace) {
      this.tools = newTools;
    } else {
      this.mergeArrays(this.tools, newTools);
    }
  }

  parseDate(date) {
    var stringDate = new Date(date);

    var day = stringDate.getDate();
    var month = stringDate.toLocaleString('default', {month: 'long'});
    var year = stringDate.getFullYear();

    var finalString = day + " " + month + " " + year; 
    return finalString;
  }

  /**
   * Parse statement selection effects for tools 
   * @function parseEffects
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance       
   */
  parseEffects() {
    const tEffects = this.contentfulLoader.getEntries().effect;
    tEffects.forEach((effect) => {
      const effectData = this.getLocaleNode(effect, this.usedLanguage.id);
      const statement = this.getStatement(effectData.statement.sys.id);
      if (statement && effectData.tools) {
        const newEffect = new Effect();
        const points = effectData.points;
        const tools = effectData.tools ? effectData.tools.map((tool) => tool.sys.id) : [];
        tools.forEach((toolId) => {
          newEffect.tools[toolId] = points;
        });

        const whenSelected = effectData.value;
        if (whenSelected) {
          statement.selectedEffects.push(newEffect);
        } else {
          statement.deselectedEffects.push(newEffect);
        }
      }
    });
  }

  /**
   * All other strings
   * @param {boolean} inPlace      
   * @function parseStrings
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance       
   */
  parseStrings(inPlace) {
    const newStrings = {};

    // String copy
    const tCopy = this.contentfulLoader.getEntries().copy;
    if (tCopy)
      tCopy.forEach((copy) => {
        const copyData = this.getLocaleNode(copy, this.usedLanguage.id);
        newStrings[copyData.id] = Object.assign(new PrimitiveString(), {
          id: copyData.id,
          value: this.insertDomain(copyData.value),
          translationOutdated: copyData.translationOutdated,
        });
      });

    // String (long) copy
    const tCopyBody = this.contentfulLoader.getEntries().copyBody;
    if (tCopyBody)
      tCopyBody.forEach((copyBody) => {
        const copyData = this.getLocaleNode(copyBody, this.usedLanguage.id);
        newStrings[copyData.id] = Object.assign(new PrimitiveString(), {
          id: copyData.id,
          value: this.insertDomain(copyData.value),
          translationOutdated: copyData.translationOutdated,
        });
      });

    // TODO: REMOVE FOR PRODUCTION
    Object.keys(ContentfulUpdates.strings).forEach((id) => {
      newStrings[id] = Object.assign(new PrimitiveString(), {
        id,
        value: this.insertDomain(ContentfulUpdates.strings[id]),
        translationOutdated: false,
      });
    });

    if (!inPlace) {
      this.strings = newStrings;
    } else {
      this.mergeObjects(this.strings, newStrings);
    }
  }

  /**
   * Returns a node with data from a specific locale
   * @param {object} node        
   * @param {object} preferredLocale     
   * @function getLocaleNode
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance    
   */
  getLocaleNode(node, preferredLocale = undefined) {
    const newObj = {};

    // Creates list of all preferred locales; default first, followed by its fallbacks, and finally our hard-coded default
    let locales = [];

    // Add preferred languages
    if (preferredLocale) {
      locales.push(preferredLocale);
      // Also add their fallbacks
      locales = locales.concat(this.getLocaleFallbacks(preferredLocale, locales));
    }

    // Also add the hardcoded default
    locales.push(this.defaultLanguage ? this.defaultLanguage.id : SecurityPlannerContentfulParser.DEFAULT_LANGUAGE);

    // Finally, search for a node with that key in the object
    for (const key in node) {
      let value = node[key];
      // If it has a key with any of the preferred locales, use it
      for (let i = 0; i < locales.length; i++) {
        if (value.hasOwnProperty(locales[i])) {
          value = value[locales[i]];
          break;
        }
      }
      newObj[key] = value;
    }
    return newObj;
  }

  /**
   * Based on a locale (e.g. "en-US"), get all fallback as defined by the languages
   * @param {string} locale        
   * @param {array} ignoreLanguages   
   * @function getLocaleFallbacks
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance       
   */
  getLocaleFallbacks(locale, ignoreLanguages = []) {
    let locales = [];

    // Search in all languages
    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].id === locale) {
        const fallbackLocale = this.languages[i].fallbackLanguage;
        if (fallbackLocale && ignoreLanguages.indexOf(fallbackLocale) === -1) {
          // Has fallback
          locales.push(fallbackLocale);

          // Also add all fallbacks of the fallback
          const allFallbacks = this.getLocaleFallbacks(fallbackLocale, ignoreLanguages.concat(locale));
          if (allFallbacks && allFallbacks.length > 0) locales = locales.concat(allFallbacks);
        }
        break;
      }
    }

    return locales;
  }

  /**
   * Based on a list of statement records, returns a list of requirements following our query format.
   * I.e. ["statement-id-1", "or", "statement-id-2"]
   *
   * Notice that the parsing is simplified. The actual engine when selecting allows much more complex querying:
   * Simple: ['my-organization-needs-better-security-practices']
   * Negation: ['!my-organization-needs-better-security-practices']
   * OR: ['my-accounts-are-not-secure-enough', 'or', 'mistakenly-entering-passwords-on-suspicious-web-sites']
   * AND: ['my-accounts-are-not-secure-enough', 'and', 'mistakenly-entering-passwords-on-suspicious-web-sites']
   * Parenthesis: ['my-accounts-are-not-secure-enough', 'and', ['mistakenly-entering-passwords-on-suspicious-web-sites', 'or', 'other']]
   * @param {array} statements        
   * @function getStatementRequirementsAny
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance          
   */
  getStatementRequirementsAny(statements) {
    const list = [];
    if (statements) {
      statements.forEach((statement) => {
        if (list.length > 0) list.push(SecurityState.REQUIREMENTS_OPERATOR_OR);
        list.push(statement.sys.id);
      });
    }
    return list;
  }

  /**
   * Based on an id, return the URL of an asset
   * @param {string} id        
   * @function getAsset
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance       
   */
  getAsset(id) {
    const lang = this.usedLanguage.id;
    const assets = this.contentfulLoader.getAssets();
    if (assets.hasOwnProperty(id)) {
      const localizedAsset = this.getLocaleNode(assets[id], lang);
      return localizedAsset;
    } else {
      this.errors.push(`Tried reading an asset id of type ${id} that was not found`);
      return { url: `ASSET_${id}_NOT_FOUND_URL`, description: '' };
    }
  }

  /**
   * Based on a color id, return an integer
   * @param {string} id        
   * @function getColor
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance          
   */
  getColor(id) {
    if (this.colors.hasOwnProperty(id)) {
      return this.colors[id];
    } else {
      this.errors.push(`Tried reading a color with id ${id} that was not found`);
      return 0x000000;
    }
  }

  /**
   * Based on an id, return a threat
   * @param {string} id        
   * @function getThreat
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance         
   */
  getThreat(id) {
    const threat = this.threats.find((threat) => threat.id === id);
    if (threat) {
      return threat;
    } else {
      this.errors.push(`Tried reading a threat with id ${id} that was not found`);
      return undefined;
    }
  }

  /**
   * Based on an id, return a statement 
   * @param {string} id        
   * @function getStatement
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance          
   */
  getStatement(id) {
    const statement = this.statements.find((statement) => statement.id === id);
    if (statement) {
      return statement;
    } else {
      this.errors.push(`Tried reading a statement with id ${id} that was not found`);
      return undefined;
    }
  }

  /**
   * Based on a label id, return its string
   * @param {string} id        
   * @function getLabelValue
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance   
   */
  getLabelValue(id) {
    if (this.labels.hasOwnProperty(id)) {
      return this.labels[id].value;
    } else {
      this.errors.push(`Tried reading a label with id ${id} that was not found`);
      return "NOT-FOUND";
    }
  }

  /**
   * Based on a link id, return its Link
   * @param {string} id        
   * @function getLink
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance      
   */
  getLink(id) {
    if (this.links.hasOwnProperty(id)) {
      return this.links[id];
    } else {
      this.errors.push(`Tried reading a link with id ${id} that was not found`);
      return undefined;
    }
  }

  /**
   * Based on a resource link id, return its ResourceLink 
   * @param {string} id        
   * @function getResourceLink
   * @memberof stores.parsing.SecurityPlannerContentfulParser     
   * @instance         
   */
  getResourceLink(id) {
    if (this.resourceLinks.hasOwnProperty(id)) {
      return this.resourceLinks[id];
    } else {
      this.errors.push(`Tried reading a resourceLink with id ${id} that was not found`);
      return undefined;
    }
  }

  /**
   * Based on a review id, return its Review
   * @param {string} id        
   * @function getReview
   * @memberof stores.parsing.SecurityPlannerContentfulParser    
   * @instance         
   */
  getReview(id) {
    if (this.reviews.hasOwnProperty(id)) {
      return this.reviews[id];
    } else {
      this.errors.push(`Tried reading a review with id ${id} that was not found`);
      return undefined;
    }
  }

  /**
   * Based on a bio id, return its Bio 
   * @param {string} id        
   * @function getBio
   * @memberof stores.parsing.SecurityPlannerContentfulParser     
   * @instance      
   */
  getBio(id) {
    const bio = this.bios.find((bio) => bio.id === id);
    if (bio) {
      return bio;
    } else {
      this.errors.push(`Tried reading a bio with id ${id} that was not found`);
      return undefined;
    }
  }

  /**
   * Pastes one array on top of another array, to change it in-place without changing references
   * @param {array} originalList    
   * @param {array} newList 
   * @param {array} ignoreFields             
   * @function mergeArrays
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance       
   */
  mergeArrays(originalList, newList, ignoreFields = []) {
    if (originalList.length != newList.length) {
      console.warn("Error: cannot merge arrays properly, different lenghts!"); // eslint-disable-line
    }
    originalList.forEach((oldItem, oldIndex) => {
      for (const key in oldItem) {
        if (!ignoreFields || ignoreFields.indexOf(key) == -1) {
          const t = typeof oldItem[key];
          if (t === "string" || t === "number" || t === "boolean" || t === "undefined") {
            oldItem[key] = newList[oldIndex][key];
          }
        }
      }
    });
  }

  /**
   * Pastes one object on top of another array, to change it in-place without changing references
   * @param {object} originalObject  
   * @param {object} newObject
   * @param {number} levels          
   * @function mergeObjects
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance          
   */
  mergeObjects(originalObject, newObject, levels = 1) {
    if (Object.keys(originalObject).length != Object.keys(newObject).length) {
      console.warn("Error: cannot merge objects properly, different number of values!"); // eslint-disable-line
    }
    Object.keys(originalObject).forEach((oldKey) => {
      const oldItem = originalObject[oldKey];
      const t = typeof oldItem;
      if (t === "string" || t === "number" || t === "boolean" || t === "undefined") {
        originalObject[oldKey] = newObject[oldKey];
      } else if (t === "object" && !Array.isArray(oldItem) && levels > 0) {
        this.mergeObjects(oldItem, newObject[oldKey], levels - 1);
      }
    });
  }

  /**
   * Converts a #rrggbb color code to its 0xrrggbb equivalent
   * @param {string} code         
   * @function parseColor
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance             
   */
  parseColor(code) {
    if (code.length !== 7) {
      this.errors.push(`Tried parsing an invalid color code of [${code}]`);
      return 0x000000;
    } else {
      return parseInt(code.substr(1, 6), 16);
    }
  }

  /**
   * Removes items that don't have .enabled:true 
   * @param {object|array} list         
   * @function removeDisabledItems
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance            
   */
  removeDisabledItems(list) {
    if (!SecurityPlannerConstants.Content.IGNORE_DISABLED_ITEMS) return list;
    if (Array.isArray(list)) {
      return list.filter((item) => item && item.enabled);
    } else {
      const keys = Object.keys(list);
      keys.forEach((key) => {
        if (!list[key].enabled) {
          delete list[key];
        }
      });
      return list;
    }
  }

  /**
   * Converts a list of internal link data into a Link array
   * @param {object|array} links        
   * @function getLinkList
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance       
   */
  getLinkList(links) {
    if (!links) return [];
    return links.map((link) => {
      return this.getLink(link.sys.id);
    });
  }

  /**
   * Converts a list of internal link data into a ResourceLink array
   * @param {object|array} resourceLinks        
   * @function getResourceLinkList
   * @memberof stores.parsing.SecurityPlannerContentfulParser       
   * @instance          
   */
  getResourceLinkList(resourceLinks) {
    if (!resourceLinks) return [];
    return resourceLinks.map((resourceLink) => {
      return this.getResourceLink(resourceLink.sys.id);
    });
  }

  /**
   * Converts a list of internal review data into a Review array
   * @param {object|array} reviews      
   * @function getReviewList
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance       
   */
  getReviewList(reviews) {
    if (!reviews) return [];
    return reviews.map((review) => {
      return this.getReview(review.sys.id);
    });
  }
}

SecurityPlannerContentfulParser.DEFAULT_LANGUAGE = "en-US";
