import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import * as marked from "marked";

import PageSectionHelper from "../../../vendor/utils/PageSectionHelper";
import DirectionUtils from "../../../vendor/utils/DirectionUtils";

import SecondaryMenu from "../../common/SecondaryMenu.react";
import FooterMenu from "../../common/FooterMenu.react";

import QAGroup from "./QaGroup.react";

import MarkdownAnchorLinkReplacer from "../../../vendor/utils/MarkdownAnchorLinkReplacer";

/**
 * <pre>
 * Class to generate a philosophy page for the Application.
 * Package Name - components/pages/philosophy
 * </pre>
 * @class components.pages.nomatch.AskExpertPage
 */
class AskExpertPage extends React.Component {
  static propTypes = {
    stringList: PropTypes.object, // StringList
    selectedLanguage: PropTypes.object, // Language
    availableLanguages: PropTypes.arrayOf(PropTypes.object), // Language[]
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    tools: PropTypes.array, // Tool[]
    goToPage: PropTypes.func.isRequired,
    checkPage: PropTypes.func.isRequired,
    hasPlan: PropTypes.func.isRequired,
  };

  helper = undefined;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.helper = new PageSectionHelper(this);
    this.markdownAnchorLinkReplacer = new MarkdownAnchorLinkReplacer(this.props.goToPage, this.props.checkPage);
  }

  componentDidMount() {
    this.helper.setElement(ReactDOM.findDOMNode(this).querySelector(".content"));
    this.markdownAnchorLinkReplacer.replaceDOMLinksWithRoutes(this.description);
  }

  componentDidUpdate() {
    this.helper.setElement(ReactDOM.findDOMNode(this).querySelector(".content"));
  }

  componentWillUnmount() {
    this.helper.destroy();
  }

  formSubmit = (event) => {
    event.preventDefault();
    const fname = document.querySelector('.ask-expert-fname').value;
    const lname = document.querySelector('.ask-expert-lname').value;
    const email = document.querySelector('.ask-expert-email').value;
    const comment = document.querySelector('.ask-expert-textarea').value;
    const consent = document.querySelector('.ask-expert-consent').checked;

    if(fname == '' || lname == '' || comment == '') {
      var divs = document.querySelectorAll('.error'), i;

      for (i = 0; i < divs.length; ++i) {
        divs[i].style.display = 'inline-block';
      }
    } else {
      console.log(fname);
      console.log(lname);
      console.log(email);
      console.log(comment);
      console.log(consent);
    }
  }

  /**
   * Renders the philosophy page object.
   * @function render
   * @memberof components.pages.nomatch.PhilosophyPage
   * @instance
   */
  render() {

    const questions = this.props.stringList.get("ask-expert-questions").split('\n');
    const answers = this.props.stringList.get("ask-expert-answers").split('---');

    return (
      <div
        className={"sectionPageHolder page page-ask-expert " + DirectionUtils.getClass(this.props.stringList)}
        aria-hidden={!this.helper.isActive()}
        style={{ height: this.helper.getWindowHeight() }}
      >
        <div className="content">
          <SecondaryMenu
            stringList={this.props.stringList}
            currentLocation={SecondaryMenu.LOCATION_PHILOSOPHY}
            availableLanguages={this.props.availableLanguages}
            selectedLanguage={this.props.selectedLanguage}
            routes={this.props.routes}
            hasPlan={this.props.hasPlan}
            goToPage={this.props.goToPage}
            allowFocus={this.helper.isActive()}
            tools={this.props.tools}
            useLightStyle={true}
            className="secondary-menu"
          />
            <div className="headline-container">
              <h1 className="headline">{this.props.stringList.get("ask-experts-title")}</h1>
              <p class="sub-headline">{this.props.stringList.get("ask-experts-sub-title")}</p>
            </div>
          <div className="middle" id={SecondaryMenu.LOCATION_PHILOSOPHY}>
            <div className="page-content">
              <h2 class="mid-headline">{this.props.stringList.get("ask-experts-accordion-title")}</h2>
              <div className="tabs-body">
                {questions.map((item,index)=>{
                 return  <QAGroup
                    index={index}
                    key={index}
                    stringList={this.props.stringList}
                    title={item}
                    content={answers[index]}
                  />
                })}
            </div>
              <h2 className="mid-headline">{this.props.stringList.get("ask-expert-form-title")}</h2>
              <p class="sub-headline">{this.props.stringList.get("ask-expert-form-text")}</p>
              <form onSubmit={this.formSubmit}>
                <div className="ask-expert-form-container-flex">
                  <div>
                    <label>{this.props.stringList.get("ask-expert-form-fname")}<sup className="error">*</sup><em className="error">Required</em></label>
                  <input type="text" className="ask-expert-fname"/>
                  </div>
                  <div>
                    <label>{this.props.stringList.get("ask-expert-form-lname")}<sup className="error">*</sup><em className="error">Required</em></label>
                  <input type="text" className="ask-expert-lname"/>
                  </div>
                  <div>
                    <label>{this.props.stringList.get("ask-expert-form-email")}</label>
                  <input type="text" className="ask-expert-email"/>
                  </div>
                </div>

                <div className="ask-expert-form-container">
                  <label>{this.props.stringList.get("ask-expert-form-textarea")}<sup className="error">*</sup><em className="error">Required</em></label>
                  <textarea className="ask-expert-textarea"></textarea>
                  <input type="checkbox" className="ask-expert-consent" name="consent" value="Consent"/>
                  <label for="consent" className="consent-label"> Consumer Reports may contact me about this issue. (optional)</label>

                  <button class="common-button-details common-button-details-like-action button-submit ask-expert-submit" tabindex="0" role="button" title="Submit feedback" data-testid="ask-expert-submit"><div>Submit feedback</div></button>
                </div>
              </form>
            </div>
          </div>
          <FooterMenu
            stringList={this.props.stringList}
            currentLocation={SecondaryMenu.LOCATION_ABOUT}
            style={FooterMenu.STYLE_LIGHT}
            routes={this.props.routes}
            goToPage={this.props.goToPage}
            allowFocus={this.helper.isActive()}
            selectedLanguage={this.props.selectedLanguage}
            availableLanguages={this.props.availableLanguages}
          />
        </div>
      </div>
    );
  }

  /**
   * Ran when the section becomes the focused section
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	   
   * @param  {object} fromOverlay	        
   * @function onActivate
   * @memberof components.pages.nomatch.PhilosophyPage
   * @instance        
   */
  onActivate = (travelOffset, viaHistoryAPI, fromOverlay) => {
    this.helper.onActivate(travelOffset, viaHistoryAPI, fromOverlay);
  };

  /**
   * Ran when the section is about to lose focus
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	   
   * @param  {object} fromOverlay	        
   * @function onDeactivate
   * @memberof components.pages.nomatch.PhilosophyPage
   * @instance     
   */
  onDeactivate = (travelOffset, viaHistoryAPI, toOverlay) => {
    this.helper.onDeactivate(travelOffset, viaHistoryAPI, toOverlay);
  };

  /**
   * Returns the color (as a number) that the locator bar should have when opaque
   * @function getDesiredLocatorBackgroundColor
   * @memberof components.pages.nomatch.AskExpertPage
   * @instance   
   */
  getDesiredLocatorBackgroundColor = () => {
    return undefined;
  };
}

export default AskExpertPage;
