/**
 * <pre>
 * Class for a Bio component in store from retrieved data.
 * Package - stores/data. 
 * </pre>
 * @class stores.data.Bio 
 */
export default class Bio {

  /**
   * Creates An Object of Bio Component. 
   * @constructor 
   */    
  constructor() {
    this.id = "";
    this.slug = ""; // Friendly slug, for reporting
    this.name = "";
    this.organization = "";
    this.image = "";
    this.label = "";
    this.description = "";
    this.sectionID = "";
    this.position = undefined;
    this.translationOutdated = false;
    this.enabled = true;
  }
}
