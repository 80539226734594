/**
 * <pre>
 * Class For Generating Tracking Information
 * Package - vendor/tracking. 
 * </pre>
 * @class vendor.tracking.MiniTracker
 */
export default class MiniTracker {
  // ================================================================================================================
  // PUBLIC STATIC INTERFACE ----------------------------------------------------------------------------------------

 /**
   * Generates a message for tracking page. 
   * @param  {number} pageId                 
   * @function trackPage
   * @memberof vendor.tracking.MiniTracker
   */
  static trackPage(pageId) {
    if (pageId.substr(0, 1) != "/") pageId = "/" + pageId;

    if (!!window.ga && pageId != MiniTracker.lastTrackedPageId) {
      window.ga("send", "pageview", pageId);

      MiniTracker.lastTrackedPageId = pageId;

      if (MiniTracker.isLogging) console.log("[TRACK] Page: [" + pageId + "]"); // eslint-disable-line
    }
  }

 /**
   * Generates a message for tracking event. 
   * @param  {string} category                 
   * @param  {string} action                  
   * @param  {string} label                 
   * @param  {string} value                 
   * @param  {string} nonInteraction                             
   * @function trackEvent
   * @memberof vendor.tracking.MiniTracker
   */
  static trackEvent(category, action, label, value, nonInteraction) {
    // Eg. button, click, nav button, 4
    // Push objects to GTM dataLayer, push string balues direct to GA
    if (window.ga && typeof category !== "object" ) {
      window.ga("send", "event", category, action, label, value, {
        nonInteraction: nonInteraction,
        page: MiniTracker.lastTrackedPageId,
      });

      if (MiniTracker.isLogging)
        console.log("[TRACK] Event: Category [" + category + "], Action [" + action + "], Label [" + label + "], Value [" + value + "] "); // eslint-disable-line
    }
    if( window.dataLayer && typeof category === "object" ) {
      window.dataLayer.push(category);
    }
  }
}

MiniTracker.lastTrackedPageId = undefined;
MiniTracker.isLogging = false;
