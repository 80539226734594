import PropTypes from "prop-types";
import React from "react";
import ADAUtils from "../../vendor/utils/ADAUtils";

/**
 * <pre>
 * Class used for Branding the Menu segment as per CR branding.
 * Package Name - components/common
 * </pre>
 * @class components.common.Branding
 */
class Branding extends React.Component {
  static propTypes = {
    stringList: PropTypes.object.isRequired, // StringList
    goToPage: PropTypes.func.isRequired,
    routes: PropTypes.object.isRequired,
    hardLoad: PropTypes.bool
  };

  /**
   * Renders the branding object.
   * @function render
   * @memberof components.common.Branding
   * @instance
   */
  render() {
    return (
      <div
        className="branding"
        onClick={this.showHome}
        aria-label={this.props.stringList.get("logo-adjacent-title")}
        onKeyDown={ADAUtils.handleKeyboard(this.showHome)}
        key="branding"
        tabIndex={0}
      >
        <div className="brand-layout">
          <a href="http://www.consumerreports.org" target="_blank" >
            <img className="logo" src={require("./../../../images/page-cover/cr-logo-condensed.svg")}
              alt={this.props.stringList.get("branding-consumer-reports-logo")} />
          </a>
          <div className="vert-divider" />
          <a href={this.props.routes.getUriCover()} onClick={(e) => {
            e.preventDefault();
            this.props.goToPage(this.props.routes.getUriCover(), false, true, this.props.hardLoad);
          }}>
            <img className="lock-logo" src={require("./../../../images/page-cover/lock-logo.svg")}
              alt={this.props.stringList.get("branding-security-planner-logo")} />
            <div className="text">{this.props.stringList.get("logo-adjacent-title")}</div>
          </a>
        </div>
      </div>
    );
  }
}

export default Branding;
