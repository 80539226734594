import React from "react";
import PropTypes from "prop-types";
import * as marked from "marked";

/**
 * <pre>
 * Class to create a lightbox for the bio in the Application.
 * Package Name - components/pages/about
 * </pre>
 * @class components.pages.about.Lightbox
 */
class Lightbox extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool,
    content: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
  }

  /**
   * Renders the lightbox section object.
   * @function render
   * @memberof components.pages.about.Lightbox
   * @instance
   */
  render() {
    return (
      <div className={`lightbox ${this.props.isActive ? "lightbox-open" : ""}`} 
        onClick={this.handleClick}
        id="lightbox-background"
        aria-hidden={this.props.isActive}
      >
        <div className="lightbox-container">
          <button 
            className="lightbox-close-button"
            onClick={() => this.props.onClose()}
            tabIndex={this.props.isActive ? 0 : -1}
          >
            <span className="sr-only">{this.props.stringList.get("common-ui-back")}</span>
            <img src={require("./../../../../images/ui/hamburger-close-dark.svg")}
              alt="" />
          </button>
          <div className="lightbox-content"
            dangerouslySetInnerHTML={{ __html: marked.parse(this.props.stringList.get("about-acknowledgements-lightbox") || "") }}/>
        </div>
      </div>
    );
  }

  /**
   * Handles the click on the lightbox.
   * @function handleClick
   * @memberof components.pages.about.Lightbox
   * @instance
   */
  handleClick = (event) => {
    if ( event.target.id === "lightbox-background" ) {
      this.props.onClose();
    }
  }

  /**
   * Handles the keypress on the lightbox.
   * @function handleKeyPress
   * @memberof components.pages.about.Lightbox
   * @instance
   */
  handleKeyPress = (event) => {
    if (event.key == "Escape") {
      document.removeEventListener("keydown", this.handleKeyPress);
      this.props.onClose();
    }
  };
}

export default Lightbox;
