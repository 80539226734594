import PropTypes from "prop-types";
import React from "react";

import cx from "classnames";

import ResizeUtils from "./../../vendor/utils/ResizeUtils";
import MiniTracker from "../../vendor/tracking/MiniTracker";

/**
 * <pre>
 * Class for the ToolCheckbox of the Application.
 * Package Name - components/common
 * </pre>
 * @class components.common.ToolCheckbox
 */
class ToolCheckbox extends React.Component {
  state = {
    checked: false,
  };

  static propTypes = {
    className: PropTypes.string,
    hidden: PropTypes.bool,
    name: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    allowFocus: PropTypes.bool,
    onClick: PropTypes.func,
    longCaption: PropTypes.string,
    shortCaption: PropTypes.string,
    tool: PropTypes.object,
  };

  componentDidMount() {
    this.uuid = `__label_id__${Date.now()}__${Math.round(Math.random() * 100000)}_`;
    this.setState({ checked: !!this.props.checked });
  }

  componentDidUpdate(prevProps) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({ checked: !!this.props.checked });
    }
  }

  /**
   * Renders the tool checkbox object.
   * @function render
   * @memberof components.common.ToolCheckbox
   * @instance
   */
  render() {
    const classes = cx(
      "checkbox",
      "tool-checkbox",
      {
        hidden: this.props.hidden,
      },
      this.props.className,
    );

    const bp = ResizeUtils.getCurrentBreakpoint();
    //const isTinyOrSmallBreakpoint = bp === "tiny" || bp === "small";
    const isTinyOrSmallBreakpoint = bp === "tiny";

    return (
      <div className={classes} onClick={this.onClick}>
		{ (!isTinyOrSmallBreakpoint) ? (
	  	  (!this.props.longCaption) ? (
	    	<label id={this.uuid} style={{"display":"none"}}>"Click To Select"</label> 
	  	  ) : ("") 
		) : ("") }
		      
        <input
          type="checkbox"
          className="input sr-only"
          name={this.props.name}
          tabIndex={this.props.allowFocus ? 0 : -1}
          checked={this.state.checked}
          aria-checked={this.state.checked}
          aria-labelledby={this.uuid}
          onChange={() => {}} // Just to stop the warnings
        />

        <svg width="32" height="32"
          viewBox="-4 -4 39 39" aria-hidden="true"
          focusable="false">
          <rect className="checkbox__bg" width="35"
            height="35" x="-2"
            y="-2" stroke="currentColor"
            fill="#FFF" strokeWidth="2" />
          <polyline className="checkbox__checkmark" points="4,14 12,23 28,5"
            stroke="transparent" strokeWidth="3"
            fill="none" />
        </svg>

        {/*{this.props.shortCaption ? (
          <span id={this.uuid} className="caption">
            {this.props.shortCaption}
          </span>
        ) : ( "" )}
        {this.props.longCaption ? (
          <span id={this.uuid} className="long-caption">
            {this.props.longCaption}
          </span>
        ) : ( "" )}*/}
        
        { (isTinyOrSmallBreakpoint) ? (
        	(this.props.shortCaption) ? ( 
          	   <span id={this.uuid} className="caption">{this.props.shortCaption}</span>
          	  ) : ( "" )  
        ) : ( "" ) } 	  

		{ (!isTinyOrSmallBreakpoint) ? (
		    (this.props.longCaption) ? (
          	   <span id={this.uuid} className="long-caption">{this.props.longCaption}</span>
        	 ) : ( "" ) 
         ) : ( "" ) }  
        
      </div>
    );
  }

  /**
   * Action for click of checkbox on the tool object.
   * @function onClick
   * @memberof components.common.ToolCheckbox
   * @instance
   */
  onClick = (event) => {
    event.stopPropagation();

    if (this.props.onClick) this.props.onClick(!this.props.checked);
    this.setState({ checked: !this.state.checked });

    MiniTracker.trackEvent({
      event: "item-complete",
      tool: this.props.tool.slug
    });

  };
}

export default ToolCheckbox;
