import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import * as marked from "marked";

import ImageContainer from "../../common/ImageContainer.react";
import DetailsButton from "../../common/DetailsButton.react";
import OverlayHeader from "../../common/OverlayHeader.react";

import PageSectionHelper from "./../../../vendor/utils/PageSectionHelper";
import DirectionUtils from "./../../../vendor/utils/DirectionUtils";
import MiniTracker from "../../../vendor/tracking/MiniTracker";

import ReviewList from "./ReviewList.react";
import SecondaryResources from "./SecondaryResources.react"; 

import JoinBannerOverlay from "./../../common/JoinBannerOverlay.react";
import OverlayPlanBanner from "../../common/OverlayPlanBanner";
import SecurityPlannerStore from "./../../../stores/SecurityPlannerStore";

/**
 * <pre>
 * Class to create tool overlay for the Application.
 * Package Name - components/overlays/tool
 * </pre>
 * @class components.overlays.tool.ToolOverlay
 */
class ToolOverlay extends React.Component {
  static propTypes = {
    metadata: PropTypes.object, // StringList
    stringList: PropTypes.object, // StringList
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    goToPage: PropTypes.func.isRequired,
    tool: PropTypes.object, // Tool
    tools: PropTypes.array, // Tool[]
    scrollPosition: PropTypes.number,
    onClickClose: PropTypes.func,
    hasPlan: PropTypes.func.isRequired,
    availableLanguages: PropTypes.array, // Language[]
    selectedLanguage: PropTypes.object, // Language
  };

  state = {
    visible: false,
    showing: false,
    hiding: false,
  };

  helper = undefined;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.helper = new PageSectionHelper(this);
  }

  componentDidMount() {
    this.helper.setComponent(this.refs.scroller);
  }

  componentDidUpdate() {
    this.helper.setComponent(this.refs.scroller);
    this.helper.forceCheck();
  }

  componentWillUnmount() {
    this.helper.destroy();
  }

  /**
   * Renders the tool overlay object in tool.
   * @function render
   * @memberof components.overlays.tool.ToolOverlay
   * @instance
   */
  render() { 

    let overlayClasses = '';
    const classes = cx(
      {
        overlay: true,
        overlayTool: true,
        hidden: !this.state.visible,
        showing: this.state.showing,
        hiding: this.state.hiding,
        "no-reviews": this.props.tool.reviews.length === 0,
      },
      DirectionUtils.getClass(this.props.stringList),
    );

    if( window.location.pathname.indexOf("/es") > -1 ){
      overlayClasses = cx({ "overlay-inside": true, 'hideOverlay':false });
    }else if(SecurityPlannerStore.isLoggedIn()){ 
      overlayClasses = cx({ "overlay-inside": true, 'hideOverlay':true });       
    } else{
      overlayClasses = cx({ "overlay-inside": true, 'hideOverlay':false });    
    }  
    
    const description = this.props.tool.longDescription;
    const importance = this.props.tool.whyItsImportant;
    const hasResources = this.props.tool.resources.length > 0;

    return (
      <div className={classes} aria-hidden={!this.helper.isActive()}
        style={{ height: this.helper.getWindowHeight() }}>
        <div className="cover" />
        <div className="mask">
          <div className="background" />
          {/*<div className="image show-fading show-sliding-right">
            <ImageContainer src={this.props.tool.image} className="image-inside"
              description={this.props.tool.imageDescription} />
          </div>*/}
          <div className="content show-fading" ref="scroller">
            <OverlayHeader 
              stringList={this.props.stringList}
              routes={this.props.routes}
              goToPage={this.props.goToPage}
              tool={this.props.tool}
              tools={this.props.tools}
              hasPlan={this.props.hasPlan}
              availableLanguages={this.props.availableLanguages}
              selectedLanguage={this.props.selectedLanguage}
              onClickClose={this.props.onClickClose}
              scrollerRef={this.refs.scroller}
            />
            <div className="wrapper" style={{ minHeight: this.helper.getMinScrollableHeight() }}>
              <div className="body show-sliding-left">
                <p className="time-stamp">{this.props.stringList.get('resource-updated')}: {this.props.tool.date}</p>
                <h1 className="title">{this.props.tool.name}</h1>
                <div className="subtitle">{this.props.tool.overlayDescription}</div>
                {/*<div className="mobile-image">
                  <ImageContainer src={this.props.tool.image} className="image-inside"
                    description={this.props.tool.imageDescription} />
                </div>*/}
                            
                <div className="mobile-image">
                  <div>
                    <ImageContainer src={this.props.tool.image} className="image-inside"
                      description={this.props.tool.imageDescription} />
                  </div>
                  { ( !this.props.hasPlan() ) ? 
                    <div className={`${overlayClasses} mobile`}> 
                      <OverlayPlanBanner stringList={this.props.stringList} routes={this.props.routes} goToPage={this.props.goToPage} />
                    </div>
                  : !window.CR.userInfo.id.length ? 
                    <div className={`${overlayClasses} mobile`}>
                        <JoinBannerOverlay key="joinBannerOverlay" ref="joinBannerOverlay"
                          register={(first, last, email) => SecurityPlannerStore.register(first, last, email)} 
                          stringList={this.props.stringList} isLoggedIn={() => SecurityPlannerStore.isLoggedIn()} /> 
                    </div>	  
                  : null
                  }
                </div>  
                <h1 className="subtitle-large">{this.props.stringList.get("overlay-tool-next-steps")}</h1>
                <div className="description" dangerouslySetInnerHTML={{ __html: marked.parse(description || "") }} />
                <div className="highlight">
                  <h1 className="subtitle-large">{this.props.stringList.get("overlay-tool-why-important")}</h1>
                  <div className="importance" dangerouslySetInnerHTML={{ __html: marked.parse(importance || "") }} />
                </div>
                <ReviewList
                  routes={this.props.routes}
                  goToPage={this.props.goToPage}
                  stringList={this.props.stringList}
                  tool={this.props.tool}
                  allowFocus={this.helper.isActive()}
                />
                {hasResources ? (
                  <SecondaryResources
                    resourceList={this.props.tool.resources}
                    stringList={this.props.stringList}
                    allowFocus={this.helper.isActive()}
                  />
                ) : null}
                <div className="trust-box">
                  <p dangerouslySetInnerHTML={{ __html: marked.parse(this.props.stringList.get("overlay-tool-trust-us") || "") }} />
                  <a href={"https://www.consumerreports.org"} target={"_blank"}>
                    <img className="logo" src={require("./../../../../images/page-cover/color-cr-logo.svg")}
                    alt={this.props.stringList.get("branding-consumer-reports-logo")} />
                  </a>
                </div>
                <div className="feedback">
                  <DetailsButton
                    className="common-button-details-transparent-dark"
                    allowFocus={this.helper.isActive()}
                    title={this.props.stringList.get("overlay-tool-button-feedback")}
                    onClick={this.onClickFeedback}
                  >
                    {this.props.stringList.get("overlay-tool-button-feedback")}
                  </DetailsButton>
                </div>
              </div>
           
			  <div className="image show-fading show-sliding-right">
            	<div>
            	<ImageContainer src={this.props.tool.image} className="image-inside"
              		description={this.props.tool.imageDescription} /> 
            	</div>  
                { ( !this.props.hasPlan() ) ? 
                    <div className={`${overlayClasses} mobile`}> 
                      <OverlayPlanBanner stringList={this.props.stringList} routes={this.props.routes} goToPage={this.props.goToPage} />
                    </div> 
                  : !window.CR.userInfo.id.length ?
                    <div className={overlayClasses}>
                      <JoinBannerOverlay key="joinBannerOverlay" ref="joinBannerOverlay"
                        register={(first, last, email) => SecurityPlannerStore.register(first, last, email)} 
                        stringList={this.props.stringList} isLoggedIn={() => SecurityPlannerStore.isLoggedIn()} /> 
        	    	    </div>	
                  : null
                 }
          	   </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Ran when the section becomes the focused section 
   * @param  {object} travelOffset 
   * @param  {object} viaHistoryAPI
   * @function onActivate
   * @memberof components.overlays.tool.ToolOverlay
   * @instance   
   */
  onActivate = (travelOffset, viaHistoryAPI) => {
    this.helper.onActivate(travelOffset, viaHistoryAPI);
  };

  /**
   * Ran when the section is about to lose focus 
   * @param  {object} travelOffset 
   * @param  {object} viaHistoryAPI
   * @function onActivate
   * @memberof components.overlays.tool.ToolOverlay
   * @instance      
   */
  onDeactivate = (travelOffset, viaHistoryAPI) => {
    this.helper.onDeactivate(travelOffset, viaHistoryAPI);
  };

  /**
   * Ran when the section is about to lose focus
   * @param  {string} callback     
   * @function startTransitionShow
   * @memberof components.overlays.tool.ToolOverlay
   * @instance    
   */
  startTransitionShow = (callback) => {
    this.setState({ visible: false, showing: true, hiding: false });

    requestAnimationFrame(callback);

    setTimeout(() => {
      if (this.state.showing) {
        this.setState({ visible: true, showing: false, hiding: false });

        document.body.style.top = `-${this.props.scrollPosition}px`;
        document.body.style.marginTop = `${this.props.scrollPosition}px`;
        document.body.style.height = `${window.innerHeight}px`;
        document.body.style.overflow = "hidden";
      }
    }, 830);
  };

  /**
   * Ran when the section is about to be hidden
   * @param  {string} callback     
   * @function startTransitionHide
   * @memberof components.overlays.tool.ToolOverlay
   * @instance    
   */
  startTransitionHide = (callback) => {
    this.setState({ visible: true, showing: false, hiding: true });

    document.body.style.top = "";
    document.body.style.marginTop = "";
    document.body.style.height = "";
    document.body.style.overflow = "";

    setTimeout(() => {
      callback();
    }, 330);
  };

  /**
   * Action when the overlay is clicked
   * @function onClickFeedback
   * @memberof components.overlays.tool.ToolOverlay
   * @instance    
   */	
  onClickFeedback = () => {
    MiniTracker.trackEvent("tool", "feedback", this.props.tool.slug);
    this.props.goToPage(this.props.routes.getUriOverlayToolFeedback(this.props.tool.slug), true, true);
  };

}

export default ToolOverlay;