import React from "react";
import PropTypes from "prop-types";
import MiniTracker from "../../vendor/tracking/MiniTracker";

/**
 * <pre>
 * Class to create progressbar for the Application.
 * Package Name - components/navigation
 * </pre>
 * @class components.navigation.ProgressBar
 */
class ProgressBar extends React.Component {
  static propTypes = {
    total: PropTypes.number,
    complete: PropTypes.number,
    isVisible: PropTypes.bool,
    stringList: PropTypes.object.isRequired
  };

  /**
   * Push events to MiniTracker when user progresses beyond specific steps
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.complete !== this.props.complete) {
      const prevProgress = prevProps.complete / prevProps.total;
      const nextProgress = this.props.complete / this.props.total;
      const progressSteps = [0.25, 0.5, 0.75, 1];

      progressSteps.forEach((step) => {
        if (prevProgress < step && nextProgress >= step) {
          MiniTracker.trackEvent({
            'event': "plan-progress", 
            'progress': `${step * 100}%`
          });
        }
      });
    }
  }

  /**
   * Renders the progressbar object.
   * @function render
   * @memberof components.navigation.ProgressBar
   * @instance
   */
  render() {
    const { isVisible = false, total = 100, complete = 0 } = this.props;

    if (!isVisible) return null;

    const percent = Math.round( complete / total * 100);

    return (
      <>
      <div className="progress-bar">
        <div className="completed" style={{ width: percent + "%" }} />
        <div className="progress-count">
            <em><strong><span className="progress-count__completed" id="completed-number">{complete}</span> <span className="completed-text-sub">{this.props.stringList.get("progress-bar-complete-of")}</span> <span className="progress-count__total">{total}</span></strong></em> <span className="completed-text-main">{complete > 1 ? this.props.stringList.get("progress-bar-complete-multiple") : this.props.stringList.get("progress-bar-complete-single")}</span>
        </div>
      </div>

      <a href="https://www.google.com/" className="escape-hatch">
      <span className="cross"></span>
      {this.props.stringList.get('escape-hatch-text')}
      </a>
      </>
    );
  }

  /**
   * Sets the state parameters of progressbar.
   * @param  {boolean} isVisible  
   * @param  {object} progress       
   * @function   setStateParameters
   * @memberof components.navigation.ProgressBar
   * @instance
   */  
  setStateParameters = (isVisible, progress) => {
    if (isVisible !== this.state.isVisible || progress !== this.state.progress) {
      this.setState({ isVisible, progress, updateFromState: true });
    }
  };
}

export default ProgressBar;
