import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import SimpleSignal from "simplesignal";
import { map } from "moremath";
import cx from "classnames";

import PositionUtils from "../../../vendor/utils/PositionUtils";
import ResizeUtils from "../../../vendor/utils/ResizeUtils";
import WindowScrollUtils from "../../../vendor/utils/WindowScrollUtils";
import DirectionUtils from "./../../../vendor/utils/DirectionUtils";

import SpeechBubble from "./../../common/SpeechBubble.react";

import SecondaryMenu from "../../common/SecondaryMenu.react";
import DetailsButton from "../../common/DetailsButton.react";
import Text from "../../common/Text.react";
import ThreatMenu from "../../common/ThreatMenu.react";
import ToolList from "./../../common/ToolList.react";
import ProgressBar from "../../common/ProgressBar.react";

import PageSectionHelper from "./../../../vendor/utils/PageSectionHelper";
import MiniTracker from "../../../vendor/tracking/MiniTracker";
import Fween from "../../../vendor/transitions/Fween";
import Easing from "../../../vendor/transitions/Easing";
import Tool from "./../../common/Tool.react";

function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
}

/**
 * <pre>
 * Class to create a threats and tools page in the Application.
 * Package Name - components/pages/threats-and-tools
 * </pre>
 * @class components.pages.threatsandtools.ThreatsAndToolsPage
 */
const ThreatsAndToolsPage = createReactClass({
  displayName: "ThreatsAndToolsPage",
  hasPerformedTracking: false,
  lastAlignedThreatId: undefined,
  lastAlignedLanguageId: undefined,
  onPageScrolled: new SimpleSignal(),
  toolsToUse: undefined,
  threatsToUse: undefined,
  isScrolling: false,
  threatScrollPosition: undefined,
  threatIdToScrollTo: undefined,
  positionToScrollFrom: undefined,
  helper: undefined,

  propTypes: {
    stringList: PropTypes.object,
    selectedLanguage: PropTypes.object, // Language
    availableLanguages: PropTypes.arrayOf(PropTypes.object), // Language[]
    currentLocation: PropTypes.string,
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    goToPage: PropTypes.func.isRequired,
    startingThreat: PropTypes.object,
    threats: PropTypes.array, // Threat
    tools: PropTypes.array, // Tool
    allTools: PropTypes.array, // Tools
    topTool: PropTypes.object,
    footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
    maxVisibleThreats: PropTypes.number,
    maxVisibleToolsPerThreat: PropTypes.number,
    menuTitle: PropTypes.string.isRequired,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    tallHeader: PropTypes.bool,
    onResetClick: PropTypes.func,
    onOpenMenu: PropTypes.func,
    downloading: PropTypes.bool,
  },

  /**
   * Generates the initial state of the threat-and-tools page.
   * @function getInitialState
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  getInitialState: function() { 
    //get initial state for language 
    let cnt=0;
    let languageOptions = this.props.availableLanguages;    
    let currLangKey = localStorage.preferredLanguage;
    for (i=0; i<languageOptions.length; i++) {         
      if ( currLangKey == languageOptions[i].id ){ break; } 
      cnt++;
    }	
          
    return {
      activeFilter: { 
        sitelanguage: cnt,       
        cost: 0,
        effort: 0,
      },
      breakpoint: ResizeUtils.getCurrentBreakpoint(),
      hasUpdatedFilter: false,
      isThreatMenuFixed: false,
      isTransitioning: false,
      threatSubtitle: "",
      statsName: "",
      statsSource: "#",
    };
  },

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate: function(nextProps, nextState) {
    const filterCategories = this.getFilterCategories();

    this.toolsToUse = nextProps.tools
      .filter((tool) => {
        return !nextProps.topTool || tool.id !== nextProps.topTool.id;
      })
      .filter((tool) => {
        const effortFilter = filterCategories.effort.filters[nextState.activeFilter.effort];

        return effortFilter === tool.label || nextState.activeFilter.effort === 0;
      })
      .filter((tool) => {
        const costFilter = filterCategories.cost.filters[nextState.activeFilter.cost];
        const isToolFree = !tool.price || !tool.price.trim() || tool.price === "Free" || tool.price == "Gratis";

        if (costFilter === this.props.stringList.get("all-tools-filter-cost-free")) {
          return isToolFree;
        } else if (costFilter === this.props.stringList.get("all-tools-filter-cost-paid")) {
          return !isToolFree;
        } else {
          return true;
        }
      });

    this.updateThreatList();
  },

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount: function() {
    this.helper = new PageSectionHelper(this);
    this.toolsToUse = this.props.tools;
    this.updateThreatList();

    this.scrollOffsets = {
      tiny: 80,
      small: 80,
      medium: 120,
      large: 120,
    };
  },

  componentDidMount: function() {
    this.helper.setComponent(this);
    this.helper.onScrolled.add(this.onScrolledContent);
    this.helper.onResized.add(this.onResize);
    this.helper.dispatchOnScrolled();

    this.headerEl = ReactDOM.findDOMNode(this.headerComp);
    this.forceHeadAdjustInterval = setInterval(this.tick, 3000);
  },

  tick: function() {
    this.adjustHeadPosition();
  },

  shouldComponentUpdate: function(nextProps, nextState) {
    return (
      nextProps.selectedLanguage !== this.props.selectedLanguage ||
      nextProps.availableLanguages !== this.props.availableLanguages ||
      nextProps.currentLocation !== this.props.currentLocation ||
      nextProps.routes !== this.props.routes ||
      nextProps.goToPage !== this.props.goToPage ||
      nextProps.stringList !== this.props.stringList ||
      nextProps.threats !== this.props.threats ||
      nextProps.tools !== this.props.tools ||
      nextProps.allTools !== this.props.allTools ||
      nextProps.topTool !== this.props.topTool ||
      (nextProps.topTool && nextProps.topTool.id) !== (this.props.topTool && this.props.topTool.id) ||
      nextProps.downloading !== this.props.downloading ||
      nextState.activeFilter.cost !== this.state.activeFilter.cost ||
      nextState.activeFilter.effort !== this.state.activeFilter.effort ||
      nextState.hasUpdatedFilter !== this.state.hasUpdatedFilter ||
      nextState.breakpoint !== this.state.breakpoint ||
      nextState.hasUpdatedFilter !== this.state.hasUpdatedFilter ||
      nextState.isThreatMenuFixed !== this.state.isThreatMenuFixed ||
      nextState.isTransitioning !== this.state.isTransitioning ||
      nextState.statsName !== this.state.statsName ||
      nextState.statsSource !== this.state.statsSource ||
      nextState.threatSubtitle !== this.state.threatSubtitle
    );
  },

  componentDidUpdate: function() {
    this.helper.setComponent(this);
    this.helper.dispatchOnScrolled();
  },

  componentWillUnmount: function() {
    this.helper.destroy();
    clearInterval(this.forceHeadAdjustInterval);
  },

  /**
   * Rebders the threat-and-tools page.
   * @function render
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  render: function() {
    // Note: this.toolsTuUse is a filtered list of tools based on cost/effort attributes and should not be used for tracking overall
    // plan completion this.props.tools should be used for that purpose

    this.hasPerformedTracking = false;
    this.updateThreatList();

    const completedCount = this.props.tools.filter((tool) => { return !!tool.completed; }).length;

    const header = isFunction(this.props.header) ? (
      <div className={cx({ title: true, tall: this.props.tallHeader })}>
        {this.props.header(this.props.downloading)}
      </div>
    ) : (
      <h1 className={cx({ title: true, tall: this.props.tallHeader })}>
        {this.props.header}
      </h1>
    );

    const pageHolderHeight = this.helper.getWindowHeight();

    return (
      <div
        className={
          "sectionPageHolder threats-and-tools " + DirectionUtils.getClass(this.props.stringList) + (this.state.isTransitioning ? " changing" : "")
        }
        aria-hidden={!this.helper.isActive()}
        style={{ height: pageHolderHeight }}
      >
        <div className="content">
          <ThreatMenu
            activeFilter={this.state.activeFilter}
            currentActiveThreatId={this.lastAlignedThreatId}
            filterCategories={this.getFilterCategories()}
            hasUpdatedFilter={this.state.hasUpdatedFilter}
            isVisible={true}
            menuTitle={this.props.menuTitle}
            onClickThreat={this.onClickThreat}
            onUpdateActiveFilter={this.updateActiveFilter}
            stringList={this.props.stringList}
            routes={this.props.routes}
            goToPage={this.props.goToPage}
            tools={this.props.tools}
            allTools={this.props.allTools}
            threatList={this.threatsToUse}
            allowFocus={this.helper.isActive()}
            onOpen={this.props.onOpenMenu}
          />
          <ProgressBar isVisible={ completedCount > 0 } total={this.props.tools.length} complete={completedCount} stringList={this.props.stringList} />
          <div className={cx({ "header-bg": true, tall: this.props.tallHeader })} />
          <div className="middle" ref="middle"
            id={SecondaryMenu.LOCATION_ALL_TOOLS}>
            {header}
            {this.renderTopTool()}
            <div className="middle-inner">
              {this.toolsToUse.length > 0 ? this.renderSidebar(this.toolsToUse.length > completedCount) : null}
              {this.toolsToUse.length > 0 ? this.renderBody() : this.renderNoMatchingTools()}
            </div>
            {this.renderCompletedTools()}
          </div>
          {isFunction(this.props.footer) ? this.props.footer() : this.props.footer}
        </div>
      </div>
    );
  },

  /**
   * Rebders the top tools on threat-and-tools page.
   * @function renderTopTool
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  renderTopTool: function() {
    if (!this.props.topTool) return null;

    return (
      <div className="top-tool-box">
        <Tool
          stringList={this.props.stringList}
          tool={this.props.topTool}
          key={this.props.topTool.id}
          ref={this.props.topTool.id}
          isTopTool={true}
          className={""}
          size={"medium"}
          routes={this.props.routes}
          goToPage={this.props.goToPage}
          allowFocus={this.props.allowFocus}
        />
      </div>
    );
  },

  /**
   * Renders the completed tools on threat-and-tools page.
   * @function renderCompletedTools
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  renderCompletedTools: function() {
    const completedTools = this.toolsToUse.filter(({ completed }) => completed);

    if (completedTools.length === 0) return null;

    const group = [
      <div key={"completed-title"} className="title">
        {this.props.stringList.get("tool-label-completed")}
      </div>,
    ];

    completedTools.forEach((tool) => {
      group.push(
        <Tool
          stringList={this.props.stringList}
          tool={tool}
          key={tool.id}
          ref={tool.id}
          isTopTool={false}
          routes={this.props.routes}
          goToPage={this.props.goToPage}
          size="medium"
          allowFocus={this.props.allowFocus}
        />,
      );
    });

    return (
      <div className="middle-inner">
        <div className="sidebar-placeholder">
          <div className="sidebar">
            <div className="threat-panel completed">
              <div className="title">{this.props.stringList.get("tool-label-completed")}</div>
            </div>
          </div>
        </div>
        <div className="body completed">
          <div className="common-tool-list">
            <div className={"group"} key="complete-group">
              {group}
            </div>
          </div>
        </div>
      </div>
    );
  },

  /**
   * Renders the sidebar on threat-and-tools page.
   * @param  {object} toolsRemaining    
   * @function renderSidebar
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  renderSidebar: function(toolsRemaining) {
    const style = toolsRemaining ? {} : { display: "none" };

    return (
      <div className="sidebar-placeholder">
        <div className="sidebar special-positioning-glued-parent" style={style}>
          <div className={cx({ "threat-panel": true })}>
            <Text className="title" ref="title" />
            <div className="threat-info">
              {this.state.threatSubtitle && this.state.threatSubtitle.length > 0 ? <SpeechBubble
                toolList={this.refs["toolList"]}
                ref="subtitle"
                link={this.state.statsSource}
                onWheel={this.onWheelStats}
                tabIndex={this.helper.isActive() && this.state.statsSource ? 0 : -1}
                subtitle={this.state.threatSubtitle}
                currentActiveThreatId={this.lastAlignedThreatId}
                threats={this.threatsToUse}
                classNameStats={DirectionUtils.getClass(this.props.stringList)}
                statsName={this.state.statsName}
              /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  },

  /**
   * Renders the body segment on threat-and-tools page.   
   * @function renderBody
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  renderBody: function() {
    return (
      <div className={cx({ body: true, empty: this.toolsToUse.filter(({ completed }) => !completed).length === 0 })}>
        <ToolList
          ref="toolList"
          stringList={this.props.stringList}
          routes={this.props.routes}
          goToPage={this.props.goToPage}
          tools={this.toolsToUse}
          threats={this.threatsToUse}
          sizeFirstTool="medium"
          sizeOtherTools="medium"
          dataTestId="all-tools"
          tabSequence={(nextThreatID, currentTool, e) => (nextThreatID ? this.scrollToThreat(nextThreatID, currentTool, e) : null)}
          allowFocus={this.helper.isActive()}
          hideCompleted={true}
          maxVisibleThreats={this.props.maxVisibleThreats}
          maxVisibleToolsPerThreat={this.props.maxVisibleToolsPerThreat}
        />
      </div>
    );
  },

  /**
   * Renders the section when no matching tools found on threat-and-tools page.   
   * @function renderNoMatchingTools
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  renderNoMatchingTools: function() {
    return (
      <div className="no-tools">
        <div className="title">{this.props.stringList.get("all-tools-no-results")}</div>
        <DetailsButton
          className="common-button-details-transparent clear-filters"
          allowFocus={this.helper.isActive()}
          title={this.props.stringList.get("all-tools-clear-filters")}
          onClick={() => this.resetActiveFilter()}
        >
          {this.props.stringList.get("all-tools-clear-filters")}
        </DetailsButton>
      </div>
    );
  },

  /**
   * Adjusts the head position on threat-and-tools page.
   * @param  {boolean} neverFixed       
   * @function adjustHeadPosition
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  adjustHeadPosition: function(neverFixed = false) {
    if (!this.helper.isActive()) neverFixed = true;

    // Adjust all elements that should be glued to the page edges
    const element = this.helper.getElement().querySelector(".special-positioning-glued-parent");
    if (element) PositionUtils.positionVerticallyOnParentOrScreen(element, neverFixed, DirectionUtils.isLTR(this.props.stringList));
  },

  /**
   * Action when scroll ended on the threat-and-tools page.
   * @param  {number} nextThreatId       
   * @param  {object} lastTool       
   * @param  {object} e             
   * @function onScrollingEnded
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  onScrollingEnded: function(nextThreatId, lastTool, e) {
    this.isScrolling = false;
    this.helper.dispatchOnScrolled();

    if (lastTool && nextThreatId && e) {
      setTimeout(this.refs["subtitle"].setFocus(lastTool, nextThreatId, e), 1);
    }
  },

  /**
   * Action when a threat segment is clicked on the threat-and-tools page.
   * @param  {number} threatId          
   * @param  {object} e             
   * @function onClickThreat
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  onClickThreat: function(threatId, e) {
    const toolList = this.refs["toolList"];
    if (!toolList.isThreatVisible(threatId)) {
      toolList.expandList();
    }

    if (e) this.refs["subtitle"].refs["speech-bubble"].focus();

    this.scrollToThreat(threatId, false, e);
  },

  /**
   * Action when scroll ended on the threat-and-tools page.
   * @param  {number} nextThreatId       
   * @param  {object} lastTool       
   * @param  {object} e             
   * @function scrollToThreat
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  scrollToThreat: function(threatId, lastTool, e) {
    this.threatIdToScrollTo = threatId;
    this.positionToScrollFrom = this.helper.getScrollPosition();
    this.isScrolling = true;
    Fween.use(this.getThreatScrollPosition, this.setThreatScrollPosition)
      .from(0)
      .to(1, 0.4, Easing.expoInOut)
      .call(() => this.onScrollingEnded(threatId, lastTool, e))
      .play();
  },

  /**
   * Fetch the scroll position on the threat-and-tools page. 
   * @function getThreatScrollPosition
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  getThreatScrollPosition: function() {
    return this.threatScrollPosition;
  },

  /**
   * Sets the scroll position on the threat-and-tools page. 
   * @function setThreatScrollPosition
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  setThreatScrollPosition: function(value) {
    this.threatScrollPosition = value;
    // Update position
    const destinationScrollY =
      this.getThreatTopY(this.threatIdToScrollTo) - (this.threatIdToScrollTo ? this.scrollOffsets[this.state.breakpoint] : 0);
    const desiredScrollY = map(this.threatScrollPosition, 0, 1, this.positionToScrollFrom, destinationScrollY);
    this.helper.setScrollPosition(desiredScrollY);
  },

  /**
   * Fetches the internal Y of a threat on the threat-and-tools page. 
   * @param  {number} threatId          
   * @function getThreatTopY
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */
  getThreatTopY: function(threatId) {
    // Find the internal Y of a threat
    if (!threatId) {
      // No threat, just find the top
      if(typeof threatId === 'undefined'){ return undefined; }       
      const element = ReactDOM.findDOMNode(this.refs["middle"]);
      const elementRect = PositionUtils.findElementRect(element, true);
      const scrollRect = PositionUtils.findElementRect(this.helper.getElement(), true);
      return elementRect.top - scrollRect.top + this.helper.getScrollPosition();
    } else {
      // Actual threat, get top from ToolList 
      if(Object.keys(this.refs).length == 0){ return undefined; }       
      const toolList = this.refs["toolList"];
      if (toolList.hasElementForThreat(threatId)) {
        const element = toolList.getElementForThreat(threatId);
        const elementRect = PositionUtils.findElementRect(element, true);
        const scrollRect = PositionUtils.findElementRect(this.helper.getElement(), true);
        return elementRect.top - scrollRect.top + this.helper.getScrollPosition();
      }
      return undefined;
    }
  },

  /**
   * Ran when the section becomes the focused section
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	   
   * @param  {object} fromOverlay	        
   * @function onActivate
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance        
   */
  onActivate: function(travelOffset, viaHistoryAPI, fromOverlay) {
    if (!fromOverlay) {
      this.adjustHeadPosition(true);

      const elements = document.querySelectorAll(".special-positioning-glued-parent");
      for (let i = 0; i < elements.length; i++) {
        PositionUtils.resetVerticalPosition(elements[i]);
      }
    }

    // Performs tracking
    if (!this.hasPerformedTracking && this.toolsToUse.length > 0) {
      this.toolsToUse.forEach((tool) => {
        MiniTracker.trackEvent("tool", "display-all-tools", tool.slug, 100, true);
      });

      this.hasPerformedTracking = true;
    }

    // Jumps to threat if needed
    if (travelOffset > 0 && this.props.startingThreat) {
      setTimeout(() => {
        this.scrollToThreat(this.props.startingThreat.id);
      }, 100);
    }

    this.helper.onActivate(travelOffset, viaHistoryAPI, fromOverlay);
  },

  /**
   * Ran when the section is about to lose focus 
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	   
   * @param  {object} toOverlay	        
   * @function onDeactivate
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance           
   */
  onDeactivate: function(travelOffset, viaHistoryAPI, toOverlay) {
    // eslint-disable-line no-unused-vars
    this.helper.onDeactivate(travelOffset, viaHistoryAPI);

    if (!toOverlay) {
      this.adjustHeadPosition(true);
      this.setState({
        isThreatMenuFixed: false,
      });

      // Reset filters after leaving
      setTimeout(() => {
        this.resetActiveFilter();
      }, 520);
    }
  },

  /**
   * Returns the color (as a number) that the locator bar should have when opaque
   * @function getDesiredLocatorBackgroundColor
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance       
   */
  getDesiredLocatorBackgroundColor: function() {
    return undefined;
  },

  // TODO: this is simply duplicated from Report... redo in a more reusable way? 
  /**
   * Fetches the content on scroll on the threat-and-tools page. 
   * @param  {number} scrollY     
   * @param  {number} maxScrollY         
   * @function onScrolledContent
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  onScrolledContent: function(scrollY, maxScrollY) {
    const newState = Object.assign({}, this.state);

    this.onPageScrolled.dispatch();
    this.adjustHeadPosition();

    // Decide whether the stickiness/visibility of the threat menu needs to change
    const isThreatMenuFixed = this.helper.isActive();

    if (isThreatMenuFixed !== undefined) {
      newState.isThreatMenuFixed = isThreatMenuFixed;
    }

    // Check which is the current threat
    const toolList = this.refs["toolList"];
    if (toolList) {
      let alignedThreat = undefined;
      if (this.isScrolling) {
        // Scrolling, change to the threat we're navigating to
        alignedThreat = this.getThreatById(this.threatIdToScrollTo);
      } else if (scrollY > maxScrollY) {
        // At the bottom, use the last threat regardless of aligment
        alignedThreat = toolList.getLastThreat();
      } else {
        // Actually use the aligned threat (middle of title)
        const targetY = WindowScrollUtils.getWindowScrollY() + window.innerHeight * 0.35; // Use the top of the screen as the "target" position
        alignedThreat = toolList.getAlignedThreat(targetY);
      }

      if (alignedThreat) {
        if (
          (alignedThreat.id !== this.lastAlignedThreatId || this.props.stringList.getLanguage().id !== this.lastAlignedLanguageId) &&
          !this.state.isTransitioning
        ) {
          newState.isTransitioning = true;
          this.changeThreatInfo(alignedThreat);
        }
      } else {
        console.log("Error: no aligned threat found."); // eslint-disable-line
      }
    } else {
      this.lastAlignedThreatId = undefined;
      newState.threatSubtitle = "";
      newState.statsSource = "";
      newState.statsName = "";
    }

    this.setState(newState);
  },

  /**
   * Fetches the threat by threatId on the threat-and-tools page. 
   * @param  {number} id     
   * @function getThreatById
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  getThreatById: function(id) {
    return this.props.threats.find((threat) => {
      return threat.id === id;
    });
  },

  /**
   * Changes the threat info on the threat-and-tools page. 
   * @param  {object} newThreat     
   * @function changeThreatInfo
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  changeThreatInfo: function(newThreat) {
    window.setTimeout(
      function() {
        this.lastAlignedThreatId = newThreat.id;
        this.lastAlignedLanguageId = this.props.stringList.getLanguage().id;
        this.refs["title"].setState({ text: newThreat.shortDescription });

        this.setState({
          isTransitioning: false,
          threatSubtitle: newThreat.stats,
          statsSource: newThreat.statsSource,
          statsName: newThreat.statsName,
        });
      }.bind(this),
      200,
    );
  },

  /**
   * Action on resize of the threat-and-tools page. 
   * @param  {number} width     
   * @param  {number} height        
   * @function onResize
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  onResize: function(width, height) {
    // eslint-disable-line no-unused-vars
    const bp = ResizeUtils.getCurrentBreakpoint();
    if (bp !== this.state.breakpoint) {
      this.setState({
        breakpoint: ResizeUtils.getCurrentBreakpoint(),
      });
    }
  },

  /**
   * If using the mousewheel atop the stats bubble, re-inject the scroll   
   * @function onWheelStats
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  onWheelStats: function(e) {
    const element = document.querySelector(".special-positioning-glued-parent");
    if (element && element.style.position == "fixed") {
      // If using the mousewheel atop the stats bubble, re-inject the scroll
      // This is super ugly, but needed because links on a position:fixed div will always steal the event
      this.helper.setScrollPosition(this.helper.getScrollPosition() + e.deltaY);
    }
  },

  /**
   * Gets All the Filtered Categories on threat-and-tools page.   
   * @function getFilterCategories
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  getFilterCategories: function() { 
    const language_options = this.showLanguageOptions(this.props.availableLanguages); 
    const language_codes = this.showLanguageCodes(this.props.availableLanguages);   
    return {
	  sitelanguage: { 
        title: "Languages",
		filters: language_options, 
      },  
	  languagecode: { 
        title: "Language Codes",
		filters: language_codes, 
      },     
      cost: {
        title: this.props.stringList.get("all-tools-filter-cost-title"),
        filters: [
          this.props.stringList.get("all-tools-filter-any"),
          this.props.stringList.get("all-tools-filter-cost-free"),
          this.props.stringList.get("all-tools-filter-cost-paid"),
        ],
      },
      effort: {
        title: this.props.stringList.get("all-tools-filter-effort-title"),
        filters: [this.props.stringList.get("all-tools-filter-any"), ...this.getToolEffortCategoryList(this.props.tools)],
      },
    };
  },

  /**
   * Gets All the Effort Categories on threat-and-tools page.   
   * @function getToolEffortCategoryList
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  getToolEffortCategoryList: function(tools) {
    // return tools.map((tool) => tool.label).filter((v, i, a) => a.indexOf(v) === i && v.length);
    return this.props.selectedLanguage.code === "es" ? ["Rápido y fácil"] : ["Quick and Easy"];

  },

  /**
   * Gets All the Effort Categories on threat-and-tools page.   
   * @function resetActiveFilter
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  resetActiveFilter: function() {
    //this.updateActiveFilter({ cost: 0, effort: 0 }); 
    let cnt=0; 
    const languageOptions = this.props.availableLanguages;
    let currLangKey = localStorage.preferredLanguage;
    for (i=0; i<languageOptions.length; i++) {         
      if ( currLangKey == languageOptions[i].id ){ break; } 
      cnt++;
    } 
    this.updateActiveFilter({ 
    	sitelanguage: cnt, cost: 0, effort: 0 
    });  
  },

  /**
   * Updates the active filter on threat-and-tools page. 
   * @param  {object} activeFilter      
   * @function updateActiveFilter
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  updateActiveFilter: function(activeFilter) { 
    if(Object.keys(this.refs).length > 0){   
      this.setState({
        activeFilter: activeFilter,
        hasUpdatedFilter: !this.checkIfDefaultFilters(activeFilter),
      });
    }

    setTimeout(() => {
      if (this.state.isThreatMenuFixed) {
        // Need to scroll
        if (this.threatsToUse.length > 0) {
          // Has threats, scroll to the first
          this.scrollToThreat(this.threatsToUse[0].id);
        } else {
          // No threats, scroll to the top of the container
          this.scrollToThreat(undefined);
        }
      }
    }, 30);
  },

  /**
   * Updates the threat list on threat-and-tools page. 
   * @function updateThreatList
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  updateThreatList() {
    this.threatsToUse = this.toolsToUse
      .map((tool) => !tool.completed ? tool.threat : false)
      .filter((v, i, a) => v && a.indexOf(v) === i)
      .sort((a, b) => {
        if (a.isAdditionalHelp) return 1;
        if (b.isAdditionalHelp) return -1;
        return 0;
      });
  },

  /**
   * Checks if the filters are the default ones on threat-and-tools page. 
   * @param  {object} activeFilter         
   * @function checkIfDefaultFilters
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  checkIfDefaultFilters: function(activeFilter) {
    // We need to check if filters are at the default values (0)
    return Object.keys(activeFilter).every((key) => {
      if (activeFilter.hasOwnProperty(key)) {
        return activeFilter[key] === 0;
      } else {
        return false;
      }
    });
  }, 

  /**
   * Returns the language options for the filters on threat-and-tools page. 
   * @param  {object} languageOptions         
   * @function showLanguageOptions
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  showLanguageOptions: function(languageOptions) { 
     var switchers = [];
     let currLangKey = localStorage.preferredLanguage;  
     for (i=0; i<languageOptions.length; i++) {         
        switchers.push(languageOptions[i].name);
     } 
	 //console.log(switchers);
	 return switchers; 
  },  

  /**
   * Returns the language codes for the filters on threat-and-tools page. 
   * @param  {object} languageOptions         
   * @function showLanguageCodes
   * @memberof components.pages.threatsandtools.ThreatsAndToolsPage
   * @instance
   */  
  showLanguageCodes: function(languageOptions) { 
     var switchers = [];
     let currLangKey = localStorage.preferredLanguage;  
     for (i=0; i<languageOptions.length; i++) {         
        switchers.push(languageOptions[i].id);
     } 
	 //console.log(switchers);
	 return switchers; 
  }, 
  
});

export default ThreatsAndToolsPage;
