import PropTypes from "prop-types";
import React from "react";
import Bowser from "bowser";
import cx from "classnames";

import ADAUtils from "../../vendor/utils/ADAUtils";
import MiniTracker from "../../vendor/tracking/MiniTracker";
import SecurityPlannerConstants from "./../../constants/SecurityPlannerConstants";

import AppDispatcher from "./../../dispatcher/AppDispatcher";

/**
 * <pre>
 * Class for creating a Language Toggle Menu in the Application.
 * Package Name - components/common  
 * </pre>
 * @class components.common.LanguageToggle
 */
class LanguageToggle extends React.Component {
  static propTypes = {
    stringList: PropTypes.object, // StringList
    selectedLanguage: PropTypes.object, // Language
    availableLanguages: PropTypes.arrayOf(PropTypes.object), // Language[]
    allowFocus: PropTypes.bool,
  };

  /**
   * Creates An Object of LanguageToggle.
   * @param  {object} props     
   * @constructor    
   */  
  constructor(props) {
    super(props);
    const isTouch = Bowser.ios || Bowser.android;

    this.state = {
      isMenuOpen: false,
      isTouch: isTouch,
    };
  }

  /**
   * Renders the language toggle object.
   * @function render
   * @memberof components.common.LanguageToggle
   * @instance
   */
  render() {
    // Don't render a toggle if there are no options to select
    if ( this.props.availableLanguages.length <= 1 ) {
        return ('');
    }

    const languageClasses = cx({
      "common-language-toggle": true,
      "item": true,
      desktop: !this.state.isTouch,
      "touch-device": this.state.isTouch,
      "is-open": this.state.isTouch && this.state.isMenuOpen,
      "is-closed": this.state.isTouch && !this.state.isMenuOpen,
      "is-open-by-ada": this.state.isMenuOpen,
      "single-language-option": this.props.availableLanguages && Object.keys(this.props.availableLanguages).length === 2,
      "rtl": this.props.selectedLanguage.direction === "right-to-left"
    });

    const elements = [];

    if (this.props.availableLanguages && this.props.selectedLanguage) {
      // Create list
      // Selected language first
      elements.push(
        <div
          key={this.props.selectedLanguage.id}
          className="language selected"
          tabIndex={this.props.allowFocus ? 0 : -1}
          role="menuitem"
          aria-label={this.props.stringList.get("common-language-menu-selected") + " " + this.props.selectedLanguage.name}
          onKeyDown={ADAUtils.handleKeyboard(this.toggleMenuOpen)}
          onClick={this.onClickSelectedLanguage}
        >
          {this.renderLanguage(this.props.selectedLanguage.name, true)}
        </div>,
      );

      // All other languages after
      for (const key in this.props.availableLanguages) {
        const language = this.props.availableLanguages[key];
        if (language != this.props.selectedLanguage) {
          elements.push(
            <div
              key={key}
              className="language not-selected"
              tabIndex={this.state.isMenuOpen && this.props.allowFocus ? 0 : -1}
              aria-label={this.props.stringList.get("common-language-menu-available") + " " + language.name}
              role="menuitem"
              onKeyDown={ADAUtils.handleKeyboard(() => this.onClickLanguage(language))}
              onClick={() => this.onClickLanguage(language)}
            >
              {this.renderLanguage(language.name)}
            </div>,
          );
        }
      }
    } else {
      // No languages yet
      elements.push(
        <div key="loading" className="language loading">
            {this.props.stringList.get("common-language-menu-loading")}
        </div>,
      );
    }

    // Finally, return the elements
    return (
      <div className={languageClasses} role="menu">
        {this.renderMenu(elements)}
      </div>
    );
  }

  /**
   * Renders the language code in language toggle menu object.
   * @param  {object} children  
   * @param  {object} preffered        
   * @function renderLanguage
   * @memberof components.common.LanguageToggle
   * @instance
   */
  renderLanguage = (children, preffered = null) => {
    if (SecurityPlannerConstants.UI.ALLOW_HOT_LANGUAGE_SWAP) { 
        let langTitle = children.indexOf('Spanish') > -1 ? 'Español' : children; 
        return (
            <button role="menuitemradio" aria-checked={preffered}>{langTitle}</button>
        )    
        /*return (
            <button role="menuitemradio" aria-checked={preffered}>{children}</button>
        )*/
    } else {
        return (
            <a href={this.props.stringList.get("common-url-home-language").split("[[language]]").join(language.id)}
                role="menuitemradio"
                aria-checked={preffered}>
                {children}
            </a>
        )
    }
  }

  /**
   * Renders the language toggle menu.
   * @param  {object} children     
   * @function renderMenu
   * @memberof components.common.LanguageToggle
   * @instance
   */
  renderMenu = (children) => {
    if ( this.props.availableLanguages.length > 2 ) {
        return(
            <div className="dropdown">
                <button
                  className="dropdown-button"
                  aria-haspopup="true"
                  aria-expanded={this.state.isMenuOpen}
                  onKeyDown={ADAUtils.handleKeyboard(this.toggleMenuOpen)}
                  onClick={() => this.toggleMenuOpen()}>
                    {this.props.stringList.get("common-home-language-select")}
                </button>
                <div className="dropdown-menu" role="menu">{children}</div>
            </div>
        );
    } else {
        return children;
    }
  }

  /**
   * Function for toggle of the language menu.  
   * @function toggleMenuOpen
   * @memberof components.common.LanguageToggle
   * @instance
   */
  toggleMenuOpen = () => {
    if (!this.state.isMenuOpen) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  };

  /**
   * Function for toggle of the language menu. 
   * Modified as per requirement in CM-5684 to redirect to corresponding site.
   * @param  {object} language       
   * @function onClickLanguage
   * @memberof components.common.LanguageToggle
   * @instance
   */
  onClickLanguage = (language) => {
    MiniTracker.trackEvent("language", "change", language.id);

    /*if (SecurityPlannerConstants.UI.ALLOW_HOT_LANGUAGE_SWAP) {
      AppDispatcher.dispatch({
        actionType: SecurityPlannerConstants.Actions.CHANGE_LANGUAGE,
        languageId: language.id,
        languageDir: language.direction,
      });
      this.closeMenu();
    }*/

    if( language.id == 'es-US' ){ 
      localStorage.setItem("preferredLanguage", "es-US");
      window.location.assign( '/es' ); 
    }else{
      localStorage.setItem("preferredLanguage", "en-US");
      window.location.assign( '/' );       
    }     
  };

  /**
   * Function for on click the selected language in menu.     
   * @function onClickSelectedLanguage
   * @memberof components.common.LanguageToggle
   * @instance
   */
  onClickSelectedLanguage = () => {
    if (this.state.isTouch) {
      this.toggleMenuOpen();
    }
  };

  /**
   * Function for on body click the selected language menu.
   * @param  {object} e         
   * @function onBodyClick
   * @memberof components.common.LanguageToggle
   * @instance
   */
  onBodyClick = (e) => {
    const el = e.target;
    const isLanguage = el.classList.contains("language") || (el.classList.contains("dropdown-button") && !this.isMenuOpen);

    if (!isLanguage) {
      this.closeMenu();
    }
  };

  /**
   * Function for handling keypress on language menu.
   * @param  {object} event       
   * @function handleKeyPress
   * @memberof components.common.LanguageToggle
   * @instance
   */
  handleKeyPress = (event) => {
    if (event.key == "Escape") {
      this.closeMenu();
    }
  };

  /**
   * Function for handling menu open action on language menu.    
   * @function openMenu
   * @memberof components.common.LanguageToggle
   * @instance
   */
  openMenu = () => {
    document.body.addEventListener("click", this.onBodyClick);
    document.body.addEventListener("keydown", this.handleKeyPress);
    this.setState({
      isMenuOpen: true,
    });
  };

  /**
   * Function for handling menu close action on language menu.    
   * @function closeMenu
   * @memberof components.common.LanguageToggle
   * @instance
   */
  closeMenu = () => {
    document.body.removeEventListener("click", this.onBodyClick);
    document.body.removeEventListener("click", this.handleKeyPress);
    this.setState({
      isMenuOpen: false,
    });
  };
}

export default LanguageToggle;
