/**
 * <pre>
 * Class for a Review component in store from retrieved data.
 * Package - stores/data. 
 * </pre>
 * @class stores.data.Review
 */
export default class Review {
  // ================================================================================================================
  // CONSTRUCTOR ----------------------------------------------------------------------------------------------------

  /**
   * Creates An Object of Review Component. 
   * @constructor    
   */    
  constructor() {
    this.id = "";
    this.slug = ""; // Friendly slug, for reporting
    this.score = 0;
    this.review = ""; // Markdown text
    this.reviewTitle = "";
    this.author = ""; // Bio
    this.date = ""; // yyyy-mm-dd
    this.translationOutdated = false;
    this.enabled = true;
  }
}
