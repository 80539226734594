import "core-js/shim";
import "requestidlecallback";
import "whatwg-fetch";

import React from "react";
import ReactDOM from "react-dom";
import Bowser from "bowser";
import Modernizr from "modernizr";

import SecurityPlannerApp from "./components/SecurityPlannerApp.react";
import SecurityPlannerConstants from "./constants/SecurityPlannerConstants";
import ValidationApp from "./components/debug/ValidationApp.react";

const browserVersion = parseFloat(Bowser.version);

let supportedBrowser = false;
const browserTestResults = Object.entries(Modernizr);
for (const browserTestResult of browserTestResults) {
  if (browserTestResult[1]) {
    supportedBrowser = true;
  } else {
    supportedBrowser = false;
    break;
  }
}
// console.log(`Supported browser? ${supportedBrowser}`);

if (SecurityPlannerConstants.Parameters.IS_VALIDATING) {
  ReactDOM.render(<ValidationApp />, document.getElementById("app-container"));
  // console.log("Validation initialized."); // eslint-disable-line
} else if (supportedBrowser || SecurityPlannerConstants.Parameters.SKIP_BROWSER_CHECK) {
  // Modern browser, start

  // Add needed CSSs
  if (Bowser.msie) document.body.classList.add("platform-browser-msie");
  if (Bowser.msedge) document.body.classList.add("platform-browser-msedge");
  if (Bowser.safari) document.body.classList.add("platform-browser-safari");
  if (Bowser.chrome) document.body.classList.add("platform-browser-chrome");
  if (Bowser.firefox) document.body.classList.add("platform-browser-firefox");
  if (Bowser.android) document.body.classList.add("platform-os-android");
  if (Bowser.iphone) document.body.classList.add("platform-device-iphone");
  if (Bowser.ios) document.body.classList.add("platform-os-ios");
  if (Bowser.ios && browserVersion >= 7 && browserVersion < 8) document.body.classList.add("platform-os-ios-7");

  // Set a CSS variable for VH that reflects actual viewport height even on iOS devices with tab bar visible
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    // Update --vh variable to reflect new window height
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  /*if (window.location.pathname === "/es") {
    localStorage.setItem("preferredLanguage", "es-US");
  } else {
    localStorage.setItem("preferredLanguage", "en-US");
  } 

  // loads langugage change on action page using ?l=es-US
  if (window.location.pathname.indexOf('/action-plan') > -1 && 
       window.location.search.indexOf('es-US') > -1) {
    localStorage.setItem("preferredLanguage", "es-US");
  }*/    

  if (window.location.pathname.indexOf("/es") > -1) {
    localStorage.setItem("preferredLanguage", "es-US");  
  } else { 
    localStorage.setItem("preferredLanguage", "en-US");
  }          

  // Render
  ReactDOM.render(<SecurityPlannerApp />, document.getElementById("app-container"));
  // console.log("App initialized. Be safe out there."); // eslint-disable-line
} else {
  // Old browsers
  // eslint-disable-next-line no-restricted-globals
  location.href = "legacy.html";
}
