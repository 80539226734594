import PropTypes from "prop-types";
import React from "react";

import SecurityPlannerConstants from "./../../../constants/SecurityPlannerConstants";
import SecurityPlannerStore from "./../../../stores/SecurityPlannerStore";
import MiniTracker from "./../../../vendor/tracking/MiniTracker";
import DirectionUtils from "./../../../vendor/utils/DirectionUtils";
import Footer from "./Footer.react";
import Header from "./Header.react";
import ThreatList from "./ThreatList.react";
import TopPriorityTool from "./TopPriorityTool.react";

/**
 * <pre>
 * Class to generate a print report page in the Application.
 * Uses the Threatlist component for report generation.
 * Package Name - components/pages/printreport
 * </pre>
 * @class components.pages.printreport.PrintReportPage
 */
class PrintReportPage extends React.Component {
  static propTypes = {
    stringList: PropTypes.object,
    threats: PropTypes.array, // Threat[]
    topTool: PropTypes.object, // Tool
    tools: PropTypes.array, // Tool[]
  };

  shareURL = undefined;
  isActive = false;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    document.body.style.overflow = "auto";
    document.body.style.background = "#ffffff";
  }

  componentDidMount() {
    MiniTracker.trackEvent("page", "print-action-plan", this.getResultsHash());

    // This is kind of horrific, but otherwise the banner appears on the printed page
    document.querySelector(".common-join-banner").remove();
  }

  /**
   * Renders the print report page object.
   * @function render
   * @memberof components.pages.printreport.PrintReportPage
   * @instance
   */
  render() {
    // We pick the first tool of the first threat as our "top" recommendation
    const topToolIndex = this.props.tools.findIndex((tool) => tool == this.props.topTool);
    const toolsToUse = this.props.tools.slice(0, topToolIndex).concat(this.props.tools.slice(topToolIndex + 1));
    const threatsToUse = this.getThreatsToUse(this.props.threats, toolsToUse);

    return (
      <div className={"pagePrintReport " + DirectionUtils.getClass(this.props.stringList)}>
        <Header stringList={this.props.stringList} toolsHash={this.getResultsHash()} />
        <hr />
        <TopPriorityTool stringList={this.props.stringList} tool={this.props.topTool} />
        <hr />
        <ThreatList stringList={this.props.stringList} threats={threatsToUse}
          tools={toolsToUse} />
        <hr className="thin" />
      </div>
    );
  }

  /**
   * Fetches the hash element for the report page.
   * @function getResultsHash
   * @memberof components.pages.printreport.PrintReportPage
   * @instance
   */
  getResultsHash = () => {
    // Returns the current state as a hash
    return SecurityPlannerStore.saveState();
  };

  /**
   * Fetches the threats to use for the report page.
   * @param  {object} allThreats       
   * @param  {object} tools          
   * @function getThreatsToUse
   * @memberof components.pages.printreport.PrintReportPage
   * @instance
   */
  getThreatsToUse = (allThreats, tools) => {
    // Get a list of threat groups with at least one tool
    const orderedThreats = [];
    allThreats.forEach((threat) => {
      if (tools.some((tool) => tool.threat.id == threat.id)) {
        orderedThreats.push(threat);
      }
    });
    return orderedThreats;
  };

  /**
   * Ran when the section becomes the focused section
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	   
   * @param  {object} fromOverlay	        
   * @function onActivate
   * @memberof components.pages.printreport.PrintReportPage
   * @instance        
   */
  onActivate = (travelOffset, viaHistoryAPI, fromOverlay) => {
    // eslint-disable-line no-unused-vars
    this.isActive = true;
  };

  /**
   * Ran when the section is about to lose focus
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	   
   * @param  {object} toOverlay	        
   * @function onDeactivate
   * @memberof components.pages.printreport.PrintReportPage
   * @instance           
   */
  onDeactivate = (travelOffset, viaHistoryAPI, toOverlay) => {
    // eslint-disable-line no-unused-vars
    // Ran when the section becomes inactive
    this.isActive = false;
  };
}

export default PrintReportPage;
