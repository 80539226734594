import PropTypes from "prop-types";
import React from "react";
import ClickTouchHandler from "../../../vendor/utils/ClickTouchHandler";

/**
 * <pre>
 * Class to create a next section on statements in the Application.
 * Package Name - components/pages/statements
 * </pre>
 * @class components.pages.statements.NextCard
 */
class NextCard extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    allowFocus: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    isEmpty: PropTypes.bool,
    backgroundColor: PropTypes.number,
  };

  componentWillUnmount() {
    this.touchHandler.dispose();
  }

  /**
   * Action on click of the next section. 
   * @function render
   * @memberof components.pages.statements.NextCard
   * @instance
   */
  onClickSelf = (e) => {
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  /**
   * Renders the next section on statements page.
   * @function render
   * @memberof components.pages.statements.NextCard
   * @instance
   */
  render() {
    this.touchHandler = new ClickTouchHandler(this.onClickSelf, true);

    return (
      <div
        data-tracking="next-button"
        className={(this.props.className || "") + (this.props.allowFocus ? " next-button is-enabled" : " is-disabled next-button")}
        tabIndex={this.props.allowFocus ? 0 : -1}
        role="button"
        title={this.props.text}
        aria-label={this.props.text}
        onKeyDown={this.touchHandler.handler}
        onClick={this.touchHandler.handler}
        onTouchStart={this.touchHandler.handler}
        onTouchMove={this.touchHandler.handler}
        onTouchEnd={this.touchHandler.handler}
        onTouchCancel={this.touchHandler.handler}
      >
        <div className="selector" />
        <div className="caption">{this.props.text}</div>
      </div>
    );
  }
}

export default NextCard;
