import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import cx from "classnames";

import ResizeUtils from "./../../vendor/utils/ResizeUtils";

import Dropdown from "./Dropdown.react";
import ImageContainer from "./ImageContainer.react";
import Branding from "./Branding.react"; 
import ADAUtils from "../../vendor/utils/ADAUtils"; 
import MiniTracker from "../../vendor/tracking/MiniTracker";
import Search from "./Search.react";

/**
 * <pre>
 * Class for creating the threat/action-page menu.
 * Package Name - components/common
 * </pre>
 * @class components.common.ThreatMenu
 */
class ThreatMenu extends React.Component {
  state = {
    activeThreatIndex: 0,
    menuDropdownOptionSelected: false,
    isSearching: false,
  };

  static propTypes = {
    activeFilter: PropTypes.shape({
      cost: PropTypes.number,
      effort: PropTypes.number,
    }).isRequired,
    backgroundColor: PropTypes.string,
    currentActiveThreatId: PropTypes.string,
    filterCategories: PropTypes.shape({
      cost: PropTypes.object,
      effort: PropTypes.object,
    }),
    hasUpdatedFilter: PropTypes.bool,
    isVisible: PropTypes.bool,
    menuTitle: PropTypes.string.isRequired,
    onClickThreat: PropTypes.func,
    onUpdateActiveFilter: PropTypes.func,
    stringList: PropTypes.object,
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    goToPage: PropTypes.func.isRequired,
    threatList: PropTypes.array, // Threat
    allowFocus: PropTypes.bool,
    onOpen: PropTypes.func,
    tools: PropTypes.array,
    allTools: PropTypes.array,
  };

  threatMenuTitle = "";
  wrapperPadding = 64; // 32px x 2

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.activeFilter.cost !== this.props.activeFilter.cost ||
      nextProps.activeFilter.effort !== this.props.activeFilter.effort ||
      nextProps.backgroundColor !== this.props.backgroundColor ||
      nextProps.currentActiveThreatId !== this.props.currentActiveThreatId ||
      (nextProps.filterCategories !== this.props.filterCategories &&
        (nextProps.filterCategories == undefined ||
          this.props.filterCategories == undefined ||
          nextProps.filterCategories.cost !== this.props.filterCategories.cost ||
          nextProps.filterCategories.effort !== this.props.filterCategories.effort)) ||
      nextProps.hasUpdatedFilter !== this.props.hasUpdatedFilter ||
      nextProps.isVisible !== this.props.isVisible ||
      nextProps.menuTitle !== this.props.menuTitle ||
      nextProps.onClickThreat !== this.props.onClickThreat ||
      nextProps.onUpdateActiveFilter !== this.props.onUpdateActiveFilter ||
      nextProps.stringList !== this.props.stringList ||
      nextProps.threatList !== this.props.threatList ||
      nextProps.allowFocus !== this.props.allowFocus ||
      nextState.activeThreatIndex !== this.state.activeThreatIndex ||
      nextState.isSearching !== this.state.isSearching
    );
  }

  componentDidUpdate(prevProps, prevState ) {
    const newState = {};

    this.threatListEl = ReactDOM.findDOMNode(this.threatListComp);
    this.wrapperEl = ReactDOM.findDOMNode(this.wrapperComp);
    this.filterDropdownEl = ReactDOM.findDOMNode(this.filterDropdownComp);
    this.printButtonEl = ReactDOM.findDOMNode(this.printButtonComp);

    this.threatListWidth = this.threatListEl ? this.threatListEl.offsetWidth : 0;

    newState.activeThreatIndex = this.getActiveThreatIndex(this.props);

    if (this.state.activeThreatIndex !== newState.activeThreatIndex) {
      this.setState(newState);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentActiveThreatId !== nextProps.currentActiveThreatId) {
      this.setState({
        activeThreatIndex: this.getActiveThreatIndex(nextProps),
      });
    }
  }

  /**
   * Renders the threat menu object.
   * @function render
   * @memberof components.common.ThreatMenu
   * @instance
   */
  render() {
    const bp = ResizeUtils.getCurrentBreakpoint();
    const isTinyOrSmallBreakpoint = bp === "tiny" || bp === "small";

    this.threatMenuTitle = this.props.menuTitle;

    const menuClasses = cx({
      "common-threat-menu": true,
      "is-hidden": !this.props.isVisible,
    });

    return (
      <div className={menuClasses} style={{ backgroundColor: this.props.backgroundColor }}>
        <div className="wrapper" ref={(r) => (this.wrapperComp = r)}>
          
          <Branding routes={this.props.routes} goToPage={this.props.goToPage}
            stringList={this.props.stringList} /> 
          {/*{this.renderLanguageMenuDropdown(isTinyOrSmallBreakpoint)}*/}             
          {this.renderMenuDropdown(isTinyOrSmallBreakpoint)}
          {this.props.onUpdateActiveFilter ? this.renderFilterDropdowns(isTinyOrSmallBreakpoint) : null}
          {this.renderSearchToggle(isTinyOrSmallBreakpoint)}
          {this.renderLanguageLink(isTinyOrSmallBreakpoint, this.goToLangugageSiteFromLink)}        
        </div>
        <Search isSearching={this.state.isSearching} stringList={this.props.stringList} tools={this.props.allTools} goToPage={this.props.goToPage} routes={this.props.routes}  onKeyDown={this.escFunction} searchChange={this.escFunction}/>
      </div>
    );
  }

  /*renderLanguageMenuDropdown = (isTinyOrSmallBreakpoint) => { 
   if(!isTinyOrSmallBreakpoint){ 
     const langOptions = this.props.filterCategories.sitelanguage.filters; 
     let langOptionsMod = [];
     for(i=0; i<langOptions.length; i++){
       let nameMod = (langOptions[i].indexOf('Spanish') > -1) ? 'Español' : langOptions[i];
       langOptionsMod.push(nameMod);
     }
     const langCodes = this.props.filterCategories.languagecode.filters;
     let currLangKey = localStorage.preferredLanguage;
     let activeOpt = 0; let cnt = 0; //console.log(langOptions); 
	 const menutitle = (currLangKey.indexOf('en') > -1) ? "Languages" : "Idiomas";    
     for (i=0; i<langCodes.length; i++) {         
       if ( currLangKey == langCodes[i] ){ activeOpt = i; } 
	 }
     return (
       <div className={"threat-menu-trigger "} ref={(r) => (this.menuDropdownComp = r)}
        style={this.props.threatList.length === 0 ? { visibility: "hidden" } : undefined}>
        <Dropdown
          className={isTinyOrSmallBreakpoint ? "full" : undefined}
          activeOption={activeOpt}
          isActive={1}
          label={menutitle}
          options={langOptionsMod}
          allowFocus={this.props.allowFocus}
          onSelectOption={(selectedIndex) => {  
             this.goToLangugageSite(selectedIndex);
          }}
        />
       </div> 
     );
   }   
  };*/

  /**
   * Renders the search menu trigger.   
   * @param  {boolean} isTinyOrSmallBreakpoint  
   * @function renderSearchToggle
   * @memberof components.common.ThreatMenu
   * @instance
   */

  renderSearchToggle = (isTinyOrSmallBreakpoint) => {
    if( !isTinyOrSmallBreakpoint ){
      return (
          <button
            className={"searchToggle" + (this.state.isSearching ? " search--active" : "")}
            tabIndex={0}  
            role="menuitem"
            title={this.props.stringList.get("menu-search-caption")}
            aria-label={this.props.stringList.get("menu-search-caption")}
            onKeyDown={ADAUtils.handleKeyboard(this.toggleSearch)}
            onClick={this.toggleSearch}
          >
            {this.props.stringList.get("menu-search-caption")}
          </button>
      );
    }else{
      return null;
    }
  };

  escFunction = (event) => {
    document.querySelector('.search-input').value = "";
    
    this.setState({
      isSearching: false,
      showSearch: true,
      showClear: false,
      searchResults: []
    });

    const searchInput = document.querySelector(".search-input");
    searchInput.value = "";
    MiniTracker.trackEvent({
      event: "search-close",
      keyword: searchInput.value
    });
    if(document.querySelector('.search-results-container')) {
      document.querySelector('.search-results-container').style.display = 'none';
    }
}

  /**
   * Toggle visibility of the search UI
   * @function toggleSearch
   * @memberof components.common.ThreatMenu
   * @instance
   */
   toggleSearch = (e) => {
    e.preventDefault();
    this.setState({
      isSearching: !this.state.isSearching,
    });
  };

  /**
   * Renders the language link on threat menu object. 
   * The language option is displayed as per CM-5610.   
   * @param  {boolean} isTinyOrSmallBreakpoint  
   * @param  {string} func    
   * @function renderLanguageLink
   * @memberof components.common.ThreatMenu
   * @instance
   */
  renderLanguageLink = (isTinyOrSmallBreakpoint, func) => { 
   if(!isTinyOrSmallBreakpoint){ 
     var switchers = []; 
     let currLangKey = localStorage.preferredLanguage;     
     const languageOptions = this.props.filterCategories.languagecode.filters; 
     const languageOptionsName = this.props.filterCategories.sitelanguage.filters;  
     for (i=0; i<languageOptions.length; i++) {         
        if ( currLangKey == languageOptions[i] ){ continue; } 
        let langTitle = (languageOptionsName[i].indexOf('Spanish') > -1) ? 'Español' : languageOptionsName[i];
        switchers.push( 
          <div className={"link-group link-group--info"} key={languageOptionsName[i]}>        
          <div className={"link"} key={languageOptionsName[i]}>
            <a href="#" tabIndex={0} id={languageOptions[i]} 
              title={languageOptionsName[i]} aria-label={languageOptionsName[i]}
              onKeyDown={ADAUtils.handleKeyboard(func)}
              onClick={func}> {langTitle} </a> 
          </div> 
          </div>    
        ); 
	 }
	 return switchers; 
   }   
  };   

  // Renders a menu dropdown instead of a trigger when the length of the names exceeds the max width of the menu container
  /**
   * Renders the dropdown for menus in threat menu. 
   * @param  {boolean} isTinyOrSmallBreakpoint  
   * @function renderMenuDropdown
   * @memberof components.common.ThreatMenu
   * @instance
   */  
  renderMenuDropdown = (isTinyOrSmallBreakpoint) => {
    if (isTinyOrSmallBreakpoint) return null;

    const options = this.props.threatList.map((threat) => threat.name);

    return (
      <div
        className={"threat-menu-trigger"}
        ref={(r) => (this.menuDropdownComp = r)}
        style={options.length === 0 ? { visibility: "hidden" } : undefined}
      >
        <Dropdown
          className={isTinyOrSmallBreakpoint ? "full" : undefined}
          activeOption={this.state.activeThreatIndex}
          isActive={this.state.menuDropdownOptionSelected}
          label={this.threatMenuTitle}
          options={options}
          allowFocus={this.props.allowFocus}
          onOpen={this.props.onOpen}
          onSelectOption={(selectedIndex) => {
            this.selectCategoryDropdown(selectedIndex);
          }}
        />
      </div>
    );
  };

  /**
   * Renders the filter dropdown for menus in threat menu for smaller screens. 
   * The language option is displayed as per CM-5610.
   * @param  {boolean} isTinyOrSmallBreakpoint  
   * @function renderFilterDropdowns
   * @memberof components.common.ThreatMenu
   * @instance
   */  
  renderFilterDropdowns = (isTinyOrSmallBreakpoint) => {
    if (isTinyOrSmallBreakpoint) {
      const options = this.props.threatList.map((threat) => threat.name);
      const langOptions = this.props.filterCategories.sitelanguage.filters;
      let langOptionsMod = [];
      for(i=0; i<langOptions.length; i++){
       let nameMod = (langOptions[i].indexOf('Spanish') > -1) ? 'Español' : langOptions[i];
       langOptionsMod.push(nameMod);
      }        
      let currLangKey = localStorage.preferredLanguage;
	  const langtitle = (currLangKey.indexOf('en') > -1) ? "Languages" : "Idiomas"; 
	  
      return (
        <>
        
      <button
        className={"searchToggle mobileSearchToggle" + (this.state.isSearching ? " search--active" : "")}
        tabIndex={0}  
        role="menuitem"
        title={this.props.stringList.get("menu-search-caption")}
        aria-label={this.props.stringList.get("menu-search-caption")}
        onKeyDown={ADAUtils.handleKeyboard(this.toggleSearch)}
        onClick={this.toggleSearch}
      >
      </button>
        <div className="filter-dropdown-wrapper" ref={(r) => (this.filterDropdownComp = r)}>
          <Dropdown
            className={isTinyOrSmallBreakpoint ? "full" : undefined}
            activeOption={[this.props.activeFilter.sitelanguage, undefined, this.props.activeFilter.cost, this.props.activeFilter.effort]}
            onOpen={this.props.onOpen}
            textLabel={this.props.stringList.get("all-tools-title")}
            label={(isMenuOpen) => (
              <div className="menu" key="menu-button">
                <a href="#" role="link">
                  <span className="sr-only">{this.props.stringList.get(isMenuOpen ? "menu-close" : "menu-open")}</span>
                  {isMenuOpen ? (
                    <img src={require("./../../../images/ui/filter-menu-close.svg")} alt="" />
                  ) : (
                    <img src={require("./../../../images/ui/filter-menu.svg")} alt="" />
                  )}
                </a>
              </div>
            )}
            hasCategories={true}
            options={[
              {
                label: (
                  <span className="filter-category">
                    <ImageContainer className="icon" src={require("./../../../images/ui/effort-green.svg")} />
                    <div className="text">{langtitle}</div>
                  </span>
                ),
                options: langOptionsMod,
              },             
              {
                label: (
                  <span className="filter-category">
                    <div className="text">{this.threatMenuTitle}</div>
                  </span>
                ),
                options,
              },
              {
                label: (
                  <span className="filter-category">
                    <ImageContainer className="icon" src={require("./../../../images/ui/cost-icon-green.svg")} />
                    <div className="text">{this.props.stringList.get("all-tools-filter-cost-title")}</div>
                  </span>
                ),
                options: this.props.filterCategories.cost.filters,
              },
              {
                label: (
                  <span className="filter-category">
                    <ImageContainer className="icon" src={require("./../../../images/ui/effort-green.svg")} />
                    <div className="text">{this.props.stringList.get("all-tools-filter-effort-title")}</div>
                  </span>
                ),
                options: this.props.filterCategories.effort.filters,
              },
            ]}
            allowFocus={this.props.allowFocus}
            onSelectOption={(selectedIndex, _, categoryIdx) => {
              switch (categoryIdx) {
              case 0: {
                return this.goToLangugageSite(selectedIndex);
              }              
              case 1: {
                return this.selectCategoryDropdown(selectedIndex);
              }
              case 2: {
                return this.selectFilterDropdown("cost", selectedIndex);
              }
              case 3: {
                return this.selectFilterDropdown("effort", selectedIndex);
              }
              default: {
                // no-op
              }
              }
            }}
          />
        </div>
        </>
      );
    }

    return (
      <div className="filter-dropdown-wrapper" ref={(r) => (this.filterDropdownComp = r)}>
        <Dropdown
          onOpen={this.props.onOpen}
          activeOption={this.props.activeFilter.cost}
          label={<>
            <ImageContainer className="icon" src={require("./../../../images/ui/cost-white.svg")} />
            <div className="text">{this.props.filterCategories.cost.title}</div>
          </>}
          options={this.props.filterCategories.cost.filters}
          allowFocus={this.props.allowFocus}
          onSelectOption={(selectedIndex) => this.selectFilterDropdown("cost", selectedIndex)}
          textLabel={this.props.filterCategories.effort.title}
        />
        <Dropdown
          onOpen={this.props.onOpen}
          activeOption={this.props.activeFilter.effort}
          label={<>
            <ImageContainer className="icon" src={require("./../../../images/ui/effort-white.svg")} />
            <div className="text">{this.props.filterCategories.effort.title}</div>
          </>}
          options={this.props.filterCategories.effort.filters}
          allowFocus={this.props.allowFocus}
          onSelectOption={(selectedIndex) => this.selectFilterDropdown("effort", selectedIndex)}
          textLabel={this.props.filterCategories.effort.title}
        />
      </div>
    );
  };

  /**
   * Action to fetch the active threat index for the menus in threat menu. 
   * @param  {boolean} props  
   * @function getActiveThreatIndex
   * @memberof components.common.ThreatMenu
   * @instance
   */  
  getActiveThreatIndex = (props) => {
    for (let i = 0; i < props.threatList.length; i++) {
      if (props.currentActiveThreatId === props.threatList[i].id) {
        return i;
      }
    }
  };

  /**
   * Action for selection of the filter dropdown for the menus in threat menu. 
   * @param  {object} filterType 
   * @param  {number} selectedIndex     
   * @function selectFilterDropdown
   * @memberof components.common.ThreatMenu
   * @instance
   */  
  selectFilterDropdown = (filterType, selectedIndex) => {
    const newFilters = Object.assign({}, this.props.activeFilter);
    newFilters[filterType] = selectedIndex;

    this.props.onUpdateActiveFilter(newFilters);
  };

  /**
   * Action for selection of the category filter in dropdown for menus in threat menu. 
   * @param  {number} threatIndex     
   * @function selectCategoryDropdown
   * @memberof components.common.ThreatMenu
   * @instance
   */  
  selectCategoryDropdown = (threatIndex) => {
    this.setState({ menuDropdownOptionSelected: true });
    this.props.onClickThreat(this.props.threatList[threatIndex].id);
  }; 

  /**
   * Action for redirecting to language site from language filter in dropdown for menus in threat menu. 
   * Action to proceed to the language site is as per CM-5609.
   * @function goToLangugageSite
   * @memberof components.common.ThreatMenu
   * @instance
   */  
  goToLangugageSite = (e) => {
    let url = ''; let index = e; let cnt=0; 
    let defLang = localStorage.preferredLanguage; 
    let langOptions = this.props.filterCategories.languagecode.filters;
    for (i=0; i<langOptions.length; i++) { 
	  if( cnt == index ) {  
	    if( defLang !== langOptions[i] ) { 
	      if( langOptions[i] == 'es-US' ) {
			localStorage.setItem("preferredLanguage", 'es-US');
        	window.location.assign( window.location.origin + '/es' ); 
      	  }else{
        	localStorage.setItem("preferredLanguage", "en-US"); 
        	window.location.assign( window.location.origin + '/' );       
      	  }	    
	    }
	  }
	  cnt++; 
	} 
  };

  /**
   * Action for redirecting to language site from language link for menus in threat menu. 
   * Action to proceed to the language site is as per CM-5609.   
   * @function goToLangugageSiteFromLink
   * @memberof components.common.ThreatMenu
   * @instance
   */  
  goToLangugageSiteFromLink = (e) => {
    MiniTracker.trackEvent("button", "click", "languageswitcher");
    if( e.currentTarget.id != localStorage.preferredLanguage ){ 
      if( e.currentTarget.id == 'es-US' ){ 
        localStorage.setItem("preferredLanguage", "es-US");
        window.location.assign( '/es' ); 
      }else{
        localStorage.setItem("preferredLanguage", "en-US");
        window.location.assign( '/' );       
      }
    }else{ 
      if( e.currentTarget.id == 'es-US' ){ 
        localStorage.setItem("preferredLanguage", "es-US");
        window.location.assign( '/es' ); 
      }else{
        localStorage.setItem("preferredLanguage", "en-US");
        window.location.assign( '/' );       
      }    
    }
    e.preventDefault();
  };     
  
}

ThreatMenu.ID_OVERLAY_THREAT_TRANSPORT = "threat-overlay";
ThreatMenu.ID_OVERLAY_TOOL_TRANSPORT = "filter-overlay";

export default ThreatMenu;
