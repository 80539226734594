import PropTypes from "prop-types";
import React from "react";
import Bowser from "bowser";
import cx from "classnames";
import reactStringReplace from "react-string-replace";
import MiniTracker from "../../vendor/tracking/MiniTracker";

/**
 * <pre>
 * Creates the search ui in the Application.  
 * Package Name - components/common
 * </pre>
 * @class components.common.Search
 */
class Search extends React.Component {
  static propTypes = {
    stringList: PropTypes.object, // StringList
    selectedLanguage: PropTypes.object, // Language
    availableLanguages: PropTypes.arrayOf(PropTypes.object), // Language[]
    allowFocus: PropTypes.bool,
    isSearching: PropTypes.bool,
    tools: PropTypes.arrayOf(PropTypes.object), // Tool[]
    goToPage: PropTypes.func, // (page: string) => void
    routes: PropTypes.object, // Routes
    searchChange: PropTypes.func
  };

  /**
   * Creates An Object of Search UI. 
   * @param  {object} props      
   * @constructor    
   */  
  constructor(props) {
    super(props);
    const isTouch = Bowser.ios || Bowser.android;

    this.state = {
      isSearching: this.props.isSearching,
      isTouch: isTouch,
      searchResults: [],
      showSearch: true,
      showClear: false,
      searchTerm: ""
    };
    this.setState({
      isSearching: this.props.isSearching
    });
  }

  //We're using props to determine open/close status, so capture close event here
  componentWillReceiveProps(nextProps) {
    if (nextProps.isSearching !== this.props.isSearching) {
      if( !nextProps.isSearching ){
        const searchInput = document.querySelector(".search-input");
        MiniTracker.trackEvent({
          event: "search-close",
          keyword: searchInput.value
        });
      }else{
        MiniTracker.trackEvent({
          event: "search-open"
        });
      }
    }
  }

  /**
   * Renders the search ui object.
   * @function render
   * @memberof components.common.Search
   * @instance
   */
  render() {

    const searchClasses = cx({
      "common-search": true,
      desktop: !this.state.isTouch,
      "touch-device": this.state.isTouch,
      "is-open": this.props.isSearching,
      "is-closed": this.state.isTouch && !this.props.isSearching,
      "is-open-by-ada": this.props.isSearching,
    });

    return (
      <div className={searchClasses} role="search" onKeyDown={this.escFunction}>
        <div className="search-container">
            <form className="search-input-container" onSubmit={this.handleSubmit}>

                <input type="text" className="search-input" placeholder={this.props.stringList.get('menu-search-placeholder')} data-mobile-placeholder={this.props.stringList.get('menu-search-placeholder-mobile')} onChange={this.handleSearchChange} />

                { this.state.showSearch ? 
                <button className="search-button">{this.props.stringList.get('menu-search-submit-label')}</button>
                :
                <button className="search-button search-button--clear" onClick={this.clearSearch}>{this.props.stringList.get('menu-search-clear-label')}</button>
                }

            </form>
            { this.props.isSearching & this.state.searchResults.length > 0 ?
              <div className="search-results-container">
                  <ul className="search-results-list">
                      {this.state.searchResults.map((result, index) => {
                          return (
                              <li key={index} className="search-results-list-item">
                                  <button className="search-results-list-item-link" onClick={() => this.showOverlay(result.tool)} tabIndex="0">
                                      <span className="search-results-list-item-link-title">
                                        { reactStringReplace(result.tool.name, this.state.searchTerm, (match, i) => ( <span key={i} className="font-bold">{match}</span> )) }
                                      </span>
                                  </button>
                              </li>
                          );
                      })}
                  </ul>
              </div>
            : null }
        </div>
      </div>
    );
  }

  // handleEscapePress = (event) => {
  //   console.log(event.key);
  // }

  componentDidMount(){
    // document.addEventListener("keydown", this.escFunction, false);
  }

  escFunction = (event) => {
    if (event.key === "Escape") {
      this.props.searchChange();
        if(window.innerWidth <= 767) {
          var newPlaceholder = document.querySelector('.search-input').getAttribute('data-mobile-placeholder');
          document.querySelector('.search-input').setAttribute('placeholder', newPlaceholder);
        }

        if(document.querySelector('.common-search').classList.contains('is-open')) {
          document.querySelector('.common-search').classList.remove('is-open');
          document.querySelector('.common-search').classList.remove('is-open-by-ada');
          document.querySelector('.link--search > .searchToggle').classList.remove('search--active');
        }
    
        this.setState({
          isSearching: false,
          showSearch: true,
          showClear: false,
          searchResults: []
        });

        const searchInput = document.querySelector(".search-input");
        searchInput.value = "";
        MiniTracker.trackEvent({
          event: "search-close",
          keyword: searchInput.value
        });
      }
  }

  toggleSearch = (e) => {
    e.preventDefault();
    if(window.innerWidth <= 767) {
      var newPlaceholder = document.querySelector('.search-input').getAttribute('data-mobile-placeholder');
      document.querySelector('.search-input').setAttribute('placeholder', newPlaceholder);
    }

    this.setState({
      isSearching: !this.state.isSearching,
    });
  };

  getSearchResults = (searchTerm) => {
    const searchResults = [];
    const searchTerms = searchTerm.toLowerCase().split(" ");
    const tools = this.props.tools;
    const toolKeys = Object.keys(tools);
    for (let i = 0; i < toolKeys.length; i++) {
      const tool = tools[toolKeys[i]];
      const toolName = tool.name.toLowerCase();
      const toolDescription = tool.longDescription.toLowerCase();
      const toolImportant = tool.whyItsImportant.toLowerCase();
      const toolKeywords = tool.keywords.join(" ").toLowerCase();
      let toolMatches = 0;
      for (let j = 0; j < searchTerms.length; j++) {
        const searchTerm = searchTerms[j];

        //Note: we could be weighting the matches here based on where they come from
        if (toolName.indexOf(searchTerm) !== -1) {
          toolMatches++;
        }
        if (toolDescription.indexOf(searchTerm) !== -1) {
          toolMatches++;
        }
        if (toolImportant.indexOf(searchTerm) !== -1) {
          toolMatches++;
        }
        if (toolKeywords.indexOf(searchTerm) !== -1) {
          toolMatches++;
        }
      }
      if (toolMatches > 0) {
        searchResults.push({
          tool: tool,
          matches: toolMatches,
        });
      }
    }
    searchResults.sort((a, b) => {
      return b.matches - a.matches;
    });
    //Reduce to 5 results
    searchResults.splice(5);

    return searchResults;
  }

  setSearchResults = (results) => {
    this.setState({
      searchResults: results
    });
  }

  clearSearch = (e) => {
    e.preventDefault();
    const searchInput = e.target.parentNode.querySelector(".search-input");

    MiniTracker.trackEvent({
      event: "search-clear",
      keyword: searchInput.value
    });

    searchInput.value = "";
    this.setState({
      showSearch: true,
      showClear: false,
      searchResults: []
    });
  }

  handleSearch = (searchTerm) => {
    if (searchTerm) {
      this.setState({
        showSearch: false,
        showClear: true,
        searchTerm: searchTerm
      })
      const searchResults = this.getSearchResults(searchTerm);
      this.setSearchResults(searchResults);
    }else{
      this.setState({
        showSearch: true,
        showClear: false,
        searchTerm: ""
      })
      this.setSearchResults([]);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const searchInput = e.target.querySelector(".search-input");
    const searchValue = searchInput.value;
    this.handleSearch(searchValue);
  }

  handleSearchChange = (e) => {
    const searchInput = e.target;
    const searchValue = searchInput.value;
    const isValid = this.isSearchValid(searchValue);
    if(isValid) {
      MiniTracker.trackEvent({
        event: "search",
        keyword: searchValue
      });
      this.handleSearch(searchValue);
    }
  }

  /**
   * Action for displaying overlay on the tool object.
   * @function showOverlay
   * @memberof components.common.Search
   * @instance
   */
  showOverlay = ( tool ) => {
    const searchInput = document.querySelector(".search-input");

    MiniTracker.trackEvent({
      event: "search-result-click",
      keyword: searchInput.value,
      tool: tool.slug
    });
    if (this.props.goToPage && this.props.routes) this.props.goToPage(this.props.routes.getUriOverlayTool(tool.slug), true, true);
  };


  /**
   * Function to check if search value is valid.
   * @param  {string} input    
   * @function isSearchValid
   * @memberof 
   * @instance
   */
   isSearchValid = (input) => {
    if(input != null) {
      if (/\S/.test(input)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

}

export default Search;
