
import * as React from "react";
import PropTypes from "prop-types";
import $, { fn } from "jquery";

export default class extends React.Component {
  static propTypes = {
    stringList: PropTypes.object.isRequired,
  };

componentDidMount() {
    
        $("#wp_cta-form").submit(function (event) {
          event.preventDefault();
      
          var formObject = {};
    
          formObject.firstName = document.getElementById('btm_first_name').value;
          formObject.lastName = document.getElementById('btm_last_name').value;
          formObject.username = document.getElementById('btm_email').value;
    
          window.firstName = document.getElementById('btm_first_name').value;
          var errorMessage = function (error) {
            $("#wp_cta-errors").text(error.responseJSON.message)
            $("#wp_cta-errors").show()
          };
      
          var success = function () {
               
         
            $('.complete-report + span > span').first().text(window.firstName + ", your Action Plan includes:") 
            $('#replace').html(`<div class="crux-body-copy crux-body-copy--extra-small"> Thanks, ${window.firstName}, for signing up to save your plan. You will receive periodic emails about this study and other information from Consumer Reports. </div>`)
              $('#monetate-section').append(` 
                <style id="monetate-thankyou-styles"> 
                    .threats-and-tools .middle>.title.tall {
                      height: 375px;
                      margin-top: -500px;
                      }
    
                    .threats-and-tools .header-bg.tall {
                      height: 500px;
                    }
    
                  @media (min-width: 640px) {
                      .threats-and-tools .header-bg.tall {
                          height: 550px;
                      }
    
                      .threats-and-tools .middle>.title.tall {
                          height: 400px;
                            margin-top: -550px;
                      }
                  }
    
                  @media only screen and (min-width: 960px) {
    
                      .threats-and-tools .header-bg.tall {
                      height: 635px;
                    }
    
                      .threats-and-tools .middle>.title.tall {
                          height: 350px;
                          margin-top: -635px;
                      } } </style>
        
             `)
          };
    
          function validateEmail(input) {
            if (input.length > 126 || input.length < 6 || input.replace(/\D/g, '').length > 10) {
              return false;
            }
    
     
            const regex = /^[\w\.\-\\+]{1,}\@([\da-zA-Z\-]{1,}\.){1,}[\da-zA-Z]+$/;
            const blacklistedValues = [/^jenmanz@aol.com$/, /^noemail@yahoo.com$/, /^some@yahoo.com$/,
              /^nowhere@anywhere.com$/, /^bogus@yahoo.com$/, /^[\w\.\-\\+]{1,}\@nowhere.com/,
              /^[\w\.\-\\+]{1,}\@none.com/, /^[\w\.\-\\+]{1,}\@noemails.com/,
              /^[\w\.\-\\+]{1,}\@no.com/, /^[\w\.\-\\+]{1,}\@noemail.com/, /^[\w\.\-\\+]{1,}\@nobody.com/,
              /^[\w\.\-\\+]{1,}\@nowhere.net/, /^[\w\.\-\\+]{1,}\@example.com/, /^[\w\.\-\\+]{1,}\@example.org/,
              /^[\w\.\-\\+]{1,}\@example.net/, /^[\w\.\-\\+]{1,}\@example.edu/];
    
            for (let i = 0; i < blacklistedValues.length; i += 1) {
              if (blacklistedValues[i].test(input)) {
                return false;
              }
            }
            return regex.test(input);
          }
          
          var ecomURL = window.CR.global.ecomHost + "/api/v1.0/authService/register/?setCookieHeaders=1&autoredirect=false";
          
          if (validateEmail(formObject.username) === true) {
            $.ajax({
              type: "POST",
              url: ecomURL,
              xhrFields: {
                  withCredentials: true
                 },
              data: formObject,
              success: success,
              error: errorMessage,
              dataType: "json",
            });
          } else {
            $("#wp_cta-errors").text('Please provide a valid email address')
            $("#wp_cta-errors").show()
          }
        });
      
}
  render() {

    //All strings coming from contentful
    var time = this.props.stringList.get("form-time");
    var member = this.props.stringList.get("member-text");
    var signIn = this.props.stringList.get("sign-in");
    var fName = this.props.stringList.get("form-first-name");
    var lName = this.props.stringList.get("form-last-name");
    var emailAdd = this.props.stringList.get("form-email");
    var signUp = this.props.stringList.get("form-sign-up-btn");
    var disclaimer1 = this.props.stringList.get("form-disclaimer-1");
    var disclaimerUA = this.props.stringList.get("form-disclaimer-ua");
    var disclaimerAnd = this.props.stringList.get("form-disclaimer-and");
    var disclaimerPP = this.props.stringList.get("form-disclaimer-pp");

    let markup = function() {
        return `

        <style>
          .wp_cta-row {
            margin: 0;
          }
        </style>
        <div class="cda-heading-block-wrapper" style="margin-top: 30px;  padding-top: 30px; border-top: 1px solid white;">
        <div class="row wp_cta-row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="cda-text-block-body wp_cta">
              <div class="col-12 wp_cta-container" id="replace">
                <div class="wp_cta-title-container">
                   <div class="crux-body-copy crux-body-copy--bold" id="wp_cta-title">
                    `+ time +`
                  </div>
                </div>
                <div class="crux-body-copy" id="wp_cta-errors" style="color: red !important;"></div>
                <div class="crux-body-copy" id="wp_cta-copy">
                    `+ member +` <a class="wp_cta-link" href="javascript:window.location.href = window.CR.global.targetHost + '/global/login?referrer=' + encodeURIComponent(window.location.pathname)">`+ signIn +`</a>
                </div>
                <form id="wp_cta-form">
              
                  <input
                    required="required"
                    class="input1 form-control cta-input crux-body-copy crux-body-copy--small"
                    aria-labelledby="petition_into_sr_label first_name_sr_label"
                    id="btm_first_name"
                    name="first_name"
                    placeholder="`+ fName +`"
                    type="text"
                    value=""
                  />
                  <input
                    required="required"
                    class="input2 form-control cta-input crux-body-copy crux-body-copy--small"
                    aria-labelledby="last_name_sr_label"
                    id="btm_last_name"
                    name="last_name"
                    placeholder="`+ lName +`"
                    type="text"
                    value=""
                  />
    
                  <input
                    required="required"
                    class="input3 form-control cta-input crux-body-copy crux-body-copy--small"
                    aria-labelledby="petition_into_sr_label email_sr_label"
                    id="btm_email"
                    name="email"
                    placeholder="` + emailAdd + `"
                    type="email"
                    value=""
                  />
                  <div class="crux-body-copy crux-body-copy--extra-small disclaimer">
                    `+ disclaimer1 +`
                    <a
                      href="https://www.consumerreports.org/cro/customerservice/privacy-policy/highlights/index.htm"
                      target="_blank"
                      class="wp_cta-link"
                      >`+ disclaimerPP +`</a
                    >
                    and
                    <a
                      href="https://www.consumerreports.org/cro/2015/01/user-agreement/index.htm"
                      target="_blank"
                      class="wp_cta-link"
                      >`+ disclaimerUA +`.</a
                    >
                  </div>
                  <button class="cta-button crux-body-copy crux-body-copy--small">
                    `+ signUp +`
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>`

    }

    let markupStyles = function(){
      return ` <style id="monetate-styles">
      .common-join-banner.is-fixed {
          display: none;
        }


.disclaimer {
grid-area: 4 / 1 / 4 / 4;
}
a {
cursor: pointer;
}

.wp_cta-link:hover,
.wp_cta-link {
color: white !important;
background: none !important;
}

#wp_cta-copy {
margin-bottom: 15px;
}

#wp_cta-errors {
display: none;
margin-bottom: 15px;
}

#wp_cta-title {
display: inline-block;
align-self: end;
font-size: 22px;
font-weight: 700;
}

.wp_cta-title-container {
display: flex;
margin-bottom: 15px;
}

input.cta-input {
border-radius: 4px;
height: 48px;
width: 100%;
display: inline-block;
}

button.cta-button {
border: none;
outline: none;
height: 45px;
padding-left: 15px;
padding-right: 15px;
font-weight: 700;
width: 225px;
color: #0b2e35;
background: #fdb933;
border-radius: 30px;
grid-area: 5/ 1/5/5;
margin-left: auto;
margin-right: auto;
}

.form-control {
display: block;
padding: 6px 12px;
line-height: 1.42858;
color: #555;
background-color: #fff;
background-image: none;
border: 1px solid #ccc;
border-radius: 4px;
}
input.cta-input {
margin-bottom: 20px;
}

#wp_cta-form {
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(3, 1fr) 0.8fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
}

.input1 {
grid-area: 1 / 1 / 2 / 2;
}
.input2 {
grid-area: 2 / 1 / 3 / 2;
}
.input3 {
grid-area: 3 / 1 / 4 / 2;
}

.threats-and-tools .middle > .title.tall {
height: 805px;
margin-top: -965px;
}

.threats-and-tools .header-bg.tall {
height: 965px;
}

@media (min-width: 640px) {
.threats-and-tools .header-bg.tall {
  height: 950px;
}

.threats-and-tools .middle > .title.tall {
  height: 810px;
  margin-top: -950px;
}
}

@media only screen and (min-width: 960px) {
.threats-and-tools .header-bg.tall {
  height: 735px;
}

.threats-and-tools .middle > .title.tall {
  height: 525px;
  margin-top: -735px;
}

.disclaimer {
  grid-area: 2 / 1 / 2 / 4;
  margin-bottom: 20px;
}
#wp_cta-form {
  display: grid;
  grid-template-columns: repeat(2, 0.7fr) 1fr 0.6fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.input1 {
  grid-area: 1 / 1 / 2 / 2;
}
.input2 {
  grid-area: 1 / 2 / 2 / 3;
}
.input3 {
  grid-area: 1 / 3 / 2 / 4;
}
button.cta-button {
  grid-area: 1 / 4 / 2 / 5;
  width: 100%;
}
}

@media (min-width: 1200px) {
#wp_cta-title {
  font-size: 26px !important;
}
}

</style>`
    }
    return (
      <>
        <div dangerouslySetInnerHTML={{__html: markup()}}>
      </div>

      { window.CR.userInfo.id.length ? <></> : (<div dangerouslySetInnerHTML={{__html: markupStyles()}}>
      </div>)}
      </>
    );
  }
}
