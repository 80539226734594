import PropTypes from "prop-types";
import React from "react";

import createReactClass from "create-react-class";

import SecurityPlannerStore from "../../../stores/SecurityPlannerStore";

import ResizeUtils from "../../../vendor/utils/ResizeUtils";

import Statement from "./Statement.react.js";
import NextCard from "./NextCard.react";

/**
 * <pre>
 * Class to create a statement list in the Application.
 * Package Name - components/pages/statements
 * </pre>
 * @class components.pages.statements.StatementList
 */
const StatementList = createReactClass({
  displayName: "StatementList",
  statementsGroupElement: undefined,

  propTypes: {
    statements: PropTypes.array.isRequired,
    allowFocus: PropTypes.bool,
    contextualTitle: PropTypes.string,
    nextTitle: PropTypes.string,
    nextOnClick: PropTypes.func,
    nextAllowFocus: PropTypes.bool,
  },

  /**
   * Fetches the initial state of the statement.
   * @function getInitialState
   * @memberof components.pages.statements.StatementList
   * @instance
   */
  getInitialState: function() {
    return {
      breakpoint: ResizeUtils.getCurrentBreakpoint(), // string
    };
  },

  componentDidMount: function() {
    window.addEventListener("resize", this.onResize);

    // Hack with timeout to focus element in IE
    this.focusTimeout = setTimeout(() => {
      this.statementsGroupElement.focus();
    }, 0);
  },

  componentWillUnmount: function() {
    window.removeEventListener("resize", this.onResize);

    clearTimeout(this.focusTimeout);
    this.focusTimeout = null;
  },

  /**
   * Renders the statements list.
   * @function render
   * @memberof components.pages.statements.StatementList
   * @instance
   */
  render: function() {
    const visibleStatements = this.props.statements.filter(
      (statement) => statement.isRequired || SecurityPlannerStore.isStatementVisible(statement.id),
    );

    return (
      <div className="statements" role="group"
        aria-label={this.props.contextualTitle} tabIndex={-1}
        ref={(e) => (this.statementsGroupElement = e)}>
        {visibleStatements
          .map((statement, i) => {
            return (<Statement statement={statement} key={statement.id + i}
              allowFocus={this.props.allowFocus} index={i} />);
          })
          .concat(
            <NextCard
              onClick={this.props.nextOnClick}
              text={this.props.nextTitle}
              allowFocus={this.props.nextAllowFocus}
              className={"statement statement-next"}
              isEmpty={true}
              key="next-1"
            />,
          )}
      </div>
    );
  },

  /**
   * Action on resize of the statements list.
   * @function onResize
   * @memberof components.pages.statements.StatementList
   * @instance
   */
  onResize: function() {
    this.setState({
      breakpoint: ResizeUtils.getCurrentBreakpoint(),
    });
  },
});

export default StatementList;
