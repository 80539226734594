import React from "react";

/**
 * <pre>
 * Class to create toast messages for the Application.
 * These display the loading screen of the Application. 
 * Package Name - components/global 
 * </pre>
 * @class components.global.ToastMessage
 */
class ToastMessages extends React.Component { 

  /**
   * Renders the loader object.
   * @function render
   * @memberof components.global.ToastMessage
   * @instance
   */
  render() {
    return (
      <div className="common-loading-screen">
        <div className="loading">
          <img src={require("./../../../images/ui/loading.gif")} alt=""
            width="25" height="25" />
        </div>
      </div>
    );
  }
}

export default ToastMessages;
