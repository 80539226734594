import PropTypes from "prop-types";
import React from "react";
import createReactClass from "create-react-class";
import SimpleSignal from "simplesignal";

import PageSectionHelper from "./../../../vendor/utils/PageSectionHelper";
import MiniTracker from "../../../vendor/tracking/MiniTracker";
import ReactUtils from "../../../vendor/utils/ReactUtils";
import DirectionUtils from "./../../../vendor/utils/DirectionUtils";

import SecurityPlannerStore from "../../../stores/SecurityPlannerStore";

import StatementList from "./StatementList.react";
import ProgressBar from "../../common/ProgressBar.react";

/**
 * <pre>
 * Class to create a statement page in the Application.
 * Package Name - components/pages/statements
 * </pre>
 * @class components.pages.statements.StatementsPage
 */
const StatementsPage = createReactClass({
  displayName: "StatementsPage",
  hasPerformedTracking: false,
  lastNumberOfStatementsActivated: 0,
  onPageScrolled: new SimpleSignal(),
  helper: undefined,

  propTypes: {
    isLastStatementPage: PropTypes.bool,
    level: PropTypes.object, // Level
    levels: PropTypes.array, // Level[]
    navigator: PropTypes.object,
    onClickNext: PropTypes.func,
    stringList: PropTypes.object,
  },

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount: function() {
    this.helper = new PageSectionHelper(this);
  },

  componentDidMount: function() {
    this.helper.setComponent(this.refs.scroller);
    this.helper.onScrolled.add(this.onScrolledContent);
  },

  componentDidUpdate: function() {
    this.helper.setComponent(this.refs.scroller);
    this.helper.dispatchOnScrolled();
  },

  componentWillUnmount: function() {
    this.helper.destroy();
  },

  // if (this.refs["progressBar"] && this.state.levels.length > 0) {
  //   this.refs["progressBar"].setStateParameters(
  //     currentLocationParams.isLocatorVisible,
  //     (this.navigator.displayedPosition + 1) / this.state.levels.length,
  //   );
  // }



  /**
   * Renders the statements page.
   * @function render
   * @memberof components.pages.statements.StatementsPage
   * @instance
   */
  render: function() {
    this.hasPerformedTracking = false;
    const actionButtonCopy = this.props.isLastStatementPage
      ? this.props.stringList.get("statements-button-results")
      : this.props.stringList.get("statements-button-next");
    const canContinue = this.canContinueToNextStep();
    const contextualTitle = this.props.stringList
      .get("statements-title")
      .replace("[[title]]", this.props.level.title)
      .replace(/<(|\/)em>/g, "");

    return (
      <div
        className={
          "sectionPageHolder pageStatements pageStatements-level-" +
          this.props.level.slug.substr(0, 1) +
          " " +
          DirectionUtils.getClass(this.props.stringList)
        }
        aria-hidden={!this.helper.isActive()}
        style={{ height: this.helper.getWindowHeight() }}
      >
        <ProgressBar isVisible="true" total={this.props.levels.length} complete={this.props.level.order} stringList={this.props.stringList} />
        <div className="content" ref="scroller">
          <div className="body">
            {this.renderTitle()}
            <StatementList
              statements={this.props.level.statements}
              allowFocus={this.helper.isActive()}
              contextualTitle={contextualTitle}
              nextOnClick={this.onClickContinue}
              nextTitle={actionButtonCopy}
              nextAllowFocus={canContinue && this.helper.isActive()}
            />
          </div>
        </div>
      </div>
    );
  },

  /**
   * Checks whether can continue onto the next step on statements page.
   * @function canContinueToNextStep
   * @memberof components.pages.statements.StatementsPage
   * @instance
   */
  canContinueToNextStep: function() {
    let numberOfStatementsActivated = 0;
    this.props.level.statements.forEach((statement) => {
      if (statement.selected) numberOfStatementsActivated++;
    });

    if (numberOfStatementsActivated !== this.lastNumberOfStatementsActivated) {
      this.onChangedSelection();
      this.lastNumberOfStatementsActivated = numberOfStatementsActivated;
    }

    return numberOfStatementsActivated >= this.props.level.answersRequired;
  },

  /**
   * Renders the statement page title.
   * @function renderTitle
   * @memberof components.pages.statements.StatementsPage
   * @instance
   */
  renderTitle: function() {
    const title = this.title
      ? ReactUtils.getReplacedTags(this.title, "em", function(innerText) {
        return <em>{innerText}</em>;
      })
      : undefined;

    return (
      <div key="title" className="common-section-title">
        <div key="text">{title}</div>
      </div>
    );
  },

  // Common events for navigation
  /**
   * Ran when the section becomes the focused section
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	   
   * @param  {object} fromOverlay	        
   * @function onActivate
   * @memberof components.pages.statements.StatementsPage
   * @instance     
   */  
  onActivate: function(travelOffset, viaHistoryAPI, fromOverlay) {
    // Ran when the section becomes active
    this.title = this.props.navigator.currentTitle;
    if (!this.hasPerformedTracking) {
      this.props.level.statements.forEach((statement) => {
        if (SecurityPlannerStore.isStatementVisible(statement.id)) {
          MiniTracker.trackEvent("statement-card", "display", statement.slug, undefined, true);
        }
      });

      this.hasPerformedTracking = true;
    }

    this.setScrollPosition(0);

    this.helper.onActivate(travelOffset, viaHistoryAPI, fromOverlay);
  },

  /**
   * Ran when the section is about to lose focus 
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	   
   * @param  {object} toOverlay	        
   * @function onDeactivate
   * @memberof components.pages.statements.StatementsPage
   * @instance        
   */
  onDeactivate: function(travelOffset, viaHistoryAPI, toOverlay) {
    // Ran when the section becomes inactive
    this.helper.onDeactivate(travelOffset, viaHistoryAPI, toOverlay);
  },

  /**
   * Action on clicking on Continue on the statement page.
   * @function onClickContinue
   * @memberof components.pages.statements.StatementsPage
   * @instance
   */
  onClickContinue: function(e) {
    if (this.canContinueToNextStep() && this.props.onClickNext) this.props.onClickNext(e);
  },

  /**
   * Action on selection changed on the statement page.
   * @function onChangedSelection
   * @memberof components.pages.statements.StatementsPage
   * @instance
   */
  onChangedSelection: function() {
    // Selection has changed: remove all the pages that come after this page
    this.props.navigator.removeLocationsAfterCurrent();
  },

  /**
   * Action on scrolled content on the statement page.
   * @function onScrolledContent
   * @memberof components.pages.statements.StatementsPage
   * @instance
   */
  onScrolledContent: function() {
    this.onPageScrolled.dispatch();
  },

  /**
   * Fetches the scrolled position on the statement page.
   * @function getScrollPosition
   * @memberof components.pages.statements.StatementsPage
   * @instance
   */
  getScrollPosition: function() {
    // Return the current scroll position of the component
    return this.helper.getScrollPosition();
  },

  /**
   * Fetches the max scrolled position on the statement page.
   * @function getMaxScrollPosition
   * @memberof components.pages.statements.StatementsPage
   * @instance
   */
  getMaxScrollPosition: function() {
    return this.helper.getMaxScrollPosition();
  },

  /**
   * Sets the scrolled position on the statement page.
   * @function setScrollPosition
   * @memberof components.pages.statements.StatementsPage
   * @instance
   */
  setScrollPosition: function(value) {
    // TODO: THIS IS A HACK TO ENSURE THE TITLE SCROLLS INTO VIEW
    // Some kind of issue with the title being undefined on first render, this throws off the auto-scroll
    setTimeout(() => this.helper.setScrollPosition(value), 200);
  },

  /**
   * Returns the color (as a number) that the locator bar should have when opaque
   * @function getDesiredLocatorBackgroundColor
   * @memberof components.pages.statements.StatementsPage
   * @instance          
   */
  getDesiredLocatorBackgroundColor: function() {
    // Return the color (as a number) that the locator bar should have when opaque
    return undefined;
  },
});

export default StatementsPage;
