import PropTypes from "prop-types";
import React from "react";

import Bio from "./../about/Bio.react";

/**
 * <pre>
 * Class to generate a biopreview page in the Application.
 * Uses the Bio component for preview.
 * Package Name - components/pages/preview
 * </pre>
 * @class components.pages.preview.BioPreview
 */
class BioPreview extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    stringList: PropTypes.object,
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    bio: PropTypes.object.isRequired, // Bio
  };

  /**
   * Renders the bio preview object.
   * @function render
   * @memberof components.pages.preview.BioPreview
   * @instance
   */
  render() {
    return (
      <div className={this.props.className}>
        <div className="subtitle">Preview</div>
        <div className="container pageAbout">
          <Bio bio={this.props.bio} routes={this.props.routes}
            goToPage={this.props.goToPage} />
        </div>
      </div>
    );
  }
}

export default BioPreview;
