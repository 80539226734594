import PropTypes from "prop-types";
import React from "react";

import Tool from "./Tool.react";

/**
 * <pre>
 * Class to generate a threat component in the Application.
 * Uses the Tool component for generation.
 * Package Name - components/pages/printreport
 * </pre>
 * @class components.pages.printreport.Threat
 */
class Threat extends React.Component {
  static propTypes = {
    stringList: PropTypes.object,
    threat: PropTypes.object, // Threat
    tools: PropTypes.array, // Tool[]
  };

  /**
   * Renders the threat object.
   * @function render
   * @memberof components.pages.printreport.Threat
   * @instance
   */
  render() {
    return (
      <div className="threat">
        <div className="title">{this.props.threat.shortDescription}</div>
        <div className="tools">{this.renderTools()}</div>
      </div>
    );
  }

  /**
   * Renders the threat tools.
   * @function renderTools
   * @memberof components.pages.printreport.Threat 
   * @instance
   */
  renderTools = () => {
    return this.props.tools.map((tool, index) => {
      return (<Tool key={index} stringList={this.props.stringList}
        tool={tool} />);
    });
  };
}

export default Threat;
