/**
 * <pre>
 * Class for a Level component in store from retrieved data. 
 * Package - stores/data.  
 * </pre>
 * @class stores.data.Level
 */
export default class Level {
  // ================================================================================================================
  // CONSTRUCTOR ----------------------------------------------------------------------------------------------------

  /**
   * Creates An Object of Level Component. 
   * @constructor    
   */    
  constructor() {
    this.id = "";
    this.slug = "";
    this.title = "";
    this.recommendationsNeeded = 0;
    this.answersRequired = 0;
    this.statements = []; // Statement
    this.translationOutdated = false;
    this.enabled = true;
  }
}
