import PropTypes from "prop-types";
import React from "react";
import Bowser from "bowser";
import cx from "classnames";

import PageSectionHelper from "./../../../vendor/utils/PageSectionHelper";
import DirectionUtils from "./../../../vendor/utils/DirectionUtils";
import iOSUtils from "./../../../vendor/utils/iOSUtils";

import SecondaryMenu from "../../common/SecondaryMenu.react";
import FooterMenu from "../../common/FooterMenu.react";
import FlexibleBanner from "../../common/FlexibleBanner.react";

import Body from "./Body.react";

/**
 * <pre>
 * Class to create the coverpage for the Application.
 * Package Name - components/pages/cover
 * </pre>
 * @class components.pages.cover.CoverPage
 */
class CoverPage extends React.Component {

  constructor(props) {
    super(props);
    this.helper = new PageSectionHelper(this);
    this.state = {
      isScrolled: false,
      isSearching: false
    }
    this.onScrolledContent = this.onScrolledContent.bind(this);
  }

  static propTypes = {
    metadata: PropTypes.object, // ContentfulLoader's .metadata
    stringList: PropTypes.object, // StringList
    selectedLanguage: PropTypes.object, // Language
    availableLanguages: PropTypes.arrayOf(PropTypes.object), // Language[]
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    tools: PropTypes.array, // Tool[]
    goToPage: PropTypes.func.isRequired,
    onClickRetake: PropTypes.func.isRequired,
    onClickPlan: PropTypes.func.isRequired,
    hasPlan: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.helper.setComponent(this.refs.scroller);
    this.helper.onScrolled.add(this.onScrolledContent);
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.metadata !== this.props.metadata ||
      nextProps.stringList !== this.props.stringList ||
      nextProps.selectedLanguage !== this.props.selectedLanguage ||
      nextProps.availableLanguages !== this.props.availableLanguages ||
      nextProps.routes !== this.props.routes ||
      nextProps.goToPage !== this.props.goToPage ||
      nextProps.onClickRetake !== this.props.onClickRetake
    );
  }

  componentDidUpdate() {
    this.helper.setComponent(this.refs.scroller);
    this.helper.forceCheck();
    if (this.helper.getElement() && Bowser.ios) {
      // iOS is not re-rendering some elements, so force it
      const elements = this.helper.getElement().querySelectorAll(".button");
      for (let i = 0; i < elements.length; i++) {
        iOSUtils.rerenderElement(elements[i]);
      }
    }
  }

  componentWillUnmount() {
    this.helper.destroy();
  }

  /**
   * Renders the coverpage object.
   * @function render
   * @memberof components.pages.cover.CoverPage
   * @instance
   */
  render() {
    return (
      <div
        className={cx("sectionPageHolder", "pageCover", DirectionUtils.getClass(this.props.stringList), this.state.isScrolled ? "is-scrolled" : "")}
        aria-hidden={!this.helper.isActive()}
        style={{ height: this.helper.getWindowHeight() }}
      >
        <div className="content" ref="scroller">
          <div className="inner">
            <FlexibleBanner
              stringList={this.props.stringList}
            ></FlexibleBanner>
            <SecondaryMenu
              stringList={this.props.stringList}
              currentLocation={SecondaryMenu.LOCATION_HOME}
              availableLanguages={this.props.availableLanguages}
              selectedLanguage={this.props.selectedLanguage}
              routes={this.props.routes}
              hasPlan={this.props.hasPlan}
              goToPage={this.props.goToPage}
              allowFocus={this.helper.isActive()}
              useLightStyle={true}
              className="header"
              tools={this.props.tools}
              ref="menu"
            />
            <div className="middle" id={SecondaryMenu.LOCATION_HOME}>
              <Body
                ref={(r) => {
                  this.body = r;
                }}
                hasPlan={this.props.hasPlan}
                onClickRetake={this.onClickRetake}
                onClickPlan={this.onClickPlan}
                lastModified={this.props.metadata ? this.props.metadata.updatedAt : null}
                stringList={this.props.stringList}
                allowFocus={this.helper.isActive()}
                onKeyDown={this.escFunction}
              />
              <object
                className="common-image-container head"
                aria-label={this.props.stringList.get("cover-image-label")} id="svg-object"
                data={require("./../../../../images/page-cover/group-desktop.svg")}
                type="image/svg+xml" />
            </div>
            <div className="slogan">{this.props.stringList.get("cover-slogan")}</div>
            <FooterMenu
              stringList={this.props.stringList}
              currentLocation={SecondaryMenu.LOCATION_HOME}
              routes={this.props.routes}
              goToPage={this.props.goToPage}
              style={FooterMenu.STYLE_LIGHT}
              allowFocus={this.helper.isActive()}
              selectedLanguage={this.props.selectedLanguage}
              availableLanguages={this.props.availableLanguages}
              cdaFooter={this.props.cdaGlobals.footer}              
            />
          </div>
        </div>
      </div>
    );
  }

  onScrolledContent = (scrollY, maxScrollY) => {
    if( !this.state.isScrolled ){
      this.setState({
        isScrolled: scrollY > 0,
      });
      this.forceUpdate();//This shouldn't be needed, but it is
    }
  }

  escFunction = (event) => {
    console.log('body');
    if (event.key === "Escape") {
      document.querySelector('.search-input').value = "";
      this.setState({
          isSearching: false,
        });
      console.log(this.state.isSearching);
    }
  }

  // Common events for navigation
  /**
   * Ran when the section becomes the focused section
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	       
   * @function onActivate
   * @memberof components.pages.cover.CoverPage
   * @instance  
   */  
  onActivate = (travelOffset, viaHistoryAPI) => {
    // Ran when the section becomes active
    this.helper.onActivate(travelOffset, viaHistoryAPI);
    if (this.body && this.body.onActivate) this.body.onActivate();
  };

  /**
   * Ran when the section is about to lose focus 
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	       
   * @function onDeactivate
   * @memberof components.pages.cover.CoverPage
   * @instance     
   */
  onDeactivate = (travelOffset, viaHistoryAPI) => {
    // Ran when the section becomes inactive
    this.helper.onDeactivate(travelOffset, viaHistoryAPI);
  };

  /**
   * Get the desired locator background color 
   * @function getDesiredLocatorBackgroundColor
   * @memberof components.pages.cover.CoverPage
   * @instance     
   */
  getDesiredLocatorBackgroundColor = () => {
    // Return the color (as a number) that the locator bar should have when opaque
    return undefined;
  };

  /**
   * Action for on click retake  
   * @function onClickRetake
   * @memberof components.pages.cover.CoverPage
   * @instance     
   */
  onClickRetake = () => {
    this.refs["menu"].closeMenuDelayed();
    this.props.onClickRetake();
  };

  /**
   * Action for click on plan on coverpage 
   * @function onClickPlan
   * @memberof components.pages.cover.CoverPage
   * @instance     
   */
  onClickPlan = () => {
    this.refs["menu"].closeMenuDelayed();
    this.props.onClickPlan();
  };
}

export default CoverPage;
