import { clamp, map } from "moremath";
import React, { Component } from "react";
import copy from "copy-to-clipboard";
import headful from "headful";
import { saveAs } from "file-saver";

import ReactDOM from "react-dom";

import { LocationHandler, MiniNavigator } from "./../vendor/routing/MiniNavigator";
import MiniRouter from "./../vendor/routing/MiniRouter";
import Easing from "./../vendor/transitions/Easing";
import Fween from "./../vendor/transitions/Fween";
import ADAUtils from "./../vendor/utils/ADAUtils";
import TrackClick from "./../vendor/tracking/AdobeAnalytics";
import SecurityPlannerConstants from "../constants/SecurityPlannerConstants";
import SecurityPlannerRoutes from "../routing/SecurityPlannerRoutes";
import SecurityPlannerStore from "../stores/SecurityPlannerStore";
import MiniTracker from "../vendor/tracking/MiniTracker";
import WindowScrollLocker from "../vendor/utils/WindowScrollLocker";
import DebugPanel from "./debug/DebugPanel.react";
import LoadingScreen from "./global/LoadingScreen.react";
import ToastMessages from "./global/ToastMessages.react";
import Locator from "./navigation/Locator.react";
import BioOverlay from "./overlays/bio/BioOverlay.react";
import ShareOverlay from "./overlays/share/ShareOverlay.react";
import ThreatMenuOverlay from "./overlays/threat-menu/ThreatMenuOverlay.react";
import ToolFeedbackOverlay from "./overlays/tool-feedback/ToolFeedbackOverlay.react";
import ToolOverlay from "./overlays/tool/ToolOverlay.react";
import ToolsFilterOverlay from "./overlays/tools-filter/ToolsFilterOverlay.react";
import AboutPage from "./pages/about/AboutPage.react";
import PhilosophyPage from "./pages/philosophy/PhilosophyPage.react";
import AskExpertPage from "./pages/expert/AskExpertPage.react";
import CoverPage from "./pages/cover/CoverPage.react";
import FeedbackPage from "./pages/feedback/FeedbackPage.react";
import InterstitialPage from "./pages/interstitial/InterstitialPage.react";
import PreviewPage from "./pages/preview/PreviewPage.react";
import PrintReportPage from "./pages/printreport/PrintReportPage.react";
import ThreatsAndToolsPage from "./pages/threats-and-tools/ThreatsAndTools.react";
import StatementsPage from "./pages/statements/StatementsPage.react";
import TermsPage from "./pages/terms/TermsPage.react";
import TermsPrintPage from "./pages/terms/TermsPrintPage.react";
import NoMatchPage from "./pages/nomatch/NoMatchPage.react";
import SecurityPlannerActions from "../actions/SecurityPlannerActions";
import ReportFooter from "./pages/report/Footer.react";
import JoinBanner from "./common/JoinBanner.react";
import ReactUtils from "./../vendor/utils/ReactUtils";
import ActionButton from "./common/ActionButton.react";
import MonetateSection from "./common/MonetateSection.react";

const savePdf = (_url, filename) => {
  return fetch(_url)
    .then((res) => res.blob())
    .then((blob) => {
      saveAs(blob, filename, { type: "application/pdf" });
    });
};

/**
 * <pre>
 * Main Class of the Application.
 * Package Name - components
 * </pre>
 * @class components.SecurityPlannerApp
 */
class SecurityPlannerApp extends Component {
  constructor(props) {
    super(props);

    this.state = this.getBuildState();

    SecurityPlannerStore.addChangeListener(this.onStoreChanged);

    // ADA Activation
    ADAUtils.init("accessibility-ada-active");

    // Navigator
    this.navigator = new MiniNavigator();
    this.navigator.onLocationChanged.add(this.onNavigatorLocationChanged);

    // Window scrolling
    this.windowScroller = new WindowScrollLocker();

    // Routes
    this.routes = new SecurityPlannerRoutes();

    // Router
    this.router = new MiniRouter();
    this.router.addTemplate(this.routes.getUriCover(), this.getUriCoverContent);
    this.router.addTemplate(this.routes.getUriStatements(), this.getUriStatementsContent);
    this.router.addTemplate(this.routes.getUriInterstitial(), this.getUriInterstitialContent);
    this.router.addTemplate(this.routes.getUriReport(), this.getUriReportContent);
    this.router.addTemplate(this.routes.getUriReportWithHash(), this.getUriReportWithHashContent);
    this.router.addTemplate(this.routes.getUriPrintReport(), this.getUriPrintReportContent);
    this.router.addTemplate(this.routes.getUriAllTools(), this.getUriAllToolsContent);
    this.router.addTemplate(this.routes.getUriAllToolsThreat(), this.getUriAllToolsContent);
    this.router.addTemplate(this.routes.getUriTerms(), this.getUriTermsContent);
    this.router.addTemplate(this.routes.getUriPrintTerms(), this.getUriTermsPrintContent);
    this.router.addTemplate(this.routes.getUriAbout(), this.getUriAboutContent);
    this.router.addTemplate(this.routes.getUriPhilosophy(), this.getUriPhilosophyContent);
    this.router.addTemplate(this.routes.getUriAskExpert(), this.getUriAskExpertContent);
    this.router.addTemplate(this.routes.getUriFeedback(), this.getUriFeedbackContent);
    this.router.addTemplate(this.routes.getUriOverlayShare(), this.getUriOverlayShareContent);
    this.router.addTemplate(this.routes.getUriOverlayTool(), this.getUriOverlayToolContent);
    this.router.addTemplate(this.routes.getUriOverlayBio(), this.getUriOverlayBioContent);
    this.router.addTemplate(this.routes.getUriOverlayToolFeedback(), this.getUriOverlayToolFeedbackContent);
    this.router.addTemplate(this.routes.getUriOverlayThreatMenu(), this.getUriOverlayThreatMenuContent);
    this.router.addTemplate(this.routes.getUriOverlayToolsFilter(), this.getUriOverlayToolsFilterContent);
    this.router.addTemplate(this.routes.getUriPreview(), this.getUriPreviewContent);
    this.router.addTemplate(this.routes.getUriSpanishLanding(), this.geturiSpanishLandingContent);

    // 404 Page
    this.router.addTemplate(this.routes.getUriNoMatch(), this.getUriNoMatch);

    // Create a handler to receive the section events
    this.sectionHandler = new LocationHandler();
    this.sectionHandler.onCreated.add(this.onCreatedPage);
    this.sectionHandler.onActivated.add(this.onActivatedPage);
    this.sectionHandler.onDeactivated.add(this.onDeactivatedPage);

    // Capture scrolls
    // document.addEventListener("touchmove", this.onTouchMoveScroll);
    window.addEventListener("scroll", this.onWindowScrollPreventHistoryScroll, true);
    window.addEventListener("scroll", this.onWindowScroll);
    window.addEventListener("resize", this.onWindowResize);
    if (!SecurityPlannerConstants.UI.LOCK_SCROLL) {
      window.addEventListener("wheel", this.onMouseWheel, false);
      window.addEventListener("wheel", this.onMouseWheel, true);
    }

    window.addEventListener("orientationchange", this.onOrientationChange);
  }

  displayName = "SecurityPlannerApp";

  // location id
  transitioningFromLocation = undefined;

  // element of previous section
  scrollingFromElement = undefined;

  // 0-1 of the element's height
  scrollingFromOffset = undefined;

  // location id
  transitioningToLocation = undefined;

  // element of the new section
  scrollingToElement = undefined;

  // 0-1 of the element's height
  scrollingToOffset = undefined;

  // 0-1
  scrollPosition = undefined;

  hasOpenedFirstPage = false;
  desiredScrollY = undefined;
  desiredScrollYPhase = undefined;
  isAutoScrolling = false;
  fixedComponentRefs = [];

  // MiniRouter: decides what to show based on a uri
  router = undefined;

  // SecurityPlannerRouter: generates the harcoded routes needed
  routes = undefined;

  prefetchedAssets = false;
  windowScroller = undefined;
  waitingToCreateLocation = undefined;

  downloading = false;

  componentDidMount() {
    document.addEventListener("visibilitychange", this.onVisibilityChange);

    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (!this.prefetchedAssets) this.prefetchAssets();

    this.updateNavigatorState();

    // Handles scrolling as needed
    // console.log(`LOCATION STATE should be ${this.state.location}, currently it is from ${this.transitioningToLocation}, previously ${this.transitioningFromLocation}`);
    // console.log(` .... history is ${this.navigator.locationHistory}`);
    if (this.state.location && this.state.location !== this.transitioningToLocation) {
      // The current section is not the section that we should have transitioned to, therefore start a new transition
      const lastLocationInfo =
        this.navigator.hasLocation(this.transitioningToLocation) && this.navigator.getLocationInfo(this.transitioningToLocation);
      const fromOverlay = lastLocationInfo && lastLocationInfo.params && lastLocationInfo.params.isOverlay;
      const toOverlay = this.navigator.currentParams && this.navigator.currentParams.isOverlay;
      // console.log(`Are overlay: ${fromOverlay} -> ${toOverlay}`);
      if ((!fromOverlay && toOverlay) || (fromOverlay && toOverlay && this.navigator.lastPositionTravelOffset > 0)) {
        // From page to overlay, or overlay to new overlay: use special transition to show
        // console.log("  ...is an overlay SHOWING with special transition!");
        this.transitionToLocationWithCustom(this.state.location, this.refs[this.state.location].startTransitionShow);
      } else if ((fromOverlay && !toOverlay) || (fromOverlay && toOverlay && this.navigator.lastPositionTravelOffset < 0)) {
        // From overlay to page, or overlay to old overlay: show special transition to hide, and always remove the overlay
        // console.log("  ...is an overlay HIDING with special transition!");
        this.clearHistoryAfterNavigation = true;
        this.transitionToLocationWithCustom(this.state.location, this.refs[this.transitioningToLocation].startTransitionHide);
      } else {
        // console.log("  ...has to scroll!");
        this.transitionToLocationWithScroll(this.state.location);
      }
    }

    this.decideFirstPage();
  }

  componentWillUnmount() {
    SecurityPlannerStore.removeChangeListener(this.onStoreChanged);
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
  }

  /**
   * Decides the FirstPage of the application.
   * Updated as per the requirement for CM-5682
   * @function decideFirstPage
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  decideFirstPage = () => {
    // Decides the first page that should open when the app starts
    if (this.state.isDataLoaded && !this.hasOpenedFirstPage && this.state.planChecked) {
      const plan = SecurityPlannerStore.getPlan();

      // Get the current location, so we can redirect return visits to certain routes
      let currentLocation = undefined;

      //Redirect user to the plan page if: the plan is not empty and the requested URI is the homepage
      if (plan && plan.hash && plan.hash.length > 0 && window.location.pathname === "/") {
        currentLocation = this.routes.getUriReportWithHash(plan.hash);
        this.goToPage(currentLocation);
        return;
      } else {
        // Get current location and include any url query parameters if present
        currentLocation = window.location.pathname + (window.location.search ? window.location.search : "");
      }

      // Try to handle the incoming location using the custom location
      const sectionObject = currentLocation && this.router.handle(currentLocation);
      if (sectionObject && sectionObject.allowAtStart) {
        if (sectionObject.injectAtStart && sectionObject.injectAtStart.length > 0) {
          // Must inject URLs too
          // This is a bit awkward since we just fire all navigations. But it works well.
          sectionObject.injectAtStart.forEach((uri) => {
            this.goToPage(uri);
          });
        }
        this.goToPage(currentLocation);
        return;
      } else if (currentLocation === "/404") {
        // Remove unrecognized URL parameters
        window.history.replaceState({}, document.title, "/");
        // Go to 404 Page
        this.goToPage(this.routes.getUriNoMatch());
        return;
      } else {
        // Normal start, go to the cover
        this.goToPage(this.routes.getUriCover());
        return;
      }
    }
  };

  /**
   * Renders the SecurityPlanner App.
   * @function render
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  render() {
    if (this.state.isDataLoaded) {
      this.fixedComponentRefs = [];

      // Create all pages
      const components = [];

      // Render everything currently listed in the locator
      if (this.navigator) {
        this.navigator.locations.forEach((location) => {
          const sectionObjectParams = location.params;
          if (sectionObjectParams.render) {
            components.push(sectionObjectParams.render.bind(this)(sectionObjectParams.componentParams));
            this.fixedComponentRefs.push(location.id);
          }
        });
      }

      // Locator
      components.push(<Locator key="locator" ref="locator" stringList={this.state.stringList} onClickLocation={this.onClickLocatorLocation} />);

      components.push(
        <JoinBanner
          key="joinBanner"
          ref="joinBanner"
          register={(first, last, email) => SecurityPlannerStore.register(first, last, email)}
          stringList={this.state.stringList}
          isLoggedIn={() => SecurityPlannerStore.isLoggedIn()}
        />,
      );

      // Toast messages
      components.push(<ToastMessages key="toasts" ref="toasts" stringList={this.state.stringList} toasts={this.state.toasts} />);

      // Debugging views
      if (SecurityPlannerConstants.Parameters.IS_DEBUGGING) {
        components.push(
          <DebugPanel
            key="debug"
            statements={this.state.statements}
            tools={this.state.tools}
            levels={this.state.levels}
            threats={this.state.threats}
            recommendedThreats={this.state.recommendedThreats}
            topRecommendedTool={this.state.topRecommendedTool}
            recommendedTools={this.state.recommendedTools}
          />,
        );
      }

      return <div>{components}</div>;
    } else {
      return <LoadingScreen />;
    }
  }

  // URI flow
  /**
   * Generates the Uri After Statements
   * @param  {number} levelIndex
   * @function getUriAfterStatements
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriAfterStatements = (levelIndex) => {
    if (this.state.levels[levelIndex].recommendationsNeeded > 0) {
      // Level should have interstitial results next
      return this.routes.getUriInterstitial(levelIndex);
    } else {
      // Level should skip to the next statements page, or maybe the final report
      return this.getUriAfterInterstitial(levelIndex);
    }
  };

  /**
   * Generates the Uri After Interstitial
   * @param  {number} levelIndex
   * @function getUriAfterInterstitial
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriAfterInterstitial = (levelIndex) => {
    if (levelIndex < this.state.levels.length - 1) {
      // Should be followed by another statement page
      return this.routes.getUriStatements(levelIndex + 1);
    } else {
      // A bit gross, but make the numbers hide before the transition
      if (this.refs["locator"]) {
        this.refs["locator"].hide();
      }

      return this.routes.getUriReportWithHash(SecurityPlannerStore.saveState());
    }
  };

  /**
   * Deselects all Statements
   * @function onClickRetake
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  onClickRetake = () => {
    TrackClick("start-button");
    SecurityPlannerActions.deselectAllStatements();
    this.goToPage(this.routes.getUriStatements(0), false, true);
  };

  // URI Spanish Landing.
  /**
   * Generates the Cover Content Uri
   * @param  {object} componentParams
   * @function geturiSpanishLandingContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  geturiSpanishLandingContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("cover-browser-title"),
      allowAtStart: true,
      injectAtStart: undefined,
      prepare: undefined,
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: undefined,
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <CoverPage
              ref={this.routes.getUriSpanishLanding()}
              key={this.routes.getUriSpanishLanding()}
              hasPlan={() => {
                const { hash } = SecurityPlannerStore.getPlan() || {};
                return hash && hash.length > 0;
              }}
              metadata={this.state.metadata}
              stringList={this.state.stringList}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
              routes={this.routes}
              tools={this.state.tools}
              goToPage={this.goToPage}
              onClickRetake={() => this.onClickRetake()}
              onClickPlan={() => {
                TrackClick("results-button");
                const { hash } = SecurityPlannerStore.getPlan() || {};
                return this.goToPage(this.routes.getUriReportWithHash(hash), false, true);
              }}
            />
          );
        },
      },
    };
  };

  // URI creators
  /**
   * Generates the Cover Content Uri
   * @param  {object} componentParams
   * @function getUriCoverContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriCoverContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("cover-browser-title"),
      allowAtStart: false,
      injectAtStart: undefined,
      prepare: undefined,
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: undefined,
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <CoverPage
              ref={this.routes.getUriCover()}
              key={this.routes.getUriCover()}
              hasPlan={() => {
                const { hash } = SecurityPlannerStore.getPlan() || {};
                return hash && hash.length > 0;
              }}
              metadata={this.state.metadata}
              stringList={this.state.stringList}
              tools={this.state.tools}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
              routes={this.routes}
              goToPage={this.goToPage}
              onClickRetake={() => this.onClickRetake()}
              onClickPlan={() => {
                TrackClick("results-button");
                const { hash } = SecurityPlannerStore.getPlan() || {};
                return this.goToPage(this.routes.getUriReportWithHash(hash), false, true);
              }}
              cdaGlobals={this.state.cdaGlobalElements}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the Statement Uri
   * @param  {object} componentParams
   * @function getUriStatementsContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriStatementsContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: true,
      title: this.state.stringList.get("statements-title").replace("[[title]]", this.state.levels[componentParams.id - 1].title),
      browserTitle: this.state.stringList.get("statements-browser-title").replace("[[index]]", componentParams.id),
      allowAtStart: false,
      injectAtStart: undefined,
      prepare: undefined,
      params: {
        isOverlay: false,
        isLocatorVisible: true,
        showStartOverButton: true,
        hideStartOverButtonIfClear: true,
        colorClass: "color-dark",
        level: this.state.levels[componentParams.id - 1],
        componentParams: componentParams,
        render: function (componentParams) {
          const level = this.state.levels[componentParams.id - 1];
          const levelIndex = this.state.levels.indexOf(level);
          return (
            <StatementsPage
              idParam={componentParams.id}
              levelIndex={levelIndex}
              ref={this.routes.getUriStatements(levelIndex)}
              key={this.routes.getUriStatements(levelIndex)}
              stringList={this.state.stringList}
              onClickNext={() => {
                TrackClick(levelIndex === this.state.levels.length - 1 ? "results-button" : "next-button");
                this.goToPage(this.getUriAfterStatements(levelIndex), false, true);
              }}
              level={level}
              levels={this.state.levels}
              navigator={this.navigator}
              isLastStatementPage={levelIndex === this.state.levels.length - 1}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the Interstitial Uri
   * @param  {object} componentParams
   * @function getUriInterstitialContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriInterstitialContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: this.state.stringList.get("interstitial-title"),
      browserTitle: undefined,
      allowAtStart: false,
      injectAtStart: undefined,
      prepare: undefined,
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: true,
        hideStartOverButtonIfClear: false,
        colorClass: "color-light",
        level: this.state.levels[componentParams.id - 1],
        componentParams: componentParams,
        render: function (componentParams) {
          const level = this.state.levels[componentParams.id - 1];
          const levelIndex = this.state.levels.indexOf(level);
          return (
            <InterstitialPage
              ref={this.routes.getUriInterstitial(levelIndex)}
              key={this.routes.getUriInterstitial(levelIndex)}
              stringList={this.state.stringList}
              routes={this.routes}
              goToPage={this.goToPage}
              onClickNext={() => this.goToPage(this.getUriAfterInterstitial(levelIndex), false, true)}
              tools={this.state.recommendedTools}
              navigator={this.navigator}
              level={level}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the Report Uri
   * @param  {object} componentParams
   * @function getUriReportContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriReportContent = (componentParams) => {
    return this.getUriReportMixedContent(componentParams, this.routes.getUriReport(), undefined, false);
  };

  /**
   * Generates the Report Uri With Hash
   * @param  {object} componentParams
   * @function getUriReportWithHashContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriReportWithHashContent = (componentParams) => {
    return this.getUriReportMixedContent(
      componentParams,
      this.routes.getUriReportWithHash(componentParams.hash),
      componentParams.hash,
      this.navigator.locations.length === 0,
    );
  };

  /**
   * Generates the PDF of the report With Hash
   * @param  {string} hash
   * @function onClickSave
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  onClickSave = (hash) => {
    TrackClick("security_planner-pdf");

    const downloadUrl = new URL(SecurityPlannerConstants.Plan.PDF_GENERATOR_URL);

    if (localStorage.getItem("preferredLanguage") == "es-US") {
      downloadUrl.searchParams.set("domain", SecurityPlannerConstants.Plan.PDF_TARGET_DOMAIN + "/es/");
    } else {
      downloadUrl.searchParams.set("domain", SecurityPlannerConstants.Plan.PDF_TARGET_DOMAIN);
    }
    downloadUrl.searchParams.set("hash", hash);
    //downloadUrl.searchParams.set("l", this.state.selectedLanguage.code );
    downloadUrl.searchParams.set("l", this.state.selectedLanguage.id);

    // Forces a new download every time, no caching
    // downloadUrl.searchParams.set("force", true);

    if (this.downloading) {
      // eslint-disable-next-line no-console
      console.warn("Already downloading");
      return;
    }

    try {
      const url = downloadUrl.toString();
      this.downloading = true;

      // This is necessary because iOS doesn't allow for downloads via blobs, so you have to open in a new tab
      if (/CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent)) {
        window.open(url);
        this.downloading = false;
        return;
      }

      this.downloading = true;
      this.updateBuiltState();

      return savePdf(url, "action-plan-" + hash + ".pdf").then(() => {
        this.downloading = false;
        this.updateBuiltState();
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error during download: ", error.stack);
    }
  };

  /**
   * Generates the print PDF of the report With Hash
   * @param  {string} hash
   * @function onClickPrint
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  onClickPrint = (hash) => {
    this.printingWindow = window.open(this.navigator.getBrowserFriendlyLocation(this.routes.getUriPrintReport(hash)), "_blank");
  };

  /**
   * Closes the printing window when not visible
   * @function onVisibilityChange
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  onVisibilityChange = () => {
    // Upon coming back from another tab, close printing tab if needed
    // This is needed because Chrome locks JavaScript execution (but not this event)
    // in the original tab until the print dialog is closed in the second tab
    if (document.visibilityState === "visible" && this.printingWindow) {
      this.printingWindow.close();
      this.printingWindow = undefined;
    }
  };

  /**
   * Internal method to generate the report content
   * @param  {object} componentParams
   * @param  {string} url
   * @param  {string} hash
   * @param  {boolean} isFromSharing
   * @function getUriReportMixedContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriReportMixedContent = (componentParams, url, hash, isFromSharing) => {
    // Necessary because the ThreatsAndToolsPage element is only rendered once, but the state gets updated
    const getHeader = (downloading) => {
      let titleStringId = "";
      if (isFromSharing) {
        // Action plan from sharing
        titleStringId = this.state.recommendedTools.length > 1 ? "action-plan-shared-subtitle-multiple" : "action-plan-shared-subtitle-single";
      } else {
        // Action plan from going through all steps
        titleStringId = this.state.recommendedTools.length ? "action-plan-subtitle-multiple" : "action-plan-subtitle-single";
      }

      /*const shareURL = this.state.stringList.get("action-plan-share-url").split("[[hash]]").join(hash) + ( this.state.selectedLanguage.default ? '' : '?l=' + this.state.selectedLanguage.id );*/
      let shareURL = "";
      let splitURL = "";
      if (localStorage.getItem("preferredLanguage") == "es-US") {
        splitURL = this.state.stringList.get("action-plan-share-url").split("/");
        shareURL = splitURL[0] + "//" + splitURL[2] + "/es/" + splitURL[3] + "/";
        shareURL += hash; //console.log(shareURL);
      } else {
        shareURL =
          this.state.stringList.get("action-plan-share-url").split("[[hash]]").join(hash) +
          (this.state.selectedLanguage.default ? "" : "?l=" + this.state.selectedLanguage.id);
      }

      const numTools = (
        <>
          <br />
          <div className="num-tools">{this.state.recommendedTools.length}</div>
        </>
      );


      return (
        <>
        
          <div className="complete-report" style={{ visibility: isFromSharing ? "hidden" : "visible" }}>
            <div className="circle" onClick={() => this.onClickLocatorLocation(3)}>
              <img className="go-back" src={require("./../../images/ui/chevron-down-green.svg")} alt="" />
            </div>
            <div className="all-done">
              <div className="check-wrapper">
                <img className="check" src={require("./../../images/ui/checkmark-small-white.svg")} alt="" />
              </div>
              {this.state.stringList.get("statements-complete")}
            </div>
          </div>
          {ReactUtils.getReplacedNodes(this.state.stringList.get(titleStringId), "[[tools]]", numTools)}
          <div id="monetate-section">
            { window.CR.userInfo.id.length ? <></> : <MonetateSection 
          stringList={this.state.stringList}/>}
          </div>
          <div className="refer-back">
            {this.state.stringList.get("refer-back")}
            <div className="refer-buttons">
              <ActionButton
                className="refer-button"
                onClick={() => {
                  TrackClick("copy_url-button");
                  copy(shareURL);
                }}
              >
                <div className="text">{this.state.stringList.get("copy-url")}</div>
                <img className="icon" src={require("./../../images/ui/copy-white.svg")} alt="" />
              </ActionButton>
              <ActionButton className={"refer-button" + (downloading ? " foo " : "")} onClick={() => this.onClickSave(hash)}>
                <div className="text">{this.state.stringList.get("download-pdf")}</div>
                <img
                  className="icon"
                  src={downloading ? require("./../../images/ui/spinner-white.svg") : require("./../../images/ui/download-white.svg")}
                  alt=""
                />
              </ActionButton>
            </div>
          </div>
        </>
      );
    };

    /**
     * Generates the footer on Report/Threat page
     * @function getFooter
     * @memberof components.SecurityPlannerApp
     * @instance
     */
    const getFooter = () => {
      const additionalThreat = this.state.recommendedThreats.find((threat) => threat.isAdditionalHelp);
      const additionalThreatSlug = additionalThreat ? additionalThreat.slug : undefined;

      /*const	shareURL = this.state.stringList.get("action-plan-share-url").split("[[hash]]").join(hash) + ( this.state.selectedLanguage.default ? '' : '?l=' + this.state.selectedLanguage.id );*/
      let shareURL = "";
      let splitURL = "";
      if (localStorage.getItem("preferredLanguage") == "es-US") {
        splitURL = this.state.stringList.get("action-plan-share-url").split("/");
        shareURL = splitURL[0] + "//" + splitURL[2] + "/es/" + splitURL[3] + "/";
        shareURL += hash; //console.log(shareURL);
      } else {
        shareURL =
          this.state.stringList.get("action-plan-share-url").split("[[hash]]").join(hash) +
          (this.state.selectedLanguage.default ? "" : "?l=" + this.state.selectedLanguage.id);
      }

      return (
        <ReportFooter
          stringList={this.state.stringList}
          routes={this.routes}
          goToPage={this.goToPage}
          additionalThreatUri={additionalThreatSlug ? this.routes.getUriAllToolsThreat(additionalThreatSlug) : undefined}
          shareURL={shareURL}
          isFromSharing={isFromSharing}
          allowFocus={true}
          downloading={this.downloading}
          onClickSave={() => this.onClickSave(hash)}
          onClickRetake={() => this.onClickRetake()}
        />
      );
    };

    return {
      handler: this.sectionHandler,
      display: !isFromSharing,
      title: this.state.stringList.get("action-plan-title"),
      browserTitle: this.state.stringList.get(isFromSharing ? "action-plan-shared-browser-title" : "action-plan-browser-title"),
      allowAtStart: isFromSharing,
      injectAtStart: undefined,
      prepare: function () {
        if (isFromSharing) {
          SecurityPlannerStore.loadState(hash);
        }
      },
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: !isFromSharing,
        hideStartOverButtonIfClear: false,
        colorClass: "section-action-plan",
        level: undefined,
        componentParams: componentParams,
        showJoinBanner: true,
        render: function () {
          return (
            <ThreatsAndToolsPage
              ref={url}
              key={url}
              stringList={this.state.stringList}
              navigator={this.navigator}
              routes={this.routes}
              goToPage={this.goToPage}
              onClickToStart={this.startFromReport}
              topTool={this.state.topRecommendedTool}
              tools={this.state.recommendedTools || []}
              allTools={this.state.tools}
              threats={this.state.recommendedThreats}
              isFromSharing={isFromSharing}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
              maxVisibleThreats={SecurityPlannerConstants.Values.RECOMMENDATION_MAX_VISIBLE_THREATS}
              maxVisibleToolsPerThreat={2}
              menuTitle={this.state.stringList.get("action-plan-navigation-title")}
              header={getHeader}
              footer={getFooter}
              tallHeader={true}
              downloading={this.downloading}
              onResetClick={this.onResetClick}
              onOpenMenu={() => {
                if (this.refs["joinBanner"]) {
                  this.refs["joinBanner"].close();
                }
              }}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the print report content
   * @param  {object} componentParams
   * @function getUriPrintReportContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriPrintReportContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: this.state.stringList.get("action-plan-title"),
      browserTitle: this.state.stringList.get("action-plan-browser-title"),
      allowAtStart: true,
      injectAtStart: [],
      prepare: function () {
        SecurityPlannerStore.loadState(componentParams.hash);
      },
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: "section-action-plan",
        level: undefined,
        componentParams: componentParams,
        showJoinBanner: false,
        render: function (componentParams) {
          return (
            <PrintReportPage
              ref={this.routes.getUriPrintReport(componentParams.hash)}
              key={this.routes.getUriPrintReport(componentParams.hash)}
              stringList={this.state.stringList}
              routes={this.routes}
              goToPage={this.goToPage}
              navigator={this.navigator}
              topTool={this.state.topRecommendedTool}
              tools={this.state.recommendedTools}
              threats={this.state.recommendedThreats}
              isFromSharing={Boolean(componentParams.hash)}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the all tools content uri
   * @param  {object} componentParams
   * @function getUriPrintReportContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriAllToolsContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("all-tools-browser-title"),
      browserDescription: this.state.stringList.get("all-tools-browser-description"),
      allowAtStart: true,
      injectAtStart: [],
      prepare: undefined,
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: "color-dark",
        level: undefined,
        componentParams: componentParams,
        showJoinBanner: true,
        render: function () {
          const startingThreat = componentParams.threatSlug
            ? this.state.threats.find((threat) => threat.slug == componentParams.threatSlug)
            : undefined;
          return (
            <ThreatsAndToolsPage
              ref={startingThreat ? this.routes.getUriAllToolsThreat(componentParams.threatSlug) : this.routes.getUriAllTools()}
              key={startingThreat ? this.routes.getUriAllToolsThreat(componentParams.threatSlug) : this.routes.getUriAllTools()}
              routes={this.routes}
              goToPage={this.goToPage}
              startingThreat={startingThreat}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
              stringList={this.state.stringList}
              threats={this.state.threats}
              tools={this.state.tools}
              allTools={this.state.tools}
              menuTitle={this.state.stringList.get("all-tools-navigation-title")}
              header={this.state.stringList.get("all-tools-subtitle")}
              downloading={this.downloading}
              onOpenMenu={() => {
                if (this.refs["joinBanner"]) {
                  this.refs["joinBanner"].close();
                }
              }}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the about page content uri
   * @param  {object} componentParams
   * @function getUriAboutContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriAboutContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("about-us-browser-title"),
      browserDescription: this.state.stringList.get("about-us-browser-description"),
      allowAtStart: true,
      injectAtStart: [],
      prepare: undefined,
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: "color-dark",
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <AboutPage
              ref={this.routes.getUriAbout()}
              bios={this.state.bios}
              key={this.routes.getUriAbout()}
              stringList={this.state.stringList}
              routes={this.routes}
              hasPlan={() => {
                const { hash } = SecurityPlannerStore.getPlan() || {};
                return hash && hash.length > 0;
              }}
              tools={this.state.tools}
              goToPage={this.goToPage}
              checkPage={this.checkPage}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the philosophy content uri
   * @param  {object} componentParams
   * @function getUriPhilosophyContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriPhilosophyContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("philosophy-browser-title"),
      allowAtStart: true,
      injectAtStart: [],
      prepare: undefined,
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: "color-dark",
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <PhilosophyPage
              ref={this.routes.getUriPhilosophy()}
              key={this.routes.getUriPhilosophy()}
              stringList={this.state.stringList}
              routes={this.routes}
              tools={this.state.tools}
              goToPage={this.goToPage}
              hasPlan={() => {
                const { hash } = SecurityPlannerStore.getPlan() || {};
                return hash && hash.length > 0;
              }}
              checkPage={this.checkPage}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
            />
          );
        },
      },
    };
  };
    /**
   * Generates the philosophy content uri
   * @param  {object} componentParams
   * @function getUriAskExpert
   * @memberof components.SecurityPlannerApp
   * @instance
   */
    getUriAskExpertContent = (componentParams) => {
      return {
        handler: this.sectionHandler,
        display: false,
        title: undefined,
        browserTitle: this.state.stringList.get("philosophy-browser-title"),
        allowAtStart: true,
        injectAtStart: [],
        prepare: undefined,
        params: {
          isOverlay: false,
          isLocatorVisible: false,
          showStartOverButton: false,
          hideStartOverButtonIfClear: false,
          colorClass: "color-dark",
          level: undefined,
          componentParams: componentParams,
          render: function () {
            return (
              <AskExpertPage
                ref={this.routes.getUriAskExpert()}
                key={this.routes.getUriAskExpert()}
                stringList={this.state.stringList}
                routes={this.routes}
                tools={this.state.tools}
                goToPage={this.goToPage}
                hasPlan={() => {
                  const { hash } = SecurityPlannerStore.getPlan() || {};
                  return hash && hash.length > 0;
                }}
                checkPage={this.checkPage}
                availableLanguages={this.state.availableLanguages}
                selectedLanguage={this.state.selectedLanguage}
              />
            );
          },
        },
      };
    };

  /**
   * Generates the terms page content uri
   * @param  {object} componentParams
   * @function getUriTermsContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriTermsContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("terms-browser-title"),
      browserDescription: this.state.stringList.get("terms-browser-description"),
      allowAtStart: true,
      injectAtStart: [],
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: "color-dark",
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <TermsPage
              ref={this.routes.getUriTerms()}
              key={this.routes.getUriTerms()}
              stringList={this.state.stringList}
              routes={this.routes}
              hasPlan={() => {
                const { hash } = SecurityPlannerStore.getPlan() || {};
                return hash && hash.length > 0;
              }}
              tools={this.state.tools}
              goToPage={this.goToPage}
              checkPage={this.checkPage}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the terms print page content uri
   * @param  {object} componentParams
   * @function getUriTermsPrintContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriTermsPrintContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: this.state.stringList.get("terms-browser-title"),
      browserTitle: this.state.stringList.get("terms-browser-title"),
      browserDescription: this.state.stringList.get("terms-browser-description"),
      allowAtStart: true,
      injectAtStart: [],
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: "section-action-plan",
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <TermsPrintPage
              ref={this.routes.getUriTerms()}
              key={this.routes.getUriTerms()}
              stringList={this.state.stringList}
              routes={this.routes}
              goToPage={this.goToPage}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the feedback content uri
   * @param  {object} componentParams
   * @function getUriFeedbackContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriFeedbackContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("feedback-general-browser-title"),
      browserDescription: this.state.stringList.get("feedback-general-browser-description"),
      allowAtStart: true,
      injectAtStart: undefined,
      prepare: undefined,
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: "color-dark",
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <FeedbackPage
              ref={this.routes.getUriFeedback()}
              key={this.routes.getUriFeedback()}
              stringList={this.state.stringList}
              routes={this.routes}
              tools={this.state.tools}
              hasPlan={() => {
                const { hash } = SecurityPlannerStore.getPlan() || {};
                return hash && hash.length > 0;
              }}
              goToPage={this.goToPage}
              onClickClose={() => this.goBack(true)}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the share content overlay uri
   * @param  {object} componentParams
   * @function getUriOverlayShareContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriOverlayShareContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("overlay-share-browser-title"),
      allowAtStart: false,
      injectAtStart: undefined,
      params: {
        isOverlay: true,
        isLocatorVisible: undefined,
        showStartOverButton: undefined,
        hideStartOverButtonIfClear: undefined,
        colorClass: undefined,
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <ShareOverlay
              key={this.routes.getUriOverlayShare()}
              ref={this.routes.getUriOverlayShare()}
              scrollPosition={this.windowScroller.getScrollY()}
              stringList={this.state.stringList}
              onClickClose={() => this.goBack(true)}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the tool content overlay uri
   * @param  {object} componentParams
   * @function getUriOverlayToolContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriOverlayToolContent = (componentParams) => {
    const tool = this.state.tools.find((tool) => tool.slug == componentParams.toolSlug);
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("overlay-tool-browser-title").replace("[[name]]", tool ? tool.name : "?"),
      allowAtStart: true,
      injectAtStart: [this.routes.getUriAllTools()],
      prepare: undefined,
      params: {
        isOverlay: true,
        isLocatorVisible: undefined,
        showStartOverButton: undefined,
        hideStartOverButtonIfClear: undefined,
        colorClass: undefined,
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <ToolOverlay
              key={this.routes.getUriOverlayTool(componentParams.toolSlug)}
              ref={this.routes.getUriOverlayTool(componentParams.toolSlug)}
              stringList={this.state.stringList}
              routes={this.routes}
              goToPage={this.goToPage}
              tool={tool}
              tools={this.state.tools}
              scrollPosition={this.windowScroller.getScrollY()}
              onClickClose={() => this.goBack(true)}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
              hasPlan={() => {
                const { hash } = SecurityPlannerStore.getPlan() || {};
                return (hash && hash.length > 0) || false;
              }}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the bio content overlay uri
   * @param  {object} componentParams
   * @function getUriOverlayBioContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriOverlayBioContent = (componentParams) => {
    const bio = this.state.bios.find((bio) => bio.slug === componentParams.bioSlug);
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("overlay-bio-browser-title").replace("[[name]]", bio ? bio.name : "?"),
      allowAtStart: false,
      injectAtStart: undefined,
      prepare: undefined,
      params: {
        isOverlay: true,
        isLocatorVisible: undefined,
        showStartOverButton: undefined,
        hideStartOverButtonIfClear: undefined,
        colorClass: undefined,
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <BioOverlay
              key={this.routes.getUriOverlayBio(componentParams.bioSlug)}
              ref={this.routes.getUriOverlayBio(componentParams.bioSlug)}
              bio={bio}
              scrollPosition={this.windowScroller.getScrollY()}
              stringList={this.state.stringList}
              onClickClose={() => this.goBack(true)}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the feedback content overlay uri
   * @param  {object} componentParams
   * @function getUriOverlayToolFeedbackContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriOverlayToolFeedbackContent = (componentParams) => {
    const tool = this.state.tools.find((tool) => tool.slug == componentParams.toolSlug);
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("overlay-toolfeedback-browser-title").replace("[[name]]", tool ? tool.name : "?"),
      allowAtStart: false,
      injectAtStart: undefined,
      prepare: undefined,
      params: {
        isOverlay: true,
        isLocatorVisible: undefined,
        showStartOverButton: undefined,
        hideStartOverButtonIfClear: undefined,
        colorClass: undefined,
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <ToolFeedbackOverlay
              key={this.routes.getUriOverlayToolFeedback(componentParams.toolSlug)}
              ref={this.routes.getUriOverlayToolFeedback(componentParams.toolSlug)}
              stringList={this.state.stringList}
              routes={this.routes}
              goToPage={this.goToPage}
              scrollPosition={this.windowScroller.getScrollY()}
              tool={tool}
              onClickClose={() => this.goBack(true)}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the threat menu content overlay uri
   * @param  {object} componentParams
   * @function getUriOverlayThreatMenuContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriOverlayThreatMenuContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("overlay-threatmenu-browser-title"),
      allowAtStart: false,
      injectAtStart: undefined,
      prepare: undefined,
      params: {
        isOverlay: true,
        isLocatorVisible: undefined,
        showStartOverButton: undefined,
        hideStartOverButtonIfClear: undefined,
        colorClass: undefined,
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <ThreatMenuOverlay
              key={this.routes.getUriOverlayThreatMenu(componentParams.transportId)}
              ref={this.routes.getUriOverlayThreatMenu(componentParams.transportId)}
              stringList={this.state.stringList}
              scrollPosition={this.windowScroller.getScrollY()}
              transportId={componentParams.transportId}
              onClickClose={() => this.goBack(true)}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the tools filter content overlay uri
   * @param  {object} componentParams
   * @function getUriOverlayToolsFilterContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriOverlayToolsFilterContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("overlay-toolsfilter-browser-title"),
      allowAtStart: false,
      injectAtStart: undefined,
      prepare: undefined,
      params: {
        isOverlay: true,
        isLocatorVisible: undefined,
        showStartOverButton: undefined,
        hideStartOverButtonIfClear: undefined,
        colorClass: undefined,
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <ToolsFilterOverlay
              key={this.routes.getUriOverlayToolsFilter(componentParams.transportId)}
              ref={this.routes.getUriOverlayToolsFilter(componentParams.transportId)}
              stringList={this.state.stringList}
              scrollPosition={this.windowScroller.getScrollY()}
              transportId={componentParams.transportId}
              onClickClose={() => this.goBack(true)}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the preview content overlay uri
   * @param  {object} componentParams
   * @function getUriPreviewContent
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriPreviewContent = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: "Preview",
      allowAtStart: true,
      injectAtStart: [],
      prepare: undefined,
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: undefined,
        level: undefined,
        componentParams: componentParams,
        render: function () {
          const rest = componentParams.rest;
          return (
            <PreviewPage
              key={this.routes.getUriPreview(rest)}
              ref={this.routes.getUriPreview(rest)}
              stringList={this.state.stringList}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
              routes={this.routes}
              goToPage={this.goToPage}
              path={rest}
              bios={this.state.bios}
              statements={this.state.statements}
              tools={this.state.tools}
              levels={this.state.levels}
              threats={this.state.threats}
              topRecommendedTool={this.state.topRecommendedTool}
              recommendedTools={this.state.recommendedTools}
              recommendedThreats={this.state.recommendedThreats}
            />
          );
        },
      },
    };
  };

  /**
   * Generates the no-match content overlay uri
   * @param  {object} componentParams
   * @function getUriNoMatch
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getUriNoMatch = (componentParams) => {
    return {
      handler: this.sectionHandler,
      display: false,
      title: undefined,
      browserTitle: this.state.stringList.get("cover-browser-title"),
      allowAtStart: false,
      injectAtStart: undefined,
      prepare: undefined,
      params: {
        isOverlay: false,
        isLocatorVisible: false,
        showStartOverButton: false,
        hideStartOverButtonIfClear: false,
        colorClass: undefined,
        level: undefined,
        componentParams: componentParams,
        render: function () {
          return (
            <NoMatchPage
              ref={this.routes.getUriNoMatch()}
              key={this.routes.getUriNoMatch()}
              metadata={this.state.metadata}
              stringList={this.state.stringList}
              availableLanguages={this.state.availableLanguages}
              selectedLanguage={this.state.selectedLanguage}
              routes={this.routes}
              hasPlan={() => {
                const { hash } = SecurityPlannerStore.getPlan() || {};
                return hash && hash.length > 0;
              }}
              tools={this.state.tools}
              goToPage={this.goToPage}
              onClickNext={() => {
                // Clears and goes to the cover
                MiniTracker.trackEvent("button", "click", "start-over");
                this.goToPage(this.routes.getUriCover(), false, true);
              }}
            />
          );
        },
      },
    };
  };

  /**
   * Deselects All Statements and Resets
   * @function onResetClick
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  onResetClick = () => {
    SecurityPlannerActions.deselectAllStatements();
    this.goToPage(this.routes.getUriCover(), false, true);
  };

  /**
   * Proceeds to the earlier url
   * @param  {boolean} removeHistoryAfter
   * @function goBack
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  goBack = (removeHistoryAfter = false) => {
    // console.log("attempting to go back, remove = ", removeHistoryAfter);
    this.clearHistoryAfterNavigation = removeHistoryAfter;
    this.navigator.goBack();
  };

  /**
   * Proceeds to the step to display.
   * @param  {number} index
   * @function goToDisplayedStep
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  goToDisplayedStep = (index) => {
    // Goes to a page that is a "displayed" step of index
    const uri = this.navigator.getDisplayedLocationAt(index);
    this.goToPage(uri);
  };

  /**
   * Proceeds to the page to display.
   * @param  {string} uri
   * @param  {boolean} removeHistory
   * @param  {boolean} treatAsExplicit
   * @function goToPage
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  goToPage = (uri, removeHistory = false, treatAsExplicit = false, hardLoad = false ) => {
    //This is a hack to get around the fact jumping from an overlay to a page breaks the transition animation logic
    if( hardLoad ) {
      window.location.href = uri;
      return;
    }
    
    if (uri === this.state.location) {
      return;
    }

    if (!this.hasOpenedFirstPage) {
      this.hasOpenedFirstPage = true;
    }

    if (removeHistory) {
      this.navigator.removeLocationsAfterCurrent();
    }

    if (this.navigator.hasLocation(uri)) {
      this.navigator.goToLocation(uri, treatAsExplicit);
    } else {
      const sectionObject = this.router.handle(uri);
      if (!sectionObject) {
        console.error(`Error: could not find handler for route [${uri}].`); // eslint-disable-line
      } else if (sectionObject.prepare) {
        sectionObject.prepare.bind(this)();
      }

      this.navigator.addLocation(
        uri,
        sectionObject.handler,
        sectionObject.display,
        sectionObject.title,
        sectionObject.browserTitle,
        sectionObject.browserDescription,
        sectionObject.params,
      );
      this.navigator.goToLocation(uri);
    }
  };

  /**
   * Checks the page to be displayed.
   * @param  {string} uri
   * @param  {boolean} removeHistory
   * @param  {boolean} treatAsExplicit
   * @function checkPage
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  checkPage = (uri, removeHistory = false, treatAsExplicit = false) => {
    if (uri === this.state.location) {
      return;
    }

    if (!this.hasOpenedFirstPage) {
      this.hasOpenedFirstPage = true;
    }

    if (removeHistory) {
      this.navigator.removeLocationsAfterCurrent();
    }

    if (this.navigator.hasLocation(uri)) {
      return true;
    } else {
      const sectionObject = this.router.handle(uri);
      if (sectionObject.prepare) {
        sectionObject.prepare.bind(this)();
      }
      return true;
    }
  };

  /**
   * Generates the build state of the application
   * @function getBuildState
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  getBuildState = function () {
    return {
      isDataLoaded: SecurityPlannerStore.hasContent(),
      planChecked: SecurityPlannerStore.checkedPlan(),
      anyStatementSelected: SecurityPlannerStore.isAnyStatementSelected(),
      availableLanguages: SecurityPlannerStore.getAvailableLanguages(),
      selectedLanguage: SecurityPlannerStore.getSelectedLanguage(),
      metadata: SecurityPlannerStore.getMetadata(),
      stringList: SecurityPlannerStore.getStringList(),
      bios: SecurityPlannerStore.getBios(),
      levels: SecurityPlannerStore.getLevels(),
      location: this.navigator ? this.navigator.currentLocationId : undefined,
      recommendedThreats: SecurityPlannerStore.getRecommendedThreats(),
      topRecommendedTool: SecurityPlannerStore.getTopRecommendedTool(),
      recommendedTools: SecurityPlannerStore.getRecommendedTools(),
      statements: SecurityPlannerStore.getStatements(),
      threats: SecurityPlannerStore.getThreats(),
      tools: SecurityPlannerStore.getTools(),
      toasts: SecurityPlannerStore.getToasts(),
      cdaGlobalElements: SecurityPlannerStore.getCdaGlobalElements(),
    };
  };

  /**
   * Updates the build state of the application
   * @function updateBuiltState
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  updateBuiltState = () => {
    // Update the internal state with a new state built from several different data sources
    this.setState(this.getBuildState());
  };

  /**
   * Pre-fetches the assets for the application
   * @function prefetchAssets
   * @memberof components.SecurityPlannerApp
   * @instance
   */
  prefetchAssets = () => {
    // Prefetches all assets that are likely to be used frequently
    // This helps make the transition to new pages smoother since we won't have a lot to load upfront
    if (this.state.isDataLoaded) {
      // Do it a bit later, to give time for other assets to be queued, layout to be composed, etc
      setTimeout(() => {
        this.state.tools.forEach((tool) => {
          if (tool.image) {
            const img = new Image();
            img.src = tool.image;
          }
        });

        this.state.statements.forEach((statement) => {
          if (statement.image) {
            const img = new Image();
            img.src = statement.image;
          }
        });
      }, 500);
      this.prefetchedAssets = true;
    }
  };

  startOver = () => {
    // Clears and goes to the cover
    MiniTracker.trackEvent("button", "click", "start-over");
    SecurityPlannerActions.deselectAllStatements();
    this.goToPage(this.routes.getUriCover(), false, true);
  };

  startFromReport = () => {
    // location.href = this.state.stringList.get("common-url-home");
    this.goToPage(this.routes.getUriCover(), false, true);
  };

  onStoreChanged = () => {
    this.updateBuiltState();
  };

  onNavigatorLocationChanged = (locationId, locationParams, oldLocationId, oldLocationParams) => {
    // eslint-disable-line
    // console.log("===> Navigator location changed, id = [" + locationId + "], position: " + this.navigator.position + " of " + this.navigator.locations.length + "");
    // console.log(`(!) Navigator location changed, id = [${locationId}], position ${this.navigator.position} / ${this.navigator.locations.length}; history = ${this.navigator.locationHistory}`);

    // Replace pages we shouldn't track with an anonymized equivalent
    SecurityPlannerConstants.PRIVATE_LOCATION_PATTERNS.map(function (privateLocationPattern) {
      if (locationId.match(privateLocationPattern[0])) {
        locationId = privateLocationPattern[1];
      }
    });
    MiniTracker.trackPage(locationId);

    // Update browser data
    let sectionName   = this.navigator.currentBrowserTitle;
    let pageName      = (sectionName && sectionName.trim()) ? this.state.stringList.get("browser-title-subsection").replace("[[section]]", sectionName) : this.state.stringList.get("browser-title-empty");
    let description   = (this.navigator.currentBrowserDescription && this.navigator.currentBrowserDescription.trim()) ? this.navigator.currentBrowserDescription : this.state.stringList.get("cover-browser-description");
    let url           = window.location.origin + locationId;

    //Pull toolDescription if this is a tool
    if( locationId.includes('/tool/') ){
      const toolSlug = locationId.split('/tool/')[1];
      const tool = this.state.tools.find( tool => tool.slug === toolSlug );
      if( tool ){
        description = tool.shortDescription;
      }
    }

    //Update <head> tag with new meta tags
    headful({
      title: pageName,
      description: description,
      url: url,
    });


    this.updateBuiltState();
  };

  getCurrentRefAndParams = () => {
    // This is not entirely correct - transitioningFromLocation and transitioningToLocation sometimes lag behind
    const lastLocationInfo = this.navigator.hasLocation(this.transitioningToLocation) && this.navigator.getLocationInfo(this.transitioningToLocation);
    const fromOverlay = lastLocationInfo && lastLocationInfo.params && lastLocationInfo.params.isOverlay;
    const currentRef = this.scrollPosition < 0.5 || fromOverlay ? this.transitioningFromLocation : this.transitioningToLocation;
    const locationInfo = this.navigator.getLocationInfo(currentRef);
    const currentLocationParams = (locationInfo || { params: {} }).params; // this.navigator.currentParams

    return { currentLocationParams, currentRef, locationInfo };
  };

  updateNavigatorState = () => {
    // if (this.isMounted()) {
    // Update start over button
    // Hide or show start over button

    const { currentLocationParams, currentRef } = this.getCurrentRefAndParams();

    const currentRefScroll =
      !!currentRef && !!this.refs[currentRef] && !!this.refs[currentRef].getScrollPosition ? this.refs[currentRef].getScrollPosition() : 0;
    const currentRefLocatorBackgroundColor =
      !!currentRef && !!this.refs[currentRef] && !!this.refs[currentRef].getDesiredLocatorBackgroundColor
        ? this.refs[currentRef].getDesiredLocatorBackgroundColor()
        : undefined;

    if (!!this.refs[currentRef] && !!this.refs[currentRef].adjustHeadPosition) {
      this.refs[currentRef].adjustHeadPosition();
    }

    if (this.refs["startOver"]) {
      this.refs["startOver"].setStateParameters(
        this.navigator.hasLocation(this.routes.getUriCover()) &&
          currentLocationParams.showStartOverButton &&
          (!this.state.hideStartOverButtonIfClear || this.state.anyStatementSelected),
        currentLocationParams.colorClass,
        currentRefScroll != 0,
        currentRefScroll,
        currentRefLocatorBackgroundColor,
        currentLocationParams.colorClass,
      );
    }

    // Update locator
    if (this.refs["locator"]) {
      // Find the internal scroll position of the current page on focus
      this.refs["locator"].setStateParameters(
        currentLocationParams.isLocatorVisible,
        this.navigator.displayedLocations.length,
        this.state.levels.length,
        this.navigator.displayedPosition,
        this.navigator.furthestDisplayedPosition,
        this.navigator.currentTitle,
        currentLocationParams.colorClass,
        this.scrollPosition != 0 && this.scrollPosition != 1,
        currentRefScroll != 0,
        currentRefScroll,
        currentRefLocatorBackgroundColor,
      );
    }

    

    if (this.refs["joinBanner"]) {
      this.refs["joinBanner"].setStateParameters(currentLocationParams.showJoinBanner);
    }
  };

  onClickLocatorLocation = (index) => {
    if (index <= 0) {
      return this.goToPage(this.routes.getUriCover());
    }

    this.goToDisplayedStep(index - 1);
  };

  onCreatedPage = (locationId) => {
    // Add events to a section when it is created
    // Will update later, because it depends on the component actually existing
    this.waitingPageComponentCreation = locationId;
    this.onCreatedPageDelayed();
  };

  onCreatedPageDelayed = () => {
    if (this.waitingPageComponentCreation && !!this.refs[this.waitingPageComponentCreation]) {
      if (this.refs[this.waitingPageComponentCreation].onPageScrolled) {
        this.refs[this.waitingPageComponentCreation].onPageScrolled.add(this.onCurrentPageScrolled);
      }

      this.waitingPageComponentCreation = undefined;
    }

    if (this.waitingPageComponentCreation) window.requestAnimationFrame(this.onCreatedPageDelayed);
  };

  onActivatedPage = (locationId, locationParams, prevLocationId, prevLocationParams) => {
    // Update the section when it is activated
    // Will update later, because it depends on the component actually existing
    this.onActivatedPageDelayed(locationId, locationParams, prevLocationId, prevLocationParams);
  };

  onActivatedPageDelayed = (locationId, locationParams, prevLocationId, prevLocationParams) => {
    if (this.refs[locationId]) {
      this.refs[locationId].onActivate(
        this.navigator.lastPositionTravelOffset,
        this.navigator.lastPositionTravelType === MiniNavigator.TRAVEL_TYPE_BROWSER_HISTORY_API,
        prevLocationParams && prevLocationParams.isOverlay,
      );
    } else {
      window.requestAnimationFrame(() => {
        this.onActivatedPageDelayed(locationId, locationParams, prevLocationId, prevLocationParams);
      });
    }
  };

  onDeactivatedPage = (locationId, locationParams, newLocationId, newLocationParams) => {
    // Update the section when it is deactivated
    if (this.refs[locationId]) {
      this.refs[locationId].onDeactivate(
        this.navigator.lastPositionTravelOffset,
        this.navigator.lastPositionTravelType === MiniNavigator.TRAVEL_TYPE_BROWSER_HISTORY_API,
        newLocationParams && newLocationParams.isOverlay,
      );
    }
  };

  transitionToLocationWithScroll = (ref, canScrollToNearestEdge = false) => {
    // Scrolls to a component's DOM container
    const element = this.getElementFromLocationId(ref);
    const wy = this.windowScroller.getScrollY();

    // console.log("scrolling to [" + ref + "], to " + wy + " element will be ", element);

    if (element != null) {
      this.transitioningFromLocation = this.transitioningToLocation;
      this.transitioningToLocation = ref;

      this.scrollingFromElement = this.getElementFromLocationId(this.transitioningFromLocation);
      this.scrollingFromOffset = 0;

      this.scrollingToElement = element;
      this.scrollingToOffset = 0;

      if (this.scrollingFromElement) {
        // Normal scroll
        this.scrollingFromOffset = (wy - this.scrollingFromElement.offsetTop) / this.scrollingFromElement.offsetHeight;

        if (canScrollToNearestEdge && this.scrollingFromElement.offsetTop > this.scrollingToElement.offsetTop) {
          // Don't necessarily scroll to the top of the section: instead, focus on the closest edge (e.g. bottom of report)
          this.scrollingToOffset = (this.scrollingToElement.offsetHeight - window.innerHeight) / this.scrollingToElement.offsetHeight;
        }

        if (element.offsetTop != wy) {
          // Need to scroll
          this.isAutoScrolling = true;
          this.setScrollPosition(0);
          this.windowScroller.unlock();
          Fween.use(this.getScrollPosition, this.setScrollPosition).from(0).to(1, 0.5, Easing.expoInOut).call(this.onTransitionScrollEnded).play();
        }
      } else {
        // No previous section exist, assume the new location is the first one
        this.setScrollPosition(1);
      }
    }
  };

  onTransitionScrollEnded = () => {
    this.isAutoScrolling = false;
    this.lockScrollOnCurrentElement();
    this.onLocationTransitionEnded();
  };

  transitionToLocationWithCustom = (ref, transitionFunc) => {
    this.transitioningFromLocation = this.transitioningToLocation;
    this.transitioningToLocation = ref;
    transitionFunc(() => {
      this.onLocationTransitionEnded();
    });
  };

  onLocationTransitionEnded = () => {
    if (this.clearHistoryAfterNavigation) {
      this.clearHistoryAfterNavigation = false;
      this.navigator.removeLocationsAfterCurrent();
      requestAnimationFrame(() => {
        this.forceUpdate();
      });
    }
  };

  lockScrollOnCurrentElement = () => {
    if (SecurityPlannerConstants.UI.LOCK_SCROLL) {
      this.windowScroller.unlock();
      this.updateScrollPosition();
      this.windowScroller.forciblyUpdateScrollY();
      const centerElement = this.getCenterElement();
      if (centerElement) this.windowScroller.lock(centerElement.offsetTop);
      window.requestAnimationFrame(() => {
        // Lock once again, because sometimes it depends on the order of execution
        if (centerElement) this.windowScroller.lock(centerElement.offsetTop);
      });
    } else {
      this.updateScrollPosition();
    }
  };

  getScrollPosition = () => {
    return this.scrollPosition;
  };

  setScrollPosition = (value) => {
    this.scrollPosition = value;
    this.updateScrollPosition();
  };

  updateScrollPosition = () => {
    if (this.scrollingFromElement) {
      const pointTo = this.scrollingToElement.offsetTop + Math.round(this.scrollingToElement.offsetHeight * this.scrollingToOffset);
      if (this.scrollPosition != 1) {
        const pointFrom = this.scrollingFromElement.offsetTop + this.scrollingFromElement.offsetHeight * this.scrollingFromOffset;
        this.windowScroller.setScrollY(Math.round(map(this.scrollPosition, 0, 1, pointFrom, pointTo)));
      } else {
        this.windowScroller.setScrollY(pointTo);
      }
      this.updateNavigatorState();
    }
  };

  getElementFromLocationId = (locationId) => {
    return ReactDOM.findDOMNode(this.refs[locationId]);
  };

  getLocationIdFromElement = (centerElement) => {
    let element = null;
    for (let i = 0; i < this.fixedComponentRefs.length; i++) {
      element = ReactDOM.findDOMNode(this.refs[this.fixedComponentRefs[i]]);
      if (element == centerElement) {
        // Found it
        return this.fixedComponentRefs[i];
      }
    }
    return undefined;
  };

  onCurrentPageScrolled = () => {
    // The internal content of a "page" section has been scrolled
    // console.log("SCROLLED PAGE!");
    this.updateNavigatorState();
  };

  onWindowScrollPreventHistoryScroll = () => {
    // On Safari, sometimes it scrolls to a different position - maybe because of the history API
    // This forces it to the correct location
    if (this.isAutoScrolling) {
      this.updateScrollPosition();
    }
  };

  onWindowScroll = () => {
    if (!this.windowScroller.isLocked()) {
      this.updateNavigatorState();
    }
  };

  onWindowResize = () => {
    // On resize, fixes the current section in the middle again
    if (this.isAutoScrolling) {
      // During a transition, just redraw the current phase
      this.updateScrollPosition();
    } else {
      // End of transition, just redraw and lock
      this.lockScrollOnCurrentElement();

      // Previously this only fired for safari, now needs to fire for everything
      if (this.windowResizeTimeoutId) window.clearTimeout(this.windowResizeTimeoutId);
      this.windowResizeTimeoutId = window.setTimeout(this.onWindowResizeFix, 10);
    }
  };

  onWindowResizeFix = () => {
    // Previously fixes for Safari, now everything mobile; needed because setting scroll during the resize or rotate doesn't work every time.
    // The scroll position is set, but never painted.
    // It may also think it's in the right position, hence why we move it up and down a bit before going back to the right position
    this.windowScroller.setScrollY(this.windowScroller.getScrollY() - 1);
    this.windowScroller.setScrollY(this.windowScroller.getScrollY() + 1);
    this.lockScrollOnCurrentElement();
    this.windowResizeTimeoutId = undefined;
  };

  onMouseWheel = (e) => {
    // Find the delta
    if (!this.isAutoScrolling) {
      let numPixels = 0;
      if (e.deltaMode == 0x00) {
        // DOM_DELTA_PIXEL
        numPixels = e.deltaY;
      } else if (e.deltaMode == 0x01) {
        // DOM_DELTA_LINE
        numPixels = Math.round(e.deltaY * 35); // Arbitrary
      } else if (e.deltaMode == 0x02) {
        // DOM_DELTA_PAGE
        numPixels = e.deltaY * window.innerHeight;
      }

      // Finds whether we can actually scroll or not
      const element = e.target;
      const canScrollUp = element.scrollTop <= 0;
      const canScrollDown = element.scrollTop >= element.scrollTop - window.innerHeight;

      // console.log(e.eventPhase, canScrollUp, canScrollDown);

      if ((numPixels < 0 && canScrollUp) || (numPixels > 0 && canScrollDown)) {
        // Can scroll the main site
        let justStartedScrolling = false;

        if (isNaN(this.desiredScrollY)) {
          // Not scrolling, will start
          this.desiredScrollY = this.windowScroller.getScrollY() + numPixels;
          this.desiredScrollYPhase = this.windowScroller.getScrollY();

          justStartedScrolling = true;
        } else {
          // Already scrolling, just update the value
          this.desiredScrollY = this.desiredScrollY + numPixels;
        }

        const documentHeight = Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.clientHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight,
        );
        this.desiredScrollY = clamp(this.desiredScrollY, 0, documentHeight - window.innerHeight);
        // console.log(this.desiredScrollY + ", " + this.desiredScrollYPhase + " should be 0 => " + (documentHeight - window.innerHeight));

        if (justStartedScrolling) {
          this.onAnimationFrameUpdateDesiredScrollY();
        }

        e.preventDefault();
      }
    }
  };

  onAnimationFrameUpdateDesiredScrollY = () => {
    // Update value
    this.desiredScrollYPhase = this.desiredScrollYPhase + (this.desiredScrollY - this.windowScroller.getScrollY()) / 6;

    // console.log("Updating to " + Math.round(this.desiredScrollYPhase) + " of " + this.desiredScrollY);

    // Update scroll
    this.windowScroller.setScrollY(Math.round(this.desiredScrollYPhase));

    // Continue animation if not there yet
    if (Math.round(this.desiredScrollYPhase) != this.desiredScrollY) {
      window.requestAnimationFrame(this.onAnimationFrameUpdateDesiredScrollY);
    } else {
      // Stop animating
      this.desiredScrollY = undefined;
    }
  };

  getCenterElement = () => {
    const screenMiddle = this.windowScroller.getScrollY() + window.innerHeight / 2;
    // console.log("screen middle = " + screenMiddle);
    const elements = document.querySelectorAll(".sectionPageHolder");
    let centerElement = null;
    for (let i = 0; i < elements.length; i++) {
      if (screenMiddle >= elements[i].offsetTop && screenMiddle <= elements[i].offsetTop + elements[i].offsetHeight) {
        // Found
        centerElement = elements[i];
        break;
      }
    }

    return centerElement;
  };
}

export default SecurityPlannerApp;
