import PropTypes from "prop-types";
import React from "react";

/**
 * <pre>
 * Class to generate a footer section in report page in the Application.
 * Package Name - components/pages/printreport
 * </pre>
 * @class components.pages.printreport.Footer
 */
class Footer extends React.Component {
  static propTypes = {
    stringList: PropTypes.object,
    toolsHash: PropTypes.string,
  };

  /**
   * Renders the footer object.
   * @function render
   * @memberof components.pages.printreport.Footer
   * @instance
   */
  render() {
    return (
      <div className="footer">
        <div className="prefix">{this.props.stringList.get("action-plan-print-header-citizen-prefix")}</div>
        <div className="logo">
          <img src={require("./../../../../images/sponsors/citizenlab-grayscale.png")} alt="The Citizen Lab" />
        </div>
        <div className="prefix" />
        <div className="logo">
          <img src={require("./../../../../images/sponsors/jigsaw-grayscale.png")} alt="Jigsaw" />
        </div>
      </div>
    );
  }
}

export default Footer;
