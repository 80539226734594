import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

/**
 * <pre>
 * Creates a Disclaimer FormField in the Application.
 * Package Name - components/common
 * </pre>
 * @class components.common.FormFieldDisclaimer
 */
class FormFieldDisclaimer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    caption: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
  };

  /**
   * Renders the formfield discalimer object.
   * @function render
   * @memberof components.common.FormFieldDisclaimer
   * @instance
   */
  render() {
    const classes = cx(
      "disclaimer",
      {
        hidden: this.props.hidden,
      },
      this.props.className,
    );

    return <div className={classes}>{this.props.caption}</div>;
  }
}

export default FormFieldDisclaimer;
