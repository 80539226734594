import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

import SecurityPlannerActions from "./../../../actions/SecurityPlannerActions";
import SecurityPlannerStore from "./../../../stores/SecurityPlannerStore";

/**
 * <pre>
 * Class to generate a effects preview in the Application.
 * Uses the Threat and Tools for preview.
 * Package Name - components/pages/preview
 * </pre>
 * @class components.pages.preview.EffectsPreview
 */
class EffectsPreview extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    stringList: PropTypes.object,
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    goToPage: PropTypes.func.isRequired,
    statements: PropTypes.array.isRequired, // Statement[]
    tools: PropTypes.array.isRequired, // Tool[]
    levels: PropTypes.array.isRequired, // Level[]
    threats: PropTypes.array.isRequired, // Threat[]
    topRecommendedTool: PropTypes.object, // Tool[]
    recommendedTools: PropTypes.array, // Tool
    recommendedThreats: PropTypes.array, // Threat[]
  };

  /**
   * Renders the effects preview object.
   * @function render
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  render() {
    return (
      <div className={this.props.className}>
        <div className="subtitle">Effects</div>
        <div className="container">{this.renderLevels(this.props.levels)}</div>
        <div className="container-right">
          {this.renderRecommendedTools(this.props.topRecommendedTool, this.props.recommendedTools, this.props.recommendedThreats)}
        </div>
      </div>
    );
  }

  /**
   * Renders the levels of the preview.
   * @param  {object} levels      
   * @function renderLevels
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderLevels = (levels) => {
    return (
      <div className="content">
        {levels.map((level) => this.renderLevel(level))}
        {this.renderResults()}
      </div>
    );
  };

  /**
   * Renders each level of the preview.
   * @param  {object} level      
   * @function renderLevel
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderLevel = (level) => {
    return (
      <div className="section level" key={level.id}>
        <div className="row header">
          <div className="column-box">{this.getLevelId(level)}</div>
          <div className="column-long">{level.title}</div>
          <div className="column-image" />
          {this.props.tools.map((tool) => this.renderEffectHeader(tool))}
        </div>
        {level.statements.map((statement, index) => this.renderStatement(level, statement, index))}
      </div>
    );
  };

  /**
   * Renders statement of each level of the preview.
   * @param  {object} level    
   * @param  {object} statement 
   * @param  {object} index             
   * @function renderLevel
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderStatement = (level, statement, index) => {
    const statementVisible = SecurityPlannerStore.isStatementVisible(statement.id);
    const statementRowClasses = cx(["row", "statement", statementVisible ? "visible" : "invisible", statement.selected ? "selected" : "deselected"]);
    const statementNameStyle = {
      color: statement.selected ? "#ffffff" : undefined,
      backgroundColor: statement.selected ? this.getColor(statement.backgroundColor) : undefined,
    };
    const statementImageStyle = {
      backgroundImage: `url('${statement.image}')`,
      borderColor: this.getColor(statement.backgroundColor),
      backgroundColor: statement.selected ? this.getColor(statement.backgroundColor) : undefined,
    };
    return (
      <div className={statementRowClasses} key={statement.id}>
        <div className="column-box">{`${this.getLevelId(level)}${index + 1}`}</div>
        <div
          className="column-long"
          style={statementNameStyle}
          onClick={statementVisible ? () => SecurityPlannerActions.toggleStatementSelected(statement.id) : null}
        >
          {statement.text}
          {statement.isRequired ? <span className="required"> {"(always visible)"} </span> : null}
        </div>
        <div className="column-image" onClick={statementVisible ? () => SecurityPlannerActions.toggleStatementSelected(statement.id) : null}>
          <div style={statementImageStyle} />
        </div>
        {this.props.tools.map((tool) => this.renderEffect(statement, tool))}
      </div>
    );
  };

  /**
   * Renders the header of the effects of the preview.
   * @param  {object} tool    
   * @function renderEffectHeader
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderEffectHeader = (tool) => {
    return (
      <div className="column-effect header" key={tool.id}>
        {tool.name}
      </div>
    );
  };

  /**
   * Renders the effects section of the preview.
   * @param  {object} tool    
   * @param  {object} statement       
   * @function renderEffect
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderEffect = (statement, tool) => {
    let selectedPoints = 0;
    statement.selectedEffects.forEach((effect) => {
      Object.keys(effect.tools).forEach((key) => {
        if (key === tool.id) selectedPoints += effect.tools[key];
      });
    });

    let deselectedPoints = 0;
    statement.deselectedEffects.forEach((effect) => {
      Object.keys(effect.tools).forEach((key) => {
        if (key === tool.id) deselectedPoints += effect.tools[key];
      });
    });

    return (
      <div className="column-effect" key={tool.id}>
        {this.renderPoints(selectedPoints, statement.selected, "cell top")}
        {this.renderPoints(deselectedPoints, !statement.selected, "cell bottom")}
      </div>
    );
  };

  /**
   * Renders the points section of the preview.
   * @param  {object} points    
   * @param  {boolean} active       
   * @param  {string} className          
   * @function renderPoints
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderPoints = (points, active, className) => {
    if (points === 0) return null;
    const p = this.getNumber(points);
    return <div className={className + (active ? " active" : " inactive")}>{p > 0 ? `+${p}` : p}</div>;
  };

  /**
   * Renders the results of the effects section of the preview.
   * @function renderResults
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderResults = () => {
    return (
      <div className="section total" key={"total"}>
        <div className="row header">
          <div className="column-box" />
          <div className="column-long">{"Total Recommendation points"}</div>
          <div className="column-image" />
          {this.props.tools.map((tool) => this.renderToolTotals(tool))}
        </div>
      </div>
    );
  };

  /**
   * Renders the total tools section of effects in the preview.
   * @param  {object} tool       
   * @function renderToolTotals
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderToolTotals = (tool) => {
    return (
      <div className="column-effect header" key={tool.id}>
        {tool.recommendationPoints}
      </div>
    );
  };

  /**
   * Renders the recommendation tools section of effects in the preview.
   * @param  {object} topTool       
   * @param  {object} tools       
   * @param  {object} threats             
   * @function renderRecommendedTools
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderRecommendedTools = (topTool, tools, threats) => {
    return (
      <div className="content">
        <div className="title">{"Recommendatiom Results"}</div>
        {threats.map((threat) => this.renderRecommendedThreat(topTool, tools, threat))}
      </div>
    );
  };

  /**
   * Renders each of the recommendation tools for the effects in the preview.
   * @param  {object} topTool       
   * @param  {object} tools       
   * @param  {object} threat             
   * @function renderRecommendedThreat
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderRecommendedThreat = (topTool, tools, threat) => {
    return (
      <div className="threat" key={threat.id}>
        <div className="title">{"Threat: " + threat.name}</div>
        {tools.map((tool) => {
          if (tool.threat === threat) {
            return this.renderRecommendedTool(tool, topTool === tool);
          } else {
            return undefined;
          }
        })}
      </div>
    );
  };

  /**
   * Renders a recommendation tools for the effects in the preview.
   * @param  {boolean} isTopTool       
   * @param  {object} tool 
   * @function renderRecommendedTool
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  renderRecommendedTool = (tool, isTopTool) => {
    return (
      <div className="tool" key={tool.id}>
        <div className="name">{tool.name + (isTopTool ? " (top)" : "")}</div>
        <div className="points">{this.getNumber(tool.recommendationPoints)}</div>
      </div>
    );
  };

  /**
   * Fetches the id of the level for the effects in the preview.       
   * @param  {object} level 
   * @function getLevelId
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  getLevelId = (level) => {
    return "ABCDEFG".substr(this.props.levels.indexOf(level), 1);
  };

  /**
   * Fetches the color for the effects in the preview.       
   * @param  {object} color 
   * @function getColor
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  getColor = (color) => {
    const clr = color.toString(16);
    return "#" + "000000".substr(0, 6 - clr.length) + clr;
  };

  /**
   * Fetches a identifier number for the effects in the preview.       
   * @param  {number} val 
   * @function getColor
   * @memberof components.pages.preview.EffectsPreview
   * @instance
   */
  getNumber = (val) => {
    return Math.round(val * 100) / 100;
  };
}

export default EffectsPreview;
