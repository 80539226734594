
import React from "react";
import PropTypes from "prop-types";
import ActionButton from "./ActionButton.react";

class OverlayPlanBanner extends React.Component {
    static propTypes = {
        stringList: PropTypes.object, // StringList
        goToPage: PropTypes.func, // (page: string) => void
        routes: PropTypes.object, // Routes
    };

    render() {
        return(
            <div className="overlay-plan-banner">
                <div className="overlay-plan-banner-content">
                    <h3 className="overlay-plan-banner-title">
                        { this.props.stringList.get("overlay-plan-banner-title") === "{NOT_FOUND}" ? "Keep your data secure with a personalized plan" : this.props.stringList.get("overlay-plan-banner-title")}
                    </h3>
                    <p className="overlay-plan-banner-text">
                        { this.props.stringList.get("overlay-plan-banner-text") === "{NOT_FOUND}" ? "Answer a few simple questions and get customized recommendations to help you cut down on data collection and prevent hackers from invading your laptop, tablet and even your phone." : this.props.stringList.get("overlay-plan-banner-text") }
                    </p>
                    <div className="overlay-plan-banner-button">
                        <ActionButton className="button button-primary common-button-yellow" onClick={() =>  
                            this.props.goToPage(this.props.routes.getUriCover( false, true ), false, false, true)}>
                            { this.props.stringList.get("overlay-plan-banner-button") === "{NOT_FOUND}" ? "Create a plan" : this.props.stringList.get("overlay-plan-banner-button") }
                        </ActionButton>
                    </div>
                </div>
            </div>
        )
    }
};

export default OverlayPlanBanner;