import PropTypes from "prop-types";
import React from "react";

import ClickTouchHandler from "./../../../vendor/utils/ClickTouchHandler.js";

/**
 * <pre>
 * Class to create a statement card section in the Application.
 * Package Name - components/pages/statements
 * </pre>
 * @class components.pages.statements.Card
 */
class Card extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    text: PropTypes.string,
    isSelected: PropTypes.bool,
    image: PropTypes.string,
    isEmpty: PropTypes.bool,
    allowFocus: PropTypes.bool,
    backgroundColor: PropTypes.number,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.touchHandler = new ClickTouchHandler(this.props.onClick, true);
  }

  componentWillUnmount() {
    this.touchHandler.dispose();
  }

  /**
   * Renders the statements card.
   * @function render
   * @memberof components.pages.statements.Card
   * @instance
   */
  render() {
    if (this.props.isEmpty) {
      return <div className={this.props.className + " is-disabled"} />;
    } else {
      const imgStyle = {
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" + this.props.image + ")",
      };
      
      return (
        <div
          className={this.props.className + (this.props.isSelected ? " is-selected" : "")}
          tabIndex={this.props.allowFocus ? 0 : -1}
          role="checkbox"
          title={this.props.text}
          aria-label={this.props.text}
          aria-checked={this.props.isSelected}
          onKeyDown={this.touchHandler.handler}
          onClick={this.touchHandler.handler}
          onTouchStart={this.touchHandler.handler}
          onTouchMove={this.touchHandler.handler}
          onTouchEnd={this.touchHandler.handler}
          onTouchCancel={this.touchHandler.handler}
        >
          <div className="selector" />
          <div className="image-wrapper">
            <div className="common-image-container image" style={imgStyle}
              title={this.props.description} />
          </div>
          <div className="caption">{this.props.text}</div>
        </div>
      );
    }
  }
}

export default Card;
