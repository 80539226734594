import PropTypes from "prop-types";
import React from "react";

import ReactUtils from "./../../../vendor/utils/ReactUtils";
import DirectionUtils from "./../../../vendor/utils/DirectionUtils";

/**
 * <pre>
 * Class to generate a tool component in the Application.
 * Package Name - components/pages/printreport
 * </pre>
 * @class components.pages.printreport.Tool
 */
class Tool extends React.Component {
  static propTypes = {
    stringList: PropTypes.object,
    tool: PropTypes.object, // Tool
  };

  /**
   * Renders the tool object.
   * @function render
   * @memberof components.pages.printreport.Tool
   * @instance
   */
  render() {
    return (
      <div className={"tool " + DirectionUtils.getClass(this.props.stringList)}>
        <div className="checkbox" />
        <div className="title">{this.props.tool.name}</div>
        <div className="description">{this.props.tool.shortDescription}</div>
        <div className="cost">{this.renderCost()}</div>
        <div className={"link " + DirectionUtils.getClass(this.props.stringList)}>{this.renderLink()}</div>
      </div>
    );
  }

  /**
   * Renders the cost embedded in the tool object.
   * @function renderCost
   * @memberof components.pages.printreport.Tool
   * @instance
   */
  renderCost = () => {
    const isToolFree = !this.props.tool.price || !this.props.tool.price.trim();

    return ReactUtils.getReplacedNodes(
      this.props.stringList.get("action-plan-print-tool-cost"),
      "[[price]]",
      <span className="price"> {isToolFree ? this.props.stringList.get("tool-label-free") : this.props.tool.price} </span>,
    );
  };

  /**
   * Renders the link embedded in the tool object.
   * @function renderLink
   * @memberof components.pages.printreport.Tool
   * @instance
   */
  renderLink = () => {
    if (this.props.tool.buttons) {
      return this.props.tool.buttons.map((button, index) => {
        return (
          <div key={"link-" + index}>
            <a href={button.url}>{this.getCleanLink(button.url)}</a>
          </div>
        );
      });
    } else {
      return null;
    }
  };

  /**
   * Generates a clean link for the tool object.
   * @param  {string} url    
   * @function getCleanLink
   * @memberof components.pages.printreport.Tool
   * @instance
   */
  getCleanLink = (url) => {
    return url.replace(/^http(s|):\/\//, "").replace(/\/$/, "");
  };
}

export default Tool;
