/**
 * <pre>
 * Class for a Resource Link component in store from retrieved data.
 * Package - stores/data. 
 * </pre>
 * @class stores.data.ResourceLink
 */
export default class ResourceLink {
  // ================================================================================================================
  // CONSTRUCTOR ----------------------------------------------------------------------------------------------------

  /**
   * Creates An Object of ResourceLink Component. 
   * @constructor    
   */    
  constructor() {
    this.id = "";
    this.slug = ""; // Friendly slug, for reporting
    this.caption = "";
    this.url = "";
    this.source = "";
    this.translationOutdated = false;
    this.date = "";
    this.dump = "";
  }
}
