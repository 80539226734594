import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

import ImageContainer from "../../common/ImageContainer.react";
import ADAUtils from "../../../vendor/utils/ADAUtils";

import ChooseQuestion from "./ChooseQuestion.react";
import RateQuestion from "./RateQuestion.react";
import TextAreaQuestion from "./TextAreaQuestion.react";
import TextQuestion from "./TextQuestion.react";
import EmailQuestion from "./EmailQuestion.react";
import { type } from "jquery";

/**
 * <pre>
 * Class to create the question for rating in the Application.
 * Package Name - components/pages/feedback
 * </pre>
 * @class components.pages.feedback.RateQuestion
 */
class Question extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    numQuestions: PropTypes.number,
    question: PropTypes.object, // Question
    stringList: PropTypes.object, // StringList
    hasDivider: PropTypes.bool,
    isExpanded: PropTypes.bool,
    allowFocus: PropTypes.bool,
    onRequestExpand: PropTypes.func, // index
    onChange: PropTypes.func, // question.id, value
    onNextQuestion: PropTypes.func, // index
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // current value
  };

  /**
   * Renders the rate question object.
   * @function render
   * @memberof components.pages.feedback.RateQuestion
   * @instance
   */
  render() {
    const classNames = cx("question", "rate", {
      expanded: this.props.isExpanded,
      "is-single": this.props.numQuestions === 1,
      "is-answered": this.hasAnswer(),
      "is-required": this.props.question.required,
    });

    const components = {
      checkbox: ChooseQuestion,
      radio: ChooseQuestion,
      rating: RateQuestion,
      textarea: TextAreaQuestion,
      email: EmailQuestion,
      text: TextQuestion,
    };

    const attributes = {
      question: this.props.question,
      onChange: this.props.onChange,
      value: this.props.value,
      stringList: this.props.stringList,
      allowFocus: this.props.allowFocus,
    };

    return (
      <div className={classNames}>
        <div
          className="header"
          tabIndex={this.props.allowFocus && !this.props.isExpanded ? 0 : -1}
          role="button"
          onKeyDown={ADAUtils.handleKeyboard(this.onClickTab)}
          onClick={this.onClickTab}
        >
          <div className="number">{this.props.index + 1 + "."}</div>
          <div className="caption">
            <span>
              {this.props.question.question} {this.props.question.required && "*"}
            </span>
          </div>
          <ImageContainer className="check" src={require("./../../../../images/page-feedback/check-small.png")} />
        </div>
        <div className={cx("collapsible-body-outer", { expanded: this.props.isExpanded })}>
          <div className="collapsible-body-inner">
            { this.props.question.description && <div className="description">{this.props.question.description}</div> }
            { components[this.props.question.type] && React.createElement(components[this.props.question.type], attributes) }
            { !this.isLast() && <div className="footer">
              <div
                className="skip"
                tabIndex={this.props.isExpanded && this.props.allowFocus ? 0 : -1}
                role="select"
                onKeyDown={ADAUtils.handleKeyboard(this.onClickSkip)}
                onClick={this.goToNext}
              >
                <span>{ this.hasAnswer() ? this.props.stringList.get("feedback-general-rate-answer-next") : this.props.stringList.get("feedback-general-rate-answer-skip")}</span>
              </div>
            </div>}
          </div>
        </div>
        <div className={cx("divider", { visible: this.props.hasDivider })} />
      </div>
    );
  }

  /**
   * Checks whether question has an answer.
   * @function hasAnswer
   * @memberof components.pages.feedback.RateQuestion
   * @instance
   */
  hasAnswer = () => {
    
    if( this.props.question.type === "checkbox" || this.props.question.type === "radio" ){ 
      return this.props.value !== undefined && this.props.value !== null && this.props.value.length > 0;
    }else{
      return this.props.value !== undefined && this.props.value !== null && this.props.value !== "";
    }
  };

  /**
   * Action when question tab has been clicked.
   * @function onClickTab
   * @memberof components.pages.feedback.RateQuestion
   * @instance
   */
  onClickTab = () => {
    if (this.props.onRequestExpand) this.props.onRequestExpand(this.props.index);
  };

  /**
   * Action when "Skip/Next" button clicked.       
   * @function goToNext
   * @memberof components.pages.feedback.RateQuestion
   * @instance
   */
  goToNext = () => {
    if (this.props.onNextQuestion) this.props.onNextQuestion(this.props.index);
  };

  /**
   * Determine if Last Question.       
   * @function isLast
   * @memberof components.pages.feedback.RateQuestion
   * @instance
   */
  isLast = () => {
    return this.props.index === this.props.numQuestions - 1;
  };
}

export default Question;
