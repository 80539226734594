import PropTypes from "prop-types";
import React from "react";

import FooterShare from "./FooterShare.react";

/**
 * <pre>
 * Class to generate a footer section on report page in the Application.
 * Uses the FooterShare component to generate.
 * Package Name - components/pages/report
 * </pre>
 * @class components.pages.report.Footer
 */
class Footer extends React.Component {
  static propTypes = {
    stringList: PropTypes.object, // StringList
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    goToPage: PropTypes.func.isRequired,
    additionalThreatUri: PropTypes.string,
    shareURL: PropTypes.string,
    isFromSharing: PropTypes.bool,
    allowFocus: PropTypes.bool,
    onClickSave: PropTypes.func.isRequired,
    onClickRetake: PropTypes.func.isRequired,
    downloading: PropTypes.bool,
  };

  /**
   * Renders the footer object.
   * @function render
   * @memberof components.pages.report.Footer
   * @instance
   */
  render() {
    return (
      <div className="footer">
        <div className="wrapper">
          <FooterShare {...this.props} />
        </div>
      </div>
    );
  }
}

export default Footer;
