/**
 * <pre>
 * Class For Generating Share Segment On Plan page
 * Package - vendor/localization. 
 * </pre>
 * @class vendor.sharing.MultiSharer
 */
export default class MultiSharer {
  // ================================================================================================================
  // PUBLIC INTERFACE -----------------------------------------------------------------------------------------------
 /**
   * Reuturns ShareUrl and opens a new window. 
   * @param  {string} channel     
   * @param  {string} url     
   * @param  {string} text     
   * @param  {string} subject              
   * @function share
   * @memberof vendor.sharing.MultiSharer
   */
  static share(channel, url, text, subject) {
    // Find URL template

    const shareURL = MultiSharer.getShareURL(channel, url, text, subject);
    const windowWidth = MultiSharer.getRecommendedWindowWidth(channel);
    const windowHeight = MultiSharer.getRecommendedWindowHeight(channel);
    // let windowSettings = "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,left=" + Math.round(screen.width/2 - windowWidth/2) + ",top=" + Math.round(screen.height/2 - windowHeight/2) + ",width=" + windowWidth + ",height=" + windowHeight;
    const windowSettings = "menubar=no,toolbar=no,resizable=yes,scrollbars=yes," + MultiSharer.getWindowParams(windowWidth, windowHeight);

    // Finally, shares it
    if (shareURL.length) {
      if (isNaN(windowWidth) || isNaN(windowHeight)) {
        // Blank tab
        window.open(shareURL, "_blank");
      } else {
        // Normal popup
        window.open(shareURL, "_blank", windowSettings);
      }
    } else {
      console.error("Share URL of type [" + channel + "] not identified!"); // eslint-disable-line
    }
  }

 /**
   * Sets Window width of the channel. 
   * @param  {string} channel     
   * @function getRecommendedWindowWidth
   * @memberof vendor.sharing.MultiSharer
   */
  static getRecommendedWindowWidth(channel) {
    if (channel == MultiSharer.EMAIL) return undefined;
    return 590;
  }

 /**
   * Sets Window height of the channel. 
   * @param  {string} channel     
   * @function getRecommendedWindowHeight
   * @memberof vendor.sharing.MultiSharer
   */
  static getRecommendedWindowHeight(channel) {
    if (channel == MultiSharer.EMAIL) return undefined;
    return 370;
  }

 /**
   * Fetches the share url of the channel. 
   * @param  {string} channel     
   * @param  {string} url     
   * @param  {string} text     
   * @param  {string} subject      
   * @function getShareURL
   * @memberof vendor.sharing.MultiSharer
   */
  static getShareURL(channel, url, text, subject) {
    let shareURL = "";

    if (channel == MultiSharer.EMAIL) {
      shareURL = "mailto:?subject=[[subject]]&body=[[text]]";
    } else if (channel == MultiSharer.FACEBOOK) {
      // https://www.facebook.com/sharing/sharing.php?u=http://www.google.com
      // https://www.facebook.com/dialog/share?app_id=348446702011488&display=popup&href=[[url]]&redirect_uri=http://mountaindew.com/kickstart";
      shareURL = "https://www.facebook.com/sharer/sharer.php?u=[[url]]";
    } else if (channel == MultiSharer.GOOGLE_PLUS) {
      shareURL = "https://plus.google.com/share?url=[[url]]";
    } else if (channel == MultiSharer.TWITTER) {
      shareURL = "https://twitter.com/intent/tweet?&text=[[text]]"; // &via=user
    }

    // Replaces fields
    if (url != undefined) shareURL = shareURL.split("[[url]]").join(encodeURIComponent(url));
    if (text != undefined) shareURL = shareURL.split("[[text]]").join(encodeURIComponent(text));
    if (subject != undefined) shareURL = shareURL.split("[[subject]]").join(encodeURIComponent(subject));

    return shareURL;
  }

 /**
   * Fetches the window parameters of the channel. 
   * @param  {number} width     
   * @param  {number} height     
   * @function getWindowParams
   * @memberof vendor.sharing.MultiSharer
   */
  static getWindowParams(width, height) {
    // Properly centers the window
    const a = typeof window.screenX != "undefined" ? window.screenX : window.screenLeft;
    const i = typeof window.screenY != "undefined" ? window.screenY : window.screenTop;
    const g = typeof window.outerWidth != "undefined" ? window.outerWidth : document.documentElement.clientWidth;
    const f = typeof window.outerHeight != "undefined" ? window.outerHeight : document.documentElement.clientHeight - 22;
    const h = a < 0 ? window.screen.width + a : a;
    const left = parseInt(h + (g - width) / 2, 10);
    const top = parseInt(i + (f - height) / 2.5, 10);
    return "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top + ",scrollbars=1";
  }
}

MultiSharer.EMAIL = "email";
MultiSharer.FACEBOOK = "facebook";
MultiSharer.GOOGLE_PLUS = "google-plus";
MultiSharer.TWITTER = "twitter";
