/**
 * <pre>
 * Class for Language component in store from retrieved data.
 * Package - stores/data.  
 * </pre>
 * @class stores.data.Language
 */
export default class Language {
  // ================================================================================================================
  // CONSTRUCTOR ----------------------------------------------------------------------------------------------------

  /**
   * Creates An Object of Language Component. 
   * @constructor    
   */    
  constructor() {
    this.id = "";
    this.name = "";
    this.direction = "";
    this.fallbackLanguage = "";
    this.default = false;
    this.enabled = true;
    this.code = "";
  }
}

/**
 * DIRECTION_RTL
 * A static member of Language component for right to left direction
 * @type {string} 
 * @memberof stores.data.Language
 */
Language.DIRECTION_RTL = "right-to-left";

/**
 * DIRECTION_LTR
 * A static member of Language component for left to right direction
 * @type {string} 
 * @memberof stores.data.Language  
 */
Language.DIRECTION_LTR = "left-to-right";

