/* eslint-disable no-console */

import Events from "events";

import AppDispatcher from "./../dispatcher/AppDispatcher";
import SecurityPlannerConstants from "./../constants/SecurityPlannerConstants";
import SecurityPlannerContentfulParser from "./parsing/SecurityPlannerContentfulParser";
import SecurityState from "./data/SecurityState";
import StringList from "./../vendor/localization/StringList";
import MiniTracker from "./../vendor/tracking/MiniTracker";
import SecurityPlan from "./data/SecurityPlan";

const EventEmitter = Events.EventEmitter;

// Initializer

const CHANGE_EVENT = "change";

let hasContent = false;
let checkedPlan = false;
const _storeState = {
  securityState: undefined, // SecurityState

  securityPlanService: undefined, // SecurityPlan

  // Data handlers
  parser: undefined,

  // String values
  stringList: undefined, // StringList

  // Toast messages
  toasts: [], // Array of { text: string, icon: string, time: datetime it was displayed in ms} 
  
  cdaGlobals: {}, //object for cda-global elements   
};

// Internal methods

function checkIfContentExists( reload = false) {
  if (!hasContent || reload ) {
    // Data not loaded and parsed yet
    if (window.hasOwnProperty("contentLoader")) {
      // All loaded!

      // Create the parser
      if (SecurityPlannerConstants.Parameters.IS_DEBUGGING) {
        console.time("parse");
      }

      const preferredLanguages = [...window.preferredLanguages];
      const persistedLanguage = localStorage.getItem('preferredLanguage');
      if ( persistedLanguage ) {
        preferredLanguages.unshift(persistedLanguage);
      }

      const loader = window.contentLoader;
      const languages = preferredLanguages;
      _storeState.parser = new SecurityPlannerContentfulParser(loader, languages, false);

      if (SecurityPlannerConstants.Parameters.IS_DEBUGGING) {
        console.log(
          "Parsed store data; " +
            _storeState.parser.getErrors().length +
            " errors and " +
            _storeState.parser.getWarnings().length +
            " warnings detected.",
        );
        if (_storeState.parser.getErrors().length > 0) console.log("Errors: ", _storeState.parser.getErrors());
        if (_storeState.parser.getWarnings().length > 0) console.log("Warnings: ", _storeState.parser.getWarnings());
        console.timeEnd("parse");
      }

      if (SecurityPlannerConstants.Parameters.IS_DEBUGGING) {
        console.log("DESIRED LANGUAGES: ", _storeState.parser.desiredLanguages);
        console.log("AUTO-SELECTED LANGUAGE: ", _storeState.parser.usedLanguage.id);
      }

      // Report on detected and selected languages
      MiniTracker.trackEvent("language", "detected", _storeState.parser.desiredLanguages[0], undefined, true);
      MiniTracker.trackEvent("language", "auto-selected", _storeState.parser.usedLanguage, undefined, true);

      // Initialize the recommendation state
      _storeState.securityState = new SecurityState(
        _storeState.parser.statements,
        _storeState.parser.tools,
        _storeState.parser.threats,
        _storeState.parser.levels,
      );

      // For testing purposes
      if (SecurityPlannerConstants.Parameters.PRESELECTED_STATEMENTS) {
        for (let i = 0; i < SecurityPlannerConstants.Parameters.PRESELECTED_STATEMENTS.length; i++) {
          _storeState.securityState.setStatementSelected(SecurityPlannerConstants.Parameters.PRESELECTED_STATEMENTS[i], true);
        }
      }

      // Create aux content
      createStringList();

      hasContent = true;

      checkIfPlanExists();
    } else {
      // Object doesn't exist (must be loading), wait and try again
      setTimeout(checkIfContentExists, 1000 / 30);
    }
  }
}

function checkIfPlanExists() {
  if (!checkedPlan) {
    // Data not loaded and parsed yet
    if (window.hasOwnProperty("initialPlan")) {
      _storeState.securityPlanService = window.planService;
      _storeState.securityState.applyPlan(window.initialPlan);

      checkedPlan = true;
    } else {
      // Object doesn't exist (must be loading), wait and try again
      setTimeout(checkIfPlanExists, 1000 / 30);
    }
  }
}

function checkInitComplete() {
  if (checkedPlan && hasContent) {
    SecurityPlannerStore.emitChange();
  } else {
    setTimeout(checkInitComplete, 1000 / 30);
  }
}

function createStringList() {
  _storeState.stringList = new StringList(_storeState.parser.usedLanguage, _storeState.parser.getStrings(), "other");
}

function changeLanguage(languageId, laguageDir) {
  if (hasContent) {
    _storeState.parser.setLanguage(languageId);

    document.documentElement.lang = languageId.substring(0, 2);
    localStorage.setItem('preferredLanguage', languageId);

    //Reload all content
    checkIfContentExists( true );
  }
}

function getTotalToastTime() {
  return (
    (SecurityPlannerConstants.UI.TOAST_TIME_FADE_IN + SecurityPlannerConstants.UI.TOAST_TIME_STAY + SecurityPlannerConstants.UI.TOAST_TIME_FADE_OUT) *
    1000
  );
}

function showToastNotification(text, success, icon) {
  const toast = {
    text: text,
    success: success,
    icon: icon,
    time: Date.now(),
  };

  // Trigger a later cleanup
  setTimeout(() => {
    cleanupToastNotifications();
    SecurityPlannerStore.emitChange();
  }, getTotalToastTime() + 10);

  _storeState.toasts.push(toast);
}

function cleanupToastNotifications() {
  // Clear the list of old toasts from the store, since they're not displayed anymore
  const now = Date.now();
  _storeState.toasts = _storeState.toasts.filter((toast) => toast.time + getTotalToastTime() > now);
}

//fetch header and footer after download 
function footerFetch(){
 const footerUrl = window.location.protocol + '//' 
 					+ window.location.host + '/footer.html'; 
 fetch(footerUrl)
  .then(response => response.text())
  .then(data => { //console.log(data); 
    console.log('Global Footer Fetched'); 
    _storeState.cdaGlobals.footer = data; 
 });
}

//fetch header and footer after download 
function headerFetch(){
 const headerUrl = window.location.protocol + '//' 
 					+ window.location.host + '/header.html';
 fetch(headerUrl)
  .then(response => response.text())
  .then(data => { //console.log(data); 
    console.log('Global Header Fetched'); 
    _storeState.cdaGlobals.header = data; 
 });
}

// Actual store
/**
 * <pre>
 * Main Class For SecurityPlannerStore. 
 * Package Name - stores
 * </pre>
 * @class stores.SecurityPlannerStore
 */
const SecurityPlannerStore = Object.assign({}, EventEmitter.prototype, { 
  /**
   * Emaits a change to the store.
   * @function emitChange
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },

  /**
   * Checks if the store has content. 
   * @function hasContent
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  hasContent: function() {
    return hasContent;
  },

  /**
   * Returns if the store has a checked plan.
   * @function checkedPlan
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  checkedPlan: function() {
    return checkedPlan;
  },

  /**
   * Returns the plan from the store.
   * @function getPlan
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getPlan: function() {
    return this.checkedPlan() ? _storeState.securityState.getPlan() : null;
  },

  /**
   * Returns the bios from the store.
   * @function getBios
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getBios: function() {
    if (!this.hasContent()) return [];
    return _storeState.parser.bios;
  },

  /**
   * Returns the forms from the store.
   * @function getForms
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getForms: function() {
    if (!this.hasContent()) return {};
    return _storeState.parser.forms;
  },


  /**
   * Based on a form id, return its JSON
   * @param {string} slug        
   * @function getForm
   * @memberof stores.parsing.SecurityPlannerContentfulParser        
   * @instance   
   */
  getForm: function(slug) {
    const forms = this.getForms();
    if (forms.hasOwnProperty(slug)) {
      return forms[slug].data;
    } else {
      console.error(`Tried reading a form with id ${slug} that was not found`);
      return "NOT-FOUND";
    }
  },


  /**
   * Returns the available languages from the store.
   * @function getAvailableLanguages
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getAvailableLanguages: function() {
    if (!this.hasContent()) return [];
    return _storeState.parser.languages;
  },

  /**
   * Returns the selected languages from the store.
   * @function getSelectedLanguage
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getSelectedLanguage: function() {
    if (!this.hasContent()) return undefined;
    return _storeState.parser.usedLanguage;
  },

  /**
   * Returns the meta data from the store.
   * @function getMetadata
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getMetadata: function() {
    if (!this.hasContent()) return {};
    return _storeState.parser.getMetadata();
  },

  /**
   * Returns the stringlist from the store.
   * @function getStringList
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getStringList: function() {
    if (!_storeState.stringList) return undefined;
    return _storeState.stringList;
  },

  /**
   * Returns the levels from the store.
   * @function getLevels
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getLevels: function() {
    if (!this.hasContent()) return [];
    return _storeState.securityState.levels;
  },

  /**
   * Returns the top recommended tool from the store.
   * @function getTopRecommendedTool
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getTopRecommendedTool: function() {
    if (!this.hasContent()) return undefined;
    return _storeState.securityState.topRecommendedTool;
  },

  /**
   * Returns the recommended tools from the store.
   * @function getRecommendedTools
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getRecommendedTools: function() {
    if (!this.hasContent()) return [];
    return _storeState.securityState.recommendedTools;
  },

  /**
   * Returns the recommended threats from the store.
   * @function getRecommendedThreats
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getRecommendedThreats: function() {
    if (!this.hasContent()) return [];
    return _storeState.securityState.recommendedThreats;
  },

  /**
   * Returns the statements from the store.
   * @function getStatements
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getStatements: function() {
    if (!this.hasContent()) return [];
    return _storeState.securityState.statements;
  },

  /**
   * Returns the threats from the store.
   * @function getThreats
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getThreats: function() {
    if (!this.hasContent()) return [];
    return _storeState.securityState.threats;
  },

  /**
   * Returns the tools from the store.
   * @function getTools
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getTools: function() {
    if (!this.hasContent()) return [];
    return _storeState.securityState.tools;
  },

  /**
   * Checks if any statement is selected from store.
   * @function isAnyStatementSelected
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  isAnyStatementSelected: function() {
    if (!this.hasContent()) return undefined;
    return _storeState.securityState.isAnyStatementSelected();
  },

  /**
   * Checks if a statement is selected on store.
   * @param  {number} statementId    
   * @function isAnyStatementSelected
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  isStatementSelected: function(statementId) {
    // TODO: remove this altogether, move to the statement data?
    if (!this.hasContent()) return undefined;
    return _storeState.securityState.isStatementSelected(statementId);
  },

  /**
   * Checks if a statement is visible on store.
   * @param  {number} statementId      
   * @function isStatementVisible
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  isStatementVisible: function(statementId) {
    // TODO: remove this altogether, move to the statement data?
    if (!this.hasContent()) return undefined;
    return _storeState.securityState.isStatementVisible(statementId);
  },

  /**
   * Loads the state based on the hash
   * @param  {string} stateHash      
   * @function loadState
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  loadState: function(stateHash) {
    if (this.hasContent()) _storeState.securityState.loadState(stateHash);
  },

  /**
   * Saves the current state of the store.   
   * @function saveState
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  saveState: function() {
    if (!this.hasContent()) return undefined;
    const hash = _storeState.securityState.saveState();

    const plan = _storeState.securityState.getPlan();
    _storeState.securityPlanService.savePlan(plan);

    return hash;
  },

  /**
   * Retrieves toats from the store.   
   * @function getToasts
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  getToasts: function() {
    return _storeState.toasts;
  },

  /**
   * Registers an user on the store. Uses the plan service to register user.
   * @param  {string} firstName   
   * @param  {string} lastName   
   * @param  {string} email            
   * @function register
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  register: function(firstName, lastName, email) {
    if (!_storeState.securityPlanService) {
      console.error("No _storeState.securityPlanService found");
      return;
    }

    return _storeState.securityPlanService.register(firstName, lastName, email);
  },

  /**
   * Checks if a user is logged in. Uses the plan service to check user logged status.   
   * @function isLoggedIn
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  isLoggedIn: function() {
    if (!_storeState.securityPlanService) {
      console.error("No _storeState.securityPlanService found");
      return;
    }
    
    return _storeState.securityPlanService.isLoggedIn();
  },

 /**
   * Adds a a change listener to store via a callback.
   * @param {string} callback      
   * @function addChangeListener
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

 /**
   * Removes a change listener from store via a callback.
   * @param {string} callback      
   * @function removeChangeListener
   * @memberof stores.SecurityPlannerStore
   * @instance
   */
  removeChangeListener: function(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  /**
   * Retrieves the "cda-global" headers and footer from store. 
   * @function removeChangeListener
   * @memberof stores.SecurityPlannerStore
   * @instance   
   */
   getCdaGlobalElements: function(callback){
   	 return _storeState.cdaGlobals;
   },
  
});

// Register callback to handle all updates
AppDispatcher.register(function(action) {
  switch (action.actionType) {
  case SecurityPlannerConstants.Actions.SECURITY_PLANNER_TOGGLE_STATEMENT_SELECTED:
    _storeState.securityState.toggleStatementSelected(action.text.trim());
    _storeState.securityState.recalculateRecommendations();
    SecurityPlannerStore.emitChange();
    break;
  case SecurityPlannerConstants.Actions.SECURITY_PLANNER_DESELECT_ALL_STATEMENTS:
    if (_storeState.securityState) {
      _storeState.securityState.deselectAllStatements();
      _storeState.securityState.clearCompletedTools();
      _storeState.securityState.recalculateRecommendations();

      _storeState.securityPlanService.reset()
        .catch((error) => {
          console.error("Error during reset: ", error.stack);
        })
        .then(() => {
          const empty = new SecurityPlan();

          _storeState.securityState.applyPlan(empty);
          SecurityPlannerStore.emitChange();
        });
    }
    break;
  case SecurityPlannerConstants.Actions.CHANGE_LANGUAGE:
    changeLanguage(action.languageId, action.languageDir);
    SecurityPlannerStore.emitChange();
    break;
  case SecurityPlannerConstants.Actions.SECURITY_PLANNER_SHOW_TOAST_NOTIFICATION:
    showToastNotification(action.text, action.success, action.icon);
    SecurityPlannerStore.emitChange();
    break;
  case SecurityPlannerConstants.Actions.SECURITY_PLANNER_CLEANUP_TOAST_NOTIFICATIONS:
    cleanupToastNotifications();
    SecurityPlannerStore.emitChange();
    break;
  case SecurityPlannerConstants.Actions.SECURITY_PLANNER_TOGGLE_TOOL_COMPLETED:
    _storeState.securityState.toggleToolCompleted(action.text.trim());
    _storeState.securityState.recalculateRecommendations();

    const plan = _storeState.securityState.getPlan();
    _storeState.securityPlanService.savePlan(plan);

    SecurityPlannerStore.emitChange();
    break;
  default:
    // None
  }
});

export default SecurityPlannerStore;

// Start waiting for the content to load
checkIfContentExists();
checkInitComplete();
headerFetch();
footerFetch(); 
/* eslint-enable no-console */
