/**
 * <pre>
 * Class For Dispatching Events within SecurityPlanner.
 * Uses "Flux" to dispatch events. 
 * Package Name - dispatcher
 * </pre>
 * @class dispatcher.Dispatcher
 */
const Dispatcher = require("flux").Dispatcher;

export default new Dispatcher();
