import MiniRouter from "./../vendor/routing/MiniRouter";

/**
 * <pre>
 * Class for all routes used by the app inside the "preview" page 
 * Package Name - routing
 * </pre> 
 * @class routing.PreviewRoutes 
 */
export default class PreviewRoutes {
  /*
	All routes used by the app inside the "preview" page
	*/

  // ================================================================================================================
  // CONSTRUCTOR ----------------------------------------------------------------------------------------------------
  /**
   * Creates an instance of PreviewRoutes
   * @constructor
   */
  constructor() {}

  // ================================================================================================================
  // PUBLIC INTERFACE -----------------------------------------------------------------------------------------------

  /**
   * Returns the uri of the tool using the slug
   * @param  {string} toolslug  slug of the tool  
   * @function getUriTool 
   * @memberof routing.PreviewRoutes 
   * @instance
   */
  getUriTool(toolSlug) {
    return "tool/" + (toolSlug == undefined ? MiniRouter.PARAMETER_BRACKET_START + "toolSlug" + MiniRouter.PARAMETER_BRACKET_END : toolSlug);
  }

  /**
   * Returns the uri of the bio using the slug
   * @param  {string} bioslug  slug of the bio
   * @function getUriBio 
   * @memberof routing.PreviewRoutes 
   * @instance
   */
  getUriBio(bioSlug) {
    return "bio/" + (bioSlug == undefined ? MiniRouter.PARAMETER_BRACKET_START + "bioSlug" + MiniRouter.PARAMETER_BRACKET_END : bioSlug);
  }

  /**
   * Returns the uri of the effects
   * @function getUriEffects 
   * @memberof routing.PreviewRoutes 
   * @instance
   */
  getUriEffects() {
    return "effects";
  }
}
