import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import * as marked from "marked";

import FormFieldInput from "./FormFieldInput.react";
import ActionButton from "./ActionButton.react";
import ImageContainer from "./ImageContainer.react";
import TrackClick from "./../../vendor/tracking/AdobeAnalytics"; 

import ResizeUtils from "./../../vendor/utils/ResizeUtils";
import TrackRegister from "./../../vendor/tracking/AdobeAnalyticsRegister";
import TrackSuccess from "./../../vendor/tracking/AdobeAnalyticsRegisterSuccess";

/**
 * <pre>
 * Class to create a JoinForm in the application.
 * Package Name - components/common
 * </pre>
 * @class components.common.JoinBanner
 */
class JoinBannerOverlay extends React.Component {
  static propTypes = {
    stringList: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.func.isRequired,
  };

  state = {
    open: true,
    visible: true,
    firstName: "",
    firstError: true,
    lastName: "",
    lastError: true,
    email: "",
    emailError: true,
    registerError: null,
    joinClicked: false,
    showSuccess: false,
  };

  componentDidMount() {
    this.setState({ visible: !this.props.isLoggedIn() });
  }
  
  componentDidUpdate() {
    if( this.state.showSuccess ){
      let mobilediv = document.querySelector('.mobile-image'); 
	  mobilediv.querySelector('.overlay-inside').style.height="auto";
      mobilediv.querySelector('.common-join-banner').style.height="auto"; 
      mobilediv.querySelector('.join-form').style.height="auto"; 
      mobilediv.querySelector('.join-form').style.padding="19px 0";
      let largescrdiv = document.querySelector('.show-sliding-right');
      largescrdiv.querySelector('.overlay-inside').style.height="auto";
      largescrdiv.querySelector('.common-join-banner').style.height="auto"; 
      largescrdiv.querySelector('.join-form').style.height="auto"; 
      largescrdiv.querySelector('.join-form').style.padding="19px 0";
	} 
  }  

  /**
   * Renders the link object.
   * @param  {string} markdown    
   * @function linkRenderer
   * @memberof components.common.JoinBanner
   * @instance
   */
  linkRenderer = (markdown) => {
    const renderer = new marked.Renderer();
    const linkRenderer = renderer.link;
    renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text);
        return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
    }
   return marked(markdown, { renderer });
  }

  /**
   * Generates the toggle for the banner.
   * @function toggleOpen
   * @memberof components.common.JoinBanner
   * @instance
   */
  /*toggleOpen = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };*/

  /**
   * Generates the firstname input in banner.
   * @param  {string} firstName      
   * @function firstNameChange 
   * @memberof components.common.JoinBanner
   * @instance
   */
  firstNameChange = (firstName) => {
    firstName = firstName.trim().slice(0, 250);

    this.setState({ firstName, firstError: !firstName || firstName.length === 0 });
  };

  /**
   * Generates the lastname input in banner.
   * @param  {string} lastName    
   * @function lastNameChange
   * @memberof components.common.JoinBanner
   * @instance
   */
  lastNameChange = (lastName) => {
    lastName = lastName.trim().slice(0, 250);

    this.setState({ lastName, lastError: !lastName || lastName.length === 0 });
  };

  /**
   * Generates the email input in banner.
   * @param  {string} email      
   * @function emailChange
   * @memberof components.common.JoinBanner
   * @instance
   */
  emailChange = (email) => {
    this.setState({ email: email.slice(0, 250).trim(), registerError: null });
  };

  /**
   * Generates the email input in banner.
   * @param  {boolean} hasError    
   * @function onError
   * @memberof components.common.JoinBanner
   * @instance
   */
  onError = (hasError) => {
    const { emailError } = this.state;

    if (emailError !== hasError) {
      this.setState({ emailError: hasError, registerError: null });
    }
  }

  /**
   * Generates the delayed action in join banner.
   * @function delayedHide
   * @memberof components.common.JoinBanner
   * @instance
   */
  delayedHide = () => {
    setTimeout(() => {
      this.setState({ visible: !this.props.isLoggedIn() });
    }, 5000);
  }

  /**
   * Generates the on close action in join banner.
   * @function close
   * @memberof components.common.JoinBanner
   * @instance
   */
  close = () => {
    this.setState({ open: false });
  }

  /**
   * Generates the setState action in join banner.
   * @param  {boolean} show    
   * @function setStateParameters
   * @memberof components.common.JoinBanner
   * @instance
   */
  setStateParameters = (show) => { 
    this.setState({ visible: show && !this.props.isLoggedIn() });
  }

  /**
   * Generates the setState action in join banner.
   * @param  {object} event   
   * @function register
   * @memberof components.common.JoinBanner
   * @instance
   */
  register = (event) => {
    TrackClick("join-button");

    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const { firstName, lastName, email, firstError, lastError, emailError } = this.state;

    if (!firstError && !lastError && !emailError) {
      TrackRegister('registration-in-process');    
      this.props.register(firstName, lastName, email)
        .then(() => {
          this.setState({ registerError: null, joinClicked: true, showSuccess: true });
         window.CRUserInfo.refresh();
          TrackSuccess('registration-success'); 
        })
        .catch(({ message }) => {
          if (message) {
            this.setState({ registerError: message, joinClicked: true });
          } else {
            this.setState({ registerError: this.props.stringList.get("join-error-message-unexpected"), joinClicked: true });
          }
        }).then(() => {
          this.delayedHide();
        });
    } else {
      this.setState({ joinClicked: true });
      // eslint-disable-next-line no-console
      console.error("Form has error");
    }
  };

  /**
   * Gets the error messages on join banner.
   * @function getErrorMessage
   * @memberof components.common.JoinBanner
   * @instance
   */
  getErrorMessage = () => {
    const { firstError, lastError, emailError, registerError } = this.state;

    if (registerError) return registerError;
    if (emailError) return this.props.stringList.get("join-error-message-invalid-email");
    if (firstError) return this.props.stringList.get("join-error-message-invalid-first");
    if (lastError) return this.props.stringList.get("join-error-message-invalid-last");

    return null;
  }

  /**
   * Renders the (join) banner object.
   * @function render
   * @memberof components.common.JoinBanner
   * @instance
   */
  render() { //console.log(this.props); 
    let joinBannerText = ''; let disclaimerText = ''; let joinButtonText = '';
    let joinMemberAlreadyText = ''; let joinMemberSignInText = ''; 
    let joinsuccesstitle = ''; let joinsuccesstext = '';    
      
    const bp = ResizeUtils.getCurrentBreakpoint();
    //const isTinyOrSmallBreakpoint = bp === "tiny" || bp === "small";
    const isTinyOrSmallBreakpoint = bp === "tiny";
      
    const { open, firstName, email, lastName, visible, joinClicked, firstError, lastError, emailError, showSuccess } = this.state;

    const errorMessage = this.getErrorMessage();
    const disabled = !!errorMessage; 
    const fname = (CRUserInfo.getFirstName() != null) ? CRUserInfo.getFirstName() : null; 
    
    /*const menuClasses = cx({
      "common-join-banner": true,
      "is-fixed": true,
      "is-hidden": !visible, 
    });*/

    const menuClasses = cx({
      "common-join-banner": true,
    });    

    const joinFormClasses = cx({
      "join-form": true,
      "is-open": open,
      "success": showSuccess,
    });

    const triangle = require("./../../../images/ui/chevron-down-white.svg"); 

    if( window.location.pathname.indexOf("/es") > -1 ){
       /*joinBannerText = "Cree una cuenta CR gratuita para acceder fácilmente a su plan de acción."; 
       disclaimerText = 'Al enviar este formulario, acepto las <a target="_blank" rel="nofollow" href="https://www.consumerreports.org/cro/customerservice/privacy-policy/highlights/index.htm">términos de la Política de privacidad de Consumer Reports</a> y <a target="_blank" rel="nofollow" href="https://www.consumerreports.org/cro/2015/01/user-agreement/index.htm">Acuerdo del Usuario.</a>'; 
       joinButtonText = "Únete ahora";  
       joinMemberAlreadyText = "Ya eres usuario?"; 
       joinMemberSignInText = "Únete ahora"; */
       
       //joinBannerText = "Create a free CR account to have easy access to your action plan."; 
       joinBannerText = "Need more time? Save your Action Plan with a free CR account.";   

      //  disclaimerText =  this.props.stringList.get('join-banner-overlay-disclaimer-part-one') === "{NOT_FOUND}" ? 'By submitting this form, I agree to the <a target="_blank" rel="nofollow" href="https://www.consumerreports.org/cro/customerservice/privacy-policy/highlights/index.htm">terms of Consumer Reports\' Privacy Policy</a> and <a target="_blank" rel="nofollow" href="https://www.consumerreports.org/cro/2015/01/user-agreement/index.htm">User Agreement.</a>' : this.props.stringList.get('join-banner-overlay-disclaimer-part-one') + this.props.stringList.get('join-banner-overlay-disclaimer-part-two'); 

       joinButtonText = this.props.stringList.get('join-button-overlay-cta-text') === "{NOT_FOUND}" ? "Sign Up" : this.props.stringList.get('join-button-overlay-cta-text');  

       //joinMemberAlreadyText = this.props.stringList.get("join-member-already"); 
       //joinMemberSignInText = this.props.stringList.get("join-member-sign-in");  

       joinsuccesstitle = (fname==null) ? "Thanks" : "Thanks, " + fname; 
       joinsuccesstitle += " for signing up to save your plan.";
       joinsuccesstext = "You will receive periodic email communications about this study and other information from Consumer Reports.";
    } else {
       //joinBannerText = "Create a free CR account to have easy access to your action plan."; 
      //  joinBannerText = "Need more time? Save your Action Plan with a free CR account.";       
      //  disclaimerText =  this.props.stringList.get('join-banner-overlay-disclaimer-part-one') === "{NOT_FOUND}" ? 'By submitting this form, I agree to the <a target="_blank" rel="nofollow" href="https://www.consumerreports.org/cro/customerservice/privacy-policy/highlights/index.htm">terms of Consumer Reports\' Privacy Policy</a> and <a target="_blank" rel="nofollow" href="https://www.consumerreports.org/cro/2015/01/user-agreement/index.htm">User Agreement.</a>' : this.props.stringList.get('join-banner-overlay-disclaimer-part-one') + this.props.stringList.get('join-banner-overlay-disclaimer-part-two'); 

       //joinButtonText = this.props.stringList.get('join-button-overlay-cta-text') === "{NOT_FOUND}" ? "Sign Up" : this.props.stringList.get('join-button-overlay-cta-text'); 

       //joinMemberAlreadyText = this.props.stringList.get("join-member-already"); 
       //joinMemberSignInText = this.props.stringList.get("join-member-sign-in");

       joinsuccesstitle = (fname==null) ? this.props.stringList.get('join-banner-overlay-success-title-thanks') : this.props.stringList.get('join-banner-overlay-success-title-thanks') + ", " + fname + ','; 

       joinsuccesstitle += " " + this.props.stringList.get('join-banner-overlay-success-title-body');
       joinsuccesstext = this.props.stringList.get('join-banner-overly-success-text') === "{NOT_FOUND}" ? "You will receive periodic email communications about this study and other information from Consumer Reports." : this.props.stringList.get('join-banner-overly-success-text'); 
    }
    
    return (
      <div className={menuClasses}>
        {/*<div className="get-exclusive-access-wrapper" onClick={this.toggleOpen}>*/}
        {/*<div className="get-exclusive-access-wrapper">        
          <div className="get-exclusive-access">
            {this.props.stringList.get("join-banner-text")} 
            <div className="join-cta">{this.props.stringList.get("join-banner-link")}</div>
          </div>
          <ImageContainer className={cx({ icon: true, "is-open": open })} src={triangle} />
        </div>*/}
         {showSuccess ? (
           <>
            <div className={joinFormClasses}>
            <div className="join-success">
              <div className="title">{joinsuccesstitle}</div>
              <div className="text">{joinsuccesstext}</div>
            </div>
            </div>
           </> 
          ) : (
           <>
           <div className="get-exclusive-access-wrapper">
            { this.props.stringList.get("join-banner-overlay-title") === "{NOT_FOUND}" ? "Need more time? Save your Action Plan with a free CR account." : this.props.stringList.get("join-banner-overlay-title")}
          </div>


           <div className={joinFormClasses}>          
            <form onSubmit={this.register}>
             { (isTinyOrSmallBreakpoint) ? (
             <>
              <div className="join-row" style={{ marginBottom: "10px" }}>
                <FormFieldInput
                  allowFocus={true}
                  inputClassName={cx({ "join-name": true, text: true, invalid: joinClicked && firstError })}
                  placeholder={this.props.stringList.get("join-field-first-name")}
                  value={firstName}
                  onChange={this.firstNameChange}
                  idmod={'overlay'}                   
                />
               </div>
               <div className="join-row" style={{ marginBottom: "10px" }}> 
                <FormFieldInput
                  allowFocus={true}
                  inputClassName={cx({ "join-name": true, text: true, invalid: joinClicked && lastError })}
                  placeholder={this.props.stringList.get("join-field-last-name")}
                  value={lastName}
                  onChange={this.lastNameChange}
                  idmod={'overlay'}                   
                />
              </div>
              </>             
             ) : (  
              <div className="join-row" style={{ marginBottom: "16px" }}>
                <FormFieldInput
                  allowFocus={true}
                  inputClassName={cx({ "join-name": true, text: true, invalid: joinClicked && firstError })}
                  placeholder={this.props.stringList.get("join-field-first-name")}
                  value={firstName}
                  onChange={this.firstNameChange}
                  idmod={'overlay'}                   
                />
                <FormFieldInput
                  allowFocus={true}
                  inputClassName={cx({ "join-name": true, text: true, invalid: joinClicked && lastError })}
                  placeholder={this.props.stringList.get("join-field-last-name")}
                  value={lastName}
                  onChange={this.lastNameChange}
                  idmod={'overlay'}                   
                />
              </div>
              )}
              <div className="join-row">
                <FormFieldInput
                  allowFocus={true}
                  type="email"
                  inputClassName={cx({ "join-email": true, text: true, invalid: joinClicked && emailError })}
                  placeholder={this.props.stringList.get("join-field-email")}
                  errorMessage={this.props.stringList.get("join-field-email-error")}
                  value={email}
                  onChange={this.emailChange}
                  onError={this.onError} 
                  idmod={'overlay'}                    
                />
              </div>
              <div className={cx({ "join-row": true, "join-error": true, visible: disabled && joinClicked })}
               style={{"fontSize":"15px","height":"34px","overflowY":"scroll"}}>
                {errorMessage || this.props.stringList.get("join-error-message-no-error")}
              </div>
              <div className="join-row">
                {/*<p className="disclaimer" dangerouslySetInnerHTML={{__html: this.linkRenderer(this.props.stringList.get("join-disclaimer") || "")}} />*/}
                <p className="disclaimer" dangerouslySetInnerHTML={{__html: this.linkRenderer(
                  
                  this.props.stringList.get('join-banner-overlay-disclaimer-part-one') === "{NOT_FOUND}" ? 'By submitting this form, I agree to the <a target="_blank" rel="nofollow" href="https://www.consumerreports.org/cro/customerservice/privacy-policy/highlights/index.htm">terms of Consumer Reports\' Privacy Policy</a> and <a target="_blank" rel="nofollow" href="https://www.consumerreports.org/cro/2015/01/user-agreement/index.htm">User Agreement.</a>' : this.props.stringList.get('join-banner-overlay-disclaimer-part-one') + this.props.stringList.get('join-banner-overlay-disclaimer-part-two') 
                  
                  || "")}} />
              </div>
              <div className="join-row" style={{ marginTop: "16px" }}>
                {/*<ActionButton disabled={disabled} onClick={this.register}
                  className="join-button">{this.props.stringList.get("join-button-text")}</ActionButton>*/}
                <ActionButton disabled={disabled} onClick={this.register} className="join-button-overlay">
                   {this.props.stringList.get('join-button-overlay-cta-text') === "{NOT_FOUND}" ? "Sign Up" : this.props.stringList.get('join-button-overlay-cta-text')}
                </ActionButton>
              </div>
              <div className="join-row">
                <p className="already-member">
                  {this.props.stringList.get("join-member-already") === "{NOT_FOUND}" ? "Already a member? " : this.props.stringList.get("join-member-already") + " "} 
                   <a className="link" href={this.props.stringList.get("sign-in-url") + "?referrer=" + encodeURIComponent(window.location.pathname) }>{this.props.stringList.get("join-member-sign-in")}</a>
                </p>
              </div>
            </form>
          </div>
         </> 
        )}
      </div>
    );
  }
}

export default JoinBannerOverlay;
