import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

import ADAUtils from "./../../vendor/utils/ADAUtils";

import SecurityPlannerActions from "./../../actions/SecurityPlannerActions";

import ImageContainer from "./ImageContainer.react";
import DetailsButton from "./DetailsButton.react";
import ToolCheckbox from "./ToolCheckbox.react";

/**
 * <pre>
 * Class for generating a tool of the Application.
 * Package Name - components/common
 * </pre>
 * @class components.common.Tool
 */
class Tool extends React.Component {
  state = {
    expanded: true,
    isHovering: false,
  };

  static propTypes = {
    stringList: PropTypes.object,
    tool: PropTypes.object.isRequired,
    isTopTool: PropTypes.bool,
    size: PropTypes.string,
    className: PropTypes.string,
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    goToPage: PropTypes.func.isRequired,
    allowFocus: PropTypes.bool,
    changeTabSequence: PropTypes.func,
    completedList: PropTypes.bool,
  };

  componentDidMount() {
    this.setState({ expanded: !this.props.tool.completed });
  }

  componentDidUpdate(prevProps) {
    if (this.props.tool.completed !== prevProps.tool.completed) {
      this.setState({ expanded: !this.props.tool.completed });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.stringList !== this.props.stringList ||
      nextProps.tool.id !== this.props.tool.id ||
      nextProps.tool.completed !== this.props.tool.completed ||
      nextProps.isTopTool !== this.props.isTopTool ||
      nextProps.size !== this.props.size ||
      nextProps.className !== this.props.className ||
      nextProps.routes !== this.props.routes ||
      nextProps.goToPage !== this.props.goToPage ||
      nextProps.allowFocus !== this.props.allowFocus ||
      nextState.expanded !== this.state.expanded ||
      nextState.isHovering !== this.state.isHovering
    );
  }

  /**
   * Action for the tool segment on being clicked in tool list.
   * @param  {number} toolId    
   * @function checkedOnClick
   * @memberof components.common.Tool
   * @instance
   */
  checkedOnClick = (toolId) => {
    this.toggleExpanded();
    setTimeout(() => {
      SecurityPlannerActions.toggleToolCompleted(toolId);
    }, 250);
  };

  /**
   * Action for expanding the tool segment in tool list.
   * @function toggleExpanded
   * @memberof components.common.Tool
   * @instance
   */
  toggleExpanded = () => {
    if (this.props.isTopTool) return;

    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  /**
   * Renders the tool object.
   * @function render
   * @memberof components.common.Tool
   * @instance
   */
  render() {
    const { expanded } = this.state;

    const isToolFree =
      !this.props.tool.price ||
      !this.props.tool.price.trim() ||
      this.props.tool.price === "Free" ||
      this.props.tool.price === this.props.stringList.get("tool-label-free");

    const classes = cx({
      "common-tool-box": true,
      ["common-tool-box-" + this.props.size]: Boolean(this.props.size),
      [this.props.className]: true,
      "top-tool": this.props.isTopTool,
      "non-top-tool": !this.props.isTopTool,
    });

    // prepping entry for tools with the price "Variable"
    const toolPrice = this.props.tool.price === "Variable" ? this.props.stringList.get("tool-label-variable") : this.props.tool.price;

    const expandingClasses = cx({
      expanding: true,
      "is-collapsed": !expanded,
    });

    const triangle = this.props.tool.completed ? require("./../../../images/ui/chevron-down-fade-pine.svg") : require("./../../../images/ui/chevron-down.svg");

    return (
      <div className={classes} title={this.props.tool.headline}>
        <div className="top-tool-banner">
          <div className="top-inner">
            <div className="caption">{this.props.stringList.get("tool-banner-top-tool")}</div>
            <div className="subcaption">{this.props.stringList.get("tool-banner-top-tool-subcaption")}</div>
            <div className="icon-wrapper">
              <img className={cx({ icon: true, "is-completed": this.props.tool.completed })} alt=""
                src={require("./../../../images/ui/primary-star.svg")} />
            </div>
          </div>
        </div>
        <div className="tool-wrapper">
          <div className={cx({ "title-banner": true, "is-collapsed": !expanded, "is-completed": this.props.tool.completed })}>
            <div className="title-headline" onClick={this.toggleExpanded}>
              <ImageContainer className={cx({ icon: true, expanded })} src={triangle} />
              {this.props.tool.headline}
            </div>
            <ToolCheckbox
              className={cx({ "is-collapsed": !expanded, "is-checked": this.props.tool.completed })}
              checked={this.props.tool.completed}
              onClick={() => this.checkedOnClick(this.props.tool.id)}
              shortCaption={this.props.stringList.get("tool-caption-short")}
              tool={this.props.tool}
            />
          </div>
          <div className={expandingClasses} aria-expanded={expanded}>
            <ImageContainer
              onMouseEnter={() => this.setState({ isHovering: true })}
              onMouseLeave={() => this.setState({ isHovering: false })}
              onClick={this.showOverlay}
              className={cx({ image: true, "is-collapsed": !expanded })}
              src={this.props.tool.image}
              description={this.props.tool.imageDescription}
            />
            <div className={cx({ text: true, "is-collapsed": !expanded })}>
              <div className="details">
                <div className="title-name">{this.props.tool.name}</div>
                <div className="description">
                  {this.props.tool.shortDescription}
                  {/*
								******* Remove comments to re-enable "Learn more" on small tool cards *******
							<span className="inline-more">{this.props.stringList.get("interstitial-tool-inline-more")}</span>
							*/}
                </div>
              </div>
              <div className="button-more">
                <DetailsButton className={this.state.isHovering ? "is-hovering" : ""} allowFocus={this.props.allowFocus}
                  href={window.location.protocol + "//" + window.location.host + this.props.routes.getUriOverlayTool(this.props.tool.slug)}
                  title={this.props.stringList.get("tool-button-more")}
                  onClick={this.showOverlay}>
                  {this.props.stringList.get("tool-button-more")}
                </DetailsButton>
              </div>
              <ImageContainer
                onMouseEnter={() => this.setState({ isHovering: true })}
                onMouseLeave={() => this.setState({ isHovering: false })}
                className={cx({ image: true, "is-collapsed": !expanded })}
                onClick={this.showOverlay}
                src={this.props.tool.image}
                description={this.props.tool.imageDescription}
              />
              <div className="attributes">
                <div className={"attribute-item"}>
                  <span className="attribute-label">{this.props.stringList.get("tool-label-cost")}</span>
                  <span className="attribute-data cost">{isToolFree ? this.props.stringList.get("tool-label-free") : toolPrice}</span>
                </div>
                {this.props.tool.label && this.props.tool.label == 'Quick and Easy' ? (
                  <div className="attribute-item">
                    <span className="attribute-label">{this.props.stringList.get("tool-label-effort")}</span>
                    <span className="attribute-data effort">{this.props.tool.label}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Action for handling tab sequencing in the tool object.
   * @function handleTabSequence
   * @memberof components.common.Tool
   * @instance
   */
  handleTabSequence = (e) => {
    if (!e.shiftKey) {
      this.props.changeTabSequence(e);
      e.preventDefault();
    }
  };

  /**
   * Action for displaying overlay on the tool object.
   * @function showOverlay
   * @memberof components.common.Tool
   * @instance
   */
  showOverlay = (e) => {
    e.preventDefault();
    if (this.props.goToPage && this.props.routes) this.props.goToPage(this.props.routes.getUriOverlayTool(this.props.tool.slug), true, true);
  };
}

export default Tool;
