 /**
  * strings
  * A static member of mamespace ContentfulUpdates.
  * Lists all strings that are used on SecurityPlanner.
  * @enum {String} 
  * @memberof stores.ContentfulUpdates
  */
const strings = {
  // Should have been added by Matt on Sept 25
  // "cover-title": "Keep Your Data Secure With a Customized Plan",
  // "cover-subtitle":
  //   "Cut down on data collection and prevent hackers from invading your laptop, tablet and even your phone. Answer a few simple questions to get customized recommendations to help you:",
  // "cover-subtitle-bullets": "• Safely backup files\n• Browse online without tracking\n• Avoid phishing scams\n• Prevent identity theft",
  // "menu-about-caption": "Our Mission",
  // "menu-emergency-resources-caption": "Find Emergency Resources",
  // "cover-slogan": "Consumer Reports - Independent testing and consumer advocacy since 1936.",
  // "menu-logos-citizen-prefix": "In partnership with CITIZEN LAB",
  // "tool-caption-short": "Done",
  // "tool-caption-long": "I've already done this",
  // "action-plan-shared-subtitle-multiple": "This Action Plan has [[tools]] tools to help everyone better secure their data and devices.",
  // "action-plan-shared-subtitle-single": "This Action Plan has [[tools]] tool to help everyone better secure their data and devices.",
  // "action-plan-subtitle-multiple": "Your Action Plan includes [[tools]] tips to help you better secure your data and devices.",
  // "action-plan-subtitle-single": "Your Action Plan includes [[tools]] tip to help you better secure your data and devices.",
  // "refer-back": "Refer back to your action plan",
  // "copy-url": "Copy URL",
  // "download-pdf": "Download PDF",
  // "copy-url-footer": "Copy URL",
  // "download-pdf-footer": "Download",
  // "join-banner-text": "Get exclusive access to free tools like this to stay safe and informed.",
  // "join-banner-link": "Join Consumer Reports today.",
  // "join-button-text": "Join Now",
  // "action-plan-more-threats-button": "Done with Those? Get More",
  // "statements-complete": "All Done!",
  // "action-plan-footer-print": "Get a printable PDF.",
  // "action-plan-footer-title": "Save this link to refer back to these recommendations.",
  // "join-success-title": "Thanks!",
  // "join-success-text": "Look out for an email to set up your password and access all your membership benefits.",

  // "browser-title-empty": "Consumer Reports Security Planner",
  // "browser-title-subsection": "[[section]] - Consumer Reports Security Planner",
  // "all-tools-browser-title": "Recommendations",
  // "all-tools-browser-description": "All the expert peer-reviewed recommendations you need to safeguard your online accounts and keep your data secure.",
  // "about-us-browser-title": "Our Mission",
  // "about-us-browser-description": "Our mission is to help consumers stay safe online by providing expert, peer reviewed recommendations for basic online practices.",
  // "cover-browser-description": "Protect your privacy by answering a few simple questions to cut down on data collection,and prevent hackers from invading your devices.",
  // "terms-browser-description": "Terms & Policies for Consumer Reports Security Planner.",
  // "feedback-general-browser-description": "Give us your feedback on the Consumer Reports Security Planner.",
  // "browser-description-empty": undefined,
  // "terms-browser-title": "Terms & Policies",
  // "terms-title": "Terms & Policies",
  // "sign-in-url": "https://www.consumerreports.org/cro/modal-login/index.htm",
  // "user-agreement-url": "https://www.consumerreports.org/cro/2015/01/user-agreement/index.htm",
  // "privacy-policy-url": "https://www.consumerreports.org/cro/customerservice/privacy-policy/highlights/index.htm",

  // "logo-adjacent-title": "Security Planner",
  // "menu-privacy-caption": "Privacy Policy",
  // "menu-privacy-link": "https://www.consumerreports.org/cro/customerservice/privacy-policy/highlights/index.htm",
  // "menu-agreement-caption": "User Agreement",
  // "menu-agreement-link": "https://www.consumerreports.org/cro/2015/01/user-agreement/index.htm",
  // "menu-feedback-link": "feedback",
  // "footer-copyright": "© 2020 Consumer Reports, Inc.",
  // "about-us-title": undefined,
  // "about-us-description-header": "About Us",
  // "about-us-description-body": "CR Security Planner™ is a free, easy-to-use guide to staying safer online. It provides personalized recommendations and expert advice on topics such as keeping social media accounts from being hacked, locking down devices such as smartphones and home security cameras, and reducing intrusive tracking by websites.  \n&nbsp;  \nIt’s offered to you by Consumer Reports — an independent, nonprofit member organization that works with consumers to create more fairness, safety, and transparency in the marketplace. We don’t run ads, and no company will ever exercise influence over the products or services we recommend.",
  // "about-us-description-icons": "[![Not for Profit](//images.contentful.com/dyix407qb7va/6FFOHTQwqwnDoqNJ2qzlvD/0981ae132204174be7d962af552b8a73/Not-for-Profit.svg)](#) [![Consumer-Driven](//images.contentful.com/dyix407qb7va/1JVAd8IKwmXEE8BExOQB8e/94eaf1b9d6a6e50268795afd2db66921/Consumer-Driven.svg)](#) [![Independent](//images.contentful.com/dyix407qb7va/3LSDeGz154PTYZFE0PeZJ5/4fa01bd5f3065d624d6f1a286f62520e/Independent.svg)](#)",
  // "about-us-philosophy-body": "Some of the most intimate aspects of our lives unfold through devices and online services. However, there are big questions about whether the devices and services we use respect our privacy, and whether they adequately safeguard our information. It is easy to feel overwhelmed by the challenge of being safer online.  \n&nbsp;  \nCR Security Planner™ is intended to provide accessible, personalized expert-reviewed advice for average users to implement quickly and easily. Human rights activists, journalists and other people who face special risks  can build on this baseline by reaching out to other organizations for more personally tailored information and assistance. Anyone facing an urgent security issue can consult the resources listed on our Emergency Resources page.  \n&nbsp;  \nYou don’t need to create a CR membership to use CR Security Planner™, and we don’t retain any personally identifiable information without your consent. We do collect site analytics to understand how users in aggregate interact with the CR Security Planner. If you opt to sign up for a free CR membership, we’ll ask for your email address, and your use of the site will be governed by CR’s User Agreement.  \n&nbsp;  \nYou can use this content for educational purposes — see CR’s content license. Legacy versions of the guide are stored onGithub.",
  // "about-feedback-title": "Feedback",
  // "about-feedback-text": "We welcome your thoughts! [Give us your feedback here](#/feedback \"Feedback Page\").",
  // "about-acknowledgements-title": "Thanks & Acknowledgements",
  // "about-acknowledgements-text": "Security Planner first launched in December 2017. Many digital security and privacy experts provided feedback and ideas. [Read more](show-acknowledgements \"Acknowledgements\")",
  // "about-acknowledgements-lightbox": "## Thanks & Acknowledgements  \n&nbsp;  \n Security Planner has been a collaborative project of the consumer privacy and security expert community since 2015. Many people have reviewed content and provided feedback and ideas. [Read about transition of SP from Citizen Lab to CR.](/# \"Article\")  \n&nbsp;  \nIncubated Jigsaw; run by Matt Braga and Citizen Lab  \n&nbsp;  \nThe project received support from many organizations including Facebook, the Cyberlaw Clinic at Harvard University’s Berkman Klein Center for Internet & Society, and Contentful.  \n&nbsp;  \nWe would like to give special thanks to Adam Hammer, Alexandra Ellerbeck, Alfred Malmros, Alison Macrina, Amira Dhalla, Andres Arrieta, Aurelia Moser, Ben Lynch, Ben Schwartz, Betsy Cooper, Bo Wandschneider, Brian Ivie, Bruce Campbell, Cade Diehm, Carlos Velez, Chris Hartgerink, Chris Palmer, Chris Snijder, Christine Schoellhorn, Dan Keyserling, David Huerta, Dmitri Vitaliev, Ed Geraghty, Ellery Biddle, Eva Galperin, Geoffrey MacDougall, Gillo Cutrupi, Jared Cohen, John Scott-Railton, Jon Camfield, Jen Weedon, Jonathon Hodge, Justin Henck, Karl Kathuria, Katie McInnis, Lindsay Beck, Lydia Lichlyter, Meagan Kruman, Melissa Romaine, Menso Heus, the Mozilla Foundation and the Mozilla Fellows Program, Nick Dagostino, Matt Mitchell, Nathalie Marechal, Oleg Bell, Orlando Del Aguila, Rebekah Woolston, Rena Tangens, Ruhiya Seward, Runa Sandvik, Russ McRee, Ryan Canney, Sarah Guerrero, Sacha van Geffen, Sam Burton, Sara Haghdoosti, Scott Carpenter, Sean Brooks, Sean Colyer, Seny Kamara, Stuart Schechter, Tom Lowenthal, Vanessa Rhinesmith, Varun Anand, Vernon Silver, Vivek Krishnamurthy, Yasmin Green, Wojtek Bogusz, as well as the entire Citizen Lab team, especially Adam Hulcoop, Andrew Hilts, Bram Abramson, Bahr Abdul Razzak, Carmen Cheung, Etienne Maynier, Irene Poetranto, Ivy Lo, Jakub Dalek, Lex Gill, Mari Zhou, Masashi Nishihata, Miles Kenyon, and Simon Humbert.",

  // "action-plan-share-url": "[[domain]]/action-plan/[[hash]]",
  // "common-friendly-url": "[[domain]]",
  // "cover-button-plan-caption": "My Action Plan",

  "action-plan-footer-retake-title": "Looking to start over?",
  "action-plan-footer-retake-button": "Start Over",
  "common-language-menu-loading": "Loading languages...",
  "branding-consumer-reports-logo": "Consumer Reports Logo",
  "branding-security-planner-logo": "Security Planner Logo",
  "menu-close": "Close menu",
  "menu-open": "Open menu",
  "menu-skip-link": "Skip to main content",
  "locator-go-back": "Go back",
  "locator-go-statements": "Go to Statements page",
  // "join-field-first-name": "First Name",
  // "join-field-last-name": "Last Name",
  // "join-field-email": "Email",
  "join-field-email-error": "This is not a valid email address",
  "join-error-message-unexpected": "Unexpected error during registration.",
  "join-error-message-invalid-email": "Invalid Email",
  "join-error-message-invalid-first": "Must provide first name",
  "join-error-message-invalid-last": "Must provide last name",
  "join-error-message-no-error": "No error",
  "join-disclaimer": "By clicking \"Join\" you accept the terms of our [User Agreement](https://www.consumerreports.org/cro/2015/01/user-agreement/index.htm) and [Privacy Policy](https://www.consumerreports.org/cro/2015/01/user-agreement/index.htm).",
  // "join-member-already": "Already a member?",
  // "join-member-sign-in": "Sign In",
  "threat-menu-overlay-label": "Threats overlay",
  "cover-image-label": "Cover Image",
  // "tool-label-completed": "Completed",

  "common-home-language-select": "Change Language",
};

/**
 * <pre>
 * Defines the strings used by SecurityPlanner. 
 * Package Name - stores
 * </pre> 
 * @namespace stores.ContentfulUpdates
 */
export default {
  strings,
};
