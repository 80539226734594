import PropTypes from "prop-types";
import React from "react";

/**
 * <pre>
 * Class for images in the application.
 * Package Name - components/common
 * </pre>
 * @class components.common.ImageContainer
 */
class ImageContainer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    src: PropTypes.string.isRequired,
    description: PropTypes.string,
    backgroundPosition: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClick: PropTypes.func,
  };

  /**
   * Renders the style of the image container.
   * @function getStyle
   * @memberof components.common.ImageContainer
   * @instance
   */
  getStyle = () => {
    return Object.assign({}, this.props.style || {}, {
      backgroundRepeat: "no-repeat",
      backgroundPosition: this.props.backgroundPosition ?? "center",
      backgroundImage: "url(" + this.props.src + ")",
    });
  };

  /**
   * Renders the image object in the application.
   * @function render
   * @memberof components.common.ImageContainer
   * @instance
   */
  render() {
    return (
      <div
        onClick={this.props.onClick}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        className={"common-image-container " + (this.props.className || "")}
        style={this.getStyle()}
        title={this.props.description ? this.props.description : ""}
        data-testid={this.props.dataTestId || null}
      />
    );
  }
}

export default ImageContainer;
