import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import * as marked from "marked";

import PageSectionHelper from "./../../../vendor/utils/PageSectionHelper";
import DirectionUtils from "./../../../vendor/utils/DirectionUtils";

import SecondaryMenu from "../../common/SecondaryMenu.react";
import FooterMenu from "../../common/FooterMenu.react";

import MarkdownAnchorLinkReplacer from "./../../../vendor/utils/MarkdownAnchorLinkReplacer";

/**
 * <pre>
 * Class to generate a philosophy page for the Application.
 * Package Name - components/pages/philosophy
 * </pre>
 * @class components.pages.nomatch.PhilosophyPage
 */
class PhilosophyPage extends React.Component {
  static propTypes = {
    stringList: PropTypes.object, // StringList
    selectedLanguage: PropTypes.object, // Language
    availableLanguages: PropTypes.arrayOf(PropTypes.object), // Language[]
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    tools: PropTypes.array, // Tool[]
    goToPage: PropTypes.func.isRequired,
    checkPage: PropTypes.func.isRequired,
    hasPlan: PropTypes.func.isRequired,
  };

  helper = undefined;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.helper = new PageSectionHelper(this);
    this.markdownAnchorLinkReplacer = new MarkdownAnchorLinkReplacer(this.props.goToPage, this.props.checkPage);
  }

  componentDidMount() {
    this.helper.setElement(ReactDOM.findDOMNode(this).querySelector(".content"));
    this.markdownAnchorLinkReplacer.replaceDOMLinksWithRoutes(this.description);
  }

  componentDidUpdate() {
    this.helper.setElement(ReactDOM.findDOMNode(this).querySelector(".content"));
  }

  componentWillUnmount() {
    this.helper.destroy();
  }

  /**
   * Renders the philosophy page object.
   * @function render
   * @memberof components.pages.nomatch.PhilosophyPage
   * @instance
   */
  render() {
    return (
      <div
        className={"sectionPageHolder page page-philosophy " + DirectionUtils.getClass(this.props.stringList)}
        aria-hidden={!this.helper.isActive()}
        style={{ height: this.helper.getWindowHeight() }}
      >
        <div className="content">
          <SecondaryMenu
            stringList={this.props.stringList}
            currentLocation={SecondaryMenu.LOCATION_PHILOSOPHY}
            availableLanguages={this.props.availableLanguages}
            selectedLanguage={this.props.selectedLanguage}
            routes={this.props.routes}
            hasPlan={this.props.hasPlan}
            goToPage={this.props.goToPage}
            allowFocus={this.helper.isActive()}
            tools={this.props.tools}
            useLightStyle={true}
            className="secondary-menu"
          />
          <div className="page-header">
            <div className="headline-container">
              <h2 className="headline">{this.props.stringList.get("about-us-philosophy-title")}</h2>
            </div>
          </div>
          <div className="middle" id={SecondaryMenu.LOCATION_PHILOSOPHY}>
            <div
              className="philosophy-description page-content"
              ref={(c) => (this.description = { elConstructor: c })}
              dangerouslySetInnerHTML={{ __html: marked.parse(this.props.stringList.get("about-us-philosophy-body") || "") }}
            />
          </div>
          <FooterMenu
            stringList={this.props.stringList}
            currentLocation={SecondaryMenu.LOCATION_ABOUT}
            style={FooterMenu.STYLE_LIGHT}
            routes={this.props.routes}
            goToPage={this.props.goToPage}
            allowFocus={this.helper.isActive()}
            selectedLanguage={this.props.selectedLanguage}
            availableLanguages={this.props.availableLanguages}
          />
        </div>
      </div>
    );
  }

  /**
   * Ran when the section becomes the focused section
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	   
   * @param  {object} fromOverlay	        
   * @function onActivate
   * @memberof components.pages.nomatch.PhilosophyPage
   * @instance        
   */
  onActivate = (travelOffset, viaHistoryAPI, fromOverlay) => {
    this.helper.onActivate(travelOffset, viaHistoryAPI, fromOverlay);
  };

  /**
   * Ran when the section is about to lose focus
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	   
   * @param  {object} fromOverlay	        
   * @function onDeactivate
   * @memberof components.pages.nomatch.PhilosophyPage
   * @instance     
   */
  onDeactivate = (travelOffset, viaHistoryAPI, toOverlay) => {
    this.helper.onDeactivate(travelOffset, viaHistoryAPI, toOverlay);
  };

  /**
   * Returns the color (as a number) that the locator bar should have when opaque
   * @function getDesiredLocatorBackgroundColor
   * @memberof components.pages.nomatch.PhilosophyPage
   * @instance   
   */
  getDesiredLocatorBackgroundColor = () => {
    return undefined;
  };
}

export default PhilosophyPage;
