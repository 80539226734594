import MiniRouter from "./../vendor/routing/MiniRouter";

/**
 * <pre>
 * Class for all routes used by SecurityPlanner.
 * Uses routeprefix to set the routes as of CM-5680. 
 * Package Name - routing  
 * </pre>
 * @class routing.SecurityPlannerRoutes
 */
export default class SecurityPlannerRoutes {
  /*
	All routes used by the app
	*/

  // ================================================================================================================
  // CONSTRUCTOR ---------------------------------------------------------------------------------------------------- 
  /**
   * Creates the routeprefix for language based on language code. 
   * Used For CM-5680. 
   * @type {String} 
   * @memberof routing.SecurityPlannerRoutes
   * @instance
   */
  routeprefix = undefined; 

  /**
   * Creates An Object of SecurityPlannerRoutes. 
   * @constructor    
   */  
  constructor() {
    if(localStorage.getItem("preferredLanguage") == "es-US") { 
      this.routeprefix = '/es/';
    }else { this.routeprefix = '/'; }    
  }

  // ================================================================================================================
  // PUBLIC INTERFACE -----------------------------------------------------------------------------------------------
  /**
   * Returns the uri of the CoverPage
   * @function getUriCover 
   * @memberof routing.SecurityPlannerRoutes
   * @instance
   */
  getUriCover() {
    return this.routeprefix + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the StatementsPage
   * @param  {number} levelIndex  index of the statements  
   * @function getUriStatements 
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriStatements(levelIndex) {
    return this.routeprefix + "statements/" + (isNaN(levelIndex) ? MiniRouter.PARAMETER_BRACKET_START + "id" + MiniRouter.PARAMETER_BRACKET_END : levelIndex + 1) + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the InterstitialPage
   * @param  {number} levelIndex  index of the interstitial  
   * @function getUriInterstitial 
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriInterstitial(levelIndex) {
    return this.routeprefix + "interstitial/" + (isNaN(levelIndex) ? MiniRouter.PARAMETER_BRACKET_START + "id" + MiniRouter.PARAMETER_BRACKET_END : levelIndex + 1) + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the Report/Action-Plan 
   * @function getUriReport
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriReport() {
    return this.routeprefix + "action-plan" + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the Report/Action-Plan Using Hash For the plan 
   * @param  {String} stateHash  hash of the plan    
   * @function getUriReportWithHash
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriReportWithHash(stateHash) {
    return this.routeprefix + "action-plan/" + (stateHash == undefined ? MiniRouter.PARAMETER_BRACKET_START + "hash" + MiniRouter.PARAMETER_BRACKET_END : stateHash) + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the Print Report/Action-Plan Using Hash For the plan 
   * @param  {String} stateHash  hash of the plan    
   * @function getUriPrintReport
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriPrintReport(stateHash) {
    return (
      this.routeprefix + "action-plan-print/" + (stateHash == undefined ? MiniRouter.PARAMETER_BRACKET_START + "hash" + MiniRouter.PARAMETER_BRACKET_END : stateHash) + ( (location.search) ? location.search : '' )
    );
  }

  /**
   * Returns the uri of the all Tools/Recommendations Page 
   * @function getUriAllTools
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriAllTools() {
    return this.routeprefix + "recommendations" + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the Tools/Recommendations Page using a slug 
   * @param  {String} threatSlug  slug of the tool     
   * @function getUriAllToolsThreat
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriAllToolsThreat(threatSlug) {
    return (
      this.routeprefix + "recommendations/" +
      (threatSlug == undefined ? MiniRouter.PARAMETER_BRACKET_START + "threatSlug" + MiniRouter.PARAMETER_BRACKET_END : threatSlug) + ( (location.search) ? location.search : '' )
    );
  }

  /**
   * Returns the uri of the About Page 
   * @function getUriAbout
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriAbout() {
    return this.routeprefix + "our-mission" + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the Spanish Landing Page 
   * @function getUriSpanishLanding
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriSpanishLanding() {
    return this.routeprefix + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the Philosophy Page 
   * @function getUriPhilosophy
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriPhilosophy() {
    return this.routeprefix + "philosophy" + ( (location.search) ? location.search : '' );
  }

    /**
   * Returns the uri of the Ask Expert Page 
   * @function getUriAskExpert
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
    getUriAskExpert() {
      return this.routeprefix + "expert" + ( (location.search) ? location.search : '' );
    }

  /**
   * Returns the uri of the Terms And Policies Page 
   * @function getUriTerms
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriTerms() {
    return this.routeprefix + "terms-policies" + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the Print Terms Page 
   * @function getUriPrintTerms
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriPrintTerms() {
    return this.routeprefix + "terms-print" + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the Feedback Page 
   * @function getUriFeedback
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriFeedback() {
    return this.routeprefix + "feedback" + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the Share Overlay Page 
   * @function getUriOverlayShare
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriOverlayShare() {
    return this.routeprefix + "share" + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the Emergency Tool Page 
   * @function getUriEmergencyTool
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriEmergencyTool() {
    return this.routeprefix + "tool/emergency-resources" + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the ToolOverlay Page Using Slug 
   * @param  {String} toolSlug  slug of the tool     
   * @function getUriOverlayTool
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriOverlayTool(toolSlug) {
    return this.routeprefix + "tool/" + (toolSlug == undefined ? MiniRouter.PARAMETER_BRACKET_START + "toolSlug" + MiniRouter.PARAMETER_BRACKET_END : toolSlug) + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the BioOverlay Page Using Slug 
   * @param  {String} bioSlug slug of the bio     
   * @function getUriOverlayBio
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriOverlayBio(bioSlug) {
    return this.routeprefix + "our-mission/bio/" + (bioSlug == undefined ? MiniRouter.PARAMETER_BRACKET_START + "bioSlug" + MiniRouter.PARAMETER_BRACKET_END : bioSlug) + ( (location.search) ? location.search : '' );
  }

  /**
   * Returns the uri of the ToolOverlay Feedback Page Using Slug 
   * @param  {String} toolslug slug of the tool     
   * @function getUriOverlayToolFeedback
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriOverlayToolFeedback(toolSlug) {
    return (
      this.routeprefix + "tool/" + (toolSlug == undefined ? MiniRouter.PARAMETER_BRACKET_START + "toolSlug" + MiniRouter.PARAMETER_BRACKET_END : toolSlug) + "/feedback" + ( (location.search) ? location.search : '' )
    );
  }

  /**
   * Returns the uri of the ThreatMenuOverlay Page Using Id
   * @param  {String} transportId  id of the threat     
   * @function getUriOverlayThreatMenu
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriOverlayThreatMenu(transportId) {
    return (
      this.routeprefix + "threat-menu/" +
      (transportId == undefined ? MiniRouter.PARAMETER_BRACKET_START + "transportId" + MiniRouter.PARAMETER_BRACKET_END : transportId) + ( (location.search) ? location.search : '' )
    );
  }

  /**
   * Returns the uri of the ThreatMenuOverlay Filter Using Id
   * @param  {String} transportId  id of the threat     
   * @function getUriOverlayToolsFilter
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriOverlayToolsFilter(transportId) {
    return (
      this.routeprefix + "tools-filter/" +
      (transportId == undefined ? MiniRouter.PARAMETER_BRACKET_START + "transportId" + MiniRouter.PARAMETER_BRACKET_END : transportId) + ( (location.search) ? location.search : '' )
    );
  }

  /**
   * Returns the uri of the Preview Using restId
   * @param  {String} rest  id of the preview     
   * @function getUriPreview
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriPreview(rest) {
    return (
      this.routeprefix + "preview/" +
      (rest == undefined ? MiniRouter.PARAMETER_BRACKET_START + MiniRouter.PARAMETER_REST_START + "rest" + MiniRouter.PARAMETER_BRACKET_END : rest) + ( (location.search) ? location.search : '' )
    );
  }

  /**
   * Returns the uri of the Preview Using restId 
   * @function getUriNoMatch
   * @memberof routing.SecurityPlannerRoutes
   * @instance   
   */  
  getUriNoMatch() {
    return this.routeprefix + "404" + ( (location.search) ? location.search : '' );
  }
}
