import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

import ActionButton from "./../../common/ActionButton.react";

/**
 * <pre>
 * Class to create the Coverpage body in the Application.
 * Package Name - components/pages/cover
 * </pre>
 * @class components.pages.cover.Body
 */
class Body extends React.Component {
  static propTypes = {
    onClickRetake: PropTypes.func.isRequired,
    onClickPlan: PropTypes.func.isRequired,
    lastModified: PropTypes.object,
    stringList: PropTypes.object, // StringList
    allowFocus: PropTypes.bool,
    hasPlan: PropTypes.func.isRequired,
  };

  /**
   * Renders the body section object.
   * @function render
   * @memberof components.pages.cover.Body
   * @instance
   */
  render() {
    const hasPlan = !!this.props.hasPlan();

    return (
      <div className={cx("body")}>
        <h1 className="title">{this.props.stringList.get("cover-title")}</h1>
        <div className={cx("body-img-container", window.outerHeight < 700 ? 'short-vp' : '' )}>
          <object
            className="common-image-container body-img"
            aria-label={this.props.stringList.get("cover-image-label")} id="svg-object"
            data={require("./../../../../images/page-cover/group-mobile.svg")}
            type="image/svg+xml" />
            <a href="#subtitle" className="mobile-scroll-down">
              <div className="icon"></div>
            </a>
        </div>
        <div className="subtitle" id="subtitle">
          {this.props.stringList.get("cover-subtitle")}
          {this.props.stringList.getList("cover-subtitle-bullets")}
        </div>
        <div className="button-column">
          <div className="button-wrapper">
            <ActionButton
              key="button"
              className="button rounded cover"
              id="main-cta"
              allowFocus={this.props.allowFocus}
              title={hasPlan ? this.props.stringList.get("cover-button-plan-caption") : this.props.stringList.get("cover-button-go-caption")}
              onClick={hasPlan ? this.props.onClickPlan : this.props.onClickRetake}
            >
              {hasPlan ? this.props.stringList.get("cover-button-plan-caption") : this.props.stringList.get("cover-button-go-caption")}
            </ActionButton>
            {hasPlan ? <div className="retake-wrapper">
              <div className="retake" onClick={this.props.onClickRetake}>{this.props.stringList.get("ui-startover-caption")}</div>
            </div> : null}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Gets the formatted date of the body object on coverpage.
   * @function getFormattedDate
   * @memberof components.pages.cover.Body
   * @instance
   */
  getFormattedDate = () => {
    // Use hardcoded date formatting because browsers are too inconsistent (namely Safari doesn't support "short" and "narrow" months)
    const day = this.props.lastModified || new Date();
    const template = this.props.stringList.get("common-formatting-datetime-dmy");
    const months = this.props.stringList.getArray("common-formatting-datetime-months-short");
    return (
      template
        .replace("d", day.getDate())
        .replace("y", day.getFullYear())
        // We have to replace `m` last, because `m` may insert a `y` (think `May`), which causes previous replacement to be borked.
        .replace("m", months[day.getMonth()])
    );
  };
}

export default Body;
