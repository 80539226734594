import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import * as marked from "marked";

import PageSectionHelper from "./../../../vendor/utils/PageSectionHelper";
import DirectionUtils from "./../../../vendor/utils/DirectionUtils";
import MiniTracker from "./../../../vendor/tracking/MiniTracker";

import Lightbox from "./Lightbox.react";
import ImageContainer from "./../../common/ImageContainer.react";
import SecondaryMenu from "../../common/SecondaryMenu.react";

import MarkdownAnchorLinkReplacer from "./../../../vendor/utils/MarkdownAnchorLinkReplacer";

/**
 * <pre>
 * Class to generate the about page for the Application.
 * Package Name - components/pages/about
 * </pre>
 * @class components.pages.about.AboutPage
 */
class AboutPage extends React.Component {
  static propTypes = {
    bios: PropTypes.array, // List of Bio objects
    stringList: PropTypes.object, // StringList
    selectedLanguage: PropTypes.object, // Language
    availableLanguages: PropTypes.arrayOf(PropTypes.object), // Language[]
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    tools: PropTypes.array, // Tool[]
    goToPage: PropTypes.func.isRequired,
    hasPlan: PropTypes.func.isRequired,
    checkPage: PropTypes.func.isRequired,
  };

  state = {
    isLightboxOpen: false,
  }

  helper = undefined;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.helper = new PageSectionHelper(this);
    this.markdownAnchorLinkReplacer = new MarkdownAnchorLinkReplacer(this.props.goToPage, this.props.checkPage);
  }

  componentDidMount() {
    /*
     * Define a list of intra-app links that are found in the parsed Markdown content for the terms
     * Associate those links with navigational function calls that the link behaviour will be replaced with.
     */
    const linkReplacements = [
      {
        href: "show-acknowledgements",
        replacementFunction: () => { this.setState({ isLightboxOpen: true }); },
      },
    ];

    this.markdownAnchorLinkReplacer.replaceDOMLinksWithRoutes(this.feedback);
    this.markdownAnchorLinkReplacer.replaceDOMLinksWithRoutes(this.acknowledgements, linkReplacements);
    this.helper.setComponent(this.refs.scroller);

  }

  componentDidUpdate() {
    this.helper.setElement(ReactDOM.findDOMNode(this).querySelector(".content"));
  }

  componentWillUnmount() {
    this.helper.destroy();
  }

  /**
   * Renders the about page object.
   * @function render
   * @memberof components.pages.about.AboutPage
   * @instance
   */
  render() {
    return (
      <div
        className={"sectionPageHolder page pageAbout " + DirectionUtils.getClass(this.props.stringList)}
        aria-hidden={!this.helper.isActive()}
        style={{ height: this.helper.getWindowHeight() }}
      >
        <div className="content">
          <SecondaryMenu
            stringList={this.props.stringList}
            currentLocation={SecondaryMenu.LOCATION_ABOUT}
            availableLanguages={this.props.availableLanguages}
            selectedLanguage={this.props.selectedLanguage}
            routes={this.props.routes}
            hasPlan={this.props.hasPlan}
            goToPage={this.props.goToPage}
            allowFocus={this.helper.isActive()}
            tools={this.props.tools}
            useLightStyle={true}
            className="secondary-menu"
          />
          <div className="middle middle-body" id={SecondaryMenu.LOCATION_ABOUT}>
            <div className="section-description">
              <h3 className="section-description-header">{this.props.stringList.get("about-us-description-header")}</h3>
              <div className="section-description-body page-content">
                <div dangerouslySetInnerHTML={{ __html: marked.parse(this.props.stringList.get("about-us-description-body") || "") }} />
                {/* <div className="icons">{this.renderIcons("about-us-description-icons")}</div> */}
                  <div className="icons">
                    <div className="icons-container">
                      <p>{this.props.stringList.get("about-img-1-title")}</p>
                      <p>{this.props.stringList.get("about-img-1-text")}</p>

                      <img class="icons-icon" src={require("./../../../../images/page-about/ICO-Nonprofit.svg")} alt=""/>
                    </div>
                    <div className="icons-container">
                      <p>{this.props.stringList.get("about-img-2-title")}</p>
                      <p>{this.props.stringList.get("about-img-2-text")}</p>
                      <img class="icons-icon" src={require("./../../../../images/page-about/ICO-Consumer-Driven.svg")} alt=""/>
                    </div>
                    <div className="icons-container">
                      <p>{this.props.stringList.get("about-img-3-title")}</p>
                      <p>{this.props.stringList.get("about-img-3-text")}</p>
                      <img class="icons-icon" src={require("./../../../../images/page-about/ICO-Independent.svg")} alt=""/>
                    </div>
                </div>
              </div>
            </div>
            <div className="section-description">
              <h3 className="section-description-header">{this.props.stringList.get("about-us-philosophy-title")}</h3>
              <div className="section-description-body page-content">
                <div dangerouslySetInnerHTML={{ __html: marked.parse(this.props.stringList.get("about-us-philosophy-body") || "") }} />
              </div>
            </div>
          </div>
          <div className="middle-wrapper-dark">
            <div className="middle middle-footer">
              <div className="section-description">
                <h3 className="section-description-header">{this.props.stringList.get("about-feedback-title")}</h3>
                <div className="section-description-body"
                  ref={c => this.feedback = { elConstructor: c }}
                  dangerouslySetInnerHTML={{ __html: marked.parse(this.props.stringList.get("about-feedback-text") || "") }}
                />
              </div>
              <div className="section-description">
                <h3 className="section-description-header">{this.props.stringList.get("about-acknowledgements-title")}</h3>
                <div className="section-description-body"
                  ref={c => this.acknowledgements = { elConstructor: c }}
                  dangerouslySetInnerHTML={{ __html: marked.parse(this.props.stringList.get("about-acknowledgements-text") || "") }} />
                <Lightbox
                  isActive={this.state.isLightboxOpen}
                  onClose={() => { this.setState({ isLightboxOpen: false }); }}
                  stringList={this.props.stringList} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders the icons on about page.
   * @param  {string} key   
   * @function renderIcons
   * @memberof components.pages.about.AboutPage
   * @instance
   */
  renderIcons = (key) => {
    const iconAssets = this.props.stringList.getAssetArray(key);
    return iconAssets.map((asset, index) => {
      return (
        <a key={index} href={asset.link}
          target="_blank" tabIndex={this.helper.isActive() ? 0 : -1}
          name={asset.title} role="link"
          className="link">
          <ImageContainer className="icon" description={asset.title}
            backgroundPosition="center top" src={asset.src} />
        </a>
      );
    });
  };

  /**
   * Ran when the section becomes the focused section
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	  
   * @param  {object} fromOverlay               
   * @function onActivate
   * @memberof components.pages.about.AboutPage
   * @instance     
   */
  onActivate = (travelOffset, viaHistoryAPI, fromOverlay) => {
    this.helper.onActivate(travelOffset, viaHistoryAPI, fromOverlay);
  };

  /**
   * Ran when the section is about to lose focus 
   * @param  {number} travelOffset 
   * @param  {object} viaHistoryAPI	  
   * @param  {object} toOverlay               
   * @function onDeactivate
   * @memberof components.pages.about.AboutPage
   * @instance        
   */
  onDeactivate = (travelOffset, viaHistoryAPI, toOverlay) => {
    this.helper.onDeactivate(travelOffset, viaHistoryAPI, toOverlay);
  };

  /**
   * Returns the color (as a number) that the locator bar should have when opaque       
   * @function getDesiredLocatorBackgroundColor
   * @memberof components.pages.about.AboutPage
   * @instance           
   */
  getDesiredLocatorBackgroundColor = () => {
    return undefined;
  };
}

export default AboutPage;
