import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

import ADAUtils from "../../vendor/utils/ADAUtils";
import MiniTracker from "../../vendor/tracking/MiniTracker";
import DirectionUtils from "./../../vendor/utils/DirectionUtils";

import LanguageToggle from "./LanguageToggle.react";

/**
 * <pre>
 * Class For Creating Footer Menu in the Application.
 * Package Name - components/common
 * </pre>
 * @class components.common.FooterMenu
 */
class FooterMenu extends React.Component {
  static propTypes = {
    currentLocation: PropTypes.string,
    stringList: PropTypes.object, // StringList
    style: PropTypes.string,
    routes: PropTypes.object.isRequired, // SecurityPlannerRoutes
    goToPage: PropTypes.func.isRequired,
    allowFocus: PropTypes.bool,
    selectedLanguage: PropTypes.object, // Language
    availableLanguages: PropTypes.arrayOf(PropTypes.object), // Language[]
  };

  componentDidMount() { 
     let footerEle = document.querySelector('.cr-footer--outer-wrapper');  
     var allFooters = document.getElementsByClassName("cr-footer--outer-wrapper");
      for (var i = 0; i < allFooters.length; i++) {
        allFooters.item(i).style.display="block";
      }
     footerEle.style.display="block";
  }

  /**
   * Renders the footer menu object.
   * @function render
   * @memberof components.common.FooterMenu
   * @instance
   */  
  render() {
    const commonMenuClasses = cx("common-footer-menu", "common-footer-menu--" + this.props.style, DirectionUtils.getClass(this.props.stringList), { "common-footer-menu--banner" : this.props.isBannerVisible }); 
    let domparser = new DOMParser();
  	// let footerdoc = domparser.parseFromString(this.props.cdaFooter, 'text/html'); This was the old footer which came from a prop

    // Get footer text strings and links from contentful
    let footerPrivacyPolicy = this.props.stringList.get("footer-privacy-policy");
    let footerUserAgr = this.props.stringList.get("footer-user-agreement");
    let footerAdChoice = this.props.stringList.get("footer-ad-choice");
    let footerPersonal = this.props.stringList.get("footer-personal-info");
    let footerFeedback = this.props.stringList.get("footer-feedback");
    let footerFeedbackLink = this.props.stringList.get("footer-feedback-link");
    let footerCopyright = this.props.stringList.get("footer-btm-copyright");

    let footerPrivacyPolicyLink = this.props.stringList.get("footer-privacy-policy-link");
    let footerUserAgrLink = this.props.stringList.get("footer-user-agreement-link");

    let footerHTMl = `<div id="global-footer"> 
      <footer id="cr-footer" class="cr-footer--outer-wrapper">  
        <div class="cr-footer--wrapper crux-container cr-footer--wrapper_slim"> 
          <div class="cr-footer--bottom"> <div class="footer-links crux-white"> 
            <a href="`+ footerPrivacyPolicyLink +`" class="crux-body-copy crux-body-copy--small" target="_blank" >`+ footerPrivacyPolicy +`</a> 
            <a href="`+ footerUserAgrLink +`" class="crux-body-copy crux-body-copy--small" target="_blank" >`+ footerUserAgr +`</a> <a href="javascript:{}" class="crux-body-copy crux-body-copy--small TRUSTeWidget_Tab_link">`+ footerAdChoice +`</a> <br class="d-md-none" /> 
            <a href="javascript:{}" onclick="OneTrust.ToggleInfoDisplay()" id="dontSellMyPi" class="crux-body-copy crux-body-copy--small crux-white" >`+ footerPersonal +`</a> 
            <a href="`+ footerFeedbackLink +`" class="crux-body-copy crux-body-copy--small crux-white" >`+ footerFeedback +`</a> 
          </div> 
          <div class="cr-footer__copyright crux-body-copy crux-white">`+ footerCopyright +`</div> 
          </div> 
        </div>  
      </footer> 
    </div> ` 

    // Finally, return the elements
    return (
      	<div className="pagefooter" dangerouslySetInnerHTML={{__html: footerHTMl}}></div> 
    ); 
        
    /*return (
      <div className={commonMenuClasses}>
        <div className="container">
          <nav className="navigation" role="menu">
            <div className="group">
              {this.renderNavigationItem("menu-privacy", this.openTab("menu-privacy-link"))}
              {this.renderNavigationItem("menu-agreement", this.openTab("menu-agreement-link"))}
              {this.renderNavigationItem("menu-feedback", (e) => {
                e.preventDefault();
                this.props.goToPage(this.props.routes.getUriFeedback(), false, true);
              }, true)}
              <LanguageToggle
                stringList={this.props.stringList}
                selectedLanguage={this.props.selectedLanguage}
                availableLanguages={this.props.availableLanguages}
              />
            </div>
          </nav>
          <div className="copyright">
            {this.props.stringList.get("footer-copyright")}
          </div>
        </div>
      </div>
    );*/
  }

  /**
   * Renders the footer menu navigation item.
   * @param  {number} captionId  
   * @param  {string} func   
   * @param  {boolean} lastItem    
   * @function renderNavigationItem
   * @memberof components.common.FooterMenu
   * @instance
   */  
  renderNavigationItem = (captionId, func, lastItem = false) => {
    return (
      <div className={lastItem ? 'last-item item' : ' 2 item'}>
        <a
          href={this.props.stringList.get(captionId + "-link")}
          target="_blank"
          rel="noreferrer"
          tabIndex={0}
          role="menuitem"
          onKeyDown={ADAUtils.handleKeyboard(func)}
          onClick={func}
        >
          {this.props.stringList.get(captionId + "-caption")}
        </a>
      </div>
    );
  };

  /**
   * Renders the open tab of the navigation menu.
   * @param  {number} captionId  
   * @function openTab
   * @memberof components.common.FooterMenu
   * @instance
   */  
  openTab = (captionId) => (e) => {
    e.preventDefault();
    window.open(this.props.stringList.get(captionId), "_blank");
  };
}

/**
 * STYLE_LIGHT
 * A static member of FooterMenu
 * @type {string} 
 * @default light
 * @memberof components.common.FooterMenu
 */
FooterMenu.STYLE_LIGHT = "light";

/**
 * STYLE_LIGHT_GREY
 * A static member of FooterMenu
 * @type {string} 
 * @default lightgrey
 * @memberof components.common.FooterMenu
 */
FooterMenu.STYLE_LIGHT_GREY = "lightgrey"; 

/**
 * STYLE_DARK
 * A static member of FooterMenu
 * @type {string} 
 * @default dark
 * @memberof components.common.FooterMenu
 */
FooterMenu.STYLE_DARK = "dark";

export default FooterMenu;
