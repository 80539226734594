export default (trackingId) => {
  // eslint-disable-next-line camelcase
  if (typeof window.s_gi !== 'undefined' && trackingId && s_gi && s_account) {
    const s = s_gi(s_account);
    s.linkTrackVars = "prop48,eVar48,prop58,eVar58,prop72,eVar72,events";
    s.linkTrackEvents = s.events = "event77";
    s.prop48 = s.eVar48 = s.pageName + " | " + trackingId;
    s.prop58 = s.eVar58 = "[ website version ]";
    s.prop72 = s.eVar72 = "[ page template version ]";
    s.tl(this, "o", trackingId);
  }
};

