/**
 * <pre>
 * Class for a Threat component in store from retrieved data.
 * Package - stores/data. 
 * </pre>
 * @class stores.data.Threat
 */
export default class Threat {
  // ================================================================================================================
  // CONSTRUCTOR ----------------------------------------------------------------------------------------------------

  /**
   * Creates An Object of Threat Component. 
   * @constructor    
   */    
  constructor() {
    this.id = "";
    this.slug = ""; // Friendly slug, for reporting
    this.name = "";
    this.shortDescription = ""; // "Defend your device."
    this.longDescription = ""; // "Your top priority should be to depend your device."
    this.stats = "";
    this.statsSource = "";
    this.statsName = "";
    this.isAdditionalHelp = undefined; // This is the "additional help" threat category
    this.translationOutdated = false;
    this.deprioritizeInLists = false;
  }
}
