import PropTypes from "prop-types";
import React from "react";
import ClickTouchHandler from "./../../vendor/utils/ClickTouchHandler.js";
import cx from "classnames";

/**
 * <pre>
 * Creates An Action Button for the Application. 
 * Package Name - components/common
 * </pre>
 * @class components.common.ActionButton
 */
class ActionButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    allowFocus: PropTypes.bool,
    title: PropTypes.string,
  };

  componentWillUnmount() {
    this.touchHandler.dispose();
  }

  /**
   * Renders the action button object.
   * @function render
   * @memberof components.common.ActionButton
   * @instance
   */
  render() {
    const buttonClasses = cx({
      "common-button-action": true,
    }, this.props.className);

    this.touchHandler = new ClickTouchHandler(this.onClickSelf, true);

    return (
      <div
        data-tracking={this.props.trackingId}
        className={buttonClasses}
        tabIndex={this.props.allowFocus ? 0 : -1}
        aria-hidden={!this.props.allowFocus}
        role="button"
        id={this.props.id}
        title={this.props.title}
        aria-label={this.props.title}
        onKeyDown={this.touchHandler.handler}
        onClick={this.touchHandler.handler}
        onTouchStart={this.touchHandler.handler}
        onTouchMove={this.touchHandler.handler}
        onTouchEnd={this.touchHandler.handler}
        onTouchCancel={this.touchHandler.handler}
      >
        <div className="text">{this.props.children}</div>
      </div>
    );
  }

  /**
   * Action on click of the action button object.
   * @function onClickSelf
   * @memberof components.common.ActionButton
   * @instance
   */
  onClickSelf = (e) => {
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };
}

export default ActionButton;
