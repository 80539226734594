import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import cx from "classnames";

import Question from "./Question.react";
import DetailsButton from "../../common/DetailsButton.react";

/**
 * <pre>
 * Class to create the question list in the Application.
 * Package Name - components/pages/feedback
 * </pre>
 * @class components.pages.feedback.QuestionList
 */
const QuestionList = createReactClass({
  displayName: "QuestionList",
  answersExist: undefined,
  answersValid: undefined,
  questionComponents: undefined,

  propTypes: {
    id: PropTypes.string,
    currentId: PropTypes.string,
    stringList: PropTypes.object, // StringList
    form: PropTypes.object, // { questions: array of objects }
    enabled: PropTypes.bool,
    visible: PropTypes.bool,
    allowFocus: PropTypes.bool,
    clear: PropTypes.bool,
    onSubmit: PropTypes.func,
    onChangedFocusElement: PropTypes.func,
  },

  /**
   * Function for fetching the initial state.
   * @function getInitialState
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  getInitialState: function() {
    this.questionComponents = [];

    return {
      answers: {},
      currentQuestion: 0,
      needToScrollToQuestion: true,
      hasAnswer: false,
      isValid: false,
      hasChangedAnything: false,
    };
  },

  componentDidUpdate: function() {
    if (this.state.needToScrollToQuestion) {
      this.setState({ needToScrollToQuestion: false });
      if (this.props.onChangedFocusElement && this.props.id === this.props.currentId)
        this.props.onChangedFocusElement(ReactDOM.findDOMNode(this.questionComponents[this.state.currentQuestion]));
    }

    if (this.props.clear && this.state.hasChangedAnything) {
      this.setState(this.getInitialState());
    }

  },

  /**
   * Renders the question list object.
   * @function render
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  render: function() {
    const isCurrentList = this.props.id === this.props.currentId && this.props.visible;
    const classNames = cx("tab-body", {
      visible: isCurrentList,
      disabled: isCurrentList && !this.props.enabled,
    });

    return <div className={classNames}>
      {this.renderQuestions(isCurrentList)}
      <div className="footer">
        <DetailsButton
          allowFocus={true}
          className={cx("common-button-details-like-action", "button-submit", { disabled: !this.canSubmit() })}
          title={this.props.stringList.get("feedback-general-both-button-submit")}
          onClick={this.onClickSubmit}
        >
          {this.props.stringList.get("feedback-general-both-button-submit")}
        </DetailsButton>
      </div>
    </div>;
  },

  /**
   * Render Questions List.
   * @param  {boolean} isCurrentList
   * @function renderQuestions
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  renderQuestions: function(isCurrentList) {
    return this.props.form.questions.map((question, index, list) => {
      return this.renderQuestion(isCurrentList, index, list.length, question);
    });
  },

  /**
   * Renders Questions. Uses "Question" class to render a question. 
   * @param   {string} component
   * @param   {boolean} isCurrentList 
   * @param   {number} index
   * @param   {number} numQuestions       
   * @param   {object} question       
   * @function renderChooseQuestion
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
      renderQuestion: function(isCurrentList, index, numQuestions, question) {
        const isLast = index === this.props.form.questions.length - 1;
        const isExpanded = index === this.state.currentQuestion;
    
        return (
          <Question
            key={index}
            index={index}
            ref={(r) => (this.questionComponents[index] = r)}
            question={question}
            numQuestions={numQuestions}
            stringList={this.props.stringList}
            hasDivider={!isExpanded || !isLast}
            isExpanded={isExpanded}
            allowFocus={isCurrentList && this.props.allowFocus}
            onRequestExpand={this.onRequestedExpandQuestion}
            onChange={this.onChangedQuestionValue}
            onNextQuestion={this.onNextQuestion}
            value={this.getQuestionValue(question.id)}
          />
        );
      },

  /**
   * Fetches the question value given id. 
   * @param  {number} index
   * @function getQuestionValue
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  getQuestionValue: function(id) {
    return this.state && this.state.answers ? this.state.answers[id] : undefined;
  },

  /**
   * Toggles the question section on click. 
   * @param  {number} index
   * @function onRequestedExpandQuestion
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  onRequestedExpandQuestion: function(index) {
    this.setCurrentQuestion(index);
  },

  /**
   * Toggles the question section on click. 
   * @param  {string} id
   * @param  {string} value
   * @param  {boolean} multi
   * @function onChangedQuestionValue
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  onChangedQuestionValue: function(id, value, multi = false, remove = false) {
    if( multi ){
      const currAnswer = this.getQuestionValue(id) || [];
      if( remove ){
        value = currAnswer.filter( v => v != value );
      } else {
        value = [...currAnswer, value];
      }
    }
    
    this.setQuestionValue(id, value);
  },

  /**
   * Action on question list has been submitted.    
   * @function onClickSubmit
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  onClickSubmit: function() {
    if (this.props.onSubmit && this.canSubmit()) this.props.onSubmit(this.props.form, this.state.answers);
  },

  /**
   * Checks whether the answer can be submitted.    
   * @function canSubmit
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  canSubmit: function() {
    return this.hasAnswers() && this.requiredAnswered() && this.checkAllEmail();
  },

  /**
   * Checks at least one question has been answered
   * @function hasAnswers
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  hasAnswers: function() {
    return Object.keys( this.state.answers ).length > 0 && Object.keys( this.state.answers ).some( id => {
      if( Array.isArray(this.state.answers[id]) ){ 
        return this.state.answers[id].length > 0;
      }else{
        return this.state.answers[id] !== undefined && this.state.answers[id] !== null && this.state.answers[id] !== "";
      }
    });
  },

  /**
   * Checks whether all required questions have been answered
   * @function requiredAnswered
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  requiredAnswered: function() {
    const requiredAnswers = Object.keys( this.props.form.questions ).reduce( (acc, index) => {
      if( this.props.form.questions[index].required ){
        acc.push( this.props.form.questions[index].id );
      }
      return acc;
    }, [] );

    return requiredAnswers.every( id => this.state.answers[id] );
  },

  /**
   * Sets the current Question based on index. 
   * @param  {number} index         
   * @function setCurrentQuestion
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  setCurrentQuestion: function(index) {
    if (index >= 0 && index < this.props.form.questions.length) {
      this.setState({
        currentQuestion: index,
        needToScrollToQuestion: true,
      });
    }
  },


  /**
   * Validates the email fields and keeps the submit button disabled until they match the regex pattern    
   * @function checkAllEmail
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  checkAllEmail: function() {
    var trackerBool = true;
    this.props.form.questions.forEach(element => {
      const val = this.getQuestionValue(element.id);
      if(element.type === "email" && val !== undefined && val !== "" ) {
        const emailRegex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if(!emailRegex.test(val)) {
          trackerBool = false
        }
      }
    });
    if(trackerBool == true) {
      return true;
    } else {
      return false;
    }
  },


  /**
   * Sets the current Question value based on index. 
   * @param  {number} index  
   * @param  {number} value           
   * @function setQuestionValue
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
  setQuestionValue: function(id, value) {
    const oldAnswers = this.state.answers;
    const newAnswer = { [id]: value };
    this.setState({
      hasChangedAnything: true,
      answers: {...oldAnswers, ...newAnswer }
    });
  },

  /**
   * Action on question skipped. 
   * @param  {number} index     
   * @function onNextQuestion
   * @memberof components.pages.feedback.QuestionList
   * @instance
   */
    onNextQuestion: function(index) {
      this.setCurrentQuestion(index + 1);
    },

});

export default QuestionList;
