import PropTypes from "prop-types";
import React from "react";
import createReactClass from "create-react-class";

import FormFieldText from "../../common/FormFieldText.react";

/**
 * <pre>
 * Class to create the question segment on feedback page in the Application.
 * Package Name - components/pages/feedback
 * </pre>
 * @class components.pages.feedback.WriteTextQuestion
 */
const TextAreaQuestion = createReactClass({
  displayName: "TextAreaQuestion",

  propTypes: {
    question: PropTypes.object,
    stringList: PropTypes.object, // StringList
    allowFocus: PropTypes.bool,
    onChange: PropTypes.func, // index, value
    value: PropTypes.string,
  },

  /**
   * Renders the question object on feedback page.
   * @function render
   * @memberof components.pages.feedback.WriteTextQuestion
   * @instance
   */
  render: function() {
    return (
      <div className="common-form">
        <FormFieldText
          title={this.props.stringList.get("feedback-general-both-comments-field-comments-title")}
          maxLength={500}
          placeholder={this.props.stringList.get("feedback-general-both-comments-field-comments-placeholder")}
          multiline={true}
          value={this.props.value || ""}
          allowFocus={this.props.allowFocus}
          onChange={this.onChangeInput}
          name={this.props.question.id}
        />
      </div>
    );
  },

  /**
   * Action when question input has been changed.       
   * @function onChangeInput
   * @memberof components.pages.feedback.RateQuestion
   * @instance
   */
  onChangeInput: function(value){
    this.props.onChange( this.props.question.id, value )
  }

});

export default TextAreaQuestion;
