/**
 * <pre>
 * Class for an Effect Component in store from retrieved data. 
 * Package - stores/data. 
 * </pre>
 * @class stores.data.Effect 
 */
export default class Effect {
  // ================================================================================================================
  // CONSTRUCTOR ----------------------------------------------------------------------------------------------------

  /**
   * Creates An Object of Effect Component. 
   * @constructor    
   */    
  constructor() {
    this.tools = {}; // key: tool id, value: points added
  }
}
