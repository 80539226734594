import MarkdownUtils from "../utils/MarkdownUtils";

/**
 * CONSTANTS
 * Lists Some Constants For Strings. 
 * @enum {String} 
 * @memberof vendor.localization.StringList
 */      
export const CONSTANTS = {
  STRING_NOT_FOUND: "{NOT_FOUND}",
};

/**
 * <pre>
 * Class For Generating the Strings Based on Language And Localization Object
 * Package - vendor/localization. 
 * </pre>
 * @class vendor.localization.StringList
 */
export default class StringList {
  /*
	var a = new StringList(new Language(), {}, "other);
	a.addStrings({'test-1':'Test 1'});
	console.log("STR => " + a.get("test-1"));
	console.log("ONJ => ", StringList.getInstance().values, StringList.instances);
	console.log("STR => " + StringList.getInstance().get("test-1"));
	*/

  constructor(language, localizationObject, key) {
    // Adds self to the list of instances
    StringList.addInstance(key, this);

    // Language[] reference
    this.language = language;

    // Resets list
    this.values = {};

    // Adds values, if any
    if (localizationObject) this.addStrings(localizationObject);
  }

  /**
   * Adds an instance of string uing the "key" and "instance" pair. 
   * @param  {string} key  key for the instance
   * @param  {string} instance  instance to be added
   * @function addInstance 
   * @memberof vendor.localization.StringList
   */
  static addInstance(key, instance) {
    const newKey = key || "default";
    if (!this.instances) this.instances = {};
    this.instances[newKey] = instance;
  }

  /**
   * Retrieves an instance of string using the "key"
   * @param  {string} key  key for the instance
   * @returns {Object} instance instance to be retrieved using key
   * @function getInstance
   * @memberof vendor.localization.StringList
   */
  static getInstance(key) {
    const newKey = key || "default";
    if (!this.instances || !this.instances.hasOwnProperty(newKey)) {
      return new StringList(newKey);
    } else {
      return this.instances[newKey];
    }
  }

  /**
   * Add a list of values to the current list.
   * @param localizationObject An object containing a key|value object for each localization string 
   * @example 
   * { 'key' :  'value '} 
   * @function addStrings
   * @memberof vendor.localization.StringList  
   * @instance 
   */
  addStrings(localizationObject) {
    Object.assign(this.values, localizationObject);
  }

  /**
   * Returns the current language.
   * @returns A Language instance
   * @function getLanguage
   * @memberof vendor.localization.StringList   
   * @instance     
   */
  getLanguage() {
    return this.language;
  }

  /**
   * Returns the value from a key.
   * @param key   
   * @function get
   * @memberof vendor.localization.StringList 
   * @instance    
   */
  get(key) {
    if (this.values.hasOwnProperty(key)) return this.values[key];

    // Not found!
    console.warn(`Warning: no value found for localization key '${key}' in string list`); // eslint-disable-line

    // Give up
    return CONSTANTS.STRING_NOT_FOUND;
  }

  /**
   * Retrieves an array from the localization object using key
   * @param  {string} key  key for the instance
   * @returns {Array} value value of the key in localization object else not found.
   * @function getArray
   * @memberof vendor.localization.StringList
   * @instance    
   */
  getArray(key) {
    const value = this.get(key);
    if (Array.isArray(value)) {
      return value;
    } else if (typeof value === "string") {
      return value.split("\n");
    }

    // Not found!
    console.warn(`Warning: no value found for localization key '${key}' in string list as Array`); // eslint-disable-line

    // Give up
    return [CONSTANTS.STRING_NOT_FOUND];
  }

  /**
   * Retrieves an asset array from the localization object using key
   * @param  {string} key  key for the instance
   * @returns {Array} assets value of the assets matching key
   * @function getAssetArray
   * @memberof vendor.localization.StringList 
   * @instance    
   */
  getAssetArray(key) {
    // Based on a blob of text, returns a list of assets { src:string, title:string, link:string }
    // Assets must contain an image, description, and optionally a link
    const src = this.get(key);
    if (src) {
      // We search for images sequentially, using their links if they have any
      const REGEX_IMAGES_WITH_LINKS = /\[!\[(.*?)\]\((.*?)\)\]\((.*?)\)|!\[(.*?)\]\((.*?)\)/g;
      let match = REGEX_IMAGES_WITH_LINKS.exec(src);
      const assets = [];
      while (match) {
        if (match[2]) {
          // First match: image with link
          assets.push({
            title: match[1],
            src: match[2],
            link: match[3],
          });
        } else if (match[5]) {
          // Second match: image
          assets.push({
            title: match[4],
            src: match[5],
            link: undefined,
          });
        }
        match = REGEX_IMAGES_WITH_LINKS.exec(src);
      }
      return assets;
    }

    // Not found!
    console.warn(`Warning: no value found for localization key '${key}' in string list as asset Array`); // eslint-disable-line

    // Give up
    return [];
  }

  /**
   * Retrieves the link from the localization object using key
   * @param  {string} key 
   * @param  {string} tagName  
   * @param  {string} className  
   * @param  {string} linkClass        
   * @function getLink
   * @memberof vendor.localization.StringList 
   * @instance    
   */
  getLink(key, tagName, className, linkClass) {
    return MarkdownUtils.parseURL(this.get(key), tagName, className, linkClass);
  }

  /**
   * Retrieves the list of elements from the localization object using key
   * @param  {string} key 
   * @param  {string} ulClass  
   * @param  {string} liClass    
   * @function getList
   * @memberof vendor.localization.StringList 
   * @instance    
   */
  getList(key, ulClass, liClass) {
    const message = this.get(key);
    if (message === CONSTANTS.STRING_NOT_FOUND) return message;

    return MarkdownUtils.parseList(message, ulClass, liClass);
  }

  /**
   * Retrieves an boolean value from the localization object using key
   * @param  {string} key  key for the instance
   * @returns {Boolean} value value of the key if boolean else undefined
   * @function getBoolean
   * @memberof vendor.localization.StringList 
   * @instance    
   */
  getBoolean(key) {
    const val = this.get(key);
    if (typeof val === "boolean") return val;

    if (typeof val === "string") {
      if (val.toLowerCase() === "true") {
        return true;
      } else if (val.toLowerCase() === "false") {
        return false;
      }
    }

    console.warn(`Warning: the string "${val}" does not contain a valid boolean`); // eslint-disable-line
    return undefined;
  }
}
