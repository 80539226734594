import PropTypes from "prop-types";
import React from "react";

/**
 * <pre>
 * Class to create the question for rating in the Application.
 * Package Name - components/pages/feedback
 * </pre>
 * @class components.pages.feedback.RateQuestion
 */
class RateQuestion extends React.Component {
  static propTypes = {
    question: PropTypes.object, // Question
    stringList: PropTypes.object, // StringList
    onChange: PropTypes.func, // question.id, value
    value: PropTypes.string, // current value
  };

  /**
   * Renders the rate question object.
   * @function render
   * @memberof components.pages.feedback.RateQuestion
   * @instance
   */
  render() {
    return (
      <div className={"cards cards-rate"}>
        {this.props.question.answers.map((answer, index) => {
          const id = this.props.question.id + "--" + index;

          return (
            <div key={index} className={"inputPreview cards-card cards-card-rate"}>
              <input
                type="radio"
                className={"css-checkbox output-rate"}
                name={this.props.question.id}
                id={id}
                value={index}
                onChange={this.onChangeInput}
              />
              <label htmlFor={id}>{answer}</label>
            </div>
          );
        })}
      </div>
    );
  }

  /**
   * Action when question input has been changed.
   * @function onChangeInput
   * @memberof components.pages.feedback.RateQuestion
   * @instance
   */
  onChangeInput = (e) => {
    this.props.onChange(this.props.question.id, e.target.value);
  };
}

export default RateQuestion;
